import React from "react";
import { ReactComponent as Verify } from "../../assets/svg/verify.svg";
import "./PowerLevelSelector.scss";

const PowerLevelOptions = [
  { value: 100, label: "Admin", className: "PowerSelectorVerifyAdmin" },
  { value: 75, label: "Governor", className: "PowerSelectorVerifyGovernor" },
  { value: 50, label: "Moderator", className: "PowerSelectorVerifyModerator" },
  {
    value: 25,
    label: "Trusted Member",
    className: "PowerSelectorVerifyTrustedMember",
  },
  { value: 0, label: "Member", className: "PowerSelectorVerifyDefault" },
];

function PowerLevelItem({ powerLevel, isSelected, onSelect }) {
  return (
    <div
      onClick={() => onSelect(powerLevel.value)}
      className="power-level-item-container"
    >
      <div className="power-level-item-container-tag">
        <Verify className={powerLevel.className} />
      </div>
      <span className="power-level-item-selector-text">{powerLevel.label}</span>
      <div
        className={`create-room-selector-outer-circle${isSelected ? "" : ""}`}
      >
        <div
          className={`create-room-selector-inner-circle${
            isSelected ? "" : " inactive"
          }`}
        ></div>
      </div>
    </div>
  );
}

function PowerLevelSelector({ value, max, onSelect }) {
  return (
    <div className="power-level-item-container-wrapper">
      {PowerLevelOptions.map(
        (powerLevel) =>
          max >= powerLevel.value && (
            <PowerLevelItem
              key={powerLevel.value}
              powerLevel={powerLevel}
              isSelected={value === powerLevel.value}
              onSelect={onSelect}
            />
          )
      )}
    </div>
  );
}

export default PowerLevelSelector;
