import React from "react";

const NoChatIcon = ({ colorStyle, height, width }) => {
  const isGradient = colorStyle.startsWith("linear-gradient");

  let gradientColors = [];

  if (isGradient) {
    const colorRegex = /#([0-9a-f]{6}|[0-9a-f]{3})/gi;
    gradientColors = colorStyle.match(colorRegex);
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isGradient && gradientColors.length >= 2 && (
        <defs>
          <linearGradient id="iconGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
      )}
      <path
        d="M19,6v11.6l-2.4-1.3L16.1,16h-0.5H5V6H19 M19.2,4H4.8C3.8,4,3,4.9,3,6v10c0,1.1,0.8,2,1.8,2h10.8l5.4,3V6
C21,4.9,20.2,4,19.2,4L19.2,4z"
        fill={isGradient ? "url(#iconGradient)" : colorStyle}
      />
      <rect
        x="7"
        y="8"
        fill={isGradient ? "url(#iconGradient)" : colorStyle}
        width="10"
        height="2"
      />
      <rect
        x="7"
        y="12"
        fill={isGradient ? "url(#iconGradient)" : colorStyle}
        width="10"
        height="2"
      />
    </svg>
  );
};

export default NoChatIcon;
