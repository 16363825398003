import React from "react";
import LineProgress from "../../atoms/progress-line/LineProgress";
import "./ViewProposal.scss";

const VotingResults = ({ governanceData, tokenSymbol }) => {
  const {
    selectedOption,
    selectedStrategyOption,
    options = [],
    votes = [],
  } = governanceData;

  const totalVotingPower = votes.reduce(
    (acc, vote) => acc + parseFloat(vote.votingPower),
    0
  );

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return number % 1 === 0 ? number.toFixed(0) : number.toFixed(2);
  };

  if (selectedOption === "Weighted voting") {
    const optionWeights = votes[0]?.optionWeights || {};

    return (
      <div className="voting-results-container">
        {options.map((option) => {
          const weight = optionWeights[option.value] || 0;
          const apportionedVotingPower = (totalVotingPower * weight) / 100;

          return (
            <div key={option.value} className="voting-option-result">
              <div className="voting-option-header">
                <span className="voting-option-result-primary-text">
                  {option.value}
                </span>
                <div className="voting-option-header-results">
                  <span className="voting-option-result-secondary-text">
                    {formatNumber(apportionedVotingPower)}
                  </span>
                  <span className="voting-option-result-secondary-text">
                    {selectedStrategyOption === "ERC20 Balance"
                      ? tokenSymbol
                      : "ETH"}
                  </span>
                </div>
                <span className="voting-option-result-secondary-text">
                  {formatNumber(weight)}%
                </span>
              </div>
              <LineProgress progress={weight} />
            </div>
          );
        })}
      </div>
    );
  }

  const voteOptions = votes.reduce((acc, vote) => {
    const { selectedVoteOption, votingPower } = vote;

    if (!selectedVoteOption) return acc;

    if (!acc[selectedVoteOption]) {
      acc[selectedVoteOption] = 0;
    }
    acc[selectedVoteOption] += parseFloat(votingPower);

    return acc;
  }, {});

  if (
    selectedOption === "Single choice voting" ||
    selectedOption === "Basic voting"
  ) {
    return (
      <div className="voting-results-container">
        {options.map((option) => {
          const power = voteOptions[option.value] || 0;
          const percentage = totalVotingPower
            ? (power / totalVotingPower) * 100
            : 0;

          return (
            <div key={option.value} className="voting-option-result">
              <div className="voting-option-header">
                <span className="voting-option-result-primary-text">
                  {option.value}
                </span>
                <div className="voting-option-header-results">
                  <span className="voting-option-result-secondary-text">
                    {formatNumber(power)}
                  </span>
                  <span className="voting-option-result-secondary-text">
                    {selectedStrategyOption === "ERC20 Balance"
                      ? tokenSymbol
                      : "ETH"}
                  </span>
                </div>
                <span className="voting-option-result-secondary-text">
                  {formatNumber(percentage)}%
                </span>
              </div>
              <LineProgress progress={percentage} />
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default VotingResults;
