import React, { useState, useEffect, useRef } from "react";
import "./Message.scss";
import { twemojify } from "../../../util/twemojify";
import initMatrix from "../../../client/initMatrix";
import usernameColorMXID from "../../../util/usernameColorMXID";
import { sanitizeCustomHtml } from "../../../util/sanitize";
import Time from "../../atoms/time/Time";
import * as Media from "../media/Media";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { formatDisplayName } from "../../../util/formatDisplayName";
import Modal from "../../atoms/modal/Modal";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import ProfileViewerSettings from "../../organisms/profile-viewer/ProfileViewerSettings";
import MediaPopover from "../../atoms/media-popover/MediaPopover";
import { getBlobSafeMimeType } from "../../utils/mimeTypes";
import { Keyboard } from "@capacitor/keyboard";
import MessageOptions from "../../atoms/message-options/MessageOptions";
import MessageOptionList from "./MessageOptionList";
import { useMessagingOptions } from "../../contexts/MessagingOptionsContext";
import { useMessagingContext } from "../../contexts/MessagingProviderContext";
import { ReactComponent as MediaIcon } from "../../assets/svg/mediagallery.svg";
import { copyToClipboard } from "../../../util/common";
import { redactEvent } from "../../../client/action/roomTimeline";
import data from "@emoji-mart/data";
import { init, getEmojiDataFromNative } from "emoji-mart";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import {
  getCommunityScoresInSpace,
  updateCommunityMembershipScore,
} from "../../../util/membershipScore";
import CommunityScoreIcon from "../../assets/svg/CommunityScoreIcon";
import DOMPurify from "dompurify";

init({ data });

const MessageAvatar = React.memo(
  ({ roomId, avatarSrc, userId, isKeyboardVisible }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const avatarSelectRef = useRef(null);

    const handleAvatarClick = () => {
      if (document.activeElement) {
        document.activeElement.blur();
      }

      if (isKeyboardVisible) {
        Keyboard.hide().then(() => {
          setSelectedMember(userId);
          setIsModalOpen(true);
        });
      } else {
        setSelectedMember(userId);
        setIsModalOpen(true);
      }
    };

    const handleCloseModal = () => {
      setSelectedMember(null);
      setIsModalOpen(false);
    };

    useEffect(() => {
      if (avatarSelectRef.current) {
        const handleMouseDown = (e) => {
          e.preventDefault();
        };

        avatarSelectRef.current.addEventListener("mousedown", handleMouseDown);

        return () => {
          if (avatarSelectRef.current) {
            avatarSelectRef.current.removeEventListener(
              "mousedown",
              handleMouseDown
            );
          }
        };
      }
    }, [avatarSelectRef]);

    return (
      <div onClick={handleAvatarClick} ref={avatarSelectRef}>
        <UserAvatar size={28} userId={userId} imageSrc={avatarSrc} />
        {selectedMember && (
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ProfileViewerSettings
              userId={selectedMember}
              roomId={roomId}
              onClose={handleCloseModal}
            />
          </Modal>
        )}
      </div>
    );
  }
);

const MessageMain = React.memo(
  ({
    id,
    isCurrentMessenger,
    isBodyOnly,
    isDM,
    userId,
    body,
    timestamp,
    fullTime,
    mEvent,
    isKeyboardVisible,
    roomId,
    setRemovableEventId,
    reactions,
    onReactionSelect,
    room,
    mx,
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const eventSelectRef = useRef(null);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchStartY, setTouchStartY] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const touchTimerRef = useRef(null);
    const { startReplying, startEditing } = useMessagingOptions();
    const { messageId, actionType } = useMessagingContext();
    const [isHighlighted, setIsHighlighted] = useState(false);
    const eventId = mEvent.getId();
    const user = mx.getUser(userId);
    const { spaceId } = useRoomSelect();

    useEffect(() => {
      if (messageId === id && actionType === "both") {
        setIsHighlighted(true);

        setTimeout(() => {
          setIsHighlighted(false);
        }, 600);
      }
    }, [messageId, actionType, id]);

    const handleTouchStart = (e) => {
      e.preventDefault();
      setTouchStartX(e.touches[0].clientX);
      setTouchStartY(e.touches[0].clientY);
      setIsScrolling(false);

      touchTimerRef.current = setTimeout(() => {
        if (!isScrolling) {
          setIsModalOpen(true);
          Haptics.impact({ style: ImpactStyle.Medium });
          if (document.activeElement) {
            document.activeElement.blur();
          }
          if (isKeyboardVisible) {
            Keyboard.hide();
          }
        }
      }, 400);
    };

    const handleTouchMove = (e) => {
      const moveX = e.touches[0].clientX;
      const moveY = e.touches[0].clientY;

      if (
        Math.abs(moveX - touchStartX) > 50 ||
        Math.abs(moveY - touchStartY) > 50
      ) {
        setIsScrolling(true);
        if (touchTimerRef.current) {
          clearTimeout(touchTimerRef.current);
        }
      }
    };

    const handleTouchEnd = (e) => {
      if (touchTimerRef.current) {
        clearTimeout(touchTimerRef.current);
        touchTimerRef.current = null;
      }
    };

    const handleOptionSelect = async ({ option, mEvent, userId, body }) => {
      if (option === "Reply") {
        startReplying({ mEvent, userId, body });
      } else if (option === "Copy") {
        copyToClipboard(body);
      } else if (option === "Delete") {
        await redactEvent(mEvent.event.room_id, mEvent.event.event_id);
        setRemovableEventId(mEvent.event.event_id);
      } else if (option === "Edit") {
        startEditing({ mEvent, userId, body });
      }
      setIsModalOpen(false);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
      if (eventSelectRef.current) {
        const handleMouseDown = (e) => {
          e.preventDefault();
        };

        eventSelectRef.current.addEventListener("mousedown", handleMouseDown);

        return () => {
          if (eventSelectRef.current) {
            eventSelectRef.current.removeEventListener(
              "mousedown",
              handleMouseDown
            );
          }
        };
      }
    }, [eventSelectRef]);

    return (
      <div
        className={`message-main-container ${
          isCurrentMessenger
            ? isHighlighted
              ? "current-messenger-highlighted"
              : "current-messenger"
            : isHighlighted
            ? "highlighted"
            : ""
        } no-touch`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onContextMenu={(e) => e.preventDefault()} // Prevent context menu
        ref={eventSelectRef}
      >
        {!isCurrentMessenger && !isBodyOnly && !isDM && (
          <MessageHeader user={user} userId={userId} mx={mx} />
        )}
        <MessageBody
          body={body}
          timestamp={timestamp}
          fullTime={fullTime}
          mEvent={mEvent}
          isCurrentMessenger={isCurrentMessenger}
          mx={mx}
        />
        {reactions && reactions.length > 0 && (
          <MessageReaction
            reactions={reactions}
            isCurrentMessenger={isCurrentMessenger}
            mEvent={mEvent}
            roomId={roomId}
            onReactionSelect={onReactionSelect}
            mx={mx}
            spaceId={spaceId}
          />
        )}
        <MessageOptions
          touchX={touchStartX}
          touchY={touchStartY}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        >
          <MessageOptionList
            onOptionSelect={handleOptionSelect}
            onReactionSelect={onReactionSelect}
            body={body}
            timestamp={timestamp}
            userId={userId}
            isCurrentMessenger={isCurrentMessenger}
            isBodyOnly={isBodyOnly}
            isDM={isDM}
            mEvent={mEvent}
            messageOptions={true}
            roomId={roomId}
            reactions={reactions}
            room={room}
            mx={mx}
            setIsModalOpen={setIsModalOpen}
          />
        </MessageOptions>
      </div>
    );
  }
);

const MessageHeader = ({ user, userId, mx }) => {
  const { spaceId } = useRoomSelect();
  const userScore = getCommunityScoresInSpace(mx, spaceId, userId);
  const displayName = user && user.displayName ? user.displayName : userId;
  const colorStyle = usernameColorMXID(userId);
  const isGradient = colorStyle.startsWith("linear-gradient");
  const textStyle = isGradient
    ? {
        background: colorStyle,
        WebkitBackgroundClip: "text",
        color: "transparent",
      }
    : { color: colorStyle };

  return (
    <div className="message__header no-touch">
      <span style={textStyle} className="message-user-text">
        {formatDisplayName(displayName)}
      </span>
      {userScore !== null && (
        <div className="message-header-user-score">
          <CommunityScoreIcon colorStyle={colorStyle} height={14} width={14} />
          <span className="message-header-user-score-text" style={textStyle}>
            {userScore}
          </span>
        </div>
      )}
    </div>
  );
};

const MessageReaction = ({
  reactions,
  isCurrentMessenger,
  mEvent,
  roomId,
  onReactionSelect,
  mx,
  spaceId,
}) => {
  const [emojiData, setEmojiData] = useState({});
  const eventId = mEvent.getId();
  const currentUserId = mx.getUserId();

  // Function to convert Unicode to native emoji
  const getNativeEmojiFromUnicode = (unicode) => {
    return String.fromCodePoint(
      ...unicode
        .split("U+")
        .filter(Boolean)
        .map((hex) => parseInt(hex, 16))
    );
  };

  // Fetch and map emoji data
  const fetchEmojiData = async (unicode) => {
    const nativeEmoji = getNativeEmojiFromUnicode(unicode);
    try {
      const data = await getEmojiDataFromNative(nativeEmoji, "native");
      return data ? data.native : nativeEmoji;
    } catch (error) {
      return nativeEmoji;
    }
  };

  // Fetch emoji data for all reactions
  useEffect(() => {
    const fetchData = async () => {
      const emojiResults = {};

      for (const reaction of reactions) {
        const { key } = reaction;
        const emojiResult = await fetchEmojiData(key);
        emojiResults[key] = emojiResult;
      }

      setEmojiData(emojiResults);
    };

    fetchData();
  }, [reactions]);

  const handleReactionClick = (unicode) => {
    onReactionSelect({ reaction: unicode, targetEvent: mEvent });
  };

  return (
    <div className="message-reaction-component">
      {reactions.map((reaction) => {
        const { key, count, users } = reaction;
        const isMyReaction = users.includes(currentUserId);
        let reactionClass = "";

        if (isCurrentMessenger && isMyReaction) {
          reactionClass = "reaction-current-messenger-mine";
        } else if (isCurrentMessenger && !isMyReaction) {
          reactionClass = "reaction-current-messenger-other";
        } else if (!isCurrentMessenger && isMyReaction) {
          reactionClass = "reaction-other-messenger-mine";
        } else {
          reactionClass = "reaction-other-messenger-other";
        }

        return (
          <div
            key={key}
            className={`message-reaction-item ${reactionClass}`}
            onClick={() => handleReactionClick(key)}
          >
            <span className="emoji-icon-span">
              {emojiData[key] || getNativeEmojiFromUnicode(key)}
            </span>
            {count > 1 && (
              <span className="message-reaction-item-count-text">{count}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

const MessageReplyContent = React.memo(
  ({ replyInfo, replyEventId, onFocus, isCurrentMessenger, mx }) => {
    if (!replyInfo) return null;
    const mediaMxc = replyInfo.reply_to_content.url;
    const userId = replyInfo.reply_to_sender;
    const user = mx.getUser(userId);

    const mediaUrlToMedia =
      mediaMxc && mediaMxc !== "null"
        ? mx.mxcUrlToHttp(mediaMxc, 40, 40, "crop")
        : null;

    return (
      <div
        onClick={() => onFocus(replyEventId)}
        className={`message-reply-content-container ${
          isCurrentMessenger ? "current-messenger" : ""
        } `}
      >
        {!mediaUrlToMedia ? (
          <div className="room-input-message-reply-text-container">
            <MessageHeader userId={userId} user={user} mx={mx} />
            <span className="room-input-reply-text">
              {replyInfo.reply_to_content.body}
            </span>
          </div>
        ) : (
          <div className="message-reply-media-container-reply">
            <div className="room-input-message-reply-text-container">
              <MessageHeader userId={userId} user={user} mx={mx} />
              <div className="room-input-message-reply-media-container-wrapper ">
                <MediaIcon className="room-input-reply-close-svglogo" />
                <span className="room-input-reply-media-text">Photo</span>
              </div>
            </div>
            <div>
              <div className="message-reply-media-container">
                <img src={mediaUrlToMedia} alt="Media content" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

const MessageBody = ({
  body,
  timestamp,
  fullTime,
  mEvent,
  isCurrentMessenger,
  mx,
  isMessageReaction,
}) => {
  const isMediaMessage = typeof body !== "string";
  const [isMediaPopoverOpen, setIsMediaPopoverOpen] = useState(false);
  const { setEventAction } = useMessagingContext();

  const normalizeForComparison = (str) => {
    if (str == null) return "";

    str = String(str);

    return str
      .replace(/<br\s*\/?>/gi, "\n") // Replace <br> tags with newlines
      .replace(/<\/?[^>]+(>|$)/g, "") // Remove any remaining HTML tags
      .replace(/\s+/g, " ") // Normalize whitespace to a single space
      .trim(); // Trim leading and trailing whitespace
  };

  const isEdited =
    normalizeForComparison(body) !==
    normalizeForComparison(mEvent?.event?.content?.body);

  const handleMediaClick = () => {
    setIsMediaPopoverOpen(true);
  };

  const handleReplyEventFocus = (id) => {
    setEventAction(id, "both");
  };

  const content = mEvent.event?.content;
  const isReply = content?.["m.relates_to"]?.["m.in_reply_to"];
  const replyInfo = content["reply_info"];

  const detectMentions = (text) => {
    if (typeof text !== "string") {
      return null;
    }
    const mentionPattern = /(@\w+(\.\.\.\w+)?)/g;
    return text.match(mentionPattern);
  };

  let renderedContent = null;

  if (!isMediaMessage) {
    let sanitizedContent = DOMPurify.sanitize(body, {
      ALLOWED_TAGS: ["br", "strong", "em", "span"],
      ALLOWED_ATTR: ["class"],
    });

    sanitizedContent = twemojify(
      sanitizedContent,
      undefined,
      true,
      false,
      true,
      isCurrentMessenger
    );

    const mentions = detectMentions(sanitizedContent);
    const renderedBodyContent = mentions
      ? sanitizedContent.split(/(@\w+(?:\.\.\.\w+)?)/g).map((part, index) => {
          if (part.startsWith("@")) {
            return (
              <span key={index} className="mention">
                {part}
              </span>
            );
          }
          return part;
        })
      : sanitizedContent;

    renderedContent = (
      <span
        className={`message-body-text ${
          isMessageReaction ? "message-body-text-is-reply" : ""
        } no-touch`}
      >
        {renderedBodyContent}
        {isEdited
          ? "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
          : ""}
        {"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
        <div className="message-body-time-text-container">
          {isEdited && (
            <span className="message-time-text-nomedia no-touch">Edited</span>
          )}
          <span className="message-time-text no-touch">
            <Time timestamp={timestamp} fullTime={fullTime} />
          </span>
        </div>
      </span>
    );
  } else {
    renderedContent = (
      <div
        onClick={handleMediaClick}
        onContextMenu={(e) => e.preventDefault()}
        className="media-message-container"
      >
        <div className="media-message-wrapper">{body}</div>
        <span className="message-time-text no-touch">
          <Time timestamp={timestamp} fullTime={fullTime} />
        </span>
      </div>
    );
  }

  return (
    <div
      className={`message-body-container ${
        isMediaMessage ? "media-message" : ""
      } no-touch`}
    >
      <div className="message-body-container-text">
        <div className="message-body-rendered-content">
          {isReply && (
            <MessageReplyContent
              replyInfo={replyInfo}
              replyEventId={mEvent.replyEventId}
              onFocus={handleReplyEventFocus}
              isCurrentMessenger={isCurrentMessenger}
              mx={mx}
            />
          )}
          <div className="message-body-rendered-content-container">
            {renderedContent}
          </div>
        </div>
      </div>
      {isMediaPopoverOpen && (
        <MediaPopover
          isOpen={isMediaPopoverOpen}
          onClose={() => setIsMediaPopoverOpen(false)}
          body={body}
        >
          {body}
        </MediaPopover>
      )}
    </div>
  );
};

function isMedia(mE) {
  return (
    mE.getContent()?.msgtype === "m.file" ||
    mE.getContent()?.msgtype === "m.image" ||
    mE.getContent()?.msgtype === "m.audio" ||
    mE.getContent()?.msgtype === "m.video" ||
    mE.getType() === "m.sticker"
  );
}

function genMediaContent(mE, mx) {
  const mContent = mE.getContent();

  let mediaMXC = mContent.url;
  const isEncryptedFile = typeof mediaMXC === "undefined";
  if (isEncryptedFile) {
    mediaMXC = mContent?.file?.url;
  } else {
  }

  let thumbnailMXC = mContent?.info?.thumbnail_url;

  if (typeof mediaMXC === "undefined" || mediaMXC === "") {
    return <span style={{ color: "var(--bg-danger)" }}>Malformed event</span>;
  }

  let msgType = mContent.msgtype;

  const safeMimetype = getBlobSafeMimeType(mContent.info?.mimetype);

  if (mE.getType() === "m.sticker") {
    msgType = "m.sticker";
  } else if (safeMimetype === "application/octet-stream") {
    msgType = "m.file";
  }

  const blurhash = mContent.info?.blurhash;

  switch (msgType) {
    case "m.file":
      return (
        <Media.File
          name={mContent.body || "File"}
          link={mx.mxcUrlToHttp(mediaMXC)}
          type={mContent.info?.mimetype}
          file={mContent.file || null}
        />
      );
    case "m.image":
      return (
        <Media.Image
          name={mContent.body || "Image"}
          width={typeof mContent.info?.w === "number" ? mContent.info?.w : null}
          height={
            typeof mContent.info?.h === "number" ? mContent.info?.h : null
          }
          link={mx.mxcUrlToHttp(mediaMXC)}
          file={isEncryptedFile ? mContent.file : null}
          type={mContent.info?.mimetype}
          blurhash={blurhash}
        />
      );
    case "m.sticker":
      return (
        <Media.Sticker
          name={mContent.body || "Sticker"}
          width={typeof mContent.info?.w === "number" ? mContent.info?.w : null}
          height={
            typeof mContent.info?.h === "number" ? mContent.info?.h : null
          }
          link={mx.mxcUrlToHttp(mediaMXC)}
          file={isEncryptedFile ? mContent.file : null}
          type={mContent.info?.mimetype}
        />
      );
    case "m.audio":
      return (
        <Media.Audio
          name={mContent.body || "Audio"}
          link={mx.mxcUrlToHttp(mediaMXC)}
          type={mContent.info?.mimetype}
          file={mContent.file || null}
        />
      );
    case "m.video":
      if (typeof thumbnailMXC === "undefined") {
        thumbnailMXC = mContent.info?.thumbnail_file?.url || null;
      }
      return (
        <Media.Video
          name={mContent.body || "Video"}
          link={mx.mxcUrlToHttp(mediaMXC)}
          thumbnail={
            thumbnailMXC === null ? null : mx.mxcUrlToHttp(thumbnailMXC)
          }
          thumbnailFile={isEncryptedFile ? mContent.info?.thumbnail_file : null}
          thumbnailType={mContent.info?.thumbnail_info?.mimetype || null}
          width={typeof mContent.info?.w === "number" ? mContent.info?.w : null}
          height={
            typeof mContent.info?.h === "number" ? mContent.info?.h : null
          }
          file={isEncryptedFile ? mContent.file : null}
          type={mContent.info?.mimetype}
          blurhash={blurhash}
        />
      );
    default:
      return <span style={{ color: "var(--bg-danger)" }}>Malformed event</span>;
  }
}

function Message({
  id,
  mEvent,
  isBodyOnly,
  fullTime,
  isKeyboardVisible,
  isCurrentMessenger,
  reactions,
  onReactionSelect,
  room,
  mx,
}) {
  const roomId = mEvent.getRoomId();
  const senderId = mEvent.getSender();
  const user = mx.getUser(senderId);
  const content = mEvent.getContent();
  const isDM = initMatrix.roomList.directs.has(roomId);
  const avatarSrc =
    user && user.avatarUrl
      ? mx.mxcUrlToHttp(user.avatarUrl, 36, 36, "crop")
      : null;
  const isCustomHTML = content.format === "org.matrix.custom.html";
  const messageContent = isMedia(mEvent)
    ? genMediaContent(mEvent, mx)
    : isCustomHTML
    ? content.formatted_body
    : content.body;
  const messageTimestamp = mEvent.getTs();
  const [removableEventId, setRemovableEventId] = useState(null);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (removableEventId) {
      const timer = setTimeout(() => {
        setIsHidden(true);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [removableEventId]);

  return (
    <div
      id={id}
      className={`message-container ${
        isCurrentMessenger ? "current-messenger" : ""
      } ${removableEventId ? "removable" : ""} ${
        isHidden ? "hidden" : ""
      } no-touch`}
    >
      <div className="message-wrapper">
        {!isCurrentMessenger &&
          !isDM &&
          (isBodyOnly ? (
            <div className="message-avatar-container" />
          ) : (
            <MessageAvatar
              roomId={roomId}
              avatarSrc={avatarSrc}
              userId={senderId}
              isKeyboardVisible={isKeyboardVisible}
            />
          ))}
        <MessageMain
          id={id}
          isCurrentMessenger={isCurrentMessenger}
          isBodyOnly={isBodyOnly}
          isDM={isDM}
          userId={senderId}
          body={messageContent}
          timestamp={messageTimestamp}
          fullTime={fullTime}
          mEvent={mEvent}
          isKeyboardVisible={isKeyboardVisible}
          roomId={roomId}
          setRemovableEventId={setRemovableEventId}
          reactions={reactions}
          onReactionSelect={onReactionSelect}
          room={room}
          mx={mx}
        />
      </div>
    </div>
  );
}

export { Message, MessageBody, MessageHeader };
