export const calculateTimeLeft = (startTimestamp, endTimestamp) => {
  const now = Math.floor(Date.now() / 1000);
  let delta = startTimestamp - now;
  let timeLeftString = "";

  if (delta > 0) {
    // Before event starts
    if (delta < 60) {
      timeLeftString = `${delta} secs`;
    } else if (delta >= 3600) {
      const hours = Math.floor(delta / 3600);
      const minutes = Math.floor((delta % 3600) / 60);
      timeLeftString = `${hours}h ${minutes.toString().padStart(2, "0")}m`;
    } else {
      const minutes = Math.floor(delta / 60);
      const seconds = delta % 60;
      timeLeftString = `${minutes}m ${seconds.toString().padStart(2, "0")}s`;
    }
  } else if (now < endTimestamp) {
    // During the event
    delta = endTimestamp - now; // Time until end
    if (delta < 60) {
      timeLeftString = `${delta} secs`;
    } else if (delta >= 3600) {
      const hours = Math.floor(delta / 3600);
      const minutes = Math.floor((delta % 3600) / 60);
      timeLeftString = `${hours}h ${minutes.toString().padStart(2, "0")}m`;
    } else {
      const minutes = Math.floor(delta / 60);
      const seconds = delta % 60;
      timeLeftString = `${minutes}m ${seconds.toString().padStart(2, "0")}s`;
    }
  } else {
    timeLeftString = "Ended";
  }

  return timeLeftString;
};
