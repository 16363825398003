import React, { useState } from "react";
import "./WalletSettings.scss";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";

const Password = ({ onSubmit }) => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = () => {
    setLoading(true);

    if (password.trim() === "") {
      setErrorMessage("Password cannot be empty.");
      setLoading(false);
      return;
    }

    if (onSubmit) {
      onSubmit(password);
    }

    setLoading(false);
  };

  return (
    <div className="password-container">
      <div className="header">
        <span className="wallet-settings-sp-tertiary-text">Enter Password</span>
      </div>
      <CustomInput
        placeholder="Password"
        type="password"
        onChange={handlePasswordChange}
        autoFocus={[true, 300]}
        value={password}
      />
      <div
        className="create-container-button-create"
        onClick={handleSubmit}
        disabled={loading}
      >
        {!loading && <span className="create-room-button-text">Submit</span>}
        {loading && (
          <Loader
            size="24px"
            dotSize="6px"
            color="var(--dark)"
            multiplier={1.4}
          />
        )}
      </div>
      {errorMessage && (
        <div className="create-room-error-text">{errorMessage}</div>
      )}
    </div>
  );
};

export default Password;
