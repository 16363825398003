import { useState, useEffect, useCallback } from "react";
import { Keyboard } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";

const useKeyboard = () => {
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const hideKeyboard = useCallback(() => {
    Keyboard.hide();
  }, []);

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") {
      return;
    }

    Keyboard.setAccessoryBarVisible({ isVisible: false });
    Keyboard.setResizeMode({ mode: "body" });

    const handleKeyboardShow = (info) => {
      setKeyboardVisible(true);
      setKeyboardHeight(info.keyboardHeight);
    };

    const handleKeyboardHide = () => {
      setKeyboardVisible(false);
      setKeyboardHeight(0);
    };

    const showListener = Keyboard.addListener(
      "keyboardWillShow",
      handleKeyboardShow
    );
    const didShowListener = Keyboard.addListener(
      "keyboardDidShow",
      handleKeyboardShow
    );
    const hideListener = Keyboard.addListener(
      "keyboardWillHide",
      handleKeyboardHide
    );
    const didHideListener = Keyboard.addListener(
      "keyboardDidHide",
      handleKeyboardHide
    );

    return () => {
      showListener.remove();
      didShowListener.remove();
      hideListener.remove();
      didHideListener.remove();
    };
  }, []);

  return {
    keyboardVisible,
    keyboardHeight,
    hideKeyboard,
  };
};

export default useKeyboard;
