import React from "react";
import PropTypes from "prop-types";
import dateFormat from "dateformat";

function Time({ timestamp }) {
  const date = new Date(timestamp);
  const formattedTime = dateFormat(date, "HH:MM");

  return (
    <time dateTime={date.toISOString()} title={formattedTime}>
      {formattedTime}
    </time>
  );
}

Time.propTypes = {
  timestamp: PropTypes.number.isRequired,
};

export default Time;
