import React, { useState } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import RoomSettings from "../settings/RoomSettings";
import { ReactComponent as RightArrow } from "../../assets/svg/right-arrow.svg";
import "./RoomViewHeader.scss";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import Popover from "../../atoms/popover/Popover";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { ReactComponent as Settings } from "../../assets/svg/settings-thick.svg";
import Modal from "../../atoms/modal/Modal";
import ProfileViewerSettings from "../profile-viewer/ProfileViewerSettings";

function RoomViewHeader({ room }) {
  const mx = initMatrix.matrixClient;
  const [openRoomSettingsDialog, setOpenRoomSettingsDialog] = useState(false);
  const handleOpenDialogWithTab = () => {
    setOpenRoomSettingsDialog(true);
  };

  let userId =
    room && room.summaryHeroes && room.summaryHeroes.length > 0
      ? room.summaryHeroes[0]
      : null;
  const isDM = initMatrix.roomList.directs.has(room.roomId);
  const avatarUrl =
    room.getAvatarUrl(initMatrix.matrixClient.baseUrl, 42, 42, "crop") || null;
  let avatarSrc = room.getAvatarUrl(mx.baseUrl, 36, 36, "crop");
  avatarSrc = isDM
    ? room.getAvatarFallbackMember()?.getAvatarUrl(mx.baseUrl, 36, 36, "crop")
    : avatarSrc;
  let username = "";
  if (room && room.summaryHeroes && room.summaryHeroes.length > 0) {
    let rawUsername = room.summaryHeroes[0];
    username = rawUsername.slice(1, rawUsername.indexOf(":")).toUpperCase();
  }

  const { setRoomId, setDmRoomId, selectedTab } = useRoomSelect();
  const handleRoomClose = () => {
    if (selectedTab === "home" || selectedTab === "discovery") {
      setRoomId(null);
    } else if (selectedTab === "directmessages") {
      setRoomId(null);
      setDmRoomId(null);
    }
  };

  const [selectedMember, setSelectedMember] = useState(null);

  const toggleModal = (userId) => {
    setSelectedMember(userId);
  };
  const getOnClickHandler = (
    isDM,
    userId,
    toggleModal,
    handleOpenDialogWithTab
  ) => {
    return isDM ? () => toggleModal(userId) : () => handleOpenDialogWithTab();
  };

  return (
    <div className="room-view-header-container">
      <div className="room-view-header-wrapper">
        <div className="back-navigation-container">
          <div
            className="back-navigation-button-container"
            onClick={handleRoomClose}
          >
            <RightArrow className="back-navigation-svglogo" />
          </div>
        </div>
        <div
          className="room-view-header-profile"
          onClick={getOnClickHandler(
            isDM,
            userId,
            toggleModal,
            handleOpenDialogWithTab
          )}
        >
          {room && room.summaryHeroes && room.summaryHeroes.length === 1 ? (
            <UserAvatar size={28} userId={userId} imageSrc={avatarUrl} />
          ) : (
            <RoomAvatar
              roomId={room.roomId}
              imageSrc={avatarUrl}
              text={room.name}
              size={32}
              borderRadius={6}
            />
          )}
          <span className="room-view-header-text">
            {isDM ? formatDisplayName(room.name) : room.name}
          </span>
        </div>
        {isDM && (
          <div
            className="spaceview-settings-container"
            onClick={() => handleOpenDialogWithTab()}
          >
            <Settings className="space-settings-svglogo" />
          </div>
        )}
      </div>
      {selectedMember && (
        <Modal
          isOpen={!!selectedMember}
          onClose={() => setSelectedMember(null)}
        >
          <ProfileViewerSettings
            userId={userId}
            roomId={room.roomId}
            onClose={() => setSelectedMember(null)}
          />
        </Modal>
      )}
      {openRoomSettingsDialog && (
        <Popover
          setOpenDialog={setOpenRoomSettingsDialog}
          direction="right"
          headerText={isDM ? "DM Settings" : "Room Settings"}
        >
          <RoomSettings
            roomId={room.roomId}
            setOpenRoomSettingsDialog={setOpenRoomSettingsDialog}
          />
        </Popover>
      )}
    </div>
  );
}

export default RoomViewHeader;
