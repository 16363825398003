import React from "react";

const ArrowIcon = ({ width, height, stroke, strokeWidth, rotate, opacity }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={{ transform: `rotate(${rotate}deg)`, opacity: opacity }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20L16 12L8 4"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowIcon;
