import { ethers } from "ethers";

const getProvider = async () => {
  const alchemyApiKey = import.meta.env.VITE_ALCHEMY_API;
  const network = import.meta.env.VITE_DEFAULT_CHAIN;
  const networks = {
    mainnet: `https://eth-mainnet.g.alchemy.com/v2/${alchemyApiKey}`,
    sepolia: `https://eth-sepolia.g.alchemy.com/v2/${alchemyApiKey}`,
  };
  const providerUrl = networks[network];
  if (!providerUrl) {
    throw new Error("Unsupported network");
  }
  const provider = new ethers.JsonRpcProvider(providerUrl);
  return { provider };
};

export default getProvider;
