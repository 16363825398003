import React from "react";

const NoNotificationIcon = ({ colorStyle, height, width }) => {
  const isGradient = colorStyle.startsWith("linear-gradient");

  let gradientColors = [];

  if (isGradient) {
    const colorRegex = /#([0-9a-f]{6}|[0-9a-f]{3})/gi;
    gradientColors = colorStyle.match(colorRegex);
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isGradient && gradientColors.length >= 2 && (
        <defs>
          <linearGradient id="iconGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
      )}
      <path
        d="M21.5619 14.1711L20.2422 11.9528C19.9502 11.469 19.6933 10.5369 19.6933 9.9705V7.76401C19.6933 3.48083 16.248 0 12.0202 0C7.78079 0.0117994 4.33551 3.48083 4.33551 7.76401V9.9587C4.33551 10.5251 4.07858 11.4572 3.79828 11.941L2.47857 14.1593C1.97638 15.0206 1.85959 16 2.17492 16.8496C2.49025 17.7109 3.20266 18.3953 4.13697 18.7021C5.39829 19.1268 6.67129 19.4336 7.96765 19.6578C8.09612 19.6814 8.22458 19.6932 8.35305 19.7168C8.51655 19.7404 8.69174 19.764 8.86692 19.7876C9.17057 19.8348 9.47422 19.8702 9.78955 19.8938C10.5253 19.9646 11.2728 20 12.0202 20C12.756 20 13.4918 19.9646 14.2159 19.8938C14.4845 19.8702 14.7531 19.8466 15.01 19.8112C15.2202 19.7876 15.4305 19.764 15.6407 19.7286C15.7691 19.7168 15.8976 19.6932 16.0261 19.6696C17.3341 19.4572 18.6305 19.1268 19.8918 18.7021C20.7911 18.3953 21.4801 17.7109 21.8071 16.8378C22.1341 15.9528 22.0407 14.9853 21.5619 14.1711ZM12.8728 9.38053C12.8728 9.87611 12.4757 10.2773 11.9852 10.2773C11.4947 10.2773 11.0976 9.87611 11.0976 9.38053V5.72271C11.0976 5.22714 11.4947 4.82596 11.9852 4.82596C12.4757 4.82596 12.8728 5.22714 12.8728 5.72271V9.38053Z"
        fill={isGradient ? "url(#iconGradient)" : colorStyle}
      />
      <path
        d="M15 22.01C14.554 23.17 13.3752 24 11.9947 24C11.1558 24 10.3274 23.68 9.74336 23.11C9.40354 22.81 9.14867 22.41 9 22C9.13805 22.02 9.27611 22.03 9.42478 22.05C9.66903 22.08 9.92389 22.11 10.1788 22.13C10.7841 22.18 11.4 22.21 12.0159 22.21C12.6212 22.21 13.2265 22.18 13.8212 22.13C14.0442 22.11 14.2673 22.1 14.4796 22.07C14.6496 22.05 14.8195 22.03 15 22.01Z"
        fill={isGradient ? "url(#iconGradient)" : colorStyle}
      />
    </svg>
  );
};

export default NoNotificationIcon;
