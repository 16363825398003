import React, { useState, useEffect } from "react";
import { useEnsName, useAccount } from "wagmi";
import initMatrix from "../../../client/initMatrix";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";
import "./DisplayName.scss";
import { ReactComponent as Tick } from "../../assets/svg/tick.svg";
import { formatDisplayName } from "../../../util/formatDisplayName";

const DisplayName = () => {
  const mx = initMatrix.matrixClient;
  const userId = mx.getUserId();
  const user = mx.getUser(userId);
  const displayName = user.displayName;
  const { address } = useAccount();
  const { data: ensName } = useEnsName({
    address: address,
    chainId: 1,
  });
  const [customName, setCustomName] = useState("");
  const [tempCustomName, setTempCustomName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
  const [isLoadingENS, setIsLoadingENS] = useState(false);
  const [isLoadingCustom, setIsLoadingCustom] = useState(false);
  const [error, setError] = useState("");
  const formatUserId = (userId) => userId.match(/@([^:]+)/)[1];
  const formattedUserId = formatUserId(userId);

  useEffect(() => {
    if (displayName === formattedUserId || displayName === userId) {
      setSelectedOption("primary");
      setCustomName(userId);
    } else if (displayName === ensName) {
      setSelectedOption("ens");
      setCustomName(ensName);
    } else {
      setSelectedOption("custom");
      setCustomName(displayName);
    }
  }, [displayName, ensName, userId]);

  const handleCustomNameChange = (e) => {
    setTempCustomName(e.target.value);
    setError("");
  };

  const validateCustomName = (name) => {
    const regex = /^[^\s]{1,22}$/;
    if (!regex.test(name)) {
      if (name.includes(" ")) {
        return "Display name should not contain spaces.";
      } else if (name.length > 22) {
        return "Display name should not exceed 22 characters.";
      } else {
        return "Display name can contain letters, numbers, and special characters, but no spaces.";
      }
    }
    return "";
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const saveCustomDisplayName = async () => {
    const validationError = validateCustomName(tempCustomName);
    if (validationError) {
      setError(validationError);
      return;
    }

    setSelectedOption("");
    setIsLoadingCustom(true);
    try {
      await mx.setProfileInfo("displayname", { displayname: tempCustomName });
      setSelectedOption("custom");
      setCustomName(tempCustomName);
    } catch (error) {
      console.error("Error setting display name:", error);
      setError("Failed to set custom display name.");
    } finally {
      setIsLoadingCustom(false);
    }
  };

  const savePrimaryDisplayName = async () => {
    setSelectedOption("");
    setIsLoadingPrimary(true);
    try {
      mx.setProfileInfo("displayname", { displayname: userId });
      await delay(1000);
      setSelectedOption("primary");
      setCustomName(userId);
    } catch (error) {
      console.error("Error setting display name:", error);
    } finally {
      setIsLoadingPrimary(false);
    }
  };

  const saveENSDisplayName = async () => {
    setSelectedOption("");
    setIsLoadingENS(true);
    try {
      mx.setProfileInfo("displayname", { displayname: ensName });
      await delay(1000);
      setSelectedOption("ens");
      setCustomName(ensName);
    } catch (error) {
      console.error("Error setting display name:", error);
    } finally {
      setIsLoadingENS(false);
    }
  };

  const displayCustomName =
    selectedOption === "custom"
      ? tempCustomName || "Custom Display Name"
      : customName === userId || customName === ensName
      ? "Custom Display Name"
      : customName;

  return (
    <div className="display-name">
      <span className="profile-editor-view-secondary-text">
        Select Display Name
      </span>
      <div className="display-name-options">
        <div
          className="display-name-button-component"
          onClick={savePrimaryDisplayName}
        >
          <span className="display-name-primary-text">
            {formatDisplayName(userId)}
          </span>
          {isLoadingPrimary ? (
            <Loader
              size="20px"
              dotSize="6px"
              color="var(--light)"
              multiplier={1.4}
            />
          ) : selectedOption === "primary" ? (
            <Tick className="theme-tick-svglogo selected" />
          ) : null}
        </div>
        {ensName && (
          <div
            className="display-name-button-component"
            onClick={saveENSDisplayName}
          >
            <span className="display-name-primary-text">{ensName}</span>
            {isLoadingENS ? (
              <Loader
                size="20px"
                dotSize="6px"
                color="var(--light)"
                multiplier={1.4}
              />
            ) : selectedOption === "ens" ? (
              <Tick className="theme-tick-svglogo selected" />
            ) : null}
          </div>
        )}
        <div
          className="display-name-button-component"
          onClick={() => setSelectedOption("custom")}
        >
          <span className="display-name-primary-text">{displayCustomName}</span>
          {isLoadingCustom ? (
            <Loader
              size="20px"
              dotSize="6px"
              color="var(--light)"
              multiplier={1.4}
            />
          ) : selectedOption === "custom" ? (
            <Tick className="theme-tick-svglogo selected" />
          ) : null}
        </div>
      </div>
      {selectedOption === "custom" && (
        <div className="display-name-options">
          <span className="profile-editor-view-secondary-text">
            Update Display Name
          </span>
          <CustomInput
            placeholder="Enter display name"
            value={tempCustomName}
            onChange={handleCustomNameChange}
            type="text"
            maxLength={22}
          />
          {error && <span className="create-room-error-text">{error}</span>}
          <div
            className="display-name-save-button"
            onClick={saveCustomDisplayName}
          >
            <span className="display-name-save-button-text">
              Set Custom Display Name
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayName;
