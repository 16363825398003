import { v4 as uuidv4 } from "uuid";
import initMatrix from "../../client/initMatrix";
import * as roomActions from "./room";

export const handleCreateRoom = async (spaceData) => {
  const mx = initMatrix.matrixClient;
  const avatarFile = spaceData.spaceAvatarUrl;
  const bannerFile = spaceData.spaceBannerUrl;

  try {
    let avatarMxcUrl = null;
    let bannerMxcUrl = null;

    if (avatarFile) {
      const uploadResponse = await mx.uploadContent(avatarFile);
      avatarMxcUrl = uploadResponse.content_uri;
    }

    if (bannerFile) {
      const uploadResponse = await mx.uploadContent(bannerFile);
      bannerMxcUrl = uploadResponse.content_uri;
    }

    const isSpace = true;
    const parentId = null;

    const roomId = await roomActions.createRoom({
      name: spaceData.spaceName,
      joinRule: spaceData.spaceJoinRule,
      alias: spaceData.spaceJoinRule === "public" ? uuidv4() : undefined,
      isEncrypted: false,
      powerLevel: 101,
      isSpace,
      parentId,
      topic: spaceData.spaceDesc,
      spaceSocialTwitter: spaceData.spaceSocialTwitter,
      spaceSocialGithub: spaceData.spaceSocialGithub,
      spaceSocialWebsite: spaceData.spaceSocialWebsite,
      tokenName: spaceData.tokenName,
      tokenSymbol: spaceData.tokenSymbol,
      tokenTotalSupply: spaceData.tokenTotalSupply,
      tokenContractAddress: spaceData.tokenContractAddress,
      tokenNewTokenizedSpace: spaceData.tokenNewTokenizedSpace,
      etherscanLink: spaceData.etherscanLink,
    });

    if (avatarMxcUrl) {
      await mx.sendStateEvent(
        roomId.room_id,
        "m.room.avatar",
        { url: avatarMxcUrl },
        ""
      );
    }

    if (bannerMxcUrl) {
      await mx.sendStateEvent(
        roomId.room_id,
        "custom.space_banner",
        { url: bannerMxcUrl },
        ""
      );
    }
    return roomId.room_id;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
