import { useRef, useState, useEffect } from "react";
import "./ViewProposal.scss";
import { marked } from "marked";
import SpaceAvatar from "../../atoms/avatar/SpaceAvatar";
import { formatDisplayName } from "../../../util/formatDisplayName";
import initMatrix from "../../../client/initMatrix";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import Modal from "../../atoms/modal/Modal";
import CastVote from "./CastVote";
import WeightedVoting from "./WeightedVoting";
import Voters from "./Voters";
import VoteEntry from "./VoteEntry";
import VotingResults from "./VotingResults";

const ViewProposal = ({
  governanceData,
  roomId,
  spaceId,
  space,
  setOpenViewDialog,
  data,
}) => {
  const chain = import.meta.env.VITE_DEFAULT_CHAIN;
  const mx = initMatrix.matrixClient;
  const user = mx.getUser(governanceData.proposalCreator);
  const userId = mx.getUserId();
  const displayName = user.displayName;
  const avatarSrc = mx.mxcUrlToHttp(user.avatarUrl, 36, 36, "crop");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedVoteOption, setSelectedVoteOption] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);
  const parsedText = marked(governanceData.proposalDescription);
  const [optionWeights, setOptionWeights] = useState(
    governanceData.options.reduce(
      (acc, option) => ({ ...acc, [option.value]: 0 }),
      {}
    )
  );
  const [isVotersModalOpen, setIsVotersModalOpen] = useState(false);
  const toggleVotersModal = () => {
    setIsVotersModalOpen(!isVotersModalOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 500);
    }
  }, [parsedText]);

  const handleOptionClick = (option) => {
    if (selectedVoteOption === option) {
      setSelectedVoteOption(null);
    } else {
      setSelectedVoteOption(option);
    }
  };

  const isCastVoteActive = () => {
    if (governanceData.selectedOption === "Basic voting") {
      return ["For", "Against", "Abstain"].includes(selectedVoteOption);
    } else if (governanceData.selectedOption === "Weighted voting") {
      return Object.values(optionWeights).reduce((a, b) => a + b, 0) > 0;
    } else {
      return !!selectedVoteOption;
    }
  };

  const handleVoteSubmit = () => {
    if (isCastVoteActive()) {
      toggleModal();
    }
  };

  const handleShowMore = () => {
    toggleVotersModal();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const formattedDate = date.toLocaleDateString(undefined, {
      year: "2-digit",
      month: "short",
      day: "numeric",
    });

    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  const readableStartTime = formatDateTime(governanceData.startTime);
  const readableEndTime = formatDateTime(governanceData.endTime);

  const getStatus = () => {
    const currentTime = new Date();
    const start = new Date(governanceData.startTime);
    const end = new Date(governanceData.endTime);

    if (currentTime < start) return "Upcoming";
    if (currentTime > end) return "Ended";
    return "Active";
  };

  const status = getStatus();

  return (
    <div className="view-proposal-container">
      <div className="view-proposal-header">
        <div className={`proposal-status ${status.toLowerCase()}`}>
          <span className={`status-label ${status.toLowerCase()}`}>
            {status}
          </span>
        </div>
      </div>
      <div className="view-proposal-content-one">
        <div className="view-proposal-content-head-one">
          <SpaceAvatar
            roomId={roomId}
            text={governanceData.proposalTitle}
            size={60}
            borderRadius={99}
            includeTransitions={false}
          />
          <span className="view-proposal-title">
            {governanceData.proposalTitle}
          </span>
        </div>
        <div className="view-proposal-container-flex">
          <div className="view-proposal-content-head">
            <UserAvatar size={28} userId={user.userId} imageSrc={avatarSrc} />
            <span className="view-proposal-subtitle">
              Proposal by{" "}
              <span className="display-name">
                {formatDisplayName(displayName)}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="view-proposal-content-two">
        <div
          ref={contentRef}
          className={`markdown-text ${isExpanded ? "expanded" : "collapsed"}`}
          dangerouslySetInnerHTML={{ __html: parsedText }}
        />
        {!isExpanded && isOverflowing && (
          <div className="view-proposal-gradient-overlay"></div>
        )}
        {isOverflowing && (
          <div
            onClick={toggleExpand}
            className={`read-more-button ${isExpanded ? "expanded" : ""}`}
          >
            <span className="read-more-button-text">
              {isExpanded ? "Read less" : "Read more"}
            </span>
          </div>
        )}
      </div>
      {status === "Active" && (
        <div
          className={`view-proposal-content-three ${
            !isOverflowing ? "overflowing" : ""
          }`}
        >
          <div className="proposal-vote-container">
            <span className="create-proposal-type-secondary-text">
              Voting Options
            </span>
            <div className="view-proposal-voting-options-container">
              {governanceData.selectedOption === "Basic voting" ? (
                <>
                  {["For", "Against", "Abstain"].map((option) => (
                    <div
                      key={option}
                      className={`view-proposal-voting-container ${
                        selectedVoteOption === option ? "active" : "inactive"
                      }`}
                      onClick={() => handleOptionClick(option)}
                    >
                      <span className="view-proposal-voting-option-container-text">
                        {option}
                      </span>
                    </div>
                  ))}
                </>
              ) : governanceData.selectedOption === "Weighted voting" ? (
                <WeightedVoting
                  options={governanceData.options}
                  optionWeights={optionWeights}
                  setOptionWeights={setOptionWeights}
                />
              ) : (
                governanceData.options.map((option) => (
                  <div
                    key={option.id}
                    className={`view-proposal-voting-container ${
                      selectedVoteOption === option.value
                        ? "active"
                        : "inactive"
                    }`}
                    onClick={() => handleOptionClick(option.value)}
                  >
                    <span className="view-proposal-voting-option-container-text">
                      {option.value}
                    </span>
                  </div>
                ))
              )}
            </div>
            <div
              className={`view-proposal-cast-vote ${
                isCastVoteActive() ? "active" : "inactive"
              }`}
              onClick={handleVoteSubmit}
            >
              <span
                className={`view-proposal-cast-vote-text ${
                  isCastVoteActive() ? "active" : "inactive"
                }`}
              >
                Cast Vote
              </span>
            </div>
          </div>
        </div>
      )}
      {governanceData && governanceData.votes?.length > 0 && (
        <div className="view-proposal-content-four">
          <div className="proposal-voters-container">
            <div className="voters-cast-vote-container">
              <span className="view-proposal-voter-text">Votes</span>
              <div className="weight-percentage-container">
                <span className="voting-percentage-text">
                  {governanceData.votes.length}
                </span>
              </div>
            </div>
            <div className="view-proposal-vote-entry-wrapper">
              {governanceData.votes.slice(0, 8).map((vote, index) => (
                <VoteEntry
                  key={index}
                  userId={vote.userId}
                  selectedVoteOption={vote.selectedVoteOption}
                  votingPower={vote.votingPower}
                  timestamp={vote.timestamp}
                  optionWeights={vote.optionWeights}
                  mx={mx}
                  selectedStrategyOption={governanceData.selectedStrategyOption}
                  tokenSymbol={data.tokenSymbol}
                />
              ))}
              {governanceData.votes.length > 6 && (
                <div
                  className="view-proposal-cast-vote-voters-list"
                  onClick={handleShowMore}
                >
                  <span className="view-proposal-cast-vote-text active">
                    Show All
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {governanceData && governanceData.votes?.length > 0 && (
        <div className="view-proposal-content-four">
          <div className="proposal-voters-container">
            <div className="voters-cast-vote-container">
              <span className="view-proposal-voter-text">Results</span>
            </div>
            <VotingResults
              governanceData={governanceData}
              tokenSymbol={data.tokenSymbol}
            />
          </div>
        </div>
      )}
      <div className="view-proposal-content-four">
        <div className="proposal-voters-container">
          <div className="voters-cast-vote-container">
            <span className="view-proposal-voter-text">Information</span>
          </div>
          <div className="view-proposal-details-wrapper">
            <div className="cast-vote-details-container">
              <span className="cast-vote-details-text">Voting Type</span>
              <span className="cast-vote-details-selection-text">
                {governanceData.selectedOption}
              </span>
            </div>
            <div className="cast-vote-details-container">
              <span className="cast-vote-details-text">Strategy Option</span>
              <span className="cast-vote-details-selection-text">
                {governanceData.selectedStrategyOption}
              </span>
            </div>
            {governanceData.proposalBlockNumber && (
              <div className="cast-vote-details-container">
                <span className="cast-vote-details-text">Block Number</span>
                <a
                  href={`https://${
                    chain === "sepolia" ? "sepolia." : ""
                  }etherscan.io/block/${governanceData.proposalBlockNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-proposal-details-wrapper-link-text"
                >
                  {governanceData.proposalBlockNumber}
                </a>
              </div>
            )}
            <div className="cast-vote-details-container">
              <span className="cast-vote-details-text">Start Time</span>
              <span className="cast-vote-details-selection-text">
                {readableStartTime}
              </span>
            </div>
            <div className="cast-vote-details-container">
              <span className="cast-vote-details-text">End Time</span>
              <span className="cast-vote-details-selection-text">
                {readableEndTime}
              </span>
            </div>
            {governanceData.proposalDiscussionLink && (
              <div className="view-proposal-discussion-container">
                <span className="cast-vote-details-text">Discussion Link</span>
                <a
                  href={governanceData.proposalDiscussionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-proposal-details-wrapper-link-text"
                  title={governanceData.proposalDiscussionLink}
                >
                  {governanceData.proposalDiscussionLink}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        <CastVote
          selectedVoteOption={selectedVoteOption}
          userId={userId}
          optionWeights={optionWeights}
          selectedStrategyOption={governanceData.selectedStrategyOption}
          onClose={toggleModal}
          space={space}
          spaceId={spaceId}
          roomId={roomId}
          data={data}
          setOpenViewDialog={setOpenViewDialog}
          governanceData={governanceData}
        />
      </Modal>
      <Modal
        isOpen={isVotersModalOpen}
        onClose={toggleVotersModal}
        shouldScroll={true}
      >
        <Voters
          governanceData={governanceData}
          mx={mx}
          tokenSymbol={data.tokenSymbol}
        />
      </Modal>
    </div>
  );
};

export default ViewProposal;
