import { encryptAttachment } from "browser-encrypt-attachment";
import { encodeBlurHash } from "./blurHash";
import {
  loadImageElement,
  loadVideoElement,
  getImageFileUrl,
  getVideoFileUrl,
  getThumbnail,
  getThumbnailDimensions,
  scaleYDimension,
} from "./dom";
import to from "await-to-js";

const generateThumbnailContent = async (mx, img, dimensions, encrypt) => {
  const thumbnail = await getThumbnail(img, ...dimensions);
  if (!thumbnail) throw new Error("Cannot create thumbnail!");
  const encThumbData = encrypt ? await encryptFile(thumbnail) : undefined;
  const thumbnailFile = encThumbData?.file ?? thumbnail;
  if (!thumbnailFile) throw new Error("Cannot create thumbnail!");
  const data = await mx.uploadContent(thumbnailFile);
  const thumbMxc = data?.content_uri;
  if (!thumbMxc) throw new Error("Failed to upload thumbnail!");
  const thumbnailContent = getThumbnailContent({
    thumbnail: thumbnailFile,
    encInfo: encThumbData?.encInfo,
    mxc: thumbMxc,
    width: dimensions[0],
    height: dimensions[1],
  });
  return thumbnailContent;
};

export const getImageMsgContent = async (mx, item, mxc) => {
  const { file, originalFile, encInfo } = item;
  if (!(originalFile instanceof Blob)) {
    console.error("Invalid file object:", originalFile);
    throw new Error("Invalid file object");
  }

  const [imgError, imgEl] = await to(
    loadImageElement(URL.createObjectURL(originalFile))
  );
  if (imgError) {
    console.warn("Error loading image element:", imgError);
    return {
      msgtype: "m.image",
      body: file.name,
      url: mxc,
      info: {},
    };
  }

  const blurHash = encodeBlurHash(
    imgEl,
    512,
    scaleYDimension(imgEl.width, 512, imgEl.height)
  );

  const content = {
    msgtype: "m.image",
    body: file.name,
    info: {
      w: imgEl.width,
      h: imgEl.height,
      mimetype: file.type,
      size: file.size,
      blurhash: blurHash,
    },
  };

  if (encInfo) {
    content.file = {
      ...encInfo,
      url: mxc,
    };
  } else {
    content.url = mxc;
  }
  return content;
};

export const getVideoMsgContent = async (mx, item, mxc) => {
  const { file, originalFile, encInfo } = item;

  const [videoError, videoEl] = await to(
    loadVideoElement(getVideoFileUrl(originalFile))
  );
  if (videoError) console.warn("Error loading video element:", videoError);

  const content = {
    msgtype: "m.video",
    body: file.name,
  };

  if (videoEl) {
    const [thumbError, thumbContent] = await to(
      generateThumbnailContent(
        mx,
        videoEl,
        getThumbnailDimensions(videoEl.videoWidth, videoEl.videoHeight),
        !!encInfo
      )
    );
    if (thumbContent && thumbContent.thumbnail_info) {
      thumbContent.thumbnail_info.blurhash = encodeBlurHash(
        videoEl,
        512,
        scaleYDimension(videoEl.videoWidth, 512, videoEl.videoHeight)
      );
    }
    if (thumbError)
      console.warn("Error generating thumbnail content:", thumbError);

    content.info = {
      ...getVideoInfo(videoEl, file),
      ...thumbContent,
    };
  }

  if (encInfo) {
    content.file = {
      ...encInfo,
      url: mxc,
    };
  } else {
    content.url = mxc;
  }

  return content;
};

export const getAudioMsgContent = (item, mxc) => {
  const { file, encInfo } = item;
  const content = {
    msgtype: "m.audio",
    body: file.name,
    info: {
      mimetype: file.type,
      size: file.size,
    },
  };

  if (encInfo) {
    content.file = {
      ...encInfo,
      url: mxc,
    };
  } else {
    content.url = mxc;
  }

  return content;
};

export const getFileMsgContent = (item, mxc) => {
  const { file, encInfo } = item;
  const content = {
    msgtype: "m.file",
    body: file.name,
    filename: file.name,
    info: {
      mimetype: file.type,
      size: file.size,
    },
  };

  if (encInfo) {
    content.file = {
      ...encInfo,
      url: mxc,
    };
  } else {
    content.url = mxc;
  }

  return content;
};

const getImageInfo = (img, fileOrBlob) => {
  const info = {};
  info.w = img.width;
  info.h = img.height;
  info.mimetype = fileOrBlob.type;
  info.size = fileOrBlob.size;
  return info;
};

const getVideoInfo = (video, fileOrBlob) => {
  const info = {};
  info.duration = Number.isNaN(video.duration)
    ? undefined
    : Math.floor(video.duration * 1000);
  info.w = video.videoWidth;
  info.h = video.videoHeight;
  info.mimetype = fileOrBlob.type;
  info.size = fileOrBlob.size;
  return info;
};

const getThumbnailContent = (thumbnailInfo) => {
  const { thumbnail, encInfo, mxc, width, height } = thumbnailInfo;

  const content = {
    thumbnail_info: {
      mimetype: thumbnail.type,
      size: thumbnail.size,
      w: width,
      h: height,
    },
  };
  if (encInfo) {
    content.thumbnail_file = {
      ...encInfo,
      url: mxc,
    };
  } else {
    content.thumbnail_url = mxc;
  }
  return content;
};

const encryptFile = async (file) => {
  const dataBuffer = await file.arrayBuffer();
  const encryptedAttachment = await encryptAttachment(dataBuffer);
  const encFile = new File([encryptedAttachment.data], file.name, {
    type: file.type,
  });
  return {
    encInfo: encryptedAttachment.info,
    file: encFile,
    originalFile: file,
  };
};
