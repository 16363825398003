import React, { useEffect, useState } from "react";
import { useLoading } from "../../contexts/LoadingContext";
import "./Loading.scss";
import Loader from "../loader/Loader";

const LoadingComponent = () => {
  const { contextLoading, messages, details } = useLoading();
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timer;
    if (contextLoading) {
      setVisible(true);
      setShow(true);
    } else if (!contextLoading && show) {
      setShow(false);
      timer = setTimeout(() => setVisible(false), 200);
    }
    return () => clearTimeout(timer);
  }, [contextLoading, show]);

  useEffect(() => {
    let messageTimer;
    if (show && messages.length > 0) {
      setCurrentIndex(0);
      messageTimer = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          return nextIndex % messages.length;
        });
      }, 2500);
    }
    return () => clearInterval(messageTimer);
  }, [show, messages.length]);

  return (
    visible && (
      <div className={`loading-overlay ${show ? "show" : "hide"}`}>
        <div className={`loading-container ${show ? "show" : "hide"}`}>
          <div className="loading-component-loading-progress">
            <Loader
              size="40px"
              dotSize="8px"
              color="var(--light)"
              multiplier={1.1}
            />
          </div>
          <span className="loading-detail-text">{details}</span>
          <div
            className={`message-animation ${show ? "show" : "hide"}`}
            key={currentIndex}
          >
            <span className="loading-message-text">
              {messages[currentIndex]}
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default LoadingComponent;
