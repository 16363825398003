export function formatTimeAgo(timestamp) {
  const now = Date.now();
  const diff = now - timestamp;

  if (diff < 0) {
    return "just now";
  }

  const minutes = Math.floor(diff / (1000 * 60));
  if (minutes < 2) {
    return "just now";
  }
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = Math.floor(hours / 24);
  if (days < 7) {
    return `${days}d`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks < 4) {
    return `${weeks}w`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `${months}m`;
  }

  const years = Math.floor(days / 365);
  return `${years}y`;
}
