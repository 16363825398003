import React from "react";

const CommunityScoreIcon = ({ colorStyle, height, width }) => {
  const isGradient = colorStyle.startsWith("linear-gradient");

  let gradientColors = [];

  if (isGradient) {
    const colorRegex = /#([0-9a-f]{6}|[0-9a-f]{3})/gi;
    gradientColors = colorStyle.match(colorRegex);
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isGradient && gradientColors.length >= 2 && (
        <defs>
          <linearGradient id="iconGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
      )}
      <path
        d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.636 0 12 0ZM16.5 12.42L12.036 17.496L11.508 18.096C10.776 18.924 10.176 18.708 10.176 17.592V12.84H8.136C7.212 12.84 6.96 12.276 7.572 11.58L12.036 6.504L12.564 5.904C13.296 5.076 13.896 5.292 13.896 6.408V11.16H15.936C16.86 11.16 17.112 11.724 16.5 12.42Z"
        fill={isGradient ? "url(#iconGradient)" : colorStyle}
      />
    </svg>
  );
};

export default CommunityScoreIcon;
