import React from "react";
import "./SetupWallet.scss";
import { generateWaivicon1 } from "waivicon";

const ConnectedWallets = ({ wallets, onWalletSelect }) => {
  const importedWallet = wallets.find(
    (wallet) => wallet.importedWalletSP || wallet.importedWalletPK
  );
  const createdWallets = wallets.filter((wallet) => wallet.createdWallet);
  const truncateAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const generateWaiviconSVG = (address, size = 28) => {
    return generateWaivicon1(address.toUpperCase(), size);
  };

  return (
    <div className="scrollable-list-container">
      {importedWallet && (
        <div
          className="connected-wallets-wrapper"
          onClick={() => onWalletSelect(importedWallet)}
        >
          <div
            className="wallet-icon"
            style={{
              backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
                generateWaiviconSVG(importedWallet.walletAddress)
              )}")`,
            }}
          />
          <span className="connected-wallets-wrapper-text">
            {truncateAddress(importedWallet.walletAddress)}
          </span>
          <div className="imported-label">
            <span className="imported-label-text">IMPORTED</span>
          </div>
        </div>
      )}
      {createdWallets.map((wallet, index) => (
        <div
          className="connected-wallets-wrapper"
          key={index}
          onClick={() => onWalletSelect(wallet)}
        >
          <div
            className="wallet-icon"
            style={{
              backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
                generateWaiviconSVG(wallet.walletAddress)
              )}")`,
            }}
          />
          <span className="connected-wallets-wrapper-text">
            {truncateAddress(wallet.walletAddress)}
          </span>
          <div className="imported-label-created">
            <span className="imported-label-text">CREATED</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConnectedWallets;
