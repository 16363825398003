import React, { useState, useCallback, useEffect } from "react";
import { ReactComponent as AddTokenizedSpace } from "../../assets/svg/new-tokenized-space.svg";
import "./TokenFundraising.scss";
import TokenFundraisingDetails from "./TokenFundraisingDetails";
import Popover from "../../atoms/popover/Popover";
import * as roomActions from "../../../client/action/room";
import initMatrix from "../../../client/initMatrix";
import { ReactComponent as FundingTokens } from "../../assets/svg/filltokens.svg";
import HighlightsContainer from "../../atoms/highlights/HighlightsContainer";

function TokenFundraising({ roomIds = [], spaceId, provider }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [filteredRoomIds, setFilteredRoomIds] = useState([]);
  useEffect(() => {
    if (!roomIds || roomIds.length === 0) {
      setFilteredRoomIds([]);
      return;
    }

    const tokenFundingRooms = roomIds.filter(
      ({ content }) => content.tokenFundingType
    );
    setFilteredRoomIds(tokenFundingRooms);
  }, [roomIds]);

  const handleRoomClick = async (roomId) => {
    const isJoined =
      initMatrix.matrixClient.getRoom(roomId)?.getMyMembership() === "join";
    if (isJoined) {
      setSelectedRoomId(roomId);
      setOpenDialog(true);
    } else {
      await joinRoom(roomId);
    }
  };

  const joinRoom = async (roomId) => {
    try {
      await roomActions.join(roomId, false);
      handleRoomClick(roomId);
    } catch (error) {
      throw error;
    }
  };

  const handleDialogOpen = useCallback(async (roomId, event) => {
    event.preventDefault();
    event.stopPropagation();
    await handleRoomClick(roomId);
  }, []);

  const renderDialogContent = useCallback(() => {
    return (
      <TokenFundraisingDetails
        roomId={selectedRoomId}
        setOpenDialog={setOpenDialog}
        spaceId={spaceId}
        provider={provider}
      />
    );
  }, [selectedRoomId, setOpenDialog]);

  const renderRoomDetails = (room) => {
    const tokenData = room.content;
    if (!tokenData || !tokenData.tokenFundingType) return null;

    return (
      <div
        className="token-fundraising-container"
        key={room.roomId}
        onClick={(event) => handleDialogOpen(room.roomId, event)}
      >
        <div className="token-fundraising-header-container">
          <div className="token-fundraising-svgicon-container">
            <div className="token-fundraising-icon-container">
              <AddTokenizedSpace className="token-fundraising-icon-svg" />
            </div>
          </div>
          <div className="token-fundraising-details-wrapper">
            <div className="token-fundraising-details-container">
              <span className="token-fundraising-subheading-text">
                Fundraise Goal
              </span>
              <span className="token-fundraising-text-fundraising">
                {tokenData.presaleTargetRaiseAmount} ETH
              </span>
            </div>
          </div>
          <div className="token-fundraising-details-wrapper">
            <div className="token-fundraising-details-container">
              <span className="token-fundraising-subheading-text">
                Fundraiser Type
              </span>
              <span className="token-fundraising-text-fundraising">
                {tokenData.tokenFundingType}{" "}
              </span>
            </div>
          </div>
          <div className="token-fundraising-token-symbol-container">
            <div className="token-fundraising-symbol-container">
              <span className="token-fundraising-symbol-text">
                ${tokenData.tokenSymbol}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="token-fundraising-wrapper">
      {filteredRoomIds.map(renderRoomDetails)}
      {filteredRoomIds.length < 1 && (
        <HighlightsContainer
          text="Fundraising Updates"
          subtext="Stay tuned for upcoming fundraising rounds and keep an eye on this space for updates."
          Icon={FundingTokens}
          iconFill="var(--color-accent-two)"
        />
      )}
      {openDialog && (
        <Popover
          setOpenDialog={setOpenDialog}
          direction="right"
          headerText="Presale Details"
        >
          {renderDialogContent()}
        </Popover>
      )}
    </div>
  );
}

export default TokenFundraising;
