import React, { createContext, useContext } from "react";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { createConfig, WagmiProvider, webSocket } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { walletConnect } from "wagmi/connectors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Web3ModalContext = createContext();

const projectId = import.meta.env.VITE_WALLETCONNECT_PROJECT_ID;
const defaultChain = import.meta.env.VITE_DEFAULT_CHAIN;

const metadata = {
  name: "Waivlength",
  description: "Redefining Web3 Collaboration",
  url: "https://waivlength.app",
  icons: ["https://waivlength.app/WaivlengthLogo512.png"],
};

const config = createConfig({
  chains: [mainnet, sepolia],
  transports: {
    [mainnet.id]: webSocket(
      `wss://eth-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_API}`,
      {
        retryCount: 20,
      }
    ),
    [sepolia.id]: webSocket(
      `wss://eth-sepolia.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_API}`,
      {
        retryCount: 20,
      }
    ),
  },
  connectors: [
    walletConnect({
      projectId,
      metadata,
      disableProviderPing: false,
      showQrModal: false,
    }),
  ],
});

const queryClient = new QueryClient();

createWeb3Modal({
  wagmiConfig: config,
  defaultChain: defaultChain,
  projectId,
  enableAnalytics: true,
  allWallets: "SHOW",
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
  ],
});

export function Web3ModalProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <Web3ModalContext.Provider value={{}}>
          {children}
        </Web3ModalContext.Provider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}

export const useWeb3Modal = () => useContext(Web3ModalContext);
