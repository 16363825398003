import { useState, useEffect } from "react";
import { ethers } from "ethers";
import PresaleABI from "../../util/abi/PresaleABI.json";

const useFetchPresaleData = (userAddress, tokenData, provider) => {
  const [presaleData, setPresaleData] = useState({
    totalRaised: "0",
    contributorCount: "0",
    totalDeposits: "0",
    startTime: "0",
    endTime: "0",
    isSuccessful: false,
    targetRaiseAmount: "0",
    token: ethers.ZeroAddress,
    totalSupply: "0",
    hasClaimed: false,
    hasReclaimed: false,
  });
  const [userContribution, setUserContribution] = useState("0");
  const [isUserOwner, setIsUserOwner] = useState(false);

  useEffect(() => {
    const fetchPresaleData = async () => {
      if (tokenData?.presaleContractAddress && provider) {
        const presaleContract = new ethers.Contract(
          tokenData.presaleContractAddress,
          PresaleABI,
          provider
        );

        try {
          const [
            totalRaised,
            contributorCount,
            totalDeposits,
            startTime,
            endTime,
            isSuccessful,
            targetRaiseAmount,
            tokenAddress,
            hasClaimed,
            hasReclaimed,
          ] = await presaleContract.getPresaleDetails(userAddress);

          const tokenContract = new ethers.Contract(
            tokenAddress,
            ["function totalSupply() view returns (uint256)"],
            provider
          );
          const totalSupply = await tokenContract.totalSupply();

          const formatToNumber = (value) => {
            if (typeof value === "bigint") {
              return Number(value);
            }
            return value;
          };

          setPresaleData({
            totalRaised: totalRaised.toString(),
            contributorCount: contributorCount.toString(),
            totalDeposits: totalDeposits.toString(),
            startTime: new Date(formatToNumber(startTime) * 1000).toISOString(),
            endTime: new Date(formatToNumber(endTime) * 1000).toISOString(),
            isSuccessful,
            targetRaiseAmount: targetRaiseAmount.toString(),
            token: tokenAddress,
            totalSupply: totalSupply.toString(),
            hasClaimed,
            hasReclaimed,
          });

          const ownerAddress = await presaleContract.owner();
          setIsUserOwner(
            userAddress.toLowerCase() === ownerAddress.toLowerCase()
          );
        } catch (error) {
          console.error("Failed to fetch presale data from contract:", error);
        }
      }
    };

    fetchPresaleData();
  }, [tokenData, provider, userAddress]);

  useEffect(() => {
    const fetchUserContribution = async () => {
      if (!userAddress || !tokenData?.presaleContractAddress || !provider)
        return;

      const contract = new ethers.Contract(
        tokenData.presaleContractAddress,
        PresaleABI,
        provider
      );

      try {
        const contribution = await contract.contributions(userAddress);
        setUserContribution(ethers.formatEther(contribution));
      } catch (error) {
        console.error(
          "Failed to fetch user contribution from contract:",
          error
        );
      }
    };

    fetchUserContribution();
  }, [userAddress, tokenData, provider]);

  return { presaleData, userContribution, isUserOwner };
};

export default useFetchPresaleData;
