import React, { createContext, useContext, useState, useEffect } from "react";
import cons from "../../client/state/cons";
import navigation from "../../client/state/navigation";
import waitForRoom from "../../util/waitForRoom";

const RoomSelectContext = createContext();

export const useRoomSelect = () => useContext(RoomSelectContext);

export const RoomSelectProvider = ({ children }) => {
  const [roomId, setRoomId] = useState(null);
  const [dmRoomId, setDmRoomId] = useState(null);
  const [spaceId, setSpaceId] = useState(null);
  const [space, setSpace] = useState(null);
  const [selectedTab, setSelectedTab] = useState(navigation.selectedTab);

  useEffect(() => {
    const onTabSelected = (tabId) => {
      setSelectedTab(tabId);
    };

    navigation.on(cons.events.navigation.TAB_SELECTED, onTabSelected);

    return () => {
      navigation.removeListener(
        cons.events.navigation.TAB_SELECTED,
        onTabSelected
      );
    };
  }, []);

  useEffect(() => {
    if (spaceId !== null) {
      waitForRoom(spaceId).then((room) => {
        if (!room) {
          setSpaceId(null);
          setSpace(null);
        } else {
          setSpace(room);
        }
      });
    }
  }, [spaceId]);

  return (
    <RoomSelectContext.Provider
      value={{
        roomId,
        setRoomId,
        dmRoomId,
        setDmRoomId,
        spaceId,
        setSpaceId,
        space,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </RoomSelectContext.Provider>
  );
};
