import React from "react";
import "./NewPostsNotification.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";

const NewPostsNotification = ({ newPostsCount, senders, onShowNewPosts }) => {
  const uniqueSenders = [
    ...new Map(senders.map((sender) => [sender.userId, sender])).values(),
  ];
  const limitedSenders = uniqueSenders.slice(0, 3);

  return (
    <div className="avatar-container-wrapper" onClick={onShowNewPosts}>
      <div className="avatar-container">
        {limitedSenders.map((sender, index) => (
          <div className="avatar-wrapper" key={`${sender.userId}-${index}`}>
            <UserAvatar
              size={28}
              userId={sender.userId}
              imageSrc={sender.avatarUrl}
            />
          </div>
        ))}
      </div>
      <span className="global-feed-header-text-new">
        {newPostsCount} new {newPostsCount === 1 ? "post" : "posts"}
      </span>
    </div>
  );
};

export default NewPostsNotification;
