import React, { useMemo } from "react";
import dateFormat from "dateformat";
import initMatrix from "../../../client/initMatrix";
import RoomIntro from "../../molecules/room-intro/RoomIntro";
import { twemojify } from "../../../util/twemojify";
import { formatDisplayName } from "../../../util/formatDisplayName";

const RoomIntroContainer = ({ event, roomId, room }) => {
  const mx = initMatrix.matrixClient;
  const { roomList } = initMatrix;

  const roomTopic = useMemo(
    () =>
      room.currentState.getStateEvents("m.room.topic")[0]?.getContent().topic,
    [room]
  );

  const isDM = useMemo(
    () => roomList.directs.has(roomId),
    [roomList.directs, roomId]
  );

  const avatarSrc = useMemo(() => {
    const src = room.getAvatarUrl(mx.baseUrl, 80, 80, "crop");
    return isDM
      ? room.getAvatarFallbackMember()?.getAvatarUrl(mx.baseUrl, 80, 80, "crop")
      : src;
  }, [isDM, room, mx.baseUrl]);

  const topic = useMemo(
    () => twemojify(roomTopic || "", undefined, true),
    [roomTopic]
  );

  const roomName = useMemo(() => {
    return formatDisplayName(room.name);
  }, [room.name]);

  const heading = useMemo(() => {
    return isDM ? roomName : `Welcome to ${roomName}`;
  }, [isDM, roomName]);

  const desc = useMemo(() => {
    return isDM ? (
      <div className="room-intro-container-wrapper">
        <span className="room-intro-container-text">
          {`This is the beginning of your direct message history with ${heading}.`}
        </span>
      </div>
    ) : (
      <div className="room-intro-container-wrapper-room">
        <span className="room-intro-container-text-room">
          {`This is the beginning of the ${roomName} room.`}
        </span>
        <span className="room-intro-container-text-room-topic">{topic}</span>
      </div>
    );
  }, [isDM, heading, topic]);

  return (
    <RoomIntro
      room={room}
      roomId={roomId}
      avatarSrc={avatarSrc}
      name={room.name}
      heading={twemojify(heading)}
      desc={desc}
      time={
        event
          ? `Created ${dateFormat(event.getDate(), "dd mmmm yyyy, hh:MM TT")}`
          : null
      }
    />
  );
};

export default RoomIntroContainer;
