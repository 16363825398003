import React from "react";
import PropTypes from "prop-types";
import RawIcon from "../system-icons/RawIcon";
import { blurOnBubbling } from "./script";
import "./IconButton.scss";

const IconButton = React.forwardRef(
  (
    {
      variant,
      size,
      type,
      tooltip,
      src,
      onClick,
      tabIndex,
      disabled,
      isImage,
      className,
    },
    ref
  ) => {
    const btn = (
      <div
        ref={ref}
        className={`ic-btn ic-btn-${variant} ${className}`}
        onMouseUp={(e) => blurOnBubbling(e, `.ic-btn-${variant}`)}
        onClick={onClick}
        type={type}
        tabIndex={tabIndex}
        disabled={disabled}
      >
        <RawIcon size={size} src={src} isImage={isImage} />
      </div>
    );

    if (tooltip === null) {
      return btn;
    }

    return (
      <div className="tooltip-container">
        {btn}
        <span className={`tooltip`}>{tooltip}</span>
      </div>
    );
  }
);

IconButton.propTypes = {
  variant: PropTypes.oneOf([
    "surface",
    "primary",
    "positive",
    "caution",
    "danger",
  ]).isRequired,
  size: PropTypes.oneOf(["normal", "small", "extra-small"]).isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  isImage: PropTypes.bool,
  className: PropTypes.string,
};

export default IconButton;
