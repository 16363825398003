import React, { useState, useEffect, useRef } from "react";
import "./SideBar.scss";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import { selectTab } from "../../../client/action/navigation";
import { generateWaivicon2 } from "waivicon";
import { abbreviateNumber } from "../../../util/common";
import NotificationBadge from "../../atoms/badge/NotificationBadge";
import { useSelectedTab } from "../../hooks/useSelectedTab";
import SpaceAvatar from "../../atoms/avatar/SpaceAvatar";
import { ReactComponent as Home } from "../../assets/svg/home.svg";
import { ReactComponent as Spaces } from "../../assets/svg/box.svg";
import { ReactComponent as DirectMessage } from "../../assets/svg/sms.svg";
import { ReactComponent as Global } from "../../assets/svg/global.svg";
import { ReactComponent as Notifications } from "../../assets/svg/notification-bell.svg";
import SidebarAvatarMobile from "../../molecules/sidebar-avatar/SidebarAvatarMobile";
import PinnedSpaces from "../../molecules/pinned-spaces/PinnedSpaces";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import Modal from "../../atoms/modal/Modal";
import RoomOptions from "../../molecules/room-options/RoomOptions";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { totalNotifications } from "../../../client/action/notifications";

function FeaturedTab() {
  const { roomList, notifications, roomsState } = initMatrix;
  const [selectedTab] = useSelectedTab();
  const [homeNoti, setHomeNoti] = useState(null);
  const [dmsNoti, setDmsNoti] = useState(null);

  const totalInviteCount = () => {
    return (
      roomList.inviteRooms.size +
      roomList.inviteSpaces.size +
      roomList.inviteDirects.size
    );
  };

  const [totalInvites, setTotalInvites] = useState(totalInviteCount());

  useEffect(() => {
    const onInviteListChange = async () => {
      setTotalInvites(totalInviteCount());
      await totalNotifications();
    };

    roomList.on(cons.events.roomList.INVITELIST_UPDATED, onInviteListChange);

    return () => {
      roomList.removeListener(
        cons.events.roomList.INVITELIST_UPDATED,
        onInviteListChange
      );
    };
  }, [roomList]);

  useEffect(() => {}, [totalInvites]);

  useEffect(() => {
    const notiChanged = (roomId, total, prevTotal) => {
      if (total === prevTotal) return;
      setHomeNoti(getHomeNoti());
      setDmsNoti(getDMsNoti());
    };

    notifications.on(cons.events.notifications.NOTI_CHANGED, notiChanged);
    notifications.on(cons.events.notifications.MUTE_TOGGLED, notiChanged);

    setHomeNoti(getHomeNoti());
    setDmsNoti(getDMsNoti());

    return () => {
      notifications.removeListener(
        cons.events.notifications.NOTI_CHANGED,
        notiChanged
      );
      notifications.removeListener(
        cons.events.notifications.MUTE_TOGGLED,
        notiChanged
      );
    };
  }, [notifications]);

  function getHomeNoti() {
    // Assuming roomList.rooms contains all rooms, and roomList.getOrphans() returns rooms not in spaces
    const allRooms = new Set([...roomList.rooms.keys()]);
    const orphansAndDirects = new Set([
      ...roomList.getOrphans(),
      ...roomList.directs,
    ]);
    // Rooms within spaces are those not in orphans or directs
    const roomsInSpaces = new Set(
      [...allRooms].filter((roomId) => !orphansAndDirects.has(roomId))
    );

    let noti = null;

    roomsInSpaces.forEach((roomId) => {
      if (!notifications.hasNoti(roomId)) return;
      if (noti === null) noti = { total: 0, highlight: 0 };
      const childNoti = notifications.getNoti(roomId);
      noti.total += childNoti.total;
      noti.highlight += childNoti.highlight;
    });

    return noti;
  }

  function getAllSpaceChildRooms() {
    const spaceChildren = new Set();
    for (const childRooms of roomsState.spaceChildrenMap.values()) {
      for (const roomId of childRooms) {
        spaceChildren.add(roomId);
      }
    }
    return spaceChildren;
  }

  function getDMsNoti() {
    const spaceChildren = getAllSpaceChildRooms();
    const dmAndRoomIds = new Set([
      ...roomsState.directMessages,
      ...roomsState.rooms,
    ]);

    const filteredRoomIds = [...dmAndRoomIds].filter(
      (roomId) =>
        !spaceChildren.has(roomId) &&
        roomId !== import.meta.env.VITE_GLOBAL_FEED_ROOM_ID
    );

    if (filteredRoomIds.length === 0) return null;
    let noti = null;

    for (const roomId of filteredRoomIds) {
      if (!notifications.hasNoti(roomId)) continue;
      if (noti === null) noti = { total: 0, highlight: 0 };
      const childNoti = notifications.getNoti(roomId);
      noti.total += childNoti.total;
      noti.highlight += childNoti.highlight;
    }

    return noti;
  }

  const { setSpaceId } = useRoomSelect();

  const handleDiscoveryView = () => {
    setSpaceId(null);
    selectTab(cons.tabs.DISCOVERY);
  };

  const handleNotificationsView = () => {
    setSpaceId(null);
    selectTab(cons.tabs.NOTIFICATIONS);
  };

  const handleHomeView = () => {
    setSpaceId(null);
    selectTab(cons.tabs.HOME);
  };

  const handleFeedView = () => {
    setSpaceId(null);
    selectTab(cons.tabs.FEED);
  };

  const handleDMView = () => {
    setSpaceId(null);
    selectTab(cons.tabs.DIRECTS);
  };

  return (
    <div className="sidebar-featured-tab-container-mobile">
      <SidebarAvatarMobile
        active={selectedTab === cons.tabs.FEED}
        onClick={handleFeedView}
        footer={
          <span
            className={`sidebar-avatar-text ${
              selectedTab === cons.tabs.FEED ? "sidebar-avatar-text-active" : ""
            }`}
          >
            Feed
          </span>
        }
        iconSrc={
          <div className="sidebar-icon-wrapper">
            <Home
              className={`sidebar-svglogo${
                selectedTab === cons.tabs.FEED
                  ? ""
                  : " sidebar-svglogo-inactive"
              }`}
            />
          </div>
        }
      />
      <SidebarAvatarMobile
        active={selectedTab === cons.tabs.HOME}
        onClick={handleHomeView}
        footer={
          <span
            className={`sidebar-avatar-text ${
              selectedTab === cons.tabs.HOME ? "sidebar-avatar-text-active" : ""
            }`}
          >
            Spaces
          </span>
        }
        iconSrc={
          <div className="sidebar-icon-wrapper">
            <Spaces
              className={`sidebar-svglogo${
                selectedTab === cons.tabs.HOME
                  ? ""
                  : " sidebar-svglogo-inactive"
              }`}
            />
          </div>
        }
        notificationBadge={
          homeNoti ? (
            <NotificationBadge
              content={abbreviateNumber(homeNoti.total) || null}
            />
          ) : null
        }
      />
      <SidebarAvatarMobile
        active={selectedTab === cons.tabs.DISCOVERY}
        onClick={handleDiscoveryView}
        footer={
          <span
            className={`sidebar-avatar-text ${
              selectedTab === cons.tabs.DISCOVERY
                ? "sidebar-avatar-text-active"
                : ""
            }`}
          >
            Discovery
          </span>
        }
        iconSrc={
          <div className="sidebar-icon-wrapper">
            <Global
              className={`sidebar-svglogo${
                selectedTab === cons.tabs.DISCOVERY
                  ? ""
                  : " sidebar-svglogo-inactive"
              }`}
            />
          </div>
        }
      />
      <SidebarAvatarMobile
        active={selectedTab === cons.tabs.NOTIFICATIONS}
        onClick={handleNotificationsView}
        footer={
          <span
            className={`sidebar-avatar-text ${
              selectedTab === cons.tabs.NOTIFICATIONS
                ? "sidebar-avatar-text-active"
                : ""
            }`}
          >
            Notifications
          </span>
        }
        iconSrc={
          <div className="sidebar-icon-wrapper">
            <Notifications
              className={`sidebar-svglogo${
                selectedTab === cons.tabs.NOTIFICATIONS
                  ? ""
                  : " sidebar-svglogo-inactive"
              }`}
            />
          </div>
        }
        notificationBadge={<NotificationBadge alert content={totalInvites} />}
      />
      <SidebarAvatarMobile
        active={selectedTab === cons.tabs.DIRECTS}
        onClick={handleDMView}
        footer={
          <span
            className={`sidebar-avatar-text ${
              selectedTab === cons.tabs.DIRECTS
                ? "sidebar-avatar-text-active"
                : ""
            }`}
          >
            Chats
          </span>
        }
        iconSrc={
          <div className="sidebar-icon-wrapper">
            <DirectMessage
              className={`sidebar-svglogo${
                selectedTab === cons.tabs.DIRECTS
                  ? ""
                  : " sidebar-svglogo-inactive"
              }`}
            />
          </div>
        }
        notificationBadge={
          dmsNoti ? (
            <NotificationBadge
              alert={dmsNoti?.highlight > 0}
              content={abbreviateNumber(dmsNoti.total) || null}
            />
          ) : null
        }
      />
    </div>
  );
}

function DraggableSpaceShortcut({ spaceId }) {
  const mx = initMatrix.matrixClient;
  const noti = initMatrix.notifications;
  const isMuted = noti.getNotiType(spaceId) === cons.notifs.MUTE;
  const room = mx.getRoom(spaceId);
  const avatarRef = useRef(null);
  const { setSpaceId } = useRoomSelect();
  const avatarUrl =
    room.getAvatarUrl(initMatrix.matrixClient.baseUrl, 42, 42, "crop") || null;
  const getStateEvent = (eventType) =>
    room.currentState.getStateEvents(eventType, "");
  const bannerEvent = getStateEvent("custom.space_banner");
  const bannerUrl = bannerEvent
    ? mx.mxcUrlToHttp(bannerEvent.getContent().url, 220, 100, "scale")
    : null;
  const tokenSymbol =
    getStateEvent("custom.token_data")?.getContent()?.tokenSymbol || "NaN";

  const generatedSvg = generateWaivicon2(room.roomId, 400);

  const [notificationCount, setNotificationCount] = useState(
    abbreviateNumber(noti.getTotalNoti(spaceId))
  );
  const [isAlert, setIsAlert] = useState(noti.getHighlightNoti(spaceId) !== 0);

  useEffect(() => {
    const notiChanged = (id, total, prevTotal) => {
      if (id === spaceId && total !== prevTotal) {
        setNotificationCount(abbreviateNumber(noti.getTotalNoti(spaceId)));
        setIsAlert(noti.getHighlightNoti(spaceId) !== 0);
      }
    };

    noti.on(cons.events.notifications.NOTI_CHANGED, notiChanged);
    noti.on(cons.events.notifications.MUTE_TOGGLED, notiChanged);

    setNotificationCount(abbreviateNumber(noti.getTotalNoti(spaceId)));
    setIsAlert(noti.getHighlightNoti(spaceId) !== 0);

    return () => {
      noti.off(cons.events.notifications.NOTI_CHANGED, notiChanged);
      noti.off(cons.events.notifications.MUTE_TOGGLED, notiChanged);
    };
  }, [noti, spaceId]);

  return (
    <div className="pinned-spaces-container-mobile">
      <PinnedSpaces
        onClick={() => {
          setSpaceId(spaceId);
        }}
        imageSrc={bannerUrl}
        generatedSvg={generatedSvg}
        tokenSymbol={tokenSymbol}
        roomName={room.name}
        avatar={
          <SpaceAvatar
            ref={avatarRef}
            roomId={room.name}
            imageSrc={avatarUrl}
            text={room.name}
            size={36}
            borderRadius={8}
            includeTransitions={false}
          />
        }
        notificationCount={notificationCount}
        isAlert={isAlert}
      />
    </div>
  );
}

export function SpaceShortcut({ shortcutId, index, length }) {
  const [selectedTab] = useSelectedTab();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [touchTimer, setTouchTimer] = useState(null);
  const [isActivePress, setIsActivePress] = useState(false); // For visual feedback
  const [isLongPress, setIsLongPress] = useState(false); // Flag for long press detection

  const handleTouchStart = () => {
    setIsActivePress(true); // Activate the scale transform
    setIsLongPress(false); // Reset long press flag
    const timer = setTimeout(() => {
      setIsLongPress(true); // Set long press flag
      setIsModalOpen(true); // Open modal on long press
      setIsActivePress(false); // Deactivate the scale transform when modal opens
      Haptics.impact({ style: ImpactStyle.Heavy }); // Optional heavier feedback
    }, 500); // Duration before considering it a long press
    setTouchTimer(timer);
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimer);
    if (!isLongPress) {
      setIsActivePress(false); // Deactivate the scale transform if not a long press
      Haptics.impact({ style: ImpactStyle.Light }); // Light feedback on single touch end
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(touchTimer);
    };
  }, [touchTimer]);

  return (
    <div
      className={`space-shortcut-wrapper no-touch ${
        isActivePress ? "active-press" : ""
      } ${index === 0 ? "div-padding-left" : ""} ${
        index === length - 1 ? "div-padding-right" : ""
      } ${selectedTab === shortcutId ? "active" : ""}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <DraggableSpaceShortcut spaceId={shortcutId} />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <RoomOptions
          roomId={shortcutId}
          afterOptionSelect={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
}

function SideBar() {
  return (
    <div className="sidebar">
      <div className="sidebar-container">
        <FeaturedTab />
      </div>
    </div>
  );
}

export default SideBar;
