import React, { useState, useEffect } from "react";
import { ReactComponent as AddProposal } from "../../assets/svg/fillproposal.svg";
import "./Governance.scss";
import Popover from "../../atoms/popover/Popover";
import HighlightsContainer from "../../atoms/highlights/HighlightsContainer";
import { ReactComponent as GovernanceIcon } from "../../assets/svg/governance.svg";
import CreateProposal from "../create-proposal/CreateProposal";
import ViewProposal from "./ViewProposal";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { formatDisplayName } from "../../../util/formatDisplayName";
import initMatrix from "../../../client/initMatrix";

function Governance({ roomIds, space, spaceId, data }) {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [filteredRoomIds, setFilteredRoomIds] = useState([]);
  const [selectedGovernanceData, setSelectedGovernanceData] = useState(null);
  const [governanceRoomId, setGovernanceRoomId] = useState(null);

  useEffect(() => {
    const userId = space.getMember(initMatrix.matrixClient.getUserId())?.userId;
    const userPowerLevel = space.getMember(userId)?.powerLevel || 0;
    const requiredPowerLevel =
      space.currentState.getStateEvents("m.room.power_levels", "")?.getContent()
        ?.events?.["m.proposal.start"] || 75;

    setHasPermission(userPowerLevel >= requiredPowerLevel);
  }, [space]);

  useEffect(() => {
    if (!roomIds || roomIds.length === 0) {
      setFilteredRoomIds([]);
      return;
    }

    const governanceRooms = roomIds.filter(
      ({ content }) => content.proposalTitle
    );
    setFilteredRoomIds(governanceRooms);
  }, [roomIds]);

  const handleAddProposal = () => {
    if (hasPermission) {
      setOpenCreateDialog(true);
    }
  };

  const handleClickRoom = (roomId, governanceData, room) => {
    setSelectedGovernanceData(governanceData);
    setGovernanceRoomId(roomId);
    setOpenViewDialog(true);
  };

  return (
    <div className="rooms-category-header-wrapper">
      {hasPermission && (
        <div className="rooms-category-add-container-floating">
          <div
            className="rooms-category-add-wrapper-floating"
            onClick={handleAddProposal}
          >
            <AddProposal className="rooms-category-add-space-svglogo-floating" />
          </div>
        </div>
      )}
      {filteredRoomIds.map(({ roomId, content }) => (
        <ProposalItem
          key={roomId}
          roomId={roomId}
          governanceData={content}
          onClickRoom={handleClickRoom}
        />
      ))}
      {filteredRoomIds.length < 1 && (
        <HighlightsContainer
          text="Governance"
          subtext="No proposals created for this Space yet, stay tuned!"
          Icon={GovernanceIcon}
          iconFill="var(--color-accent-one)"
        />
      )}
      {openCreateDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenCreateDialog}
          headerText="Create Governance Proposal"
        >
          <CreateProposal
            setOpenDialog={setOpenCreateDialog}
            parentId={spaceId}
          />
        </Popover>
      )}
      {openViewDialog && selectedGovernanceData && (
        <Popover
          direction="right"
          setOpenDialog={setOpenViewDialog}
          headerText="View Proposal"
        >
          <ViewProposal
            governanceData={selectedGovernanceData}
            roomId={governanceRoomId}
            space={space}
            spaceId={spaceId}
            setOpenViewDialog={setOpenViewDialog}
            data={data}
          />
        </Popover>
      )}
    </div>
  );
}

function ProposalItem({ roomId, governanceData, onClickRoom }) {
  const getStatus = () => {
    const currentTime = new Date();
    const start = new Date(governanceData.startTime);
    const end = new Date(governanceData.endTime);

    if (currentTime < start) return "Upcoming";
    if (currentTime > end) return "Ended";
    return "Active";
  };

  const status = getStatus();
  const user = initMatrix.matrixClient.getUser(governanceData.proposalCreator);
  const displayName = user?.displayName || "Default Name";
  const avatarSrc = user?.avatarUrl
    ? initMatrix.matrixClient.mxcUrlToHttp(user.avatarUrl, 36, 36, "crop")
    : "default-avatar-url";

  return (
    <div
      key={roomId}
      className="proposal-item"
      onClick={() => onClickRoom(roomId, governanceData, roomId)}
    >
      <div className="view-proposal-header">
        <div className="view-proposal-header-title-container">
          <span className="proposal-title">{governanceData.proposalTitle}</span>
          <span className="proposal-description">
            {governanceData.proposalDescription
              .replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/[#]+/g, "")
              .replace(/\*\*|__/g, "")
              .replace(/\*/g, "")
              .replace(/_/g, "")}
          </span>
        </div>
        <div>
          <div className={`governance-proposal-status ${status.toLowerCase()}`}>
            <span className={`governance-status-label ${status.toLowerCase()}`}>
              {status}
            </span>
          </div>
        </div>
      </div>
      <div className="governance-proposal-container-flex">
        <UserAvatar
          size={22}
          userId={governanceData.proposalCreator}
          imageSrc={avatarSrc}
        />
        <span className="view-proposal-subtitle">
          Proposal by{" "}
          <span className="display-name">{formatDisplayName(displayName)}</span>
        </span>
      </div>
    </div>
  );
}

export default Governance;
