import React, { useEffect } from "react";
import { IonDatetime } from "@ionic/react";
import "./DateTimePicker.scss";

function toISOStringWithOffset(date) {
  const tzOffset = date.getTimezoneOffset() * 60000;
  const localISOTime = new Date(date.getTime() - tzOffset)
    .toISOString()
    .slice(0, -1);
  return localISOTime;
}

function DateTime({ value, setValue }) {
  useEffect(() => {
    if (!value) {
      const currentTime = toISOStringWithOffset(new Date());
      setValue(currentTime);
    }
  }, [value, setValue]);

  return (
    <IonDatetime
      id="endDatetime"
      preferWheel={true}
      value={value}
      onIonChange={(e) => setValue(e.detail.value)}
    />
  );
}

export default DateTime;
