import React from "react";
import PropTypes from "prop-types";
import "./TimelineChange.scss";
import RawIcon from "../../atoms/system-icons/RawIcon";
import JoinArraowIC from "../../assets/svg/join-arrow.svg";
import LeaveArraowIC from "../../assets/svg/leave-arrow.svg";
import InviteArraowIC from "../../assets/svg/invite-arrow.svg";
import InviteCancelArraowIC from "../../assets/svg/invite-cancel-arrow.svg";
import UserIC from "../../assets/svg/user.svg";

function TimelineChange({ variant, content }) {
  let iconSrc;

  switch (variant) {
    case "join":
      iconSrc = JoinArraowIC;
      break;
    case "leave":
      iconSrc = LeaveArraowIC;
      break;
    case "invite":
      iconSrc = InviteArraowIC;
      break;
    case "invite-cancel":
      iconSrc = InviteCancelArraowIC;
      break;
    case "avatar":
      iconSrc = UserIC;
      break;
    default:
      iconSrc = JoinArraowIC;
      break;
  }

  return (
    <div className="timeline-change-container">
      <div className="timeline-change">
        <div className="timeline-change-container-icon">
          <RawIcon src={iconSrc} size="extra-small" />
        </div>
        <span className="timeline-change-text">{content}</span>
      </div>
    </div>
  );
}

TimelineChange.propTypes = {
  variant: PropTypes.oneOf([
    "join",
    "leave",
    "invite",
    "invite-cancel",
    "avatar",
    "other",
  ]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  timestamp: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default TimelineChange;
