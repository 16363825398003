import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./RoomPermissions.scss";
import initMatrix from "../../../client/initMatrix";
import { getPowerLabel } from "../../../util/matrixUtil";
import PowerLevelSelector from "../power-level-selector/PowerLevelSelector";
import { ReactComponent as DownArrow } from "../../assets/svg/down-arrow.svg";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import Modal from "../../atoms/modal/Modal";

function getPermissionsInfo(isSpace) {
  const roomTypeDescription = isSpace ? "space" : "room";
  return {
    users_default: {
      name: "Default role",
      description: "Set default role for all members.",
      default: 0,
    },
    events_default: {
      name: "Send messages",
      description: "Set minimum power level to send messages in room.",
      default: 0,
    },
    "m.reaction": {
      parent: "events",
      name: "Send reactions",
      description: "Set minimum power level to send reactions in room.",
      default: 0,
    },
    redact: {
      name: "Delete messages from others",
      description: "Set minimum power level to delete messages in room.",
      default: 100,
    },
    notifications: {
      name: "Ping room",
      description: "Set minimum power level to ping room.",
      default: 100,
    },
    "m.space.child": {
      parent: "events",
      name: "Manage rooms in space",
      description: "Set minimum power level to manage rooms in space.",
      default: 100,
    },
    invite: {
      name: "Invite",
      description: "Set minimum power level to invite members.",
      default: 50,
    },
    kick: {
      name: "Kick",
      description: "Set minimum power level to kick members.",
      default: 50,
    },
    ban: {
      name: "Ban",
      description: "Set minimum power level to ban members.",
      default: 50,
    },
    "m.room.avatar": {
      parent: "events",
      name: "Change avatar",
      description: `Set minimum power level to change ${roomTypeDescription} avatar.`,
      default: 100,
    },
    "m.room.name": {
      parent: "events",
      name: "Change name",
      description: `Set minimum power level to change ${roomTypeDescription} name.`,
      default: 100,
    },
    "m.room.topic": {
      parent: "events",
      name: "Change topic",
      description: `Set minimum power level to change ${roomTypeDescription} topic.`,
      default: 100,
    },
    state_default: {
      name: "Change settings",
      description: "Set minimum power level to change settings.",
      default: 100,
    },
    "m.room.canonical_alias": {
      parent: "events",
      name: "Change published address",
      description: "Set minimum power level to publish and set main address.",
      default: 100,
    },
    "m.room.power_levels": {
      parent: "events",
      name: "Change permissions",
      description: "Set minimum power level to change permissions.",
      default: 100,
    },
    "m.room.encryption": {
      parent: "events",
      name: "Enable room encryption",
      description: "Set minimum power level to enable room encryption.",
      default: 100,
    },
    "m.room.history_visibility": {
      parent: "events",
      name: "Change history visibility",
      description:
        "Set minimum power level to change room messages history visibility.",
      default: 50,
    },
    "m.room.tombstone": {
      parent: "events",
      name: "Upgrade room",
      description: "Set minimum power level to upgrade room.",
      default: 50,
    },
    "m.room.pinned_events": {
      parent: "events",
      name: "Pin messages",
      description: "Set minimum power level to pin messages in room.",
      default: 50,
    },
    "m.proposal.start": {
      parent: "events",
      name: "Create governance",
      description:
        "Set minimum power level to create governance proposals in room.",
      default: 75,
    },
  };
}

const roomPermsGroups = {
  "General Permissions": ["events_default", "m.reaction", "redact"],
  "Manage members permissions": ["invite", "kick", "ban"],
  "Room profile permissions": ["m.room.avatar", "m.room.name", "m.room.topic"],
  "Settings permissions": ["m.room.power_levels", "m.room.history_visibility"],
};

const spacePermsGroups = {
  "General Permissions": ["m.space.child"],
  "Manage members permissions": ["invite", "kick", "ban"],
  "Space profile permissions": ["m.room.avatar", "m.room.name", "m.room.topic"],
  "Settings permissions": ["m.room.power_levels"],
  "Governance Permissions": ["m.proposal.start"],
};

function useRoomStateUpdate(roomId) {
  const [, forceUpdate] = useForceUpdate();
  const mx = initMatrix.matrixClient;

  useEffect(() => {
    const handleStateEvent = (event) => {
      if (event.getRoomId() !== roomId) return;
      forceUpdate();
    };

    mx.on("RoomState.events", handleStateEvent);
    return () => {
      mx.removeListener("RoomState.events", handleStateEvent);
    };
  }, [roomId]);
}

function RoomPermissions({ roomId }) {
  useRoomStateUpdate(roomId);
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const pLEvent = room.currentState.getStateEvents("m.room.power_levels")[0];
  const permissions = pLEvent.getContent();
  const myPowerLevel = room.getMember(mx.getUserId())?.powerLevel ?? 100;

  const [popoverKey, setPopoverKey] = useState(null);
  const [popoverPowerLevel, setPopoverPowerLevel] = useState(null);
  const [openPowerLevelSelectorDialog, setPowerLevelSelectorDialog] =
    useState(false);

  const handlePowerLevelChange = (newPowerLevel) => {
    const newPermissions = { ...permissions };
    newPermissions[popoverKey] = newPowerLevel;
    mx.sendStateEvent(roomId, "m.room.power_levels", newPermissions).then(
      () => {
        setPowerLevelSelectorDialog(false);
      }
    );
  };

  const permsGroups = room.isSpaceRoom() ? spacePermsGroups : roomPermsGroups;
  const isSpace = room.getType() === "m.space";
  const permissionsInfo = getPermissionsInfo(isSpace);

  return (
    <div className="room-permissions-container">
      {Object.keys(permsGroups).map((groupKey) => (
        <div key={groupKey}>
          <div className="general-settings-room-options">
            <span className="space-landing-space-data-title-text">
              {groupKey}
            </span>
          </div>
          <div className="room-permissions-card">
            {permsGroups[groupKey].map((permKey) => {
              const permInfo = permissionsInfo[permKey];
              let powerLevel = permissions[permKey] ?? permInfo.default;
              const { label, color, colorSoft } = getPowerLabel(powerLevel);

              return (
                <div
                  className="room-permissions-permission-container"
                  key={`${groupKey}-${permKey}`}
                  onClick={() => {
                    setPopoverKey(permKey);
                    setPopoverPowerLevel(powerLevel);
                    setPowerLevelSelectorDialog(true);
                  }}
                >
                  <div className="room-permissions-permission-text-container">
                    <div className="room-permissions-permission-wrapper-body-container">
                      <span className="room-permissions-permission-header-text">
                        {permInfo.name}
                      </span>
                      <div
                        className="room-permissions-permission-wrapper-container"
                        style={{
                          borderColor: `var(${color})`,
                          backgroundColor: `var(${colorSoft})`,
                          border: `1px solid var(${color})`,
                        }}
                      >
                        <span
                          className="room-permissions-permission-tag-text"
                          style={{ color: `var(${color})` }}
                        >
                          {label}
                        </span>
                      </div>
                    </div>
                    <span className="room-permissions-permission-body-text">
                      {permInfo.description}
                    </span>
                  </div>
                  <DownArrow className="room-permissions-right-svglogo" />
                </div>
              );
            })}
          </div>
        </div>
      ))}
      {openPowerLevelSelectorDialog && (
        <Modal
          isOpen={openPowerLevelSelectorDialog}
          onClose={() => setPowerLevelSelectorDialog(false)}
        >
          <PowerLevelSelector
            value={popoverPowerLevel}
            max={myPowerLevel}
            onSelect={handlePowerLevelChange}
          />
        </Modal>
      )}
    </div>
  );
}

RoomPermissions.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default RoomPermissions;
