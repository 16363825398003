import { useState, useEffect } from "react";
import { ethers } from "ethers";
import VestingABI from "../../util/abi/VestingABI.json";

const useFetchVestingData = (tokenData, signer) => {
  const [vesting, setVesting] = useState(false);
  const [vestingTimeLeft, setVestingTimeLeft] = useState("");
  const [vestingPool, setVestingPool] = useState("");
  const [vestingAddress, setVestingAddress] = useState([]);
  const [vestingUnclaimedPool, setVestingUnclaimedPool] = useState("");

  useEffect(() => {
    const fetchVestingData = async () => {
      const nowUnix = Math.floor(Date.now() / 1000);

      const times = tokenData.tokenTokenomics
        .filter((item) => {
          const hasEndTime = item.endTime && !isNaN(Number(item.endTime));
          return hasEndTime;
        })
        .map((item) => {
          const endTimeBigInt = BigInt(item.endTime);
          const timeLeftSeconds = Number(endTimeBigInt - BigInt(nowUnix));
          return {
            timeLeftSeconds,
            pool: item.Pool,
            address: item.vestingContract,
          };
        });

      const futureVestingPools = times
        .filter((item) => item.timeLeftSeconds > 0)
        .sort((a, b) => a.timeLeftSeconds - b.timeLeftSeconds);

      const releasablePools = [];
      const relevantAddresses = [];

      for (const item of times) {
        try {
          const vestingContract = new ethers.Contract(
            item.address,
            VestingABI,
            signer
          );
          const tokenContract = new ethers.Contract(
            tokenData.tokenContractAddress,
            ["function balanceOf(address owner) view returns (uint256)"],
            signer
          );

          const balance = await tokenContract.balanceOf(item.address);
          if (balance > 0n) {
            try {
              const releasableAmount = await vestingContract.releasableAmount(
                tokenData.tokenContractAddress
              );
              if (releasableAmount > 0n) {
                releasablePools.push(item.pool);
                relevantAddresses.push(item.address);
              }
            } catch (innerError) {
              console.error(
                `Failed to fetch releasable amount for ${item.address}:`,
                innerError
              );
            }
          }
        } catch (error) {
          console.error(
            `Failed to interact with contract for ${item.address}:`,
            error
          );
        }
      }

      setVesting((prevVesting) => {
        const newVestingState = releasablePools.length > 0 && times.length >= 1;
        return newVestingState;
      });

      if (releasablePools.length > 0) {
        setVestingUnclaimedPool(releasablePools.join(" & "));
        setVestingAddress(relevantAddresses);
      }

      if (futureVestingPools.length) {
        const nextVestingTime = futureVestingPools[0].timeLeftSeconds;
        const poolsVestingTogether = futureVestingPools
          .filter((pool) => pool.timeLeftSeconds === nextVestingTime)
          .map((pool) => pool.pool)
          .join(", ");
        setVestingPool(poolsVestingTogether);

        const formattedTimeLeft = formatTimeLeft(Number(nextVestingTime));
        setVestingTimeLeft(formattedTimeLeft);
      } else {
        setVestingTimeLeft("None");
        setVestingPool("None");
      }
    };

    const interval = setInterval(fetchVestingData, 1000);
    return () => clearInterval(interval);
  }, [tokenData, signer]);

  return {
    vesting,
    vestingTimeLeft,
    vestingPool,
    vestingAddress,
    vestingUnclaimedPool,
  };
};

function formatTimeLeft(seconds) {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = [];
  if (days > 0) result.push(`${days} day${days > 1 ? "s" : ""}`);
  if (hours > 0) result.push(`${hours} hr${hours > 1 ? "s" : ""}`);
  if (minutes > 0) result.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
  if (remainingSeconds > 0 || result.length === 0)
    result.push(`${remainingSeconds} sec${remainingSeconds !== 1 ? "s" : ""}`);

  return result.join(", ");
}

export default useFetchVestingData;
