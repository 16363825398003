import React, { useState, useEffect } from "react";
import { useDialog } from "../../contexts/DialogContext";
import "./SignUpDialog.scss";
import { addEmailAndAddress } from "../../../util/Firebase";
import { validateAndExecute } from "../../../util/validateAndExecute";
import XLogo from "../../assets/svg/X-logo";
import CloseIcon from "../../assets/svg/close-icon";
import image1 from "../../assets/image/Signup1.png";
import image2 from "../../assets/image/Signup2.png";
import { Link } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";

const SignUpDialog = () => {
  const { isVisible, toggleDialog } = useDialog();
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const { theme, updateThemeColor } = useTheme();

  useEffect(() => {
    const themeColorMetaTag = document.querySelector(
      "meta[name='theme-color']"
    );
    const originalThemeColor = themeColorMetaTag
      ? themeColorMetaTag.getAttribute("content")
      : theme === "dark-theme"
      ? "#1b1b1b"
      : "#f9f9f9";

    if (isVisible) {
      document.body.style.overflow = "hidden";
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute(
          "content",
          theme === "dark-theme" ? "#111111" : "#ffffff"
        );
      }
    } else {
      document.body.style.overflow = "unset";
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute("content", originalThemeColor);
      }
    }

    return () => {
      document.body.style.overflow = "unset";
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute("content", originalThemeColor);
      }
    };
  }, [isVisible, theme]);

  const openTwitter = () =>
    window.open("https://twitter.com/waivlength_io", "_blank");

  const handleAddAddress = (acceptTermsAndPrivacy) => {
    validateAndExecute({
      input: address,
      acceptTermsAndPrivacy: acceptTermsAndPrivacy,
      validateInput: {
        isValid: (address) => {
          const addressPattern = /^0x[a-fA-F0-9]{40}$/;
          const ensPattern = /^[a-zA-Z0-9-]+\.eth$/;
          return (
            address &&
            (addressPattern.test(address) || ensPattern.test(address))
          );
        },
        failureMessage: "Address is invalid",
        logMessage: "Address check failed: Address is invalid",
      },
      action: addEmailAndAddress,
      onSuccess: (msg) => {
        setMessage(msg);
      },
      onError: (msg) => {
        setMessage(msg);
      },
      onValidationFailure: setValidationMessage,
      resetInput: () => setAddress(""),
    });
  };

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      toggleDialog();
    }
  };

  return (
    <div
      className={`dialog-background ${isVisible ? "visible" : ""}`}
      onClick={handleBackgroundClick}
    >
      <div className="dialog-content" onClick={(e) => e.stopPropagation()}>
        <div className="dialog-content-container">
          <div className="dialog-sign-up-top">
            <div className="dialog-sign-up-tag">
              <span className="dialog-sign-up-button-primary-text">
                Sign up for early access
              </span>
            </div>
            <div onClick={toggleDialog} className="dialog-sign-up-close">
              <CloseIcon
                fill="var(--color-bg-primary-flip)"
                height={16}
                width={16}
              />
            </div>
          </div>
          <span className="dialog-sign-up-header-text">
            Be first to try our beta Mobile app!
          </span>
          <span className="dialog-sign-up-body-text">
            When our mobile app launches, early access tokens will let you
            access it before anyone else.
          </span>
          <div className="dialog-sign-up-input-container">
            <span className="dialog-sign-up-footer-text">
              Ethereum address for early access
            </span>
            <input
              className="dialog-sign-up-input"
              placeholder={message || "Ethereum address or .eth domain"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <div
              className="dialog-sign-up-button"
              onClick={() => handleAddAddress(true)}
            >
              <span className="dialog-sign-up-button-text">
                {validationMessage || "Sign up"}
              </span>
            </div>
            <span className="dialog-sign-up-terms-text">
              By signing up, you agree to the{" "}
              <Link
                to="/legal/terms-of-service"
                className="terms-link"
                onClick={toggleDialog}
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                to="/legal/privacy-policy"
                className="terms-link"
                onClick={toggleDialog}
              >
                Privacy Policy
              </Link>
              .
            </span>
          </div>
          <div className="dialog-sign-up-socials-container">
            <span className="dialog-sign-up-header-socials-header-text">
              Know when Desktop beta is released!
            </span>
            <span className="dialog-sign-up-header-socials-text">
              @waivlength_io will be posting updates
            </span>
            <div onClick={openTwitter} className="dialog-sign-up-social">
              <span className="dialog-sign-up-button-primary-text">
                Follow us on
              </span>
              <XLogo
                fill="var(--color-bg-primary-flip)"
                height={16}
                width={16}
              />
            </div>
          </div>
        </div>
        <div className="dialog-mobile-preview-container">
          <div onClick={toggleDialog} className="dialog-sign-up-close-right">
            <CloseIcon
              fill="var(--color-bg-primary-flip)"
              height={16}
              width={16}
            />
          </div>
          <div className="dialog-sign-up-app-container">
            <img
              src={image1}
              alt="app-display"
              className="dialog-sign-up-app"
            />
            <img
              src={image2}
              alt="app-display"
              className="dialog-sign-up-app"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpDialog;
