import { useState, useEffect } from "react";
import initMatrix from "../../client/initMatrix";

function useMatrix() {
  const [user, setUser] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (!initMatrix || !initMatrix.matrixClient) {
      return;
    }

    const mx = initMatrix.matrixClient;
    const matrixUser = mx.getUser(mx.getUserId());

    setUser(matrixUser);

    if (matrixUser && matrixUser.avatarUrl && matrixUser.avatarUrl !== "null") {
      setAvatarUrl(mx.mxcUrlToHttp(matrixUser.avatarUrl, 80, 80, "crop"));
    }
  }, [initMatrix.matrixClient]);

  return { user, avatarUrl };
}

export default useMatrix;
