import React from "react";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import "./Navigation.scss";
import { Link } from "react-router-dom";
import ArrowIcon from "../../assets/svg/arrow-icon";

const NavigationLegal = () => {
  return (
    <div className="landing-page-navigation-legal">
      <div className="landing-page-navigation-logo-container">
        <WaivlengthLogo
          fill="var(--color-bg-primary-flip)"
          height={26}
          width={36}
        />
        <span className="landing-page-nav-logo-text">waivlength</span>
      </div>
      <div className="landing-page-navigation-sections"></div>
      <Link
        to="/"
        className="landing-page-navigation-signup-button-container-link"
      >
        <button className="landing-page-navigation-signup-button-container">
          <ArrowIcon
            stroke="var(--color-bg-primary)"
            strokeWidth={3}
            height={16}
            width={16}
            rotate={180}
          />
          <span className="landing-page-nav-signup-text">Return to home</span>
        </button>
      </Link>
    </div>
  );
};

export default NavigationLegal;
