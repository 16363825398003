import React, { useEffect, useRef, useState } from "react";
import "./RoomEventsTimeline.scss";
import initMatrix from "../../../client/initMatrix";
import TimelineEventWrapper from "./TimelineEventWrapper";
import { markAsRead } from "../../../client/action/notifications";
import ScrollDown from "../../atoms/scroll-down/ScrollDown";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as Background } from "../../assets/svg/message-background.svg";

const RoomEventsTimeline = ({
  roomId,
  room,
  scrollToBottomButtonRef,
  isKeyboardVisible,
  keyboardHeight,
  mx,
}) => {
  const [events, setEvents] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [lastReadEventId, setLastReadEventId] = useState(null);
  const containerRef = useRef(null);
  const [newEventCount, setNewEventCount] = useState(0);
  const platform = Capacitor.getPlatform();
  const isIOS = platform === "ios";
  const timelineStyles = {
    flexDirection: isIOS ? "column" : "column-reverse",
    justifyContent: isIOS ? "flex-end" : "",
  };
  const { roomsState } = initMatrix;
  const roomState = roomsState.getRoomState(roomId);
  const [showScrollDown, setShowScrollDown] = useState(false);

  useEffect(() => {
    const initializeRoomState = async () => {
      if (!roomState) {
        const initializedRoomState = await roomsState.initializeNewRoomState(
          room
        );
        setEvents(initializedRoomState.getEvents());
      } else {
        setEvents(roomState.getEvents());
      }
    };

    initializeRoomState();

    const lastRead = room.getEventReadUpTo(mx.getUserId());
    setLastReadEventId(lastRead);
  }, [roomId, room, mx, roomState]);

  useEffect(() => {
    if (!roomState) return;

    const liveEventListener = (newEvent, isReaction) => {
      if (isReaction) {
        setEvents([...roomState.getEvents()]);
      } else {
        setEvents((prevEvents) => [newEvent, ...prevEvents]);
      }
    };

    roomState.on("newEvent", liveEventListener);

    return () => {
      roomState.off("newEvent", liveEventListener);
    };
  }, [roomState]);

  useEffect(() => {
    if (events.length > 0) {
      const latestEvent = events[0];
      requestAnimationFrame(() => markAsRead(roomId, latestEvent.getId()));
    }
  }, [events, roomId]);

  const throttleRef = useRef(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !roomState) return;

    const handleScroll = async () => {
      if (throttleRef.current) return; // Exit if throttled
      throttleRef.current = true; // Set throttling flag

      // Prevent the handler from being called again for 300ms
      setTimeout(() => {
        throttleRef.current = false;
      }, 300);

      // Your existing scroll handling logic
      if (isFetching || !roomState.canPaginate) return;

      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight + scrollTop - clientHeight < 3000) {
        setIsFetching(true);
        try {
          const oldEvents = await roomState.fetchOldEvents();
          if (oldEvents.length > 0) {
            setEvents((prevEvents) => [...prevEvents, ...oldEvents]);
          }
        } catch (error) {
          console.error("Error fetching more events:", error);
        } finally {
          setIsFetching(false);
        }
      }
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching, roomState, containerRef, setEvents]);

  useEffect(() => {
    const handleScrollPosition = () => {
      if (!containerRef.current) return;
      const scrollTop = Math.abs(containerRef.current.scrollTop);
      if (scrollTop > 1000) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
      if (scrollTop < 10) {
        setNewEventCount(0);
      }
    };
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScrollPosition);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener(
          "scroll",
          handleScrollPosition
        );
      }
    };
  }, []);

  const handleScrollBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
      setNewEventCount(0);
    }
  };

  const handleReactionSelect = async ({ reaction, targetEvent }) => {
    if (!roomState) return;

    const targetEventId = targetEvent.getId();
    const userId = mx.getUserId();
    const userCurrentReaction = roomState.getUserReaction(
      targetEventId,
      userId
    );

    try {
      if (userCurrentReaction) {
        if (userCurrentReaction.key === reaction) {
          await roomState.removeReaction(targetEvent, userCurrentReaction.key);
        } else {
          await roomState.removeReaction(targetEvent, userCurrentReaction.key);
          await roomState.sendReaction(targetEvent, reaction);
        }
      } else {
        await roomState.sendReaction(targetEvent, reaction);
      }
      setEvents([...roomState.getEvents()]);
    } catch (error) {}
  };

  return (
    <div
      className="room-events-timeline"
      style={timelineStyles}
      ref={containerRef}
    >
      <Background className="background-svg" />
      {(isIOS ? [...events].reverse() : events).map((event, index) => (
        <TimelineEventWrapper
          key={`${event.getId()}-${index}`}
          event={event}
          lastReadEventId={lastReadEventId}
          events={events}
          containerRef={containerRef}
          room={room}
          roomId={roomId}
          loadMoreEvents={() => roomState.fetchOldEvents()}
          isKeyboardVisible={isKeyboardVisible}
          mx={mx}
          reactions={roomState.getReactions(event.getId())}
          onReactionSelect={handleReactionSelect}
          platform={platform}
        />
      ))}
      <ScrollDown
        handleScroll={handleScrollBottom}
        show={showScrollDown}
        scrollToBottomButtonRef={scrollToBottomButtonRef}
        newEventCount={newEventCount}
        keyboardHeight={keyboardHeight}
      />
    </div>
  );
};

export default RoomEventsTimeline;
