import React from "react";
import "./Landing.scss";
import Navigation from "../../components/navigation/Navigation";
import BackgroundHeaderGradient from "../../assets/svg/background-header-gradient";
import Hero from "../../components/hero/Hero";
import { Link } from "react-router-dom";

function Landing() {
  return (
    <div className="app-wrapper">
      <div className="app-background-header-gradient">
        <BackgroundHeaderGradient
          height={1900}
          width={1900}
          fillOpacity={"0.6"}
          fillOpacityWhite={"0.4"}
          fill="var(--color-accent-one)"
        />
      </div>
      <Navigation />
      <Hero />
      <div className="footer-component">
        <span className="footer-text-waivlength">© Waivlength 2024</span>
        <Link to="/legal/terms-of-service" className="footer-terms-link">
          Terms of Service
        </Link>
        <Link to="/legal/privacy-policy" className="footer-terms-link">
          Privacy Policy
        </Link>
      </div>
    </div>
  );
}

export default Landing;
