import { selectTab } from "../../client/action/navigation";
import initMatrix from "../../client/initMatrix";
import cons from "../../client/state/cons";
import { useRoomSelect } from "../contexts/RoomSelectContext";
import { App as CapacitorApp } from "@capacitor/app";

const useNotificationHandler = () => {
  const { setDmRoomId, setSpaceId, setRoomId, roomId, dmRoomId } =
    useRoomSelect();
  const checkAppStateAndRoom = (newRoomId, callback) => {
    let retries = 0;
    const intervalId = setInterval(async () => {
      const mx = initMatrix.matrixClient;
      const room = mx.getRoom(newRoomId);
      const appState = await CapacitorApp.getState();
      if (initMatrix.isInitialized && room && appState.isActive) {
        clearInterval(intervalId);
        callback(room);
      } else if (retries > 30) {
        clearInterval(intervalId);
        console.error("Failed to initialize room or app state:", newRoomId);
      }
      retries++;
    }, 100);
  };

  const handleNotification = async (newRoomId, isInvite, eventId) => {
    checkAppStateAndRoom(newRoomId, (room) => {
      if (isInvite) {
        setSpaceId(null);
        setDmRoomId(null);
        setRoomId(null);
        selectTab(cons.tabs.NOTIFICATIONS);
        return;
      }

      if (newRoomId === roomId || newRoomId === dmRoomId) {
        return;
      }

      setSpaceId(null);
      setDmRoomId(null);
      setRoomId(null);

      const isDM = initMatrix.roomList.directs.has(newRoomId);
      const spaceParentEventMap =
        room.currentState.events.get("m.space.parent");
      const spaceParentId = spaceParentEventMap
        ? Array.from(spaceParentEventMap.values())[0].getStateKey()
        : null;

      if (room.getType() === "m.space") {
        selectTab(cons.tabs.HOME);
        setSpaceId(newRoomId);
      } else if (isDM) {
        selectTab(cons.tabs.DIRECTS);
        setDmRoomId(newRoomId);
      } else if (spaceParentId) {
        selectTab(cons.tabs.HOME);
        setSpaceId(spaceParentId);
        setRoomId(newRoomId);
      } else {
        selectTab(cons.tabs.HOME);
        setRoomId(newRoomId);
      }
    });
  };

  return { handleNotification };
};

export default useNotificationHandler;
