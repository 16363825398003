import React from "react";

const ReferralScoreIcon = ({ colorStyle, height, width }) => {
  const isGradient = colorStyle.startsWith("linear-gradient");

  let gradientColors = [];

  if (isGradient) {
    const colorRegex = /#([0-9a-f]{6}|[0-9a-f]{3})/gi;
    gradientColors = colorStyle.match(colorRegex);
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isGradient && gradientColors.length >= 2 && (
        <defs>
          <linearGradient id="iconGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop offset="100%" stopColor={gradientColors[1]} />
          </linearGradient>
        </defs>
      )}
      <path
        d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12.396 18C12.18 18.072 11.808 18.072 11.592 18C9.72 17.364 5.52 14.688 5.52 10.152C5.52 8.148 7.128 6.528 9.12 6.528C10.296 6.528 11.34 7.092 12 7.98C12.648 7.104 13.704 6.528 14.88 6.528C16.872 6.528 18.48 8.148 18.48 10.152C18.48 14.688 14.28 17.364 12.396 18Z"
        fill={isGradient ? "url(#iconGradient)" : colorStyle}
      />
    </svg>
  );
};

export default ReferralScoreIcon;
