import React from "react";
import "./ChatFilter.scss";

const ChatFilter = ({ label, isActive, onClick }) => {
  return (
    <div
      className={`chat-filter-item ${isActive ? "active" : "inactive"}`}
      onClick={onClick}
    >
      <span className={`chat-filter-label ${isActive ? "active" : "inactive"}`}>
        {label}
      </span>
    </div>
  );
};

export default ChatFilter;
