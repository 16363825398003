// valueChecker.js

/**
 * Checks if the given value is a valid number format.
 *
 * @param {string} value - The value to check.
 * @returns {boolean} - Returns true if the value is valid, otherwise false.
 */
export function isValidValue(value) {
  // Regular expression to match valid numbers
  // Allows numbers starting with 0 followed by a digit (e.g., 02, 07) and other valid formats
  const validNumberPattern = /^(0[1-9]|0?\.\d*[1-9]+\d*|[1-9]+\d*(\.\d+)?)$/;

  // Test the value against the pattern
  return validNumberPattern.test(value);
}
