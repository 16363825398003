import React from "react";
import PropTypes from "prop-types";
import "./DrawerAvatar.scss";
import RawIcon from "../system-icons/RawIcon";

const DrawerAvatar = React.forwardRef(({ iconSrc, iconColor, size }, ref) => {
  if (size === "large");
  if (size === "small");
  if (size === "extra-small");
  if (size === "really-small");

  return (
    <div
      ref={ref}
      className={`drawer-avatar-container drawer-avatar-container__${size} noselect`}
    >
      <RawIcon size={size} src={iconSrc} color={iconColor} />
    </div>
  );
});


DrawerAvatar.propTypes = {
  iconSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  iconColor: PropTypes.string,
  imageSrc: PropTypes.string,
  size: PropTypes.oneOf([
    "large",
    "normal",
    "small",
    "extra-small",
    "really-small",
  ]),
};

export default DrawerAvatar;
