import React, { useState } from "react";
import cons from "../../../client/state/cons";
import Loader from "../../atoms/loader/Loader";
import "./ReferralComponent.scss";
import { ReactComponent as Copy } from "../../assets/svg/fillcopy.svg";
import { ReactComponent as CopySuccess } from "../../assets/svg/fillcopysuccess.svg";
import { ReactComponent as Referral } from "../../assets/svg/fillshare.svg";
import usernameColorMXID from "../../../util/usernameColorMXID";
import ReferralScoreIcon from "../../assets/svg/ReferralScoreIcon";
import { generateReferralCode } from "../../../client/referrals/api";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";

const ReferralComponent = ({
  referralCode,
  error,
  actionScore,
  matrixUser,
  mx,
}) => {
  const [newReferralCode, setNewReferralCode] = useState(referralCode);
  const [newError, setNewError] = useState(error);
  const [generating, setGenerating] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const colorStyle = usernameColorMXID(matrixUser.userId);
  const isGradient = colorStyle.startsWith("linear-gradient");
  const textStyle = isGradient
    ? {
        background: colorStyle,
        WebkitBackgroundClip: "text",
        color: "transparent",
      }
    : { color: colorStyle };

  const handleGenerateReferral = async () => {
    const accessToken = localStorage.getItem(cons.secretKey.ACCESS_TOKEN);

    if (!accessToken) {
      setNewError("Unauthorized: No access token found.");
      return;
    }

    setGenerating(true);
    setNewError(null);
    try {
      const code = await generateReferralCode(accessToken);
      await updateCommunityMembershipScore(mx, "referral");
      setNewReferralCode(code);
    } catch (err) {
      setNewError(err || "Failed to generate referral code.");
    } finally {
      setGenerating(false);
    }
  };

  const handleCopy = () => {
    const copyToClipboard = async (text) => {
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(text);
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "fixed";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };

    copyToClipboard(newReferralCode);
  };

  return (
    <div className="membership-score-container">
      <div className="membership-score-wrapper">
        <div className="membership-score-user-score-container">
          <ReferralScoreIcon colorStyle={colorStyle} height={56} width={56} />
          <span className="membership-score-user-score-text" style={textStyle}>
            {actionScore}
          </span>
        </div>
        <span className="membership-score-user-score-title-text">
          Friends You've Successfully Referred
        </span>
      </div>
      <div className="membership-score-more-wrapper">
        {newError && <span className="create-room-error-text">{newError}</span>}
        {newReferralCode ? (
          <div className="referral-generate-container">
            <div
              className="wallet-connected-container-wrapper"
              onClick={handleCopy}
            >
              <div className="referral-code-container">
                <span className="wallet-connected-settings-primary-text">
                  {newReferralCode}
                </span>
              </div>
              <div className="wallet-settings-icon-button-container">
                {copySuccess ? (
                  <CopySuccess className="wallet-copy-container-svglogo-fill" />
                ) : (
                  <Copy className="wallet-copy-container-svglogo-stroke" />
                )}
              </div>
            </div>
            <span className="membership-score-details-subtitle-text">
              You will be able to generate a new referral code once the user you
              shared the code with activates their account
            </span>
          </div>
        ) : (
          <div className="referral-generate-container">
            <div className="membership-score-details-wrapper">
              <div
                className="membership-score-details-container"
                disabled={generating}
                onClick={handleGenerateReferral}
              >
                <Referral className="gitbook-svglogo-fill" />
                {!generating ? (
                  <span className="membership-score-details-title-text">
                    Generate Referral Code
                  </span>
                ) : (
                  <Loader
                    size="20px"
                    dotSize="6px"
                    color="var(--light)"
                    multiplier={1.4}
                  />
                )}
              </div>
            </div>
            <span className="membership-score-details-subtitle-text">
              You can generate a referral code for your friend to use. Share it
              with them to give them access to special features and benefits!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralComponent;
