import React, { useState } from "react";
import "./WalletSettings.scss";
import { ReactComponent as Tick } from "../../assets/svg/fillcircletick.svg";
import { ReactComponent as Copy } from "../../assets/svg/fillcopy.svg";
import { ReactComponent as CopySuccess } from "../../assets/svg/fillcopysuccess.svg";
import Loader from "../../atoms/loader/Loader";

const PrivateKey = ({ privateKey, setOpenDialog }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    const copyToClipboard = async (text) => {
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(text);
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "fixed";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };

    copyToClipboard(privateKey);
  };

  return (
    <div className="wallet-backup-container">
      <div className="wallet-backup-wrapper">
        <div className="wallet-backup-notification">
          <Tick className="wallet-secret-phrase-svglogo-fill" />
          <span className="wallet-sp-text-primary">Save your Private Key</span>
          <span className="wallet-backup-text-secondary">
            This key is the access to your wallet. Make sure you save it in a
            secure location.
          </span>
          <div onClick={handleCopy} className="wallet-copy-container">
            {copySuccess ? (
              <CopySuccess className="wallet-copy-container-svglogo-fill" />
            ) : (
              <Copy className="wallet-copy-container-svglogo-stroke" />
            )}
            {copySuccess ? (
              <span className="wallet-sp-text-secondary">Copied</span>
            ) : (
              <span className="wallet-sp-text-secondary">
                Copy to clipboard
              </span>
            )}
          </div>
          <div className="private-key-container">
            <span className="private-key-text-primary">{privateKey}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateKey;
