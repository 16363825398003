import React, { createContext, useContext, useState } from "react";

const DialogContext = createContext({
  isVisible: false,
  toggleDialog: () => {},
});

export const DialogProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleDialog = () => setIsVisible(!isVisible);

  return (
    <DialogContext.Provider value={{ isVisible, toggleDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
