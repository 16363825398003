import React, { useState } from "react";
import { ReactComponent as RemoveRow } from "../../assets/svg/minus.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import "./ViewProposal.scss";

const WeightedVoting = ({ options, optionWeights, setOptionWeights }) => {
  const [clickCounts, setClickCounts] = useState(
    options.reduce((acc, option) => ({ ...acc, [option.value]: 0 }), {})
  );

  const getTotalClicks = (counts) =>
    Object.values(counts).reduce((a, b) => a + b, 0);

  const recalculateWeights = (newClickCounts) => {
    const totalClicks = getTotalClicks(newClickCounts);
    const updatedWeights = options.reduce((acc, option) => {
      acc[option.value] =
        totalClicks > 0
          ? (newClickCounts[option.value] / totalClicks) * 100
          : 0;
      return acc;
    }, {});
    setOptionWeights(updatedWeights);
  };

  const increaseWeight = (optionValue) => {
    setClickCounts((prevCounts) => {
      const selectedOptionsCount = Object.values(prevCounts).filter(
        (count) => count > 0
      ).length;

      const isOnlyOneSelected =
        selectedOptionsCount === 1 && prevCounts[optionValue] > 0;

      if (isOnlyOneSelected) return prevCounts;

      const newClickCounts = {
        ...prevCounts,
        [optionValue]: prevCounts[optionValue] + 1,
      };
      recalculateWeights(newClickCounts);
      return newClickCounts;
    });
  };

  const decreaseWeight = (optionValue) => {
    setClickCounts((prevCounts) => {
      if (prevCounts[optionValue] === 0) return prevCounts;
      const newClickCounts = {
        ...prevCounts,
        [optionValue]: prevCounts[optionValue] - 1,
      };
      recalculateWeights(newClickCounts);
      return newClickCounts;
    });
  };

  const calculatePercentage = (optionValue) => {
    return (optionWeights[optionValue] || 0).toFixed(1);
  };

  return (
    <>
      {options.map((option) => (
        <div
          key={option.id}
          className={`view-proposal-voting-container ${
            optionWeights[option.value] > 0 ? "active" : "inactive"
          }`}
        >
          <div className="weight-buttons">
            <div
              onClick={() => decreaseWeight(option.value)}
              className="weight-percentage-button-svg"
            >
              <RemoveRow className="weight-percentage-button-svg-icon" />
            </div>
            <div className="weight-buttons-text-container">
              <span className="view-proposal-voting-percentage-text">
                {option.value}
              </span>
              <div className="weight-percentage-container">
                <span className="voting-percentage-text">
                  {calculatePercentage(option.value)}%
                </span>
              </div>
            </div>
            <div
              onClick={() => increaseWeight(option.value)}
              className="weight-percentage-button-svg"
            >
              <Plus className="weight-percentage-button-svg-icon" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default WeightedVoting;
