import { incrementActionCount } from "../client/referrals/api";
import cons from "../client/state/cons";

const ACTION_POINTS = {
  message: 1,
  reaction: 1,
  referral: 20,
  create_account: 0,
  daily_active_user: 0,
  create_space: 20,
  create_presale: 20,
  finalize_presale: 20,
  cast_vote: 3,
  create_proposal: 5,
  swap: 3,
  create_room: 2,
  post: 2,
  vote: 1,
  comment: 1,
};

const RATE_LIMIT_INTERVALS = {
  message: 10 * 60 * 1000, // 10 minutes
  comment: 10 * 60 * 1000, // 10 minutes
  post: 10 * 60 * 1000, // 10 minutes
  vote: 10 * 60 * 1000, // 10 minutes
  reaction: 10 * 60 * 1000, // 10 minutes
  create_proposal: 24 * 60 * 60 * 1000, // 24 hours
  create_room: 24 * 60 * 60 * 1000, // 24 hours
};

const EVENT_TYPE = "my.custom.community.scores";

/**
 * Updates the community membership score based on the action performed.
 *
 * @param {Object} mxClient - The client instance for interacting with the backend.
 * @param {string} actionType - The type of action performed.
 * @param {string|null} spaceId - (Optional) The ID of the space where the action occurred.
 * @param {boolean} useStateKey - (Optional) Whether to use state key.
 */
export async function updateCommunityMembershipScore(
  mxClient,
  actionType,
  spaceId = null,
  useStateKey = false
) {
  // Skip score updates in development environment
  if (import.meta.env.VITE_ENVIRONMENT === "development") {
    return;
  }

  const points = ACTION_POINTS[actionType] || 0;
  const accountDataEvent = mxClient.getAccountData(EVENT_TYPE);
  const accountDataRaw = accountDataEvent
    ? accountDataEvent.getContent()
    : null;
  const stateKey = mxClient.getUserId();
  const accessToken = localStorage.getItem(cons.secretKey.ACCESS_TOKEN);
  const accountData = accountDataRaw?.communityMembershipScores || {
    individualScore: { total: 0, actions: [], lastActionTimestamps: {} },
    spaces: {},
  };
  const currentTime = Date.now();
  let isRateLimited = false;

  // Rate limiting logic
  if (RATE_LIMIT_INTERVALS[actionType]) {
    const rateLimitInterval = RATE_LIMIT_INTERVALS[actionType];

    // Initialize lastActionTimestamps if not present
    if (!accountData.individualScore.lastActionTimestamps) {
      accountData.individualScore.lastActionTimestamps = {};
    }
    const lastIndividualActionTimestamp =
      accountData.individualScore.lastActionTimestamps[actionType] || 0;

    if (currentTime - lastIndividualActionTimestamp < rateLimitInterval) {
      isRateLimited = true;
    } else {
      // Update the timestamp for individual actions
      accountData.individualScore.lastActionTimestamps[actionType] =
        currentTime;
    }

    if (spaceId) {
      // Initialize space data if not present
      if (!accountData.spaces[spaceId]) {
        accountData.spaces[spaceId] = {
          total: 0,
          actions: [],
          lastActionTimestamps: {},
        };
      }
      if (!accountData.spaces[spaceId].lastActionTimestamps) {
        accountData.spaces[spaceId].lastActionTimestamps = {};
      }
      const lastSpaceActionTimestamp =
        accountData.spaces[spaceId].lastActionTimestamps[actionType] || 0;

      if (currentTime - lastSpaceActionTimestamp < rateLimitInterval) {
        isRateLimited = true;
      } else {
        // Update the timestamp for space-specific actions
        accountData.spaces[spaceId].lastActionTimestamps[actionType] =
          currentTime;
      }
    }
  }

  // If not rate-limited, update community scores
  if (!isRateLimited) {
    if (spaceId) {
      // Initialize space data if not present
      if (!accountData.spaces[spaceId]) {
        accountData.spaces[spaceId] = {
          total: 0,
          actions: [],
          lastActionTimestamps: {},
        };
      }

      // Update space total points and actions
      accountData.spaces[spaceId].total += points;
      accountData.spaces[spaceId].actions.push({
        type: actionType,
        timestamp: currentTime,
        points,
      });

      // Prepare content for state event
      const content = {
        score: accountData.spaces[spaceId].total,
      };

      // Send state event to update space score
      await mxClient.sendStateEvent(spaceId, EVENT_TYPE, content, stateKey);
    }

    // Update individual score total and actions
    accountData.individualScore.total += points;
    accountData.individualScore.actions.push({
      type: actionType,
      timestamp: currentTime,
      points,
    });
  }

  // Save updated account data
  try {
    await mxClient.setAccountData(EVENT_TYPE, {
      communityMembershipScores: accountData,
    });
  } catch (error) {
    console.error("Error updating account data:", error);
    // Depending on your application's requirements, you might choose to proceed or halt here.
    // For now, we'll proceed to increment the action count regardless.
  }

  // Always increment the action count, regardless of rate limiting
  try {
    await incrementActionCount(actionType, stateKey, accessToken, useStateKey);
  } catch (error) {
    console.error("Error incrementing action count:", error);
    // Handle error as needed, e.g., retry logic or user notification
  }
}

/**
 * Retrieves the community membership score.
 *
 * @param {Object} mxClient - The client instance for interacting with the backend.
 * @param {string|null} spaceId - (Optional) The ID of the space to retrieve scores for.
 * @param {string|null} actionType - (Optional) The type of action to retrieve the count for.
 * @returns {Object} An object containing `totalScore` and `actionScore`.
 */
export function getCommunityMembershipScore(
  mxClient,
  spaceId = null,
  actionType = null
) {
  const accountDataEvent = mxClient.getAccountData(EVENT_TYPE);
  const accountDataRaw = accountDataEvent
    ? accountDataEvent.getContent()
    : null;
  const accountData = accountDataRaw?.communityMembershipScores;

  if (!accountData) {
    return { totalScore: 0, actionScore: 0 };
  }

  let totalScore = 0;
  let actionCount = 0;

  if (spaceId) {
    const spaceData = accountData.spaces[spaceId];
    if (spaceData && spaceData.total != null) {
      totalScore = spaceData.total;

      if (actionType) {
        spaceData.actions.forEach((action) => {
          if (action.type === actionType) {
            actionCount++; // Increment count instead of accumulating points
          }
        });
      }
    }
  } else {
    totalScore = accountData.individualScore?.total || 0;
    if (actionType && accountData.individualScore?.actions) {
      accountData.individualScore.actions.forEach((action) => {
        if (action.type === actionType) {
          actionCount++; // Increment count instead of accumulating points
        }
      });
    }
  }

  return { totalScore, actionScore: actionCount }; // Return the count as actionScore
}

/**
 * Retrieves the community scores within a specific space for a user.
 *
 * @param {Object} mxClient - The client instance for interacting with the backend.
 * @param {string} spaceId - The ID of the space.
 * @param {string} userId - The ID of the user.
 * @returns {number} The score within the specified space.
 */
export function getCommunityScoresInSpace(mxClient, spaceId, userId) {
  const room = mxClient.getRoom(spaceId);
  if (!room) {
    return 0;
  }

  const stateEvent = room.currentState.getStateEvents(EVENT_TYPE, userId);
  if (!stateEvent) {
    return 0;
  }

  const content = stateEvent.getContent();
  const score = content.score || 0;
  return score;
}
