import React from "react";
import Appbody from "./app/pages/app-body/Appbody";
import { AppContextProvider } from "./app/contexts/AppContextProvider";
import LoadingComponent from "./app/atoms/loading/Loading";
import { DiscoverSpacesSalesProvider } from "./app/contexts/DiscoverSpacesSalesContext";

const App = () => {
  return (
    <AppContextProvider>
      <DiscoverSpacesSalesProvider>
        <Appbody />
        <LoadingComponent />
      </DiscoverSpacesSalesProvider>
    </AppContextProvider>
  );
};

export default App;
