import React from "react";
import "./Loader.scss";

const Loader = ({
  size = "47px",
  dotSize = "8px",
  color = "var(--light)",
  multiplier = 1.6,
}) => {
  return (
    <div
      className="loader-dot-wave"
      style={{
        "--uib-size": size,
        "--uib-dot-size": dotSize,
        "--uib-color": color,
        "--uib-multiplier": multiplier,
      }}
    >
      <div className="loader-dot-wave__dot"></div>
      <div className="loader-dot-wave__dot"></div>
      <div className="loader-dot-wave__dot"></div>
      <div className="loader-dot-wave__dot"></div>
    </div>
  );
};

export default Loader;
