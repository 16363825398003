import React from "react";
import PropTypes from "prop-types";
import "./Settings.scss";
import initMatrix from "../../../client/initMatrix";
import * as roomActions from "../../../client/action/room";
import { Dialog } from "@capacitor/dialog";
import { Capacitor } from "@capacitor/core";
import { useRoomSelect } from "../../contexts/RoomSelectContext";

function GeneralSettings({ roomId }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const isDM = initMatrix.roomList.directs.has(roomId);
  const isSpace = room?.getType() === "m.space";
  let spaceParentId = null;
  if (!isSpace) {
    const spaceParentEventMap = room.currentState.events.get("m.space.parent");
    spaceParentId = spaceParentEventMap
      ? Array.from(spaceParentEventMap.values())[0]?.getStateKey()
      : null;
  }

  const { setDmRoomId, setSpaceId, setRoomId } = useRoomSelect();

  const handleConfirm = async () => {
    const isSpace = room?.getType() === "m.space";
    const isDM = initMatrix.roomList.directs.has(roomId);

    let message = "";
    if (isDM) {
      message = `Are you sure that you want to leave the DM with ${room.name}?`;
    } else {
      const roomType = isSpace ? "Space" : "Room";
      message = `Are you sure that you want to leave ${room.name} ${roomType}?`;
    }

    if (Capacitor.isNativePlatform()) {
      const { value } = await Dialog.confirm({
        title: `Leave ${isSpace ? "Space" : isDM ? "DM" : "Room"}`,
        message: message,
      });

      if (value) {
        await roomActions.leave(roomId);
        if (isDM) {
          setDmRoomId(null);
        } else if (isSpace) {
          setSpaceId(null);
        } else {
          setRoomId(null);
        }
      }
    } else {
      const confirmLeave = window.confirm(message);
      if (confirmLeave) {
        await roomActions.leave(roomId);
        if (isDM) {
          setDmRoomId(null);
        } else if (isSpace) {
          setSpaceId(null);
        } else {
          setRoomId(null);
        }
      }
    }
  };

  return (
    <div className="general-settings-wrapper">
      {/* {isDM && !isSpace && (
        <div>
          <div className="general-settings-room-options">
            <span className="space-landing-space-data-title-text">
              Notifications
            </span>
          </div>
          <RoomNotification roomId={roomId} />
        </div>
      )} */}
      <div className="disconnect-button" onClick={handleConfirm}>
        <span className="disconnect-button-text">
          Leave {isDM ? "DM" : isSpace ? "Space" : "Room"}
        </span>
      </div>
    </div>
  );
}

GeneralSettings.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default GeneralSettings;
