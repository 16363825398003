import dayjs from "dayjs";

export const validateSpaceTokenomics = (tokenTokenomics) => {
  if (parseFloat(tokenTokenomics[0]["Supply (%)"]) <= 20) {
    return {
      isValid: false,
      message: `${tokenTokenomics[0]["Pool"]} must be greater than 20% of the supply.`,
    };
  }

  const totalSupplyPercentage = tokenTokenomics.reduce((total, pool) => {
    return total + parseFloat(pool["Supply (%)"] || 0);
  }, 0);

  if (totalSupplyPercentage !== 100) {
    return {
      isValid: false,
      message: "Total supply percentage must add up to 100%.",
    };
  }

  for (const pool of tokenTokenomics) {
    if (
      !pool.Pool ||
      !pool["Supply (%)"] ||
      isNaN(pool["Supply (%)"]) ||
      parseFloat(pool["Supply (%)"]) <= 0 ||
      parseFloat(pool["Supply (%)"]) > 100 ||
      !/^\d+(\.\d+)?$/.test(pool["Supply (%)"]) ||
      !/^\d+(\.\d+)?$/.test(pool["Lock Up (m)"]) ||
      !/^\d+(\.\d+)?$/.test(pool["Vesting (m)"])
    ) {
      return {
        isValid: false,
        message:
          "All pools must have a valid name and numeric values for supply, lockup, and vesting periods.",
      };
    }
  }

  return { isValid: true };
};

export const validateFundraiserDetails = (presaleData) => {
  const {
    presaleTargetRaiseAmount,
    presaleFundraiserMinContribution,
    presaleFundraiserMaxContribution,
    presaleFundraiserDescription,
  } = presaleData;

  if (
    !presaleTargetRaiseAmount ||
    isNaN(presaleTargetRaiseAmount) ||
    presaleTargetRaiseAmount.trim() === ""
  ) {
    return {
      isValid: false,
      message: "Fundraising Goal must be a valid number.",
    };
  } else if (parseFloat(presaleTargetRaiseAmount) <= 0) {
    return {
      isValid: false,
      message: "Fundraising Goal must be a number greater than 0.",
    };
  }
  if (
    presaleFundraiserMaxContribution &&
    parseFloat(presaleFundraiserMaxContribution) >
      parseFloat(presaleTargetRaiseAmount)
  ) {
    return {
      isValid: false,
      message: "Max Contribution cannot be greater than the Fundraising Goal.",
    };
  }
  if (
    presaleFundraiserMinContribution &&
    presaleFundraiserMaxContribution &&
    parseFloat(presaleFundraiserMinContribution) >
      parseFloat(presaleFundraiserMaxContribution)
  ) {
    return {
      isValid: false,
      message: "Min Contribution cannot be greater than Max Contribution.",
    };
  }
  if (
    !presaleFundraiserDescription ||
    presaleFundraiserDescription.trim() === ""
  ) {
    return {
      isValid: false,
      message: "Fundraiser Description cannot be empty.",
    };
  }
  return { isValid: true };
};

export const validateTimingTact = (presaleData) => {
  const { presaleFundraiserStartTimestamp, presaleFundraiserEndTimestamp } =
    presaleData;
  const now = dayjs();
  const startTime = dayjs.unix(presaleFundraiserStartTimestamp);
  const endTime = dayjs.unix(presaleFundraiserEndTimestamp);

  if (startTime.diff(now, "minute") < 30) {
    return {
      isValid: false,
      message:
        "The start time must be at least 30 minutes from the current time.",
    };
  }
  if (endTime.diff(startTime, "minute") < 30) {
    return {
      isValid: false,
      message: "The end time must be at least 30 minutes after the start time.",
    };
  }

  return { isValid: true };
};
