const gradients = [
  {
    gradient: "linear-gradient(135deg, #FDE1BC 0%, #FFAEAE 100%)",
    textColor: "rgba(204, 61, 0, 0.8)", // CC3D00 80%
  },
  {
    gradient: "linear-gradient(135deg, #FBCEFF 0%, #FFD1D1 100%)",
    textColor: "rgba(140, 0, 126, 0.8)", // 8C007E 80%
  },
  {
    gradient: "linear-gradient(135deg, #F0D6FF 0%, #D3CDFF 100%)",
    textColor: "rgba(92, 27, 174, 0.8)", // 5C1BAE 80%
  },
  {
    gradient: "linear-gradient(135deg, #C3C2FF 0%, #9DBEFF 100%)",
    textColor: "rgba(28, 48, 157, 0.7)", // 1C309D 70%
  },
  {
    gradient: "linear-gradient(135deg, #B8F8FF 0%, #AFD4FF 100%)",
    textColor: "rgba(0, 113, 194, 0.8)", // 0071C2 80%
  },
  {
    gradient: "linear-gradient(135deg, #D0F9BE 0%, #ADE9F1 100%)",
    textColor: "rgba(29, 162, 114, 0.8)", // 1DA272 80%
  },
  {
    gradient: "linear-gradient(135deg, #FFF7E3 0%, #DAFFBC 100%)",
    textColor: "rgba(172, 198, 16, 0.8)", // ACC610 80%
  },
];

export const getRandomGradient = (roomId) => {
  const hash = Array.from(roomId).reduce(
    (acc, char) => acc + char.charCodeAt(0),
    0
  );
  const index = hash % gradients.length;
  return gradients[index];
};
