import React, { useState } from "react";
import "./WalletSettings.scss";
import { ReactComponent as Warn } from "../../assets/svg/filldanger.svg";
import Loader from "../../atoms/loader/Loader";
import { Capacitor } from "@capacitor/core";
import SecureStoragePluginIOS from "../../../util/secureStoragePluginIOS";
import { decrypt } from "../../../util/encryption";
import Popover from "../../atoms/popover/Popover";
import SecretPhrase from "./SecretPhrase";
import PasswordAccess from "./PasswordAccess";
import SecureStoragePluginAndroid from "../../../util/secureStoragePluginAndroid";

const WalletBackup = () => {
  const [loading, setLoading] = useState(false);
  const platform = Capacitor.getPlatform();
  const [secretPhraseDialog, setSecretPhraseDialog] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [mnemonic, setMnemonic] = useState("");

  const storedWalletAddress = localStorage.getItem("waivlength_wallet_address");
  const walletArray =
    JSON.parse(localStorage.getItem("waivlength_wallets")) || [];
  const walletData = walletArray.find(
    (wallet) => wallet.walletAddress === storedWalletAddress
  );

  const handleShowSecretPhrase = async () => {
    setLoading(true);

    try {
      if (walletData) {
        let retrievedData;
        if (platform === "ios" && walletData.useFaceId) {
          retrievedData = await SecureStoragePluginIOS.retrieveWithFaceId({
            key: walletData.walletAddress,
          });
        } else if (platform === "android" && walletData.useFaceId) {
          retrievedData = await SecureStoragePluginAndroid.retrieveWithFaceId({
            key: walletData.walletAddress,
          });
        }

        if (retrievedData) {
          const password = retrievedData.password;
          const decryptedMnemonic = decrypt(retrievedData.mnemonic, password);
          setMnemonic(decryptedMnemonic);
          setSecretPhraseDialog(true);
        } else {
          setPasswordDialog(true);
        }
      }
    } catch (error) {
      console.error("Error retrieving mnemonic:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wallet-backup-container">
      <div className="wallet-backup-wrapper">
        <div className="wallet-backup-notification">
          <Warn className="wallet-backup-svglogo-fill" />
          <span className="wallet-backup-text-primary">Before you proceed</span>
          <span className="wallet-backup-text-secondary">
            Never share your Secret Phrase or enter it into apps or websites.
          </span>
          <span className="wallet-backup-text-secondary">
            Anyone who has your Secret Phrase can access your entire wallet.
          </span>
          <span className="wallet-backup-text-secondary">
            Ensure nobody can see your screen when viewing your Secret Phrase.
          </span>
          <span className="wallet-backup-text-secondary">
            Waivlength, or any representatives, will never ask you for your
            Secret Phrase.
          </span>
        </div>
      </div>
      <div
        disabled={loading}
        onClick={handleShowSecretPhrase}
        className="create-container-button-create"
      >
        {!loading && (
          <span className="create-room-button-text">Show Secret Phrase</span>
        )}
        {loading && (
          <Loader
            size="24px"
            dotSize="6px"
            color="var(--dark)"
            multiplier={1.4}
          />
        )}
      </div>
      {secretPhraseDialog && (
        <Popover
          direction="right"
          setOpenDialog={setSecretPhraseDialog}
          headerText="Secret Phrase"
        >
          <SecretPhrase
            mnemonic={mnemonic}
            setOpenDialog={setSecretPhraseDialog}
            saveManualButton={true}
          />
        </Popover>
      )}
      {passwordDialog && (
        <Popover
          direction="right"
          setOpenDialog={setPasswordDialog}
          headerText="Enter Password"
        >
          <PasswordAccess
            walletAddress={walletData?.walletAddress}
            saveManualButton={true}
          />
        </Popover>
      )}
    </div>
  );
};

export default WalletBackup;
