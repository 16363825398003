import React from "react";
import "./Toggle.scss";

function Toggle({ isActive, onToggle, disabled }) {
  const className = `toggle${isActive ? " toggle--active" : ""}`;
  if (onToggle === null) return <span className={className} />;
  return (
    <div
      onClick={() => !disabled && onToggle(!isActive)}
      className={className}
    />
  );
}

export default Toggle;
