import { ethers } from "ethers";
import SwapRouter from "@uniswap/v3-periphery/artifacts/contracts/interfaces/ISwapRouter.sol/ISwapRouter.json";
import IERC20 from "@openzeppelin/contracts/build/contracts/ERC20.json";

const swapRouterAddress = import.meta.env.VITE_SWAP_ROUTER_SEPOLIA;

function calculateAmountOutMinimum(amountOut, slippageTolerance) {
  const slippageToleranceScaled = Math.floor(slippageTolerance * 100);
  const slippage = (BigInt(slippageToleranceScaled) * amountOut) / 10000n;
  return amountOut - slippage;
}

async function safeTransferFrom(signer, token, from, to, value) {
  const erc20Contract = new ethers.Contract(token, IERC20.abi, signer);
  try {
    const tx = await erc20Contract.approve(to, value);
    await tx.wait();
  } catch (error) {
    console.error("Transfer failed:", error);
    throw new Error("STF");
  }
}

export async function executeTrade(
  signer,
  tokenIn,
  tokenInAddress,
  tokenOutAddress,
  amountIn,
  amountOut,
  slippageTolerance,
  deadline,
  walletAddress
) {
  try {
    const swapRouterContract = new ethers.Contract(
      swapRouterAddress,
      SwapRouter.abi,
      signer
    );

    const amountInParsed = ethers.parseUnits(amountIn.toString(), 18);
    const etherOutputValue = Number(amountOut) / 10 ** 18;
    const formattedEtherValue = etherOutputValue.toFixed(4);
    const slippageFactor = (100 - slippageTolerance) / 100;
    const adjustedEtherValue = formattedEtherValue * slippageFactor;
    const amountOutParsed = ethers.parseUnits(
      adjustedEtherValue.toString(),
      18
    );

    if (tokenIn !== "ETH") {
      await safeTransferFrom(
        signer,
        tokenInAddress,
        walletAddress,
        swapRouterAddress,
        amountInParsed
      );
    }

    const params = {
      tokenIn: tokenInAddress,
      tokenOut: tokenOutAddress,
      fee: 3000,
      recipient: walletAddress,
      deadline: Math.floor(Date.now() / 1000) + 60 * deadline,
      amountIn: amountInParsed,
      amountOutMinimum: amountOutParsed,
      sqrtPriceLimitX96: 0n,
    };

    try {
      let tx;
      if (tokenIn !== "ETH") {
        tx = await swapRouterContract.exactInputSingle(params);
        await tx.wait();
      } else {
        tx = await swapRouterContract.exactInputSingle(params, {
          value: amountInParsed,
        });
        await tx.wait();
      }
      return { success: true, transactionHash: tx.hash };
    } catch (error) {
      console.error("Error executing trade:", error);
      if (
        error.code === "CALL_EXCEPTION" &&
        error.reason === "Too little received"
      ) {
        console.error(
          "Slippage too low or high volume in the market. Consider increasing slippage tolerance."
        );
      }
      return { success: false, error: error.message };
    }
  } catch (error) {
    console.error("Error executing trade:", error);
    return { success: false, error: error.message };
  }
}
