export function getUserPresenceStatus(roomMember) {
  const now = Date.now();
  const presence = roomMember?.user?.presence;
  const lastPresenceTs = roomMember?.user?.lastPresenceTs;

  if (!lastPresenceTs) {
    return { status: "Unknown", timeSinceLastActive: "No activity data" };
  }

  if (presence === "online") {
    return { status: "Online", timeSinceLastActive: null };
  } else {
    const diffInMs = now - lastPresenceTs;

    if (diffInMs < 0) {
      return { status: "Offline", timeSinceLastActive: "Invalid timestamp" };
    }

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    let timeSinceLastActive;

    if (diffInSeconds < 60) {
      timeSinceLastActive = "less than 1 minute ago";
    } else if (diffInMinutes < 60) {
      timeSinceLastActive = `${diffInMinutes} minute${
        diffInMinutes !== 1 ? "s" : ""
      } ago`;
    } else if (diffInHours < 24) {
      timeSinceLastActive = `${diffInHours} hour${
        diffInHours !== 1 ? "s" : ""
      } ago`;
    } else {
      timeSinceLastActive = `${diffInDays} day${
        diffInDays !== 1 ? "s" : ""
      } ago`;
    }

    return { status: "Offline", timeSinceLastActive };
  }
}
