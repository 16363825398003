import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(utc);

const LaunchAssuranceComponent = ({ presaleData, data }) => {
  return (
    <div className="presale-form-content">
      <span className="presale-primary-text">Launch Confirmation</span>
      <span className="presale-secondary-text">
        Verify and ignite: A meticulous final check before the leap. This is
        where precision pays off, ensuring every detail of your preparation is
        flawless, allowing for a smooth transition from concept to a live,
        thriving project.
      </span>
      <div className="presale-tokenomics-container">
        <span className="presale-header-summary-text">Fundraising Type</span>
        <span className="presale-summary-text">
          {presaleData.tokenFundingType}
        </span>
        <div className="token-offering-name-symbol-container">
          <div className="token-offering-space-name-container-left">
            <span className="presale-header-summary-text">Token Name</span>
            <span className="presale-summary-text">{data.tokenName}</span>
          </div>
          <div className="token-offering-space-name-container-right">
            <span className="presale-header-summary-text">Token Symbol</span>
            <span className="presale-summary-text">
              {data.tokenSymbol}
            </span>
          </div>
        </div>
        <span className="presale-header-summary-text">Total Token Supply</span>
        <span className="presale-summary-text">
          {data.tokenTotalSupply} {data.tokenSymbol}
        </span>
        <span className="presale-header-summary-text">Fundraising Amount</span>
        <span className="presale-summary-text">
          {presaleData.presaleTargetRaiseAmount} ETH
        </span>
        <span className="presale-header-summary-text">
          Fundraising Description
        </span>
        <span className="presale-summary-text">
          {presaleData.presaleFundraiserDescription
            ? presaleData.presaleFundraiserDescription
            : "null"}
        </span>
        <div className="token-offering-name-symbol-container">
          <div className="token-offering-space-name-container-left">
            <span className="presale-header-summary-text">
              Min Contribution
            </span>
            <span className="presale-summary-text">
              {presaleData.presaleFundraiserMinContribution} ETH
            </span>
          </div>
          <div className="token-offering-space-name-container-right">
            <span className="presale-header-summary-text">
              Max Contribution
            </span>
            <span className="presale-summary-text">
              {presaleData.presaleFundraiserMaxContribution} ETH
            </span>
          </div>
        </div>
        <div className="token-offering-name-symbol-container">
          <div className="token-offering-space-name-container-left">
            <span className="presale-header-summary-text">Start Timestamp</span>
            <span className="presale-summary-text">
              {dayjs
                .unix(presaleData.presaleFundraiserStartTimestamp)
                .utc()
                .local()
                .format("D MMMM, YYYY - h:mm A")}
            </span>
          </div>
          <div className="token-offering-space-name-container-right">
            <span className="presale-header-summary-text">End Timestamp</span>
            <span className="presale-summary-text">
              {dayjs
                .unix(presaleData.presaleFundraiserEndTimestamp)
                .utc()
                .local()
                .format("D MMMM, YYYY - h:mm A")}
            </span>
          </div>
        </div>
        <span className="presale-header-summary-text-header ">Tokenomics</span>
        <div className="presale-tokenomics-container">
          {presaleData.tokenTokenomics.map((item, index) => (
            <div
              key={index}
              className="presale-tokenomics-container-pool-component-summary"
            >
              <div className="presale-tokenomics-container-level">
                <div className="token-offering-tokenomics-container-summary-top">
                  <span className="token-offering-summary-text-final-header">
                    Pool
                  </span>
                  <span className="token-offering-summary-text-final-body">{`${item.Pool}`}</span>
                </div>
                <div className="token-offering-tokenomics-container-summary-top">
                  <span className="token-offering-summary-text-final-header">
                    Token Allocation
                  </span>
                  <span className="token-offering-summary-text-final-body">{`${item.Tokens}`}</span>
                </div>
              </div>
              <div className="token-offering-tokenomics-container-summary">
                <div className="token-offering-tokenomics-container-summary-bottom">
                  <span className="token-offering-summary-text-final-header">
                    Supply
                  </span>
                  <span className="token-offering-summary-text-final-body-bottom">{`${item["Supply (%)"]} %`}</span>
                </div>
                <div className="token-offering-tokenomics-container-summary-bottom">
                  <span className="token-offering-summary-text-final-header">
                    Vesting
                  </span>
                  <span className="token-offering-summary-text-final-body-bottom">{`${item["Vesting (m)"]} months`}</span>
                </div>
                <div className="token-offering-tokenomics-container-summary-bottom">
                  <span className="token-offering-summary-text-final-header">
                    Lockup
                  </span>
                  <span className="token-offering-summary-text-final-body-bottom">{`${item["Lock Up (m)"]} months`}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LaunchAssuranceComponent;
