import React, { createContext, useContext, useState, useEffect } from "react";
import { useRoomSelect } from "./RoomSelectContext";

const MessagingOptionsContext = createContext();

export const MessagingOptionsProvider = ({ children }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [replyData, setReplyData] = useState({
    mEvent: null,
    userId: null,
    body: null,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({
    mEvent: null,
    userId: null,
    body: null,
  });

  const { roomId } = useRoomSelect();

  useEffect(() => {
    if (!roomId) {
      setIsReplying(false);
      setReplyData({ mEvent: null, userId: null, body: null });
      setIsEditing(false);
      setEditData({ mEvent: null, userId: null, body: null });
    }
  }, [roomId]);

  const startReplying = ({ mEvent, userId, body }) => {
    setIsReplying(true);
    setReplyData({ mEvent, userId, body });
  };

  const stopReplying = () => {
    setIsReplying(false);
    setReplyData({ mEvent: null, userId: null, body: null });
  };

  const startEditing = ({ mEvent, userId, body }) => {
    setIsEditing(true);
    setEditData({ mEvent, userId, body });
  };

  const stopEditing = () => {
    setIsEditing(false);
    setEditData({ mEvent: null, userId: null, body: null });
  };

  return (
    <MessagingOptionsContext.Provider
      value={{
        isReplying,
        startReplying,
        stopReplying,
        replyData,
        isEditing,
        startEditing,
        stopEditing,
        editData,
      }}
    >
      {children}
    </MessagingOptionsContext.Provider>
  );
};

export const useMessagingOptions = () => useContext(MessagingOptionsContext);
