import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./MessageOptions.scss";

const MessageOptions = ({ isOpen, onClose, children, touchX, touchY }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      // Activate immediately for smooth transition
      requestAnimationFrame(() => {
        setIsActive(true);
      });
    } else if (isVisible) {
      setIsActive(false);
      // Wait for the transition to complete before unmounting
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300); // Match this duration with the CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [isOpen, isVisible]);

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className={`message-options-overlay ${isActive ? "active" : ""}`}>
      <div
        className="message-options"
        ref={optionsRef}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.getElementById("message-options-root")
  );
};

export default MessageOptions;
