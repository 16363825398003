import { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import { calculateTimeLeft } from "../../util/calculateTimeLeft";
import PresaleABI from "../../util/abi/PresaleABI.json";

const usePresaleStatus = (
  startUnix,
  endUnix,
  tokenData,
  provider,
  userAddress
) => {
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(startUnix, endUnix)
  );
  const [result, setResult] = useState("");
  const [status, setStatus] = useState("Upcoming");

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const updateStatusAndTimeLeft = () => {
      const nowUnix = Math.floor(Date.now() / 1000);
      const calculatedTimeLeft = calculateTimeLeft(startUnix, endUnix);

      if (!isMounted.current) return;

      setTimeLeft(calculatedTimeLeft);

      if (nowUnix < startUnix) {
        setStatus("Upcoming");
      } else if (nowUnix >= startUnix && nowUnix <= endUnix) {
        setStatus("Live");
      } else {
        setStatus("Ended");
      }
    };

    updateStatusAndTimeLeft();
    const timer = setInterval(updateStatusAndTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [startUnix, endUnix]);

  useEffect(() => {
    if (status === "Live" || status === "Ended") {
      const contract = new ethers.Contract(
        tokenData.presaleContractAddress,
        PresaleABI,
        provider
      );

      const checkCondition = async () => {
        try {
          const [totalRaised] = await contract.getPresaleDetails(userAddress);
          const targetRaiseAmount = BigInt(
            ethers.parseUnits(
              (tokenData.presaleTargetRaiseAmount / 2).toString(),
              "ether"
            )
          );

          const condition = BigInt(totalRaised) >= targetRaiseAmount;

          if (isMounted.current) {
            setResult(condition ? "Success" : "Failed");
          }
        } catch (error) {
          console.error("Failed to fetch data from contract:", error);
          if (isMounted.current) {
            setResult("No data");
          }
        }
      };

      checkCondition();
      const intervalId = setInterval(checkCondition, 1000);
      return () => clearInterval(intervalId);
    }
  }, [tokenData, provider, status, userAddress]);

  return { timeLeft, result, status };
};

export default usePresaleStatus;
