/**
 * Formats a timestamp into "Created Month Year" format.
 * @param {number} timestamp - The timestamp to format.
 * @returns {string} - The formatted date string.
 */
export function formatCreationTimestamp(timestamp) {
    if (timestamp) {
      // Convert the timestamp to a Date object
      const date = new Date(timestamp);
  
      // Extract the month and year
      const options = { year: 'numeric', month: 'long' };
      const formattedDate = date.toLocaleDateString('en-US', options);
  
      return `Created ${formattedDate}`;
    } else {
      return "Invalid timestamp";
    }
  }
  