import React, { useState, useEffect, useRef } from "react";
import "./GlobalFeed.scss";
import GlobalFeedClass from "../../../client/state/GlobalFeed";
import { ReactComponent as AddMessage } from "../../assets/svg/message-text.svg";
import CreatePost from "../../molecules/create-post/CreatePost";
import ViewPost from "../../molecules/view-post/ViewPost";
import initMatrix from "../../../client/initMatrix";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { prepareMessageContent, renderMedia } from "../../../util/MediaUtils";
import { formatDisplayName } from "../../../util/formatDisplayName";
import usernameColorMXID from "../../../util/usernameColorMXID";
import { formatTimeAgo } from "../../../util/formatTimeAgo";
import Modal2 from "../../atoms/modal/Modal2";
import NewPostsNotification from "../../atoms/post-notification/NewPostsNotification";
import PostContent from "./PostContent";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";
import PostComments from "./PostComments";
import PostActions from "./PostActions";

const GlobalFeed = ({ mx, user, avatarUrl }) => {
  const [events, setEvents] = useState([]);
  const [isCreatePostOpen, setIsCreatePostOpen] = useState(false);
  const [isViewPostOpen, setIsViewPostOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [senders, setSenders] = useState([]);
  const [newPostsAvailable, setNewPostsAvailable] = useState(false);
  const [newPostsCount, setNewPostsCount] = useState(0);
  const globalFeed = useRef(null);
  const roomId = import.meta.env.VITE_GLOBAL_FEED_ROOM_ID;
  const containerRef = useRef(null);

  useEffect(() => {
    globalFeed.current = new GlobalFeedClass(initMatrix.matrixClient);
    const initFeed = async () => {
      const processedEvents = await globalFeed.current.initGlobalFeed();
      setEvents(processedEvents);
    };

    initFeed();
    return () => {
      globalFeed.current.removeLiveEventsListener();
    };
  }, []);

  const getDisplayName = (userId) => {
    const user = initMatrix.matrixClient.getUser(userId);
    return user && user.displayName ? user.displayName : userId;
  };

  const openViewPost = (post) => {
    let selectedPost = post;
    if (post.getType() === "m.repost") {
      const originalEventId = post.getContent()["m.relates_to"]?.event_id;
      const originalEvent = globalFeed.current.eventsMap.get(originalEventId);
      if (originalEvent) {
        selectedPost = originalEvent;
      }
    }
    setSelectedPost(selectedPost);
    setIsViewPostOpen(true);
  };

  const closeViewPost = () => {
    setIsViewPostOpen(false);
    setSelectedPost(null);
  };

  useEffect(() => {
    if (!user || !user.userId) {
      return;
    }

    const liveEventListener = (update) => {
      if (update.type === "newPosts") {
        setNewPostsAvailable(true);
        setNewPostsCount(update.newPostsCount);
        setSenders(update.senders);
      } else if (update.type === "update") {
        setEvents([...globalFeed.current.getEvents()]);
      } else if (update.type === "myEvent") {
        const myEvents = globalFeed.current.getMyEvents();
        setEvents((prevEvents) => {
          return [...myEvents, ...prevEvents];
        });
      }
    };

    if (globalFeed.current) {
      globalFeed.current.listenForLiveEvents(liveEventListener, user.userId);
    }

    return () => {
      if (globalFeed.current) {
        globalFeed.current.removeLiveEventsListener();
      }
    };
  }, [user]);

  const handleShowNewPosts = () => {
    const newEvents = globalFeed.current.getNewEvents();
    setEvents((prevEvents) => [...newEvents, ...prevEvents]);
    setNewPostsAvailable(false);
    setNewPostsCount(0);
  };

  const handlePost = async (body, mediaUrl, mediaInfo, mediaType, fileName) => {
    const content = prepareMessageContent(
      body,
      mediaUrl,
      mediaInfo,
      mediaType,
      fileName
    );
    await initMatrix.matrixClient.sendMessage(roomId, content);
    setIsCreatePostOpen(false);
  };

  const handleCancel = () => {
    setIsCreatePostOpen(false);
  };

  const handleVote = async (eventId, type, targetType = "posts") => {
    const userId = user.userId;
    await globalFeed.current.handleVote(eventId, type, userId, targetType);
    await updateCommunityMembershipScore(mx, "vote");
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = async () => {
      if (isFetching || !globalFeed.current.canPaginate) return;
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop - clientHeight < 1000) {
        setIsFetching(true);
        try {
          const oldEvents = await globalFeed.current.fetchOldEvents();
          if (oldEvents.length > 0) {
            setEvents((prevEvents) => [...prevEvents, ...oldEvents]);
          }
        } catch (error) {
          console.error("Error fetching more events:", error);
        } finally {
          setIsFetching(false);
        }
      }
    };

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching]);

  return (
    <div className="global-feed">
      <div className="global-feed-container" ref={containerRef}>
        <div className="global-feed-header">
          <span className="global-feed-header-text">What's new?</span>
          {newPostsAvailable && (
            <div
              className={`new-posts-notification ${
                newPostsAvailable ? "show" : "hide"
              }`}
            >
              <NewPostsNotification
                newPostsAvailable={newPostsAvailable}
                newPostsCount={newPostsCount}
                senders={senders}
                onShowNewPosts={handleShowNewPosts}
              />
            </div>
          )}
        </div>
        {events.map((event) => {
          const originalEvent = globalFeed.current.getOriginalEvent(event);
          if (!originalEvent || !originalEvent.event) return null;

          const eventData = originalEvent.event;

          let eventId = eventData.event_id;
          let sender = eventData.sender;
          let timestamp = formatTimeAgo(eventData.origin_server_ts);
          let content = eventData.content?.body || "No content available";
          let mediaElement = null;

          try {
            mediaElement = renderMedia(originalEvent, initMatrix.matrixClient);
          } catch (error) {
            console.error("Error rendering media:", error);
          }

          let displayName = getDisplayName(sender);
          let formattedDisplayName = formatDisplayName(displayName);

          const upvoteCount = globalFeed.current.getUpvoteCount(eventId);
          const downvoteCount = globalFeed.current.getDownvoteCount(eventId);
          const combinedCount = upvoteCount - downvoteCount;
          const userVote = globalFeed.current.getUserVote(eventId, user.userId);

          const colorStyle = usernameColorMXID(sender);
          const isGradient = colorStyle.startsWith("linear-gradient");
          const textStyle = isGradient
            ? {
                background: colorStyle,
                WebkitBackgroundClip: "text",
                color: "transparent",
              }
            : { color: colorStyle };

          const commentsCount =
            globalFeed.current.getComments(eventId)?.length || 0;
          const topComments = globalFeed.current.getTopComments(eventId, 3);

          return (
            <div key={eventId} className="feed-post">
              <div className="post-header">
                <div>
                  <UserAvatar size={36} userId={sender} imageSrc={avatarUrl} />
                </div>
                <div className="post-header-content-container">
                  <div className="post-header-text-container">
                    <span className="post-author" style={textStyle}>
                      {formattedDisplayName}
                    </span>
                    <span className="post-timestamp">{timestamp}</span>
                  </div>
                  <PostContent content={content} mediaElement={mediaElement} />
                  <PostActions
                    userVote={userVote}
                    handleVote={handleVote}
                    eventId={eventId}
                    combinedCount={combinedCount}
                    commentsCount={commentsCount}
                    openViewPost={openViewPost}
                    event={event}
                  />
                  {topComments.length > 0 && (
                    <PostComments
                      topComments={topComments}
                      avatarUrl={avatarUrl}
                      user={user}
                      globalFeed={globalFeed.current}
                      handleVote={handleVote}
                      getDisplayName={getDisplayName}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="global-feed-post-container-floating">
        <div
          className="rooms-category-add-wrapper-floating"
          onClick={() => setIsCreatePostOpen(true)}
        >
          <AddMessage className="rooms-category-add-space-svglogo-floating" />
        </div>
      </div>
      <Modal2
        isOpen={isCreatePostOpen}
        onClose={() => setIsCreatePostOpen(false)}
      >
        <CreatePost
          user={user}
          avatarUrl={avatarUrl}
          onPost={handlePost}
          onCancel={handleCancel}
          onClose={() => setIsCreatePostOpen(false)}
          mx={mx}
        />
      </Modal2>
      <Modal2 isOpen={isViewPostOpen} onClose={closeViewPost}>
        {selectedPost && (
          <ViewPost
            post={selectedPost}
            globalFeed={globalFeed.current}
            user={user}
            avatarUrl={avatarUrl}
            mx={mx}
          />
        )}
      </Modal2>
    </div>
  );
};

export default GlobalFeed;
