import React, { useState, useRef, useMemo, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as Verify } from "../../assets/svg/verify.svg";
import * as roomActions from "../../../client/action/room";
import "./Discovery.scss";
import "swiper/scss";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import initMatrix from "../../../client/initMatrix";
import { getRandomGradient } from "../../../util/gradients";
import { useRoomSelect } from "../../contexts/RoomSelectContext";

const Carousel = ({ spaces, avatarUrls }) => {
  const swiperRef = useRef(null);
  const mx = initMatrix.matrixClient;
  const { setSpaceId } = useRoomSelect();

  const handleRoomClick = (roomId) => {
    const isJoined =
      initMatrix.matrixClient.getRoom(roomId)?.getMyMembership() === "join";
    if (isJoined) {
      handleViewRoom(roomId);
    } else {
      joinRoom(roomId);
    }
  };

  const handleSaleClick = async (roomId) => {
    const isJoined =
      initMatrix.matrixClient.getRoom(roomId)?.getMyMembership() === "join";
    if (isJoined) {
      handleViewRoom(roomId);
    } else {
      await joinRoom(roomId);
    }
  };

  const handleViewRoom = async (roomId) => {
    const room = initMatrix.matrixClient.getRoom(roomId);
    setSpaceId(roomId);
  };

  async function joinRoom(roomId) {
    try {
      await roomActions.join(roomId, false);
      handleRoomClick(roomId);
    } catch (error) {
      console.error("Error joining room:", error);
    }
  }

  useEffect(() => {
    const handleNext = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideNext();
      }
    };

    const interval = setInterval(handleNext, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="discovery-carousel-wrapper">
      <div className="discovery-carousel-wrapper-header">
        <span className="discovery-text">
          Browse, Discover, Connect, Thrive.
        </span>
      </div>
      <div className="discovery-carousel-wrapper-body">
        <div className="discovery-carousel-wrapper-body-swipper">
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={50}
            loop={spaces.length > 1} // Ensure loop mode only if more than 1 slide
            pagination={{
              clickable: true,
            }}
          >
            {spaces.map((space, index) => {
              const mx = initMatrix.matrixClient;
              const room = mx.getRoom(space.id);
              const isSpace = room?.getType() === "m.space";
              const getStateEvent = (eventType) =>
                room ? room.currentState.getStateEvents(eventType, "") : null;
              const bannerEvent = getStateEvent("custom.space_banner");
              const bannerUrl = bannerEvent
                ? mx.mxcUrlToHttp(
                    bannerEvent.getContent().url,
                    320,
                    180,
                    "scale"
                  )
                : null;
              const getSpaceId = (space) => space.id || space.roomId;
              const { gradient, textColor } = getRandomGradient(
                getSpaceId(space)
              );
              return (
                <SwiperSlide
                  onClick={() =>
                    isSpace
                      ? handleRoomClick(space.id)
                      : handleSaleClick(space.spaceId)
                  }
                  key={`space-${space.id}-${index}`} // Ensure unique key
                >
                  <div className="discovery-carousel-container">
                    <div className="discovery-carousel-container-wrapper">
                      {bannerUrl && (
                        <img
                          src={bannerUrl}
                          alt="Room Banner"
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      {space.name ? (
                        <div className="discovery-carousel-container-wrapper-flexbox">
                          <div className="discovery-carousel-room-avatar-container">
                            <RoomAvatar
                              roomId={space.id}
                              imageSrc={avatarUrls[space.id]}
                              size={64}
                              borderRadius={8}
                              key={`avatar-${space.id}-${index}`} // Ensure unique key
                            />
                          </div>
                          <div className="discovery-carousel-room-highlights-container">
                            <span
                              className="discovery-table-cell-text-spacename"
                              key={`name-${space.id}-${index}`} // Ensure unique key
                            >
                              {space.name}
                            </span>
                            <div className="flex-container">
                              {space.tokenNewTokenizedSpace ? (
                                <Verify
                                  className="verified-contract-address-tick"
                                  key={`verify-${space.id}-${index}`} // Ensure unique key
                                />
                              ) : (
                                <Verify
                                  className="non-verified-contract-address-tick"
                                  key={`non-verify-${space.id}-${index}`} // Ensure unique key
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="discovery-fundraiser-highlights-wrapper"
                          style={{
                            backgroundImage: gradient,
                          }}
                          key={`highlight-${space.id}-${index}`} // Ensure unique key
                        >
                          <div className="discovery-fundraiser-highlights-container">
                            <span
                              className="discovery-table-cell-text-fundraiser"
                              style={{ color: textColor }}
                              key={`fundraiser-${space.id}-${index}`} // Ensure unique key
                            >
                              {space.tokenFundingType}
                            </span>
                            <div
                              style={{
                                backgroundColor: `${textColor.replace(
                                  /[\d\.]+\)$/g,
                                  "0.2)"
                                )}`,
                              }}
                              className="discovery-carousel-contribution-container"
                              key={`min-${space.id}-${index}`} // Ensure unique key
                            >
                              <span
                                className="discovery-table-cell-text-minmax"
                                style={{ color: textColor }}
                                key={`min-text-${space.id}-${index}`} // Ensure unique key
                              >
                                Min {space.presaleFundraiserMinContribution} ETH
                              </span>
                            </div>
                            <div
                              style={{
                                backgroundColor: `${textColor.replace(
                                  /[\d\.]+\)$/g,
                                  "0.2)"
                                )}`,
                              }}
                              className="discovery-carousel-contribution-container"
                              key={`max-${space.id}-${index}`} // Ensure unique key
                            >
                              <span
                                className="discovery-table-cell-text-minmax"
                                style={{ color: textColor }}
                                key={`max-text-${space.id}-${index}`} // Ensure unique key
                              >
                                Max {space.presaleFundraiserMaxContribution} ETH
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="discovery-carousel-container-info-wrapper">
                      <div className="discovery-carousel-container-info-wrapper-container">
                        <span
                          className="discovery-text-secondary"
                          key={`token-label-${space.id}-${index}`} // Ensure unique key
                        >
                          Token
                        </span>
                        <span
                          className="discovery-text-swiper"
                          key={`token-${space.id}-${index}`} // Ensure unique key
                        >
                          {space.tokenName}
                        </span>
                      </div>
                      <div className="discovery-carousel-container-info-wrapper-container">
                        <span
                          className="discovery-text-secondary"
                          key={`symbol-label-${space.id}-${index}`} // Ensure unique key
                        >
                          Symbol
                        </span>
                        <span
                          className="discovery-text-swiper"
                          key={`symbol-${space.id}-${index}`} // Ensure unique key
                        >
                          ${space.tokenSymbol}
                        </span>
                      </div>
                      {space.name ? (
                        <div className="discovery-carousel-container-info-wrapper-container">
                          <span
                            className="discovery-text-secondary"
                            key={`members-label-${space.id}-${index}`} // Ensure unique key
                          >
                            Members
                          </span>
                          <span
                            className="discovery-text-swiper"
                            key={`members-${space.id}-${index}`} // Ensure unique key
                          >
                            {space.memberCount}
                          </span>
                        </div>
                      ) : (
                        <div className="discovery-carousel-container-info-wrapper-container">
                          <span
                            className="discovery-text-secondary"
                            key={`status-label-${space.id}-${index}`} // Ensure unique key
                          >
                            Sale Status
                          </span>
                          <span
                            className="discovery-text-swiper"
                            key={`status-${space.id}-${index}`} // Ensure unique key
                          >
                            {space.status}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

function Discovery({ spaces, saleRooms }) {
  const [activeList, setActiveList] = useState(1);
  const [initialTopSpaces, setInitialTopSpaces] = useState([]);
  const [initialTopSaleRooms, setInitialTopSaleRooms] = useState([]);
  const mx = initMatrix.matrixClient;
  const [avatarUrls, setAvatarUrls] = useState({});

  useEffect(() => {
    const parsedSpaces = JSON.parse(JSON.stringify(spaces));
    const newAvatarUrls = {};
    mx.publicRooms()
      .then((response) => {
        const publicRooms = response.chunk;
        parsedSpaces.forEach((space) => {
          const foundRoom = publicRooms.find(
            (room) => room.room_id === space.id
          );
          if (foundRoom && foundRoom.avatar_url) {
            const httpAvatarUrl = mx.mxcUrlToHttp(
              foundRoom.avatar_url,
              42,
              42,
              "crop"
            );
            newAvatarUrls[space.id] = httpAvatarUrl;
          }
        });
        setAvatarUrls(newAvatarUrls);
      })
      .catch((error) => {
        console.error("Error fetching public rooms:", error);
      });
  }, [spaces]);

  useEffect(() => {
    const discoveryContainer = document.querySelector(".discovery-container");
    if (!discoveryContainer) {
      return;
    }

    const handleScroll = () => {
      if (discoveryContainer.scrollTop > 20) {
        discoveryContainer.classList.add("show-border");
      } else {
        discoveryContainer.classList.remove("show-border");
      }
    };

    discoveryContainer.addEventListener("scroll", handleScroll);

    return () => {
      discoveryContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { setSpaceId } = useRoomSelect();

  const handleRoomClick = (roomId) => {
    const isJoined =
      initMatrix.matrixClient.getRoom(roomId)?.getMyMembership() === "join";
    if (isJoined) {
      handleViewRoom(roomId);
    } else {
      joinRoom(roomId);
    }
  };

  const handleSaleClick = async (roomId) => {
    const isJoined =
      initMatrix.matrixClient.getRoom(roomId)?.getMyMembership() === "join";
    if (isJoined) {
      handleViewRoom(roomId);
    } else {
      await joinRoom(roomId);
    }
  };

  const handleViewRoom = async (roomId) => {
    const room = initMatrix.matrixClient.getRoom(roomId);
    setSpaceId(roomId);
  };

  async function joinRoom(roomId) {
    try {
      await roomActions.join(roomId, false);
      handleRoomClick(roomId);
    } catch (error) {
      console.error("Error joining room:", error);
    }
  }

  const sortedSpaces = useMemo(() => {
    return [...spaces].sort((a, b) => b.memberCount - a.memberCount);
  }, [spaces]);

  const categorizeAndSortSales = (sales) => {
    const nowUnix = Math.floor(Date.now() / 1000);
    const liveSales = [];
    const upcomingSales = [];
    const endedSales = [];

    sales.forEach((sale) => {
      const { presaleFundraiserStartTimestamp, presaleFundraiserEndTimestamp } =
        sale;

      if (
        nowUnix > presaleFundraiserStartTimestamp &&
        nowUnix < presaleFundraiserEndTimestamp
      ) {
        sale.status = "Live";
        liveSales.push(sale);
      } else if (nowUnix < presaleFundraiserStartTimestamp) {
        sale.status = "Upcoming";
        upcomingSales.push(sale);
      } else if (nowUnix > presaleFundraiserEndTimestamp) {
        sale.status = "Ended";
        endedSales.push(sale);
      }
    });

    endedSales.sort((a, b) => {
      const aAmount = parseFloat(a.presaleTargetRaiseAmount);
      const bAmount = parseFloat(b.presaleTargetRaiseAmount);

      return bAmount - aAmount;
    });

    liveSales.sort(
      (a, b) =>
        parseFloat(b.presaleTargetRaiseAmount) -
        parseFloat(a.presaleTargetRaiseAmount)
    );
    upcomingSales.sort(
      (a, b) =>
        parseFloat(b.presaleTargetRaiseAmount) -
        parseFloat(a.presaleTargetRaiseAmount)
    );

    return [...liveSales, ...upcomingSales, ...endedSales];
  };

  const sortedSales = useMemo(() => {
    const flattenedSales = saleRooms.flat();
    return categorizeAndSortSales(flattenedSales);
  }, [saleRooms]);

  const statusOrder = { Live: 1, Upcoming: 2, Ended: 3 };

  const getTopItems = (items, sortByField, count) => {
    return items
      .sort((a, b) => b[sortByField] - a[sortByField])
      .slice(0, count);
  };

  const getTopSaleRoomsSorted = (items, count) => {
    return items
      .sort((a, b) => {
        const statusA = statusOrder[a.status];
        const statusB = statusOrder[b.status];
        if (statusA !== statusB) {
          return statusA - statusB;
        }
        return (
          parseFloat(b.presaleTargetRaiseAmount) -
          parseFloat(a.presaleTargetRaiseAmount)
        );
      })
      .slice(0, count);
  };

  useEffect(() => {
    const flattenedSaleRooms = saleRooms.flat();
    setInitialTopSaleRooms(getTopSaleRoomsSorted(flattenedSaleRooms, 3));
    setInitialTopSpaces(getTopItems(spaces, "memberCount", 3));
  }, [spaces, saleRooms]);

  const topSpacesForCarousel = useMemo(() => {
    let result = [];
    const maxLength = Math.max(
      initialTopSpaces.length,
      initialTopSaleRooms.length
    );
    for (let i = 0; i < maxLength; i++) {
      if (i < initialTopSpaces.length) {
        result.push(initialTopSpaces[i]);
      }
      if (i < initialTopSaleRooms.length) {
        result.push(initialTopSaleRooms[i]);
      }
    }
    return result;
  }, [initialTopSpaces, initialTopSaleRooms]);

  const renderSpaces = (sortedSpaces) => (
    <div className="discovery-table-container">
      <table className="discovery-table-table">
        <thead>
          <tr>
            <th className="discovery-table-cell-head-nohover-nodisplay"></th>
            <th className="discovery-table-cell-head-nohover">
              <span className="discovery-table-cell-head-text-nohover">
                Space Name
              </span>
            </th>
            <th className="discovery-table-cell-head-nohover-nodisplay">
              <span className="discovery-table-cell-head-text-nohover">
                Topic
              </span>
            </th>
            <th className="discovery-table-cell-head-nohover">
              <span className="discovery-table-cell-head-text-nohover">
                Token
              </span>
            </th>
            <th className="discovery-table-cell-head">
              <span className="discovery-table-cell-head-text">Members</span>
            </th>
          </tr>
        </thead>
        <tbody className="discovery-table-table-body">
          {sortedSpaces.map((space, index) => {
            const roomId = space.id;
            const avatarUrl = avatarUrls[roomId] || null;
            return (
              <tr
                className="discovery-table-row"
                key={space.id}
                onClick={() => handleRoomClick(space.id)}
              >
                <td className="discovery-table-cell-body-count">
                  <span className="discovery-table-cell-head-text-nohover">
                    {index + 1}
                  </span>
                </td>
                <td className="discovery-table-cell-body">
                  <div className="discovery-table-spacedata-container">
                    <RoomAvatar
                      roomId={space.id}
                      imageSrc={avatarUrl}
                      size={32}
                      borderRadius={6}
                    />
                    <div className="discovery-table-spacedata">
                      <span className="discovery-table-cell-body-text-topic">
                        {space.name}
                      </span>
                    </div>
                    <div className="flex-container">
                      {space.tokenNewTokenizedSpace ? (
                        <Verify className="verified-contract-address-tick" />
                      ) : (
                        <Verify className="non-verified-contract-address-tick" />
                      )}
                    </div>
                  </div>
                </td>
                <td className="discovery-table-cell-body-topic">
                  <span className="discovery-table-cell-body-text-topic">
                    {space.topic}
                  </span>
                </td>
                <td className="discovery-table-cell-body-token">
                  <div className="discovery-table-cell-body-token-wrapper">
                    <div className="token-fundraising-symbol-container">
                      <span className="discovery-table-cell-body-text">
                        ${space.tokenSymbol}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="discovery-table-cell-body-members">
                  <span className="discovery-table-cell-body-text">
                    {space.memberCount}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  const renderSaleRooms = (sortedSales) => (
    <div className="discovery-table-container">
      <table className="discovery-table-table">
        <thead>
          <tr>
            <th className="discovery-table-cell-head-nohover-nodisplay"></th>
            <th className="discovery-table-cell-head-nohover">
              <span className="discovery-table-cell-head-text-nohover">
                Token
              </span>
            </th>
            <th className="discovery-table-cell-head-nohover">
              <div className="discovery-table-cell-head-text-fundraiser-display">
                <span className="discovery-table-cell-head-text-nohover">
                  Fundraising Type
                </span>
              </div>
              <div className="discovery-table-cell-head-text-fundraiser-mobile-display">
                <span className="discovery-table-cell-head-text-nohover">
                  Fundraiser
                </span>
              </div>
            </th>
            <th className="discovery-table-cell-head-raiseamount">
              <div>
                <span className="discovery-table-cell-head-text">
                  Raise Amount
                </span>
              </div>
            </th>
            <th className="discovery-table-cell-head-nohover">
              <span className="discovery-table-cell-head-text-nohover">
                Status
              </span>
            </th>
          </tr>
        </thead>
        <tbody className="discovery-table-table-body">
          {sortedSales.map((room, index) => {
            const { tokenSymbol, tokenFundingType, presaleTargetRaiseAmount } =
              room;
            const status = room.status;
            let containerClassName = "";
            let textColor = "";
            let backgroundColor = "";
            let borderColor = "";

            if (status === "Live") {
              containerClassName = "live";
              textColor = "var(--color-accent-two)";
              backgroundColor = "var(--color-accent-two-soft)";
              borderColor = "var(--color-accent-two)";
            } else if (status === "Upcoming") {
              containerClassName = "upcoming";
              textColor = "var(--color-accent-six)";
              backgroundColor = "var(--color-accent-six-soft)";
              borderColor = "var(--color-accent-six)";
            } else if (status === "Ended") {
              containerClassName = "ended";
              textColor = "var(--color-accent-three)";
              backgroundColor = "var(--color-accent-three-soft)";
              borderColor = "var(--color-accent-three)";
            }

            return (
              <tr
                key={room.spaceId}
                className="discovery-table-row"
                onClick={() => handleSaleClick(room.spaceId)}
              >
                <td className="discovery-table-cell-body-count">
                  <span className="discovery-table-cell-head-text-nohover">
                    {index + 1}
                  </span>
                </td>
                <td className="discovery-table-cell-body-token">
                  <div className="discovery-table-cell-body-token-wrapper">
                    <div className="token-fundraising-symbol-container">
                      <span className="discovery-table-cell-body-text">
                        ${tokenSymbol}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="discovery-table-cell-body-fundaiser-details">
                  <span className="discovery-table-cell-body-text">
                    {tokenFundingType}
                  </span>
                  <div className="discovery-table-cell-head-text-fundraiser-mobile-display">
                    <span className="discovery-table-cell-body-text-fundraiser-amount">
                      {presaleTargetRaiseAmount} ETH
                    </span>
                  </div>
                </td>
                <td className="discovery-table-cell-body-fundaiser-details-nodisplay">
                  <span className="discovery-table-cell-body-text">
                    {presaleTargetRaiseAmount} ETH
                  </span>
                </td>
                <td className="discovery-table-cell-body-fundaiser-details">
                  <div
                    className={`discovery-fundraiser-status-container ${containerClassName}`}
                    style={{
                      backgroundColor: backgroundColor,
                      borderColor: borderColor,
                    }}
                  >
                    <span
                      className="discovery-table-cell-body-text-fundraiser-status"
                      style={{ color: textColor }}
                    >
                      {status}
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="discovery-container">
      <Carousel spaces={topSpacesForCarousel} avatarUrls={avatarUrls} />
      <div className="discovery-buttons-container">
        <div className="discovery-space-sales-container">
          <div className="discovery-toggle-spaces-sales-container">
            <div
              onClick={() => setActiveList(1)}
              className={`discovery-toggle-spaces-sales-div ${
                activeList === 1 ? "selected" : ""
              }`}
            >
              <span className="discovery-toggle-div-text">Spaces</span>
            </div>
            <div
              onClick={() => setActiveList(2)}
              className={`discovery-toggle-spaces-sales-div ${
                activeList === 2 ? "selected" : ""
              }`}
            >
              <span className="discovery-toggle-div-text">Sales</span>
            </div>
          </div>
        </div>
      </div>
      {activeList === 1
        ? renderSpaces(sortedSpaces)
        : renderSaleRooms(sortedSales)}
    </div>
  );
}

export default Discovery;
