import { ethers } from "ethers";
import initMatrix from "../client/initMatrix";
import PresaleABI from "./abi/PresaleABI.json";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";
import ERC20 from "@openzeppelin/contracts/build/contracts/ERC20.json";

export const finalizePresale = async ({
  signer,
  presaleData,
  setErrorMessage,
  tokenData,
  setOpenDialog,
  address,
  spaceId,
}) => {
  setErrorMessage("");

  try {
    const mx = initMatrix.matrixClient;
    if (!signer || !signer.provider) {
      throw new Error("Signer does not have a provider.");
    }
    const totalRaised = ethers.parseEther(presaleData.totalRaised.toString());
    const halfOfTargetRaiseAmount = ethers.parseEther(
      (presaleData.targetRaiseAmount / 2).toString()
    );
    if (totalRaised < halfOfTargetRaiseAmount) {
      throw new Error(
        "Cannot finalize the presale yet: the minimum fundraising threshold has not been met."
      );
    }
    const presaleContract = new ethers.Contract(
      tokenData.presaleContractAddress,
      PresaleABI,
      signer
    );

    const ownerAddress = await presaleContract.owner();
    if (address.toLowerCase() !== ownerAddress.toLowerCase()) {
      throw new Error("Only the contract owner can finalize the presale.");
    }
    const presaleTokenomics = tokenData.tokenTokenomics;
    const nowUtcUnix = Math.floor(new Date().getTime() / 1000);
    const startTime = BigInt(nowUtcUnix);
    const vestingData = prepareVestingData({
      poolsData: presaleTokenomics,
      beneficiary: ownerAddress,
      startTime,
    });
    const presaleStartTime = BigInt(await presaleContract.presaleEndTime());
    if (startTime <= presaleStartTime) {
      throw new Error("Presale not yet concluded");
    }

    const tx = await presaleContract.finalizePresale(vestingData);
    const txR = await tx.wait();

    const liquidityPoolEvents = txR.logs.filter(
      (log) => log.fragment?.name === "LiquidityPoolCreated"
    );

    const poolAddress = liquidityPoolEvents[0]?.args.poolAddress;
    const provider = signer.provider;
    const poolData = await getPoolData(poolAddress, provider);

    const liquidityData = {
      poolAddress: poolAddress,
      ...formatReadableData(poolData),
    };

    await mx.sendStateEvent(spaceId, "custom.liquidity", liquidityData, "");

    const vestingEvents = txR.logs.filter(
      (log) => log.fragment?.name === "VestingCreated"
    );

    vestingEvents.forEach((event, index) => {
      const targetIndex = index + 1;
      if (tokenData.tokenTokenomics.length > targetIndex) {
        if (event.args) {
          tokenData.tokenTokenomics[targetIndex].vestingContract =
            event.args[0];
          tokenData.tokenTokenomics[targetIndex].endTime = event.args[1];
          tokenData.tokenTokenomics[targetIndex].endTime =
            tokenData.tokenTokenomics[targetIndex].endTime.toString();
        } else {
          console.error(`Event at index ${index} has no args: `, event);
        }
      } else {
        console.error(
          `No existing tokenomics entry at index ${targetIndex} to update with a vesting contract and end time.`
        );
      }
    });

    try {
      const tokenTokenomicsStringified = JSON.parse(
        JSON.stringify(tokenData.tokenTokenomics, (key, value) =>
          typeof value === "bigint" ? value.toString() : value
        )
      );

      await mx.sendStateEvent(
        spaceId,
        "custom.presale_data",
        { vestingData: tokenTokenomicsStringified },
        ""
      );
    } catch (error) {
      console.error("Error sending token tokenomics data:", error);
    }

    setOpenDialog(false);
  } catch (error) {
    let errorMsg;
    if (error.code === "ACTION_REJECTED") {
      errorMsg = "Transaction was cancelled.";
    } else if (error.info && error.info.error) {
      errorMsg = error.info.error.message;
    } else {
      errorMsg = "Failed to finalize presale.";
    }
    setErrorMessage(errorMsg);
  }
};

export const prepareVestingData = ({ poolsData, beneficiary, startTime }) => {
  const SECONDS_IN_A_MONTH = 2592000n;
  const vestingData = poolsData.map((pool) => {
    const tokenAmountAsString = pool["Tokens"].toString();
    const durationInSeconds = BigInt(pool["Vesting (m)"]) * SECONDS_IN_A_MONTH;
    const lockUpPeriodInSeconds =
      BigInt(pool["Lock Up (m)"]) * SECONDS_IN_A_MONTH;

    return {
      beneficiary,
      amount: ethers.parseUnits(tokenAmountAsString, 18),
      startTime: BigInt(startTime),
      duration: durationInSeconds,
      lockUpPeriod: lockUpPeriodInSeconds,
    };
  });

  return vestingData;
};

async function getTokenDetails(tokenAddress, provider) {
  const tokenContract = new ethers.Contract(tokenAddress, ERC20.abi, provider);
  const [name, symbol] = await Promise.all([
    tokenContract.name(),
    tokenContract.symbol(),
  ]);
  return { name, symbol, address: tokenAddress };
}

async function getPoolData(poolAddress, provider) {
  const poolContract = new ethers.Contract(
    poolAddress,
    UniswapV3Pool.abi,
    provider
  );

  const [tickSpacing, fee, liquidity, slot0, token0, token1] =
    await Promise.all([
      poolContract.tickSpacing(),
      poolContract.fee(),
      poolContract.liquidity(),
      poolContract.slot0(),
      poolContract.token0(),
      poolContract.token1(),
    ]);

  const token0Details = await getTokenDetails(token0, provider);
  const token1Details = await getTokenDetails(token1, provider);

  return {
    tickSpacing: tickSpacing,
    fee: fee,
    liquidity: liquidity.toString(),
    sqrtPriceX96: slot0[0],
    tick: slot0[1],
    token0: token0Details,
    token1: token1Details,
  };
}

function formatReadableData(poolData) {
  return {
    fee: poolData.fee.toString(),
    liquidity: ethers.formatUnits(poolData.liquidity, 18),
    sqrtPriceX96: ethers.formatUnits(poolData.sqrtPriceX96, 0),
    token0: poolData.token0,
    token1: poolData.token1,
  };
}
