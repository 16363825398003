import EventEmitter from "events";
import * as sdk from "matrix-js-sdk";
import Olm from "@matrix-org/olm";
import RoomList from "./state/RoomList";
import AccountData from "./state/AccountData";
import RoomsInput from "./state/RoomsInput";
import Notifications from "./state/Notifications";
import { cryptoCallbacks } from "./state/secretStorageKeys";
import navigation from "./state/navigation";
import { logger } from "matrix-js-sdk/lib/logger";
import GlobalFeedClass from "./state/GlobalFeed";
import RoomsState from "./state/RoomsState";

global.Olm = Olm;

logger.disableAll();

class InitMatrix extends EventEmitter {
  constructor() {
    super();
    navigation.initMatrix = this;
    this.matrixClient = null;
    this.roomList = null;
    this.accountData = null;
    this.roomsInput = null;
    this.notifications = null;
    this.isInitialized = false;
    this.roomsState = null;
    this.globalFeed = null;
  }

  async init(config) {
    if (this.matrixClient) {
      this.matrixClient.removeAllListeners();
      this.matrixClient.stopClient();
      this.matrixClient = null;
    }

    try {
      await this.startClient(config);
      await this.waitForPreparedState();
      await this.setupState();
      this.isInitialized = true;
      return this.matrixClient;
    } catch (error) {
      this.isInitialized = false;
      throw error;
    }
  }

  async startClient(config) {
    const indexedDBStore = new sdk.IndexedDBStore({
      indexedDB: global.indexedDB,
      localStorage: global.localStorage,
      dbName: "web-sync-store",
    });
    await indexedDBStore.startup();

    this.matrixClient = sdk.createClient({
      baseUrl: config.baseUrl,
      accessToken: config.accessToken,
      userId: config.userId,
      store: indexedDBStore,
      cryptoStore: new sdk.IndexedDBCryptoStore(
        global.indexedDB,
        "crypto-store"
      ),
      deviceId: config.deviceId,
      timelineSupport: true,
      cryptoCallbacks,
      verificationMethods: ["m.sas.v1"],
    });

    await this.matrixClient.initCrypto();
    await this.matrixClient.startClient({ lazyLoadMembers: true });
  }

  async waitForPreparedState(maxRetries = 50, retryInterval = 100) {
    return new Promise((resolve, reject) => {
      let initialSyncComplete = false;
      let retryCount = 0;

      const handleSync = (state, prevState, data) => {
        if (state === "PREPARED") {
          initialSyncComplete = true;
          this.matrixClient.off("sync", handleSync);
          resolve();
        } else if (
          state === "SYNCING" &&
          initialSyncComplete &&
          data &&
          data.catchingUp === false
        ) {
          this.matrixClient.off("sync", handleSync);
          resolve();
        } else if (state === "ERROR" || state === null || state === "STOPPED") {
          retryCount++;

          if (retryCount <= maxRetries) {
            setTimeout(() => {
              this.matrixClient.startClient({ lazyLoadMembers: true });
            }, retryInterval);
          } else {
            console.error("Max retries reached. Rejecting sync.");
            this.matrixClient.off("sync", handleSync);
            reject(
              new Error(`Matrix sync failed after ${maxRetries} retries.`)
            );
          }
        }
      };

      this.matrixClient.on("sync", handleSync);
    });
  }

  // 1. retry removeing the rewrite a recconect waitForPreparedState
  // 2. rewrite a new waitForPreparedState
  // 3. test with new retry timer

  async reconnect() {
    this.matrixClient.stopClient();
    await this.matrixClient.startClient({
      lazyLoadMembers: true,
      initialSyncLimit: 10,
    });
    // await this.waitForPreparedState();
  }

  async setupState() {
    this.roomsState = new RoomsState(this.matrixClient);
    this.roomList = new RoomList(this.matrixClient);
    this.accountData = new AccountData(this.roomList);
    this.roomsInput = new RoomsInput(this.matrixClient, this.roomList);
    this.notifications = new Notifications(this.roomList);
    this.globalFeed = new GlobalFeedClass(this.matrixClient);
    await this.notifications.initNoti();
    await this.globalFeed.initGlobalFeed();
    await this.roomsState.initRoomsState();
  }

  async logout() {
    if (this.matrixClient) {
      this.matrixClient.stopClient();
      await this.matrixClient.logout();
      await this.matrixClient.clearStores();
      this.clearLocalStorage();
      this.clearCacheAndReload();
      this.matrixClient = null;
      this.roomList = null;
      this.accountData = null;
      this.roomsInput = null;
      this.notifications = null;
      this.isInitialized = false;
      this.roomsState = null;
      this.globalFeed = null;
    }
  }

  clearLocalStorage() {
    localStorage.removeItem("waivlength_access_token");
    localStorage.removeItem("waivlength_device_id");
    localStorage.removeItem("waivlength_user_id");
    localStorage.removeItem("waivlength_base_url");
    localStorage.removeItem("waivlength_wallet_address");

    const matrixKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith("mxjssdk_")
    );
    matrixKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  clearCacheAndReload() {
    if (this.matrixClient) {
      this.matrixClient.stopClient();
      this.matrixClient.store.deleteAllData();
    }
  }
}

const initMatrix = new InitMatrix();

export default initMatrix;
