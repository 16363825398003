import React, { useState, useEffect } from "react";
import "./WalletSettings.scss";
import { ReactComponent as Warn } from "../../assets/svg/fillinformation.svg";
import { ReactComponent as Backup } from "../../assets/svg/fillbackup.svg";
import { ReactComponent as SecretPhraseIcon } from "../../assets/svg/fillsecretphrase.svg";
import SettingButton from "../../atoms/button/SettingsButton";
import Popover from "../../atoms/popover/Popover";
import initMatrix from "../../../client/initMatrix";
import { generateWaivicon1 } from "waivicon";
import WalletBackup from "./WalletBackup";
import SecretPhrase from "./SecretPhrase";
import { Capacitor } from "@capacitor/core";
import SecureStoragePluginIOS from "../../../util/secureStoragePluginIOS";
import { decrypt } from "../../../util/encryption";
import { ReactComponent as Copy } from "../../assets/svg/fillcopy.svg";
import { ReactComponent as CopySuccess } from "../../assets/svg/fillcopysuccess.svg";
import { ReactComponent as TokensIcon } from "../../assets/svg/filltokens.svg";
import { ReactComponent as KeyIcon } from "../../assets/svg/fillkey.svg";
import PasswordAccess from "./PasswordAccess";
import Modal from "../../atoms/modal/Modal";
import { ReactComponent as ArrowBottom } from "../../assets/svg/arrow-bottom.svg";
import { hasDMWith } from "../../../util/matrixUtil";
import { selectTab } from "../../../client/action/navigation";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import cons from "../../../client/state/cons";
import * as roomActions from "../../../client/action/room";
import SecureStoragePluginAndroid from "../../../util/secureStoragePluginAndroid";
import PrivateKey from "./PrivateKey";

const WalletSettings = ({ closeAllDialogs }) => {
  const mx = initMatrix.matrixClient;
  const user = mx.getUser(mx.getUserId());
  const [backupDialog, setBackupDialog] = useState(false);
  const [secretPhraseDialog, setSecretPhraseDialog] = useState(false);
  const [privateKeyDialog, setPrivateKeyDialog] = useState(false);
  const [privateKey, setPrivateKey] = useState(false);

  const [avatarSrc, setAvatarSrc] = useState(
    user.avatarUrl ? mx.mxcUrlToHttp(user.avatarUrl, 80, 80, "crop") : null
  );
  const platform = Capacitor.getPlatform();
  const svg = generateWaivicon1(user.userId.toUpperCase(), 80);
  const truncatedId = user.userId
    .match(/@(.+):/)[1]
    .replace(/^(.{6}).*(.{4})$/, "$1...$2");
  const extractedId = user.userId.match(/@(.+):/)[1];
  const [mnemonic, setMnemonic] = useState("");
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const storedWalletAddress = localStorage.getItem("waivlength_wallet_address");
  const walletArray =
    JSON.parse(localStorage.getItem("waivlength_wallets")) || [];
  const walletData = walletArray.find(
    (wallet) => wallet.walletAddress === storedWalletAddress
  );
  const isBackedUp = walletData?.isBackedUp || false;
  const isImportedWallet = walletData?.importedWalletPK || false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const { setDmRoomId } = useRoomSelect();
  const [procUsers, updateProcUsers] = useState(new Set());
  const [createdDM, updateCreatedDM] = useState(new Map());
  const [roomIdToUserId, updateRoomIdToUserId] = useState(new Map());

  const domain = import.meta.env.VITE_WAIVLENGTH_APP_DOMAIN;
  const address = "0x99beD6B7485EAc1C6925a6493d38328e3C12486d";

  function getMapCopy(myMap) {
    const newMap = new Map();
    myMap.forEach((data, key) => {
      newMap.set(key, data);
    });
    return newMap;
  }

  function deleteUserFromProc(userId) {
    procUsers.delete(userId);
    updateProcUsers(new Set(Array.from(procUsers)));
  }

  async function createDM() {
    const userId = `@${address}:${domain}`;
    const dmRoomId = hasDMWith(userId);

    if (dmRoomId) {
      setIsModalOpen(false);
      closeAllDialogs();
      selectTab(cons.tabs.DIRECTS);
      setDmRoomId(dmRoomId);
      return;
    }

    try {
      const result = await roomActions.createDM(userId, false);
      roomIdToUserId.set(result.room_id, userId);
      updateRoomIdToUserId(getMapCopy(roomIdToUserId));
      setIsModalOpen(false);
      closeAllDialogs();
      selectTab(cons.tabs.DIRECTS);
      setDmRoomId(result.room_id);
    } catch (e) {
      console.error("Error creating DM:", {
        message: e.message,
        name: e.name,
        stack: e.stack,
        errorObject: e,
      });
    }
  }

  useEffect(() => {
    if (initMatrix && initMatrix.roomList) {
      initMatrix.roomList.on(cons.events.roomList.ROOM_CREATED, onDMCreated);

      return () => {
        initMatrix.roomList.removeListener(
          cons.events.roomList.ROOM_CREATED,
          onDMCreated
        );
      };
    }
  }, [procUsers, createdDM, roomIdToUserId]);

  function onDMCreated(newRoomId) {
    const myDMPartnerId = roomIdToUserId.get(newRoomId);
    if (typeof myDMPartnerId === "undefined") return;

    createdDM.set(myDMPartnerId, newRoomId);
    roomIdToUserId.delete(newRoomId);

    deleteUserFromProc(myDMPartnerId);
    updateCreatedDM(getMapCopy(createdDM));
    updateRoomIdToUserId(getMapCopy(roomIdToUserId));
  }

  const handleCopy = () => {
    const copyToClipboard = async (text) => {
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(text);
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "fixed";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };

    copyToClipboard(extractedId);
  };

  const handleShowSecretPhrase = async () => {
    try {
      if (walletData) {
        let retrievedData;
        if (walletData.useFaceId) {
          retrievedData = await (platform === "ios"
            ? SecureStoragePluginIOS.retrieveWithFaceId({
                key: walletData.walletAddress,
              })
            : SecureStoragePluginAndroid.retrieveWithFaceId({
                key: walletData.walletAddress,
              }));

          if (retrievedData) {
            const password = retrievedData.password;
            const decryptedMnemonic = decrypt(retrievedData.mnemonic, password);
            setMnemonic(decryptedMnemonic);
            setSecretPhraseDialog(true);
            return;
          }
        } else {
          setPasswordDialog(true);
          return;
        }
      }
    } catch (error) {
      console.error("Error retrieving mnemonic:", error);
    }
  };

  const handleShowPrivateKey = async () => {
    try {
      if (walletData) {
        let retrievedData;
        if (walletData.useFaceId) {
          retrievedData = await (platform === "ios"
            ? SecureStoragePluginIOS.retrieveWithFaceId({
                key: walletData.walletAddress,
              })
            : SecureStoragePluginAndroid.retrieveWithFaceId({
                key: walletData.walletAddress,
              }));

          if (retrievedData) {
            const password = retrievedData.password;
            const decryptedPrivateKey = decrypt(retrievedData.value, password);
            setPrivateKey(decryptedPrivateKey);
            setPrivateKeyDialog(true);
            return;
          }
        } else {
          setPasswordDialog(true);
          return;
        }
      }
    } catch (error) {
      console.error("Error retrieving private key:", error);
    }
  };

  return (
    <div className="wallet-settings-container">
      {!isImportedWallet && !isBackedUp && (
        <div className="wallet-settings-backup-notification">
          <Warn className="wallet-settings-svglogo-fill" />
          <span className="wallet-settings-text-primary">Not backed up</span>
          <span className="wallet-settings-text-secondary">
            This Secret Phrase isn't backed up. Back up now so you can restore
            it if you lose your device, get a new one, or delete the app
          </span>
        </div>
      )}
      <div className="wallet-settings-container-options">
        {!isImportedWallet && !isBackedUp && (
          <SettingButton
            primaryText={"Back Up Manually"}
            Icon={() => <Backup className="wallet-connected-svglogo-fill" />}
            onClick={() => setBackupDialog(true)}
          />
        )}
        {backupDialog && (
          <Popover
            direction="right"
            setOpenDialog={setBackupDialog}
            headerText="Backup Wallet"
          >
            <WalletBackup />
          </Popover>
        )}
        {!isBackedUp && (
          <span className="wallet-settings-sp-secondary-text">Wallet</span>
        )}
        {!isImportedWallet && (
          <SettingButton
            primaryText={"View Secret Phrase"}
            Icon={() => (
              <SecretPhraseIcon className="wallet-connected-svglogo-fill" />
            )}
            onClick={handleShowSecretPhrase}
          />
        )}
        {!isImportedWallet && secretPhraseDialog && (
          <Popover
            direction="right"
            setOpenDialog={setSecretPhraseDialog}
            headerText="Secret Phrase"
          >
            <SecretPhrase
              mnemonic={mnemonic}
              setOpenDialog={setSecretPhraseDialog}
              saveManualButton={false}
            />
          </Popover>
        )}
        <SettingButton
          primaryText={"View Private Key"}
          Icon={() => <KeyIcon className="wallet-connected-svglogo-fill" />}
          onClick={handleShowPrivateKey}
        />
        {privateKeyDialog && (
          <Popover
            direction="right"
            setOpenDialog={setPrivateKeyDialog}
            headerText="Private Key"
          >
            <PrivateKey
              privateKey={privateKey}
              setOpenDialog={setPrivateKeyDialog}
            />
          </Popover>
        )}
        {passwordDialog && (
          <Popover
            direction="right"
            setOpenDialog={setPasswordDialog}
            headerText="Enter Password"
          >
            <PasswordAccess walletAddress={walletData?.walletAddress} />
          </Popover>
        )}
        <SettingButton
          primaryText={"Request Test Sepolia ETH"}
          Icon={() => <TokensIcon className="wallet-connected-svglogo-fill" />}
          onClick={toggleModal}
        />
        <span className="wallet-settings-view-secondary-text">
          Wallet Address
        </span>
      </div>
      <div className="wallet-connected-container-wrapper" onClick={handleCopy}>
        <div
          className="user-avatar-container"
          style={{
            width: "32px",
            height: "32px",
            backgroundImage: avatarSrc
              ? `url(${avatarSrc})`
              : `url("data:image/svg+xml,${encodeURIComponent(svg)}")`,
            backgroundSize: "cover",
          }}
        />
        <div className="wallet-connected-settings-text-container-notifications">
          <span className="wallet-connected-settings-primary-text">
            {truncatedId}
          </span>
        </div>
        <div className="wallet-settings-icon-button-container">
          {copySuccess ? (
            <CopySuccess className="wallet-copy-container-svglogo-fill" />
          ) : (
            <Copy className="wallet-copy-container-svglogo-stroke" />
          )}
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        <div className="wallet-settings-test-eth-wrapper">
          <span className="wallet-settings-test-eth-text">
            To top up your account, you can request Sepolia ETH from a faucet
            like{" "}
            <a
              className="wallet-settings-test-eth-text-bold"
              href="https://www.alchemy.com/faucets/ethereum-sepolia"
            >
              Alchemy
            </a>{" "}
            or{" "}
            <a
              className="wallet-settings-test-eth-text-bold"
              href="https://www.infura.io/faucet/sepolia"
            >
              Infura
            </a>
            . Please note that you will need to set up an account and have a
            transaction history on the mainnet to use these services.
          </span>
          <span className="wallet-settings-test-eth-text">
            If you need test ETH to get started immediately, we can also send
            you some. Simply message our account and request some test ETH.
          </span>
          <div
            className="wallet-connected-container-wrapper"
            onClick={createDM}
          >
            <div className="wallet-connected-icon">
              <TokensIcon className="wallet-connected-svglogo-fill" />
            </div>
            <div className="wallet-connected-settings-text-container-notifications">
              <span className="wallet-connected-settings-primary-text">
                waivlength
              </span>
            </div>
            <ArrowBottom className="wallet-connected-svglogo" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WalletSettings;
