// File: src/components/pages/NotificationsPage.jsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import "./NotificationsPage.scss";
import cons from "../../../client/state/cons";
import * as roomActions from "../../../client/action/room";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import {
  handleInvite,
  addNotificationToHistory,
  fetchNotificationsHistory,
} from "./notificationUtils";
import NotificationItem from "./NotificationItem";
import ChatFilter from "../../molecules/chats/ChatFilter";
import initMatrix from "../../../client/initMatrix";
import NoNotificationsIcon from "../../assets/svg/NoNotification"; // Ensure this icon exists
import usernameColorMXID from "../../../util/usernameColorMXID";

function NotificationsPage({ mx, roomList, matrixUser }) {
  const [loadingStates, setLoadingStates] = useState({});
  const [historicalNotifications, setHistoricalNotifications] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const { setDmRoomId, setSpaceId, setRoomId } = useRoomSelect();
  const colorStyle = usernameColorMXID(matrixUser.userId);

  const NOTIFICATIONS_BATCH_SIZE = 20;

  const chatListRef = useRef(null);
  const throttleRef = useRef(false);

  const acceptInvite = async (roomId, isDM) => {
    setLoadingStates((prev) => ({ ...prev, [roomId]: true }));
    await handleInvite(
      mx,
      roomId,
      isDM,
      null,
      setDmRoomId,
      setSpaceId,
      setRoomId
    );
    setLoadingStates((prev) => ({ ...prev, [roomId]: false }));
    const room = mx.getRoom(roomId);
    if (room) {
      const inviterName =
        room.getMember(mx.getUserId())?.events?.member?.getSender?.() ?? "";
      const user = mx.getUser(inviterName);
      const displayName = user?.displayName || "Unknown";
      const roomName = room.name || "Unnamed Room";
      const avatarUrl = room.getAvatarUrl(mx.baseUrl, 42, 42, "crop") || null;

      // Obtain parentRoomId from the joined room
      const parentEvent = room?.currentState.events
        .get("m.space.parent")
        ?.values()
        .next().value;
      const parentRoomId = parentEvent ? parentEvent.getStateKey() : null;

      const notification = {
        roomId,
        type: isDM ? "Direct Message" : room.getType(),
        action: "Accepted",
        timestamp: Date.now(),
        name: roomName,
        inviterName: isDM ? displayName : inviterName,
        avatarUrl,
        parentRoomId,
      };
      await addNotificationToHistory(mx, notification);
      fetchAndSetHistoricalNotifications();
    }
  };

  const rejectInvite = async (roomId, isDM) => {
    await roomActions.leave(roomId, isDM);
    const room = mx.getRoom(roomId);
    if (room) {
      const inviterName =
        room.getMember(mx.getUserId())?.events?.member?.getSender?.() ?? "";
      const user = mx.getUser(inviterName);
      const displayName = user?.displayName || "Unknown";
      const roomName = room.name || "Unnamed Room";
      const avatarUrl = room.getAvatarUrl(mx.baseUrl, 42, 42, "crop") || null;

      const notification = {
        roomId,
        type: isDM ? "Direct Message" : room.getType(),
        action: "Rejected",
        timestamp: Date.now(),
        name: roomName,
        inviterName: isDM ? displayName : inviterName,
        avatarUrl,
      };
      await addNotificationToHistory(mx, notification);
      fetchAndSetHistoricalNotifications();
    }
  };

  const updateInviteList = useCallback(() => {
    fetchAndSetHistoricalNotifications();
  }, []);

  useEffect(() => {
    initMatrix.roomList.on(
      cons.events.roomList.INVITELIST_UPDATED,
      updateInviteList
    );

    return () => {
      initMatrix.roomList.removeListener(
        cons.events.roomList.INVITELIST_UPDATED,
        updateInviteList
      );
    };
  }, [initMatrix, updateInviteList]);

  const fetchAndSetHistoricalNotifications = useCallback(async () => {
    const allHistorical = await fetchNotificationsHistory(
      mx,
      NOTIFICATIONS_BATCH_SIZE,
      0
    );
    setHistoricalNotifications(allHistorical);
    if (allHistorical.length < NOTIFICATIONS_BATCH_SIZE) {
      setHasMore(false);
    }
  }, [mx]);

  const loadMoreNotifications = useCallback(async () => {
    if (loadingHistory || !hasMore) return;
    setLoadingHistory(true);
    const currentCount = historicalNotifications.length;
    try {
      const newNotifications = await fetchNotificationsHistory(
        mx,
        NOTIFICATIONS_BATCH_SIZE,
        currentCount
      );
      if (newNotifications.length < NOTIFICATIONS_BATCH_SIZE) {
        setHasMore(false);
      }
      const uniqueNotifications = newNotifications.filter(
        (newNotif) =>
          !historicalNotifications.some(
            (existingNotif) =>
              existingNotif.roomId === newNotif.roomId &&
              existingNotif.timestamp === newNotif.timestamp
          )
      );
      setHistoricalNotifications((prev) => [...prev, ...uniqueNotifications]);
    } catch (error) {
      console.error("Error fetching more notifications:", error);
    } finally {
      setLoadingHistory(false);
    }
  }, [loadingHistory, hasMore, historicalNotifications, mx]);

  useEffect(() => {
    fetchAndSetHistoricalNotifications();
  }, [fetchAndSetHistoricalNotifications]);

  const handleScroll = useCallback(async () => {
    if (throttleRef.current) return;
    throttleRef.current = true;

    setTimeout(() => {
      throttleRef.current = false;
    }, 300); // Throttle duration

    const container = chatListRef.current;
    if (!container || loadingHistory || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    console.log(scrollHeight - scrollTop - clientHeight);
    if (scrollHeight - scrollTop - clientHeight < 1000) {
      await loadMoreNotifications();
    }
  }, [loadMoreNotifications, loadingHistory, hasMore]);

  useEffect(() => {
    const container = chatListRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const getInvitations = () => {
    if (
      !roomList ||
      !roomList.inviteDirects ||
      !roomList.inviteSpaces ||
      !roomList.inviteRooms
    ) {
      return [];
    }
    const invites = [
      ...Array.from(roomList.inviteDirects),
      ...Array.from(roomList.inviteSpaces),
      ...Array.from(roomList.inviteRooms),
    ];
    return invites;
  };

  const filteredInvitations = getInvitations().filter((roomId) => {
    if (selectedFilter === "All") return true;
    const notificationHistory = historicalNotifications;
    const notification = notificationHistory.find((n) => n.roomId === roomId);
    if (selectedFilter === "New") {
      return !notification;
    }
    if (selectedFilter === "Old") {
      return !!notification;
    }
    return true;
  });

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const renderNotification = (roomId, type) => {
    const room = mx.getRoom(roomId);
    if (!room) return null;

    const inviterName =
      room.getMember(mx.getUserId())?.events?.member?.getSender?.() ?? "";
    const user = mx.getUser(inviterName);
    const displayName = user?.displayName || "Unknown";
    const roomName = room.name || "Unnamed Room";
    const avatarUrl = room.getAvatarUrl(mx.baseUrl, 42, 42, "crop") || null;

    return (
      <NotificationItem
        key={roomId}
        roomId={roomId}
        type={type}
        isDM={type === "Direct Message"}
        name={roomName}
        inviterName={displayName}
        avatarUrl={avatarUrl}
        loading={loadingStates[roomId]}
        onAccept={acceptInvite}
        onReject={rejectInvite}
        isHistorical={false}
      />
    );
  };

  const allNotifications = [
    ...filteredInvitations.map((roomId) => {
      let type = "Room";
      if (roomList.inviteDirects.has(roomId)) type = "Direct Message";
      else if (roomList.inviteSpaces.has(roomId)) type = "Space";
      return { roomId, type };
    }),
    ...historicalNotifications,
  ];

  return (
    <div className="chats-container">
      <div className="chat-header-container">
        <span className="chat-header-text">Notifications</span>
      </div>
      <div className="chat-filters">
        {["All", "New", "Old"].map((filter) => (
          <ChatFilter
            key={filter}
            label={filter}
            isActive={selectedFilter === filter}
            onClick={() => handleFilterClick(filter)}
          />
        ))}
      </div>
      <div className="chat-list" ref={chatListRef}>
        {allNotifications.length === 0 ? (
          <div className="no-chat-container">
            <div className="no-chat-icon-container">
              <NoNotificationsIcon
                colorStyle={colorStyle}
                height={56}
                width={56}
              />
            </div>
            <span className="no-chat-title-text">
              You have no notifications.
            </span>
          </div>
        ) : (
          <>
            {filteredInvitations.length === 0 && selectedFilter === "New" ? (
              <div className="no-chat-container">
                <div className="no-chat-icon-container">
                  <NoNotificationsIcon
                    colorStyle={colorStyle}
                    height={56}
                    width={56}
                  />
                </div>
                <span className="no-chat-title-text">
                  No {selectedFilter} Notifications here.
                </span>
              </div>
            ) : (
              filteredInvitations.map((roomId) => {
                let type = "Room";
                if (roomList.inviteDirects.has(roomId)) type = "Direct Message";
                else if (roomList.inviteSpaces.has(roomId)) type = "Space";
                return renderNotification(roomId, type);
              })
            )}
            {historicalNotifications.length > 0 && selectedFilter !== "New" && (
              <div className="historical-notifications">
                {historicalNotifications.map((notification) => (
                  <NotificationItem
                    key={`${notification.roomId}-${notification.timestamp}`}
                    roomId={notification.roomId}
                    type={notification.type}
                    isDM={notification.type === "Direct Message"}
                    name={notification.name}
                    inviterName={notification.inviterName}
                    avatarUrl={notification.avatarUrl}
                    loading={false}
                    onAccept={() => {}}
                    onReject={() => {}}
                    isHistorical={true}
                    action={notification.action}
                  />
                ))}
                {!hasMore && historicalNotifications.length > 0 && (
                  <div className="no-chat-container">
                    <div className="no-more-history-container">
                      <div className="no-notifications-icon-container">
                        <NoNotificationsIcon
                          colorStyle={colorStyle}
                          height={24}
                          width={24}
                        />
                      </div>
                      <span className="no-notifications-page-text">
                        No more notifications.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default NotificationsPage;
