import React, { useState, useEffect } from "react";
import NavigationLegal from "../../components/navigation/NavigationLegal";
import Header from "../../atoms/blog/Header";
import Paragraph from "../../atoms/blog/Paragraph";
import { Dialog } from "@capacitor/dialog";
import { Capacitor } from "@capacitor/core";
import initMatrix from "../../../client/initMatrix";
import "./AccountDeletion.scss";
import { useAppConnection } from "../../contexts/AppConnectionContext";
import LoadingDisplay from "../loading-display/LoadingDisplay";

const AccountDeletion = ({ isPopover }) => {
  const [deletionStatus, setDeletionStatus] = useState("active");
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setIsDisconnecting } = useAppConnection();

  const mx = initMatrix.matrixClient;
  const userId = mx.getUserId();

  useEffect(() => {
    const fetchDeletionStatus = async () => {
      try {
        const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
        const response = await fetch(
          `${baseUrl}/_synapse/client/account_deletion_status?user_id=${userId}`
        );
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Network response was not ok: ${errorText}`);
        }
        const data = await response.json();
        if (data && data.status) {
          setDeletionStatus(data.status);
          if (data.status === "pending_deletion") {
            const deletionDate = new Date(data.deletion_timestamp);
            const now = new Date();
            const remainingTime = deletionDate - now;
            setDaysRemaining(Math.ceil(remainingTime / (1000 * 60 * 60 * 24)));
          }
        } else {
          console.log("Invalid response data:", data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeletionStatus();
  }, [userId]);

  const handleDeleteAccount = async () => {
    const message =
      "Are you sure you want to deactivate your account? Your account will be set for deactivation and you will have 90 days to reactivate it by logging back in and revoking deactivation. After 90 days, your account will be permanently deactivated and you will not be able to log in again.";
    const title = "Confirm Account Deactivation";

    if (Capacitor.isNativePlatform()) {
      const { value } = await Dialog.confirm({
        title,
        message,
      });
      if (value) {
        await initiateAccountDeactivation();
        setIsDisconnecting(true);
      }
    } else {
      const confirmed = window.confirm(message);
      if (confirmed) {
        await initiateAccountDeactivation();
        setIsDisconnecting(true);
      }
    }
  };

  const initiateAccountDeactivation = async () => {
    try {
      const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
      const response = await fetch(
        `${baseUrl}/_synapse/client/account_deletion`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${errorText}`);
      }
      const data = await response.json();
      console.log("Account deactivation initiated:", data);
      setDeletionStatus("pending_deactivation");
      setDaysRemaining(90);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRevokeDeactivation = async () => {
    const message =
      "Are you sure you want to revoke the deactivation of your account? This will cancel the deactivation process.";
    const title = "Confirm Revoke Deactivation";

    let confirmed = false;
    if (Capacitor.isNativePlatform()) {
      const { value } = await Dialog.confirm({
        title,
        message,
      });
      confirmed = value;
    } else {
      confirmed = window.confirm(message);
    }

    if (confirmed) {
      try {
        const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
        const response = await fetch(
          `${baseUrl}/_synapse/client/revoke_account_deletion`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id: userId }),
          }
        );
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Network response was not ok: ${errorText}`);
        }
        const data = await response.json();
        console.log("Account deactivation revoked:", data);
        setDeletionStatus("active");
        setDaysRemaining(null);
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (loading) {
    return (
      <div className="account-deletion-loading-container">
        <LoadingDisplay />
      </div>
    );
  }

  if (deletionStatus === "pending_deletion") {
    return (
      <div
        className={
          isPopover
            ? "popover-account-deletion-app-wrapper"
            : "account-deletion-wrapper"
        }
      >
        {!isPopover && <NavigationLegal />}
        <Header
          text="Account Deactivation Pending"
          tagText="ACCOUNT DEACTIVATION"
          marginBottom={false}
        />
        <Paragraph
          primaryText={`Your account is scheduled for deactivation. You have ${daysRemaining} days remaining to reactivate your account by logging back in and revoking deactivation.`}
        />
        <div className="delete-account-container">
          <div
            className="delete-account-button"
            onClick={handleRevokeDeactivation}
          >
            <span className="delete-account-button-text">
              Revoke Deactivation
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        isPopover
          ? "popover-account-deletion-app-wrapper"
          : "account-deletion-wrapper"
      }
    >
      {!isPopover && <NavigationLegal />}
      <Header
        text="Deactivate Your Account"
        tagText="ACCOUNT DEACTIVATION"
        marginBottom={false}
      />
      <Paragraph primaryText="By deactivating your account, you are initiating a process that will prevent you from logging in after a 90-day grace period. During this period, you can reactivate your account by logging back in and revoking deactivation. If you do not log in within 90 days, your account will be permanently deactivated and you will not be able to log in again." />
      <div className="delete-account-container">
        <div className="delete-account-button" onClick={handleDeleteAccount}>
          <span className="delete-account-button-text">Deactivate Account</span>
        </div>
      </div>
    </div>
  );
};

export default AccountDeletion;
