import React, { useState, useEffect } from "react";
import CustomInput from "../../atoms/input/CustomInput";
import "./Presale.scss";

const FundraiserDetailsComponent = ({
  presaleData,
  setPresaleData,
  scrollToInput,
}) => {
  const [localState, setLocalState] = useState({
    presaleTargetRaiseAmount: "",
    presaleFundraiserMinContribution: "",
    presaleFundraiserMaxContribution: "",
    presaleFundraiserDescription: "",
  });

  useEffect(() => {
    if (presaleData) {
      setLocalState({
        presaleTargetRaiseAmount: presaleData.presaleTargetRaiseAmount || "",
        presaleFundraiserMinContribution:
          presaleData.presaleFundraiserMinContribution || "",
        presaleFundraiserMaxContribution:
          presaleData.presaleFundraiserMaxContribution || "",
        presaleFundraiserDescription:
          presaleData.presaleFundraiserDescription || "",
      });
    }
  }, [presaleData]);

  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setLocalState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleBlur = (field) => (e) => {
    const value = e.target.value;
    setPresaleData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div className="presale-form-content">
      <span className="presale-primary-text">Presale Particulars</span>
      <span className="presale-secondary-text">
        Define key financial metrics. Set your fundraising goals and
        contribution limits. Here, you're not just deciding numbers, but shaping
        the financial backbone of your project.
      </span>
      <div className="presale-tokenomics-container">
        <div className="presale-fundraising-info-container">
          <div className="presale-tokenomics-container-level">
            <div className="presale-tokenomics-container-level-fundraise">
              <span className="presale-tokenomics-input-header-text">
                Fundraising Amount
              </span>
              <CustomInput
                placeholder="Hard Cap ETH"
                value={localState.presaleTargetRaiseAmount}
                onChange={handleInputChange("presaleTargetRaiseAmount")}
                onBlur={handleBlur("presaleTargetRaiseAmount")}
                scrollToInput={scrollToInput}
              />
            </div>
          </div>
          <div className="presale-tokenomics-container-level">
            <div className="presale-tokenomics-container-level-min">
              <span className="presale-tokenomics-input-header-text">
                Min Contribution
              </span>
              <CustomInput
                placeholder="Min ETH"
                value={localState.presaleFundraiserMinContribution}
                onChange={handleInputChange("presaleFundraiserMinContribution")}
                onBlur={handleBlur("presaleFundraiserMinContribution")}
                scrollToInput={scrollToInput}
              />
            </div>
            <div className="presale-tokenomics-container-level-max">
              <span className="presale-tokenomics-input-header-text">
                Max Contribution
              </span>
              <CustomInput
                placeholder="Max ETH"
                value={localState.presaleFundraiserMaxContribution}
                onChange={handleInputChange("presaleFundraiserMaxContribution")}
                onBlur={handleBlur("presaleFundraiserMaxContribution")}
                scrollToInput={scrollToInput}
              />
            </div>
          </div>
        </div>
        <div className="presale-tokenomics-container-level-fundraise">
          <span className="presale-tokenomics-input-header-text">
            Presale Description
          </span>
          <CustomInput
            value={localState.presaleFundraiserDescription}
            placeholder="Fundraiser Description"
            onChange={handleInputChange("presaleFundraiserDescription")}
            onBlur={handleBlur("presaleFundraiserDescription")}
            resizable={true}
            scrollToInput={scrollToInput}
          />
        </div>
      </div>
    </div>
  );
};

export default FundraiserDetailsComponent;
