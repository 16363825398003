import React, { useState, useEffect } from "react";
import "./Token.scss";
import { ReactComponent as Etherscan } from "../../assets/svg/etherscan.svg";
import HighlightsContainer from "../../atoms/highlights/HighlightsContainer";
import { ReactComponent as Goal } from "../../assets/svg/fillgoal.svg";
import { ReactComponent as SwapIcon } from "../../assets/svg/buy-crypto.svg";
import Popover from "../../atoms/popover/Popover";
import Swap from "./Swap";
import { ReactComponent as Settings } from "../../assets/svg/settings-thick.svg";
import getProvider from "../../../util/getProvider";

const Token = ({ liquidityData, data, avatarUrl, spaceId }) => {
  const token0Symbol = liquidityData?.token0?.symbol || "Unknown Token";
  const [swapDialog, setSwapDialog] = useState(false);
  const quoterAddress = import.meta.env.VITE_QUOTER_SEPOLIA;
  const swapRouterAddress = import.meta.env.VITE_SWAP_ROUTER_SEPOLIA;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [provider, setProvider] = useState(false);

  const SwapHeader = ({ setIsModalOpen }) => (
    <div
      className="spaceview-settings-container"
      onClick={() => setIsModalOpen(true)}
    >
      <Settings className="space-settings-svglogo" />
    </div>
  );

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const { provider } = await getProvider();
        setProvider(provider);
      } catch (error) {
        console.error("Error fetching provider:", error);
      }
    };

    fetchProvider();
  }, []);

  return (
    <div className="token-component-wrapper">
      {liquidityData ? (
        <div onClick={() => setSwapDialog(true)}>
          <HighlightsContainer
            text={`Swap ${token0Symbol}/ETH`}
            subtext={`Enjoy seamless trading and liquidity provision for the ${token0Symbol}/ETH pair.`}
            Icon={SwapIcon}
            iconFill="var(--color-accent-three)"
          />
        </div>
      ) : (
        <HighlightsContainer
          text="Liquidity Features"
          subtext="The token does not have a market made for it yet. Watch fundraisers and await swapping when it does."
          Icon={Goal}
          iconFill="var(--color-accent-two)"
        />
      )}
      <div className="space-token-data-container">
        <div className="data-container">
          <span className="space-landing-space-data-title-text-primary">
            Token Name
          </span>
          <span className="space-landing-space-data-text">
            {data.tokenName}
          </span>
        </div>
        <div className="data-container">
          <span className="space-landing-space-data-title-text-primary">
            Symbol
          </span>
          <span className="space-landing-space-data-text">
            {data.tokenSymbol}
          </span>
        </div>
      </div>
      <div className="social-link-component-mobile-etherscan">
        <div className="flex-container-token">
          <span className="space-landing-space-data-title-text-primary">
            Token Address
          </span>
          <div
            onClick={() => {
              const url = data.etherscanLink.startsWith("http")
                ? data.etherscanLink
                : `https://${data.etherscanLink}`;
              window.open(url, "_blank");
            }}
            className="social-link-container"
          >
            <Etherscan className="social-link-svg" />
            <span className="space-landing-space-social-text">Etherscan</span>
          </div>
        </div>
      </div>
      {swapDialog && (
        <Popover
          direction="right"
          setOpenDialog={setSwapDialog}
          headerText={`Swap ${token0Symbol}/ETH`}
          headerContent={<SwapHeader setIsModalOpen={setIsModalOpen} />}
          shouldResize={false}
        >
          <Swap
            liquidityData={liquidityData}
            setOpenDialog={setSwapDialog}
            data={data}
            quoterAddress={quoterAddress}
            swapRouterAddress={swapRouterAddress}
            provider={provider}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            spaceId={spaceId}
            avatarUrl={avatarUrl}
          />
        </Popover>
      )}
    </div>
  );
};

export default Token;
