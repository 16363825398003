import React from "react";
import PropTypes from "prop-types";
import "./SpaceAvatar.scss";
import { getRandomGradient } from "../../../util/gradients";

const SpaceAvatar = React.forwardRef(
  ({ size, imageSrc, roomId, text, borderRadius, includeTransitions }, ref) => {
    const gradientInfo = roomId && !imageSrc ? getRandomGradient(roomId) : null;

    const getFormattedText = (text) => {
      return text && text.length ? text.charAt(0).toUpperCase() : "";
    };
    const shouldApplyBorder = size > 26;
    const formattedText = getFormattedText(text);
    const avatarClassName = `space-avatar-contain${
      includeTransitions ? " include-transitions" : ""
    }`;

    return (
      <div
        className={avatarClassName}
        ref={ref}
        style={{
          backgroundImage: gradientInfo ? gradientInfo.gradient : "none",
          backgroundColor: imageSrc ? "transparent" : undefined,
          width: `${size}px`,
          height: `${size}px`,
          display: "inline-flex",
          position: "relative",
          overflow: "hidden",
          borderRadius: `${borderRadius}px`,
          border: shouldApplyBorder
            ? "1px solid var(--color-hover-primary)"
            : "none",
        }}
      >
        {imageSrc ? (
          <img
            draggable="false"
            src={imageSrc}
            onLoad={(e) => {
              e.target.style.backgroundColor = "transparent";
            }}
            alt=""
            style={{ width: `${size}px`, height: `${size}px` }}
          />
        ) : (
          <span
            className="space-avatar-text-color"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
            style={{
              color: gradientInfo ? gradientInfo.textColor : undefined,
            }}
          >
            {formattedText}
          </span>
        )}
      </div>
    );
  }
);


export default SpaceAvatar;
