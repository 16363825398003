import React from "react";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import "./LeftSideContainer.scss";

function LeftSideContainer() {
  return (
    <div className="left-side-container-wrapper">
      <WaivlengthLogo fill="var(--color-text-primary)" height={26} width={36} />
    </div>
  );
}

export default LeftSideContainer;
