import React from "react";
import { ReactComponent as MarkRead } from "../../assets/svg/fillmarkread.svg";
import { ReactComponent as AddMember } from "../../assets/svg/filladduser.svg";
import "./CreateNewRoom.scss";
import SettingButton from "../../atoms/button/SettingsButton";

function CreateNewRoom({
  afterOptionSelect,
  onOpenDMSearchPopover,
  onOpenCreateRoomPopover,
}) {
  const handleCreateDM = () => {
    afterOptionSelect(); // Close the modal
    onOpenDMSearchPopover(); // Open the DM search popover
  };

  const handleCreateNewRoom = () => {
    afterOptionSelect(); // Close the modal
    onOpenCreateRoomPopover(); // Open the create room popover
  };

  return (
    <div className="room-options-container">
      <span className="room-options-secondary-text">Create Room</span>
      <SettingButton
        primaryText="Create new room"
        Icon={() => <AddMember className="wallet-connected-svglogo-fill" />}
        onClick={handleCreateNewRoom}
      />
      <SettingButton
        primaryText="Start Direct Message"
        Icon={() => <MarkRead className="wallet-connected-svglogo-fill" />}
        onClick={handleCreateDM}
      />
    </div>
  );
}

export default CreateNewRoom;
