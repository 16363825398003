import React from "react";
import "./ScrollDown.scss";
import { ReactComponent as RightArrow } from "../../assets/svg/arrow-circle-down.svg";

const ScrollDown = ({
  handleScroll,
  show,
  scrollToBottomButtonRef,
  newEventCount,
  keyboardHeight,
}) => {
  return (
    <div
      className={`scroll-down-wrapper ${show ? "show" : "hide"}`}
      onClick={handleScroll}
      ref={scrollToBottomButtonRef}
      style={{ bottom: `${keyboardHeight > 1 ? keyboardHeight + 84 : 120}px` }}
    >
      {newEventCount > 0 && (
        <span className="scroll-down-counter-text">{newEventCount}</span>
      )}
      <RightArrow className="scroll-down-svglogo" />
    </div>
  );
};

export default ScrollDown;
