import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./InviteUser.scss";
import initMatrix from "../../../client/initMatrix";
import * as roomActions from "../../../client/action/room";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { ReactComponent as Tick } from "../../assets/svg/tick.svg";
import CustomInput from "../../atoms/input/CustomInput";
import { formatDisplayName } from "../../../util/formatDisplayName";
import Loader from "../../atoms/loader/Loader";

function InviteUser({ roomId, isDialogOpen }) {
  const [isSearching, updateIsSearching] = useState(false);
  const [searchQuery, updateSearchQuery] = useState({});
  const [users, updateUsers] = useState([]);
  const [procUsers, updateProcUsers] = useState(new Set());
  const [procUserError, updateUserProcError] = useState(new Map());
  const [invitedUserIds, updateInvitedUserIds] = useState(new Set());
  const [joinedUserIds, updateJoinedUserIds] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState("");
  const mx = initMatrix.matrixClient;

  useEffect(() => {
    const fetchRoomMemberships = async () => {
      const room = mx.getRoom(roomId);
      if (!room) {
        return;
      }
      const invitedMembers = room.getMembersWithMembership("invite");
      const joinedMembers = room.getMembersWithMembership("join");

      const invitedUserIdsSet = new Set(
        invitedMembers.map((member) => member.userId)
      );
      const joinedUserIdsSet = new Set(
        joinedMembers.map((member) => member.userId)
      );
      updateInvitedUserIds(invitedUserIdsSet);
      updateJoinedUserIds(joinedUserIdsSet);
    };

    if (isDialogOpen) {
      fetchRoomMemberships();
    }
  }, [isDialogOpen, roomId, mx]);

  function getMapCopy(myMap) {
    const newMap = new Map();
    myMap.forEach((data, key) => {
      newMap.set(key, data);
    });
    return newMap;
  }

  function addUserToProc(userId) {
    procUsers.add(userId);
    updateProcUsers(new Set(Array.from(procUsers)));
  }

  function deleteUserFromProc(userId) {
    procUsers.delete(userId);
    updateProcUsers(new Set(Array.from(procUsers)));
  }

  async function inviteToRoom(userId) {
    if (typeof roomId === "undefined") return;
    try {
      addUserToProc(userId);
      procUserError.delete(userId);
      updateUserProcError(getMapCopy(procUserError));

      await roomActions.invite(roomId, userId);

      updateInvitedUserIds((prevInvitedUserIds) => {
        const newInvitedUserIds = new Set(prevInvitedUserIds);
        newInvitedUserIds.add(userId);
        return newInvitedUserIds;
      });
      deleteUserFromProc(userId);
    } catch (e) {
      deleteUserFromProc(userId);
      if (typeof e.message === "string") procUserError.set(userId, e.message);
      else procUserError.set(userId, "Something went wrong!");
      updateUserProcError(getMapCopy(procUserError));
    }
  }

  useEffect(() => {
    if (searchTerm.trim() === "") {
      resetSearch();
    } else {
      searchUser(searchTerm);
    }
  }, [searchTerm]);

  function handleInputChange(e) {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
  }

  function resetSearch() {
    updateUsers([]);
    updateSearchQuery({});
  }

  async function searchUser(username) {
    const inputUsername = username.trim();
    if (
      isSearching ||
      inputUsername === "" ||
      inputUsername === searchQuery.username
    )
      return;

    const isInputUserId =
      inputUsername[0] === "@" && inputUsername.indexOf(":") > 1;
    updateIsSearching(true);
    updateSearchQuery({ username: inputUsername });

    try {
      const result = isInputUserId
        ? await mx.getProfileInfo(inputUsername)
        : await mx.searchUserDirectory({ term: inputUsername, limit: 20 });

      const users = isInputUserId
        ? [
            {
              user_id: inputUsername,
              display_name: result.displayname,
              avatar_url: result.avatar_url,
            },
          ]
        : result.results;

      // Filter out users with join membership
      const filteredUsers = users.filter(
        (user) => !joinedUserIds.has(user.user_id)
      );

      updateUsers(filteredUsers.length ? filteredUsers : []);
      updateSearchQuery(
        filteredUsers.length
          ? {}
          : { error: `No matches found for "${inputUsername}"!` }
      );
    } catch (e) {
      updateSearchQuery({ error: "Something went wrong!" });
    }
    updateIsSearching(false);
  }

  function renderUserList() {
    const displayableUsers = users.filter(
      (user) => !joinedUserIds.has(user.user_id)
    );
    const sortedUsers = displayableUsers.sort((a, b) =>
      a.user_id.localeCompare(b.user_id)
    );
    const maxUsers = sortedUsers.slice(0, 12);
    return maxUsers.map((user) => {
      const userId = user.user_id;
      const name = formatDisplayName(user.display_name);
      const avatarUrl =
        typeof user.avatar_url === "string"
          ? initMatrix.matrixClient.mxcUrlToHttp(
              user.avatar_url,
              28,
              28,
              "crop"
            )
          : null;

      return (
        <div
          key={userId}
          className="people-selector"
          onClick={() => inviteToRoom(userId)}
          disabled={invitedUserIds.has(userId)}
        >
          <div>
            <UserAvatar size={28} userId={userId} imageSrc={avatarUrl} />
          </div>
          <span className="people-selector-text-color">
            {formatDisplayName(name)}
          </span>
          {procUsers.has(userId) ? (
            <div className="invite-user-loader-container">
              <Loader
                size="14px"
                dotSize="4px"
                color="var(--light)"
                multiplier={1.5}
              />
            </div>
          ) : invitedUserIds.has(userId) ? (
            <div className="invite-user-tick-container">
              <Tick className="notification-selector-svglogo-tick" />
            </div>
          ) : null}
        </div>
      );
    });
  }

  return (
    <div className="search-bar-search-results-container">
      <div className="search-bar-mobile-results">
        <span className="search-bar-mobile-results-header">
          Invite to {mx.getRoom(roomId).name}
        </span>
      </div>
      <CustomInput
        placeholder="Search users"
        value={searchTerm}
        onChange={(e) => handleInputChange(e)}
        autoFocus={[true, 300]}
      />
      <div className="search-bar-mobile-results">
        <span className="search-bar-mobile-results-header">Users</span>
      </div>
      {users.length === 0 && (
        <div className="search-bar-mobile-results">
          <span className="search-bar-mobile-results-footer">
            No users matched
          </span>
        </div>
      )}
      {users.length !== 0 && renderUserList()}
    </div>
  );
}

InviteUser.propTypes = {
  roomId: PropTypes.string,
  searchTerm: PropTypes.string,
};

export default InviteUser;
