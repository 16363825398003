import React from "react";
import "./RoomIntro.scss";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import initMatrix from "../../../client/initMatrix";

function RoomIntro({ roomId, heading, desc, time, room }) {
  const mx = initMatrix.matrixClient;
  let userId =
    room && room.summaryHeroes && room.summaryHeroes.length > 0
      ? room.summaryHeroes[0]
      : null;
  const isDM = initMatrix.roomList.directs.has(roomId);
  const avatarUrl =
    room.getAvatarUrl(initMatrix.matrixClient.baseUrl, 42, 42, "crop") || null;
  let avatarSrc = room.getAvatarUrl(mx.baseUrl, 36, 36, "crop");
  avatarSrc = isDM
    ? room.getAvatarFallbackMember()?.getAvatarUrl(mx.baseUrl, 36, 36, "crop")
    : avatarSrc;
  let username = "";
  if (room && room.summaryHeroes && room.summaryHeroes.length > 0) {
    let rawUsername = room.summaryHeroes[0];
    username = rawUsername.slice(1, rawUsername.indexOf(":")).toUpperCase();
  }

  return (
    <div className="room-intro">
      <div className="room-intro-container">
        {room && room.summaryHeroes && room.summaryHeroes.length === 1 ? (
          <UserAvatar size={72} userId={userId} imageSrc={avatarUrl} />
        ) : (
          <RoomAvatar
            roomId={roomId}
            imageSrc={avatarUrl}
            size={72}
            borderRadius={16}
          />
        )}
      </div>
      <div className="room-intro-content">
        <span className="room-intro-text-header">{heading}</span>
        <span className="room-intro-text-desc">{desc}</span>
        {time !== null && <span className="room-intro-text-time">{time}</span>}
      </div>
    </div>
  );
}

export default RoomIntro;
