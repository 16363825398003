import React from "react";
import "./MembershipScore.scss";
import CommunityScoreIcon from "../../assets/svg/CommunityScoreIcon";
import usernameColorMXID from "../../../util/usernameColorMXID";
import { ReactComponent as Gitbook } from "../../assets/svg/gitbook.svg";

const MembershipScore = ({ totalScore, mx, matrixUser }) => {
  const colorStyle = usernameColorMXID(matrixUser.userId);
  const isGradient = colorStyle.startsWith("linear-gradient");
  const textStyle = isGradient
    ? {
        background: colorStyle,
        WebkitBackgroundClip: "text",
        color: "transparent",
      }
    : { color: colorStyle };
  return (
    <div className="membership-score-container">
      <div className="membership-score-wrapper">
        {totalScore !== null && (
          <div className="membership-score-user-score-container">
            <CommunityScoreIcon
              colorStyle={colorStyle}
              height={56}
              width={56}
            />
            <span
              className="membership-score-user-score-text"
              style={textStyle}
            >
              {totalScore}
            </span>
          </div>
        )}
        <span className="membership-score-user-score-title-text">
          Your Waivlength Community Membership Score
        </span>
      </div>
      <div className="membership-score-more-wrapper">
        <a
          href="https://docs.waivlength.io/other/community-membership-score-program"
          target="_blank"
          rel="noopener noreferrer"
          className="membership-score-link"
        >
          <div className="membership-score-details-container">
            <Gitbook className="gitbook-svglogo-fill" />
            <span className="membership-score-details-title-text">
              Read More
            </span>
          </div>
        </a>
        <span className="membership-score-details-subtitle-text">
          Earn rewards and airdrops of the $WAIV token through your engagement.
          The more you contribute, the more you benefit—so get involved!
        </span>
      </div>
    </div>
  );
};

export default MembershipScore;
