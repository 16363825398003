import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import SpaceView from "./SpaceView";
import "./SpaceViewWrapper.scss";
import { useRoomSelect } from "../../contexts/RoomSelectContext";

const SpaceViewWrapper = ({ isOpen, isClosing, onClose, spaceId, space }) => {
  const { setSpaceId } = useRoomSelect();
  const { roomList } = initMatrix;
  const [childRooms, setChildRooms] = useState([]);
  const [isSwiping, setIsSwiping] = useState(false);

  const updateChildRooms = () => {
    if (!space) {
      return;
    }
    const spaceChildEvents = space.currentState.getStateEvents("m.space.child");
    const childRoomIds = Array.from(spaceChildEvents.values()).map((event) => {
      const content = event.getContent();
      return {
        roomId: event.getStateKey(),
        content,
      };
    });
    setChildRooms(childRoomIds);
  };

  useEffect(() => {
    if (space) {
      updateChildRooms();
      roomList.on(cons.events.roomList.ROOMLIST_UPDATED, updateChildRooms);

      return () => {
        roomList.removeListener(
          cons.events.roomList.ROOMLIST_UPDATED,
          updateChildRooms
        );
      };
    }
  }, [space, roomList]);

  const swipeHandlers = useSwipeable({
    onSwiping: () => setIsSwiping(true),
    onSwipedRight: () => {
      setIsSwiping(true);
      setTimeout(() => {
        setSpaceId(null);
        setIsSwiping(false);
      }, 300);
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  if (!space) {
    return null;
  }

  return (
    <>
      <div
        className={`space-view-background ${
          isClosing ? "closing" : isOpen ? "open" : ""
        }`}
      ></div>
      <div
        {...swipeHandlers}
        className={`space-view-wrapper ${isSwiping ? "swiping-right" : ""} ${
          isClosing ? "closing" : isOpen ? "open" : ""
        }`}
      >
        <SpaceView
          spaceId={spaceId}
          onClose={onClose}
          childRooms={childRooms}
          space={space}
        />
      </div>
    </>
  );
};

export default SpaceViewWrapper;
