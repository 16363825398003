import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./Modal2.scss";
import useKeyboard from "../../hooks/useKeyboard";
import LoadingComponent from "../loading/Loading";

const Modal2 = ({ isOpen, children, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const startY = useRef(null);
  const currentY = useRef(0);
  const modalContentRef = useRef(null);
  const backgroundRef = useRef(null);
  const { hideKeyboard } = useKeyboard();

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsActive(true), 10); // Delay for animation
    } else {
      setIsActive(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
        if (onClose) onClose(); // Close modal only after animation
      }, 300); // 300ms for smooth transition
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  const handleTouchStart = (e) => {
    if (e.target.closest(".custom-modal2-divider")) {
      startY.current = e.touches[0].clientY;
      currentY.current = startY.current;
      modalContentRef.current.style.transition = "none";
      if (backgroundRef.current) {
        backgroundRef.current.style.transition = "none";
      }
    }
  };

  const handleTouchMove = (e) => {
    if (!startY.current) return;

    currentY.current = e.touches[0].clientY;
    const deltaY = currentY.current - startY.current;

    // Only allow downward movement to slide the modal
    if (deltaY > 0) {
      hideKeyboard(); // Close keyboard on downward swipe
      const opacity = 1 - deltaY / (window.innerHeight / 2);
      modalContentRef.current.style.transform = `translateY(${deltaY}px)`;
      if (backgroundRef.current) {
        backgroundRef.current.style.opacity = Math.max(opacity, 0);
      }
    }
  };

  const handleTouchEnd = () => {
    if (!startY.current) return;

    const deltaY = currentY.current - startY.current;

    modalContentRef.current.style.transition =
      "transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)";
    if (backgroundRef.current) {
      backgroundRef.current.style.transition =
        "opacity 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)";
    }

    if (deltaY > window.innerHeight / 4) {
      modalContentRef.current.style.transform = "translateY(100%)";
      if (backgroundRef.current) {
        backgroundRef.current.style.opacity = "0";
      }
      setTimeout(onClose, 300);
    } else {
      modalContentRef.current.style.transform = "translateY(0)";
      if (backgroundRef.current) {
        backgroundRef.current.style.opacity = "1";
      }
    }

    startY.current = null;
    currentY.current = 0;
  };

  if (!isVisible && !isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div
        className={`custom-modal2-background-container ${
          isActive ? "fade-in" : "fade-out"
        }`}
        ref={backgroundRef}
      ></div>
      <div className="modal2-overlay" onClick={onClose}>
        <div
          className={`custom-modal2-container ${
            isActive ? "slide-up" : "slide-down"
          }`}
          onClick={(e) => e.stopPropagation()}
          ref={modalContentRef}
        >
          <div
            className="custom-modal2-divider"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="custom-modal2-divider-component"></div>
          </div>
          {children}
        </div>
      </div>
      <LoadingComponent />
    </>,
    document.getElementById("custom-modal-root2")
  );
};

export default Modal2;
