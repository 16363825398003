import { PushNotifications } from "@capacitor/push-notifications";
import { Device } from "@capacitor/device";
import initMatrix from "../client/initMatrix";
import { Capacitor } from "@capacitor/core";

const sygnalServer = import.meta.env.VITE_SYGNAL_SERVER_URL;

const addPusher = async (deviceToken) => {
  const matrixClient = initMatrix.matrixClient;
  const info = await Device.getInfo();
  const platform = Capacitor.getPlatform();
  const userId = matrixClient.getUserId();

  const pusher = {
    pushkey: deviceToken,
    kind: "http",
    app_id: platform === "ios" ? "app.waivlength" : "app.waivlength.android",
    app_display_name: "Waivlength",
    device_display_name: info.name,
    profile_tag: "tag",
    lang: "en",
    data: {
      url: `${sygnalServer}/_matrix/push/v1/notify`,
      user_id: userId,
      ...(platform === "ios" && {
        default_payload: {
          aps: {
            sound: "default",
          },
        },
      }),
    },
  };

  try {
    await matrixClient.setPusher(pusher);
  } catch (error) {
    console.error("Failed to set pusher:", error);
  }
};

export const registerDevice = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();

  PushNotifications.addListener("registration", (token) => {
    addPusher(token.value);
    localStorage.setItem("devicePushKey", token.value);
  });

  PushNotifications.addListener("registrationError", (error) => {
    console.error("Registration error:", error.error);
  });
};

export const requestPermissions = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();
};
