import { useCallback, useMemo } from "react";

export type PowerLevelTag = {
  name: string;
};

export const usePowerLevelTags = () => {
  const powerLevelTags = useMemo(
    () => ({
      101: {
        name: "Founder",
      },
      100: {
        name: "Admin",
      },
      75: {
        name: "Governor",
      },
      50: {
        name: "Moderator",
      },
      25: {
        name: "TrustedMember",
      },
      0: {
        name: "Default",
      },
    }),
    []
  );

  return useCallback(
    (powerLevel: number): PowerLevelTag => {
      if (powerLevel >= 101) return powerLevelTags[101];
      if (powerLevel === 100) return powerLevelTags[100];
      if (powerLevel >= 75) return powerLevelTags[75];
      if (powerLevel >= 50) return powerLevelTags[50];
      if (powerLevel >= 25) return powerLevelTags[25];
      return powerLevelTags[0];
    },
    [powerLevelTags]
  );
};
