import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as ArrowBottom } from "../../assets/svg/arrow-bottom.svg";
import useKeyboard from "../../hooks/useKeyboard";
import "./Popover.scss";
import LoadingComponent from "../loading/Loading";
import { Capacitor } from "@capacitor/core";

const Popover = ({
  children,
  direction = "right",
  setOpenDialog,
  headerText,
  headerContent,
  shouldResize = true,
}) => {
  const [closing, setClosing] = useState(false);
  const { keyboardVisible, keyboardHeight } = useKeyboard();
  const popoverContentRef = useRef(null);
  const headerRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  const [heightAdjusted, setHeightAdjusted] = useState(false);
  const [keyboardWasClosed, setKeyboardWasClosed] = useState(!keyboardVisible);

  useEffect(() => {
    if (popoverContentRef.current && headerRef.current) {
      const platform = Capacitor.getPlatform();
      const headerHeight = headerRef.current.offsetHeight;

      if (keyboardVisible && shouldResize) {
        if (platform === "ios") {
          popoverContentRef.current.style.height = `calc(100% - ${keyboardHeight}px - ${headerHeight}px - 2px)`;
        } else if (platform === "android") {
          popoverContentRef.current.style.height = `calc(100%)`;
        }
        setHeightAdjusted(true);
      } else {
        popoverContentRef.current.style.height = "100%";
        setHeightAdjusted(false);
      }
    }
  }, [keyboardVisible, keyboardHeight]);

  useEffect(() => {
    if (!keyboardVisible) {
      setKeyboardWasClosed(true);
    } else if (keyboardWasClosed && keyboardVisible) {
      setKeyboardWasClosed(false);
    }
  }, [keyboardVisible]);

  const scrollToInput = (inputElement) => {
    if (inputElement) {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      if (keyboardWasClosed && !heightAdjusted) {
        scrollTimeoutRef.current = setTimeout(() => {
          executeScroll(inputElement);
        }, 150);
      } else {
        executeScroll(inputElement);
      }
    }
  };

  const executeScroll = (inputElement) => {
    if (!popoverContentRef.current) return;
    const popoverRect = popoverContentRef.current.getBoundingClientRect();
    const inputRect = inputElement.getBoundingClientRect();
    const scrollOffset =
      inputRect.top -
      popoverRect.top -
      (popoverRect.height / 2 - inputRect.height / 2);
    popoverContentRef.current.scrollTo({
      top: popoverContentRef.current.scrollTop + scrollOffset,
      behavior: "smooth",
    });
  };

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setOpenDialog(false);
      setClosing(false);
    }, 300);
  };

  const childProps = {
    openNestedPopover: setOpenDialog,
    setOpenDialog,
    scrollToInput,
  };

  return ReactDOM.createPortal(
    <div className={`popover ${direction} ${closing ? "closing" : ""}`}>
      <div className="popover-header-container" ref={headerRef}>
        <ArrowBottom className="popover-header-svglogo" onClick={handleClose} />
        <span className="popover-header-text">{headerText}</span>
        {headerContent || (
          <ArrowBottom className="popover-header-svglogo-hidden" />
        )}
      </div>
      <div className="popover-content" ref={popoverContentRef}>
        {React.Children.map(children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(child, childProps)
            : child
        )}
      </div>
      <LoadingComponent />
    </div>,
    document.getElementById("popover-root")
  );
};

export default Popover;
