import React from "react";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import Loader from "../../atoms/loader/Loader";
import { formatDisplayName } from "../../../util/formatDisplayName";
import "./NotificationItem.scss";

const NotificationItem = ({
  roomId,
  type,
  isDM,
  name,
  inviterName,
  avatarUrl,
  loading,
  onAccept,
  onReject,
  isHistorical,
  action,
}) => {
  const renderNotificationText = () => {
    if (isHistorical) {
      if (type === "Direct Message") {
        return action === "Accepted" ? (
          <>
            <span className="lower-text">You have joined the chat with </span>
            <span>{formatDisplayName(name)}</span>
          </>
        ) : (
          <>
            <span className="lower-text">
              You have rejected the invitation to chat with{" "}
            </span>
            <span>{formatDisplayName(name)}</span>
          </>
        );
      } else {
        return action === "Accepted" ? (
          <>
            <span className="lower-text">You have joined </span>
            <span>{name}</span>
          </>
        ) : (
          <>
            <span className="lower-text">
              You have rejected the invitation to join{" "}
            </span>
            <span>{name}</span>
          </>
        );
      }
    } else {
      if (type === "Direct Message") {
        return (
          <>
            <span className="lower-text">Invited to join chat with </span>
            <span>{formatDisplayName(name)}</span>
          </>
        );
      } else {
        return (
          <>
            <span className="lower-text">You have been invited by </span>
            <span>{formatDisplayName(inviterName)}</span>
            <span className="lower-text"> to join </span>
            <span>{name}</span>
          </>
        );
      }
    }
  };

  return (
    <div className="chat-room">
      <div className="notification-content">
        <div>
          {type === "Direct Message" ? (
            <UserAvatar size={48} userId={inviterName} imageSrc={avatarUrl} />
          ) : (
            <RoomAvatar
              imageSrc={avatarUrl}
              roomId={roomId}
              text={name}
              size={48}
              borderRadius={99}
            />
          )}
        </div>
        <div className="chat-info">
          <span className="notification-item-text">
            {renderNotificationText()}
          </span>
          {!isHistorical ? (
            <div className="notification-item-wrapper">
              <div className="notifications-page-notification-actions-container">
                <div
                  onClick={() => onReject(roomId, isDM)}
                  className="notifications-page-invite-container-reject"
                >
                  <span className="notifications-page-notification-text-reject">
                    Reject
                  </span>
                </div>
                <div
                  onClick={() => onAccept(roomId, isDM)}
                  className="notifications-page-invite-container-accept"
                >
                  {loading ? (
                    <Loader
                      size="20px"
                      dotSize="6px"
                      color="var(--dark)"
                      multiplier={1.4}
                    />
                  ) : (
                    <span className="notifications-page-notification-text-accept">
                      Accept
                    </span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="notification-item-wrapper">
              <div
                className={`notification-item-text-container ${
                  action === "Accepted" ? "accepted" : "rejected"
                }`}
              >
                <span className="notification-item-text">
                  {action === "Accepted" ? (
                    <span className="accepted-text">Joined</span>
                  ) : (
                    <span className="rejected-text">Rejected</span>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
