import { ethers } from "ethers";

export const validateTransaction = async (
  provider,
  address,
  requiredBalance
) => {
  const errors = [];

  if (!provider) {
    errors.push("provider is not provided.");
  }

  if (provider) {
    try {
      const network = await provider.getNetwork();
      const chainId = Number(network.chainId);

      if (chainId !== 11155111) {
        errors.push(
          "Connected to the wrong network. Please switch to Sepolia."
        );
      }

      const balanceWei = await provider.getBalance(address);
      const balanceEth = ethers.formatEther(balanceWei);

      if (balanceEth < requiredBalance) {
        errors.push(
          `Insufficient funds. Required balance is ${requiredBalance} ETH.`
        );
      }
    } catch (error) {
      console.error("Validation error:", error);
      errors.push(`Error: ${error.message}`);
    }
  }

  return errors.join(", ");
};
