import EventEmitter from "events";
import appDispatcher from "../dispatcher";
import cons from "./cons";

function getSettings() {
  const settings = localStorage.getItem("settings");
  return settings ? JSON.parse(settings) : {};
}

function setSettings(key, value) {
  const settings = getSettings();
  settings[key] = value;
  localStorage.setItem("settings", JSON.stringify(settings));
}

class Settings extends EventEmitter {
  constructor() {
    super();
    this.isMarkdown = this.getIsMarkdown();
    this.isPeopleDrawer = this.getIsPeopleDrawer();
    this.hideMembershipEvents = this.getHideMembershipEvents();
    this.hideNickAvatarEvents = this.getHideNickAvatarEvents();
    this._showNotifications = this.getShowNotifications();
    this.isNotificationSounds = this.getIsNotificationSounds();

    this.darkModeQueryList = window.matchMedia("(prefers-color-scheme: dark)");

    this.darkModeQueryList.addEventListener("change", () => this.applyTheme());

    this.isTouchScreenDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
  }

  getIsMarkdown() {
    const settings = getSettings();
    return settings.isMarkdown ?? true;
  }

  getHideMembershipEvents() {
    const settings = getSettings();
    return settings.hideMembershipEvents ?? false;
  }

  getHideNickAvatarEvents() {
    const settings = getSettings();
    return settings.hideNickAvatarEvents ?? true;
  }

  getIsPeopleDrawer() {
    const settings = getSettings();
    return settings.isPeopleDrawer ?? true;
  }

  getShowNotifications() {
    const settings = getSettings();
    return settings.showNotifications ?? true;
  }

  getIsNotificationSounds() {
    const settings = getSettings();
    return settings.isNotificationSounds ?? true;
  }

  setter(action) {
    const actions = {
      [cons.actions.settings.TOGGLE_SYSTEM_THEME]: () => {
        this.toggleUseSystemTheme();
      },
      [cons.actions.settings.TOGGLE_MARKDOWN]: () => {
        this.isMarkdown = !this.isMarkdown;
        setSettings("isMarkdown", this.isMarkdown);
        this.emit(cons.events.settings.MARKDOWN_TOGGLED, this.isMarkdown);
      },
      [cons.actions.settings.TOGGLE_PEOPLE_DRAWER]: () => {
        this.isPeopleDrawer = !this.isPeopleDrawer;
        setSettings("isPeopleDrawer", this.isPeopleDrawer);
        this.emit(
          cons.events.settings.PEOPLE_DRAWER_TOGGLED,
          this.isPeopleDrawer
        );
      },
      [cons.actions.settings.TOGGLE_MEMBERSHIP_EVENT]: () => {
        this.hideMembershipEvents = !this.hideMembershipEvents;
        setSettings("hideMembershipEvents", this.hideMembershipEvents);
        this.emit(
          cons.events.settings.MEMBERSHIP_EVENTS_TOGGLED,
          this.hideMembershipEvents
        );
      },
      [cons.actions.settings.TOGGLE_NICKAVATAR_EVENT]: () => {
        this.hideNickAvatarEvents = !this.hideNickAvatarEvents;
        setSettings("hideNickAvatarEvents", this.hideNickAvatarEvents);
        this.emit(
          cons.events.settings.NICKAVATAR_EVENTS_TOGGLED,
          this.hideNickAvatarEvents
        );
      },
      [cons.actions.settings.TOGGLE_NOTIFICATIONS]: async () => {
        this._showNotifications = !this._showNotifications;
        setSettings("showNotifications", this._showNotifications);
        this.emit(
          cons.events.settings.NOTIFICATIONS_TOGGLED,
          this._showNotifications
        );
      },
      [cons.actions.settings.TOGGLE_NOTIFICATION_SOUNDS]: () => {
        this.isNotificationSounds = !this.isNotificationSounds;
        setSettings("isNotificationSounds", this.isNotificationSounds);
        this.emit(
          cons.events.settings.NOTIFICATION_SOUNDS_TOGGLED,
          this.isNotificationSounds
        );
      },
    };

    actions[action.type]?.();
  }
}

const settings = new Settings();
appDispatcher.register(settings.setter.bind(settings));

export default settings;
