import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import Selector from "./Selector";
import { ReactComponent as ArrowBottom } from "../../assets/svg/arrow-bottom.svg";
import { ReactComponent as Add } from "../../assets/svg/plus.svg";
import "./RoomsCategory.scss";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import CreateRoom from "../create-room/CreateRoom";
import Popover from "../../atoms/popover/Popover";
import CreateSpace from "../create-space/CreateSpace";

function RoomsCategory({ name, roomIds }) {
  const { spaces, directs } = initMatrix.roomList;
  const { spaceId, setSpaceId, setRoomId, setDmRoomId } = useRoomSelect();
  const mx = initMatrix.matrixClient;
  const [isSelectorOpen, setIsSelectorOpen] = useState(true);
  const isDirectMessagesCategory = name === "People";
  const room = mx.getRoom(spaceId);
  const isSpace = room?.getType() === "m.space";
  const userId = mx.getUserId();
  const [openDialog, setOpenDialog] = useState(false);
  const [openSpaceDialog, setOpenSpaceDialog] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (isSpace) {
      const userPowerLevel = room.getMember(userId)?.powerLevel || 0;
      const requiredPowerLevel =
        room.currentState
          .getStateEvents("m.room.power_levels", "")
          ?.getContent()?.events?.["m.room.child"] ||
        room.currentState
          .getStateEvents("m.room.power_levels", "")
          ?.getContent()?.state_default ||
        50;

      setHasPermission(userPowerLevel >= requiredPowerLevel);
    }
  }, [isSpace, room, userId]);

  const handleAddSpaceOrRoomClick = () => {
    if (isSpace) {
      if (hasPermission) {
        setOpenDialog({ isSelectorOpen: true, isSpace: true });
      } else {
        alert("You do not have permission to add rooms to this space.");
      }
    } else {
      switch (name) {
        case "Spaces":
          setOpenSpaceDialog(true);
          break;
        case "Rooms":
          setOpenDialog({ isSelectorOpen: true, isSpace: false });
          break;
        case "People":
          break;
        default:
      }
    }
  };

  const handleSelectorClick = (roomId) => {
    const isSpace = spaces.has(roomId);
    const isDM = directs.has(roomId);

    if (isSpace) {
      setSpaceId(roomId);
    } else if (isDM) {
      setDmRoomId(roomId);
    } else {
      setRoomId(roomId);
    }
  };

  return (
    <div className="rooms-category-header-wrapper">
      <div className="rooms-category-header-wrapper-container">
        <div
          className="rooms-category-header-container"
          onClick={() => setIsSelectorOpen(!isSelectorOpen)}
        >
          <div className="rooms-category-header-text">
            <span className="notifications-page-header-text-color">
              {name === "People" ? "Direct Messages" : name}
            </span>
          </div>
          {!isDirectMessagesCategory && (
            <div className="rooms-category-arrow-container">
              <ArrowBottom
                className={`rooms-category-svglogo ${
                  isSelectorOpen ? "" : "selected"
                }`}
              />
            </div>
          )}
        </div>
        {!isDirectMessagesCategory && (
          <div
            className="rooms-category-add-container"
            onClick={handleAddSpaceOrRoomClick}
          >
            <div className="rooms-category-add-wrapper">
              <Add className="rooms-category-add-space-svglogo" />
            </div>
          </div>
        )}
      </div>
      {isSelectorOpen && (
        <div className="rooms-category-render-selector">
          {roomIds.map((roomId) => {
            const isSpace = spaces.has(roomId);
            const isDM = directs.has(roomId);
            const room = mx.getRoom(roomId);
            const spaceParentEventMap =
              room.currentState.events.get("m.space.parent");
            const spaceParentId = spaceParentEventMap
              ? Array.from(spaceParentEventMap.values())[0].getStateKey()
              : "No Parent Found";
            const isChildRoom = room.currentState.events.has("m.children.room");

            if (
              !isChildRoom &&
              ((isSpace && spaceParentId === "No Parent Found") ||
                (!isSpace && !spaces.has(roomId)))
            ) {
              return (
                <Selector
                  key={roomId}
                  roomId={roomId}
                  isDM={isDM}
                  onClick={() => handleSelectorClick(roomId)}
                />
              );
            }
            return null;
          })}
        </div>
      )}
      {openDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenDialog}
          headerText="Create Room"
        >
          <CreateRoom
            setOpenDialog={() =>
              setOpenDialog({ isSelectorOpen: false, isSpace: false })
            }
            parentId={null}
            isSpace={false}
          />
        </Popover>
      )}
      {openSpaceDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenSpaceDialog}
          headerText="Create Space"
        >
          <CreateSpace setOpenSpaceDialog={setOpenSpaceDialog} />
        </Popover>
      )}
    </div>
  );
}

RoomsCategory.propTypes = {
  name: PropTypes.string.isRequired,
  roomIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RoomsCategory;
