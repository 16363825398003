import React, { useState, useEffect } from "react";
import DisconnectButton from "./DisconnectButton";
import "./WalletConnected.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import useMatrix from "../../hooks/useMatrix";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { useTheme } from "../../contexts/ThemeContext";
import { ReactComponent as ArrowBottom } from "../../assets/svg/arrow-bottom.svg";
import { ReactComponent as Tick } from "../../assets/svg/tick.svg";
import { ReactComponent as Mask } from "../../assets/svg/mask.svg";
import { ReactComponent as Sun } from "../../assets/svg/fillsun.svg";
import { ReactComponent as Moon } from "../../assets/svg/fillmoon.svg";
import { ReactComponent as Feedback } from "../../assets/svg/like-shapes.svg";
import { ReactComponent as Privacy } from "../../assets/svg/privacy.svg";
import { ReactComponent as Referral } from "../../assets/svg/fillshare.svg";
import { ReactComponent as Terms } from "../../assets/svg/terms.svg";
import packageJson from "../../../../package.json";
import Popover from "../../atoms/popover/Popover";
import ProfileEditor from "../../organisms/profile-editor/ProfileEditor";
import PrivacyPolicy from "../../pages/privacy-policy/PrivacyPolicy";
import TermsOfUse from "../../pages/terms-of-use/TermsOfUse";
import SettingButton from "../../atoms/button/SettingsButton";
import useBalance from "../../hooks/useBalance";
import { useAccount } from "wagmi";
import ReferralComponent from "../../organisms/referral/ReferralComponent";
import { fetchReferralCode } from "../../../client/referrals/api";
import { getCommunityMembershipScore } from "../../../util/membershipScore";
import cons from "../../../client/state/cons";
import initMatrix from "../../../client/initMatrix";

const WalletConnected = ({ closeAllDialogs, mx, matrixUser }) => {
  const { user, avatarUrl } = useMatrix();
  const { theme, toggleTheme } = useTheme();
  const [nestedProfileDialog, setNestedProfileDialog] = useState(false);
  const [nestedAppearanceDialog, setNestedAppearanceDialog] = useState(false);
  const [nestedPrivacyDialog, setNestedPrivacyDialog] = useState(false);
  const [nestedTermsDialog, setNestedTermsDialog] = useState(false);
  const [nestedFeedbackDialog, setNestedFeedbackDialog] = useState(false);
  const [nestedReferralDialog, setNestedReferralDialog] = useState(false);
  const { address } = useAccount();
  const storedWalletAddress = localStorage.getItem("waivlength_wallet_address");
  const walletAddress = address || storedWalletAddress;
  const { balance } = useBalance(walletAddress, "sepolia");
  const closeWalletConnected = () => {
    setNestedProfileDialog(false);
    closeAllDialogs();
  };
  const [referralCode, setReferralCode] = useState(null);
  const [error, setError] = useState(null);
  const { actionScore } = getCommunityMembershipScore(mx, null, "referral");

  useEffect(() => {
    const getReferralCode = async () => {
      const accessToken = localStorage.getItem(cons.secretKey.ACCESS_TOKEN);
      if (!accessToken) {
        setError("Unauthorized: No access token found.");
        return;
      }

      try {
        const code = await fetchReferralCode(accessToken);
        setReferralCode(code);
      } catch (err) {
        setError("Failed to fetch referral code.");
      }
    };

    getReferralCode();
  }, [nestedReferralDialog]);

  if (!user) {
    return null;
  }

  const ThemeComponent = ({ toggleTheme }) => (
    <div className="appearance-view-wrapper">
      <div className="appearance-settings-container" onClick={toggleTheme}>
        <div className="wallet-connected-icon">
          <Sun className="wallet-connected-svglogo-fill" />
        </div>
        <div className="appearance-settings-text-container">
          <span className="wallet-connected-settings-primary-text">
            Light mode
          </span>
          <span className="wallet-connected-settings-secondary-text ">
            Always use light mode
          </span>
        </div>
        {theme === "light-theme" && (
          <Tick className="theme-tick-svglogo selected" />
        )}
      </div>
      <div className="appearance-settings-container" onClick={toggleTheme}>
        <div className="wallet-connected-icon">
          <Moon className="wallet-connected-svglogo-fill" />
        </div>
        <div className="appearance-settings-text-container">
          <span className="wallet-connected-settings-primary-text">
            Dark mode
          </span>
          <span className="wallet-connected-settings-secondary-text ">
            Always use dark mode
          </span>
        </div>
        {theme === "dark-theme" && (
          <Tick className="theme-tick-svglogo selected" />
        )}
      </div>
    </div>
  );

  return (
    <div className="wallet-connected-wrapper">
      <span className="wallet-connected-secondary-text">Profile Settings</span>
      <div
        className="wallet-connected-profile-settings"
        onClick={() => setNestedProfileDialog(true)}
      >
        <div>
          <UserAvatar size={48} userId={user.userId} imageSrc={avatarUrl} />
        </div>
        <div className="wallet-connected-profile-names">
          <span className="wallet-connected-primary-text">
            {formatDisplayName(user.displayName)}
          </span>
          {balance !== null ? (
            <span className="wallet-connected-tertiary-text">
              {parseFloat(balance).toFixed(4)} ETH
            </span>
          ) : (
            <div className="wallet-balance-loader"></div>
          )}
        </div>
        <ArrowBottom className="wallet-connected-svglogo" />
      </div>
      {nestedProfileDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedProfileDialog}
          headerText="Profile Settings"
        >
          <ProfileEditor
            userId={initMatrix.matrixClient.getUserId()}
            closeAllDialogs={closeWalletConnected}
          />
        </Popover>
      )}
      <span className="wallet-connected-secondary-text">Preferences</span>
      <SettingButton
        primaryText={"Appearance"}
        secondaryText={theme === "light-theme" ? "Light mode" : "Dark mode"}
        Icon={() => <Mask className="wallet-connected-svglogo-fill" />}
        onClick={() => setNestedAppearanceDialog(true)}
      />
      {nestedAppearanceDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedAppearanceDialog}
          headerText="Appearance"
        >
          <ThemeComponent toggleTheme={toggleTheme} />
        </Popover>
      )}
      <span className="wallet-connected-secondary-text">Support</span>
      <SettingButton
        primaryText={"Share Feedback"}
        Icon={() => <Feedback className="wallet-connected-svglogo-fill" />}
        onClick={() => setNestedFeedbackDialog(true)}
      />
      {nestedFeedbackDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedFeedbackDialog}
          headerText="Feedback"
        >
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdEa6C6t303wyMptuA_W0OVWjbz7PEf6mOz5Sv1vDalWIBOEQ/viewform?embedded=true"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </Popover>
      )}
      <SettingButton
        primaryText={"Refer a friend"}
        Icon={() => <Referral className="wallet-connected-svglogo-fill" />}
        onClick={() => setNestedReferralDialog(true)}
      />
      {nestedReferralDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedReferralDialog}
          headerText="Refer a friend"
        >
          <ReferralComponent
            referralCode={referralCode}
            error={error}
            actionScore={actionScore}
            matrixUser={matrixUser}
            mx={mx}
          />
        </Popover>
      )}
      <span className="wallet-connected-secondary-text">About</span>
      <SettingButton
        primaryText={"Privacy Policy"}
        Icon={() => <Privacy className="wallet-connected-svglogo-fill" />}
        onClick={() => setNestedPrivacyDialog(true)}
      />
      {nestedPrivacyDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedPrivacyDialog}
          headerText="Privacy Policy"
        >
          <PrivacyPolicy isPopover={true} />
        </Popover>
      )}
      <SettingButton
        primaryText={"Terms of service"}
        Icon={() => <Terms className="wallet-connected-svglogo-fill" />}
        onClick={() => setNestedTermsDialog(true)}
      />
      {nestedTermsDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedTermsDialog}
          headerText="Terms of Service"
        >
          <TermsOfUse isTermsPopover={true} />
        </Popover>
      )}
      <span className="wallet-connected-secondary-text">
        Version {packageJson.version}
      </span>
      <div className="wallet-connected-disconnect-container">
        <DisconnectButton />
      </div>
    </div>
  );
};

export default WalletConnected;
