import React from "react";
import "./EventItem.scss";
import TimelineChange from "../../molecules/message/TimelineChange";
import { Message } from "../../molecules/message/Message";
import { diffMinutes } from "../../../util/common";
import { parseTimelineChange } from "./common";
import RoomIntroContainer from "./RoomIntroContainer";

const EventItem = ({
  event,
  id,
  prevEvent,
  room,
  roomId,
  isKeyboardVisible,
  isCurrentMessenger,
  reactions,
  onReactionSelect,
  mx,
}) => {
  const eventType = event.getType();
  const timestamp = event.getTs();
  const MAX_MSG_DIFF_MINUTES = 60;

  const isBodyOnly =
    prevEvent &&
    prevEvent.getSender &&
    prevEvent.getType &&
    prevEvent.getSender() === event.getSender() &&
    prevEvent.getType() === "m.room.message" &&
    event.getType() === "m.room.message" &&
    diffMinutes(event.getDate(), prevEvent.getDate()) <= MAX_MSG_DIFF_MINUTES;

  const renderEventTypeSpecificContent = () => {
    if (eventType === "m.room.create") {
      return (
        <RoomIntroContainer
          key={event.getId()}
          roomId={roomId}
          event={event}
          room={room}
        />
      );
    }

    if (eventType === "m.room.member") {
      const timelineChange = parseTimelineChange(event);
      if (timelineChange === null) return <div key={event.getId()} />;
      return (
        <TimelineChange
          key={event.getId()}
          variant={timelineChange.variant}
          content={timelineChange.content}
          timestamp={timestamp}
        />
      );
    }

    if (eventType === "m.room.message") {
      return (
        <Message
          key={event.getId()}
          id={event.getId()}
          mEvent={event}
          isBodyOnly={isBodyOnly}
          fullTime={false}
          isKeyboardVisible={isKeyboardVisible}
          isCurrentMessenger={isCurrentMessenger}
          room={room}
          reactions={reactions}
          onReactionSelect={onReactionSelect}
          mx={mx}
        />
      );
    }

    return null;
  };

  return (
    <div className="event-item" id={id}>
      {renderEventTypeSpecificContent()}
    </div>
  );
};

export default EventItem;
