import CryptoJS from "crypto-js";

export function encrypt(text, password) {
  const iv = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(password, "salt", { keySize: 256 / 32 });
  const cipher = CryptoJS.AES.encrypt(text, key, { iv: iv });
  return `${iv.toString(CryptoJS.enc.Hex)}:${cipher.toString()}`;
}

export function decrypt(encryptedText, password) {
  const [iv, content] = encryptedText.split(":");
  const key = CryptoJS.PBKDF2(password, "salt", { keySize: 256 / 32 });
  const decipher = CryptoJS.AES.decrypt(content, key, {
    iv: CryptoJS.enc.Hex.parse(iv),
  });
  const decryptedText = decipher.toString(CryptoJS.enc.Utf8);

  if (!decryptedText) {
    throw new Error("Decryption failed. Incorrect password.");
  }

  return decryptedText;
}
