import { ethers } from "ethers";
import VestingABI from "./abi/VestingABI.json";

export const claimVesting = async ({
  signer,
  setErrorMessage,
  tokenData,
  setOpenDialog,
  vestingAddress,
}) => {
  setErrorMessage("");

  try {
    if (!Array.isArray(vestingAddress)) {
      vestingAddress = [vestingAddress];
    }

    for (const address of vestingAddress) {
      const vestingContract = new ethers.Contract(address, VestingABI, signer);
      const currentTime = Math.floor(Date.now() / 1000);
      const startTime = await vestingContract.startTime();
      const duration = await vestingContract.duration();
      if (currentTime <= Number(startTime) + Number(duration)) {
        continue;
      }
      const releasableAmount = await vestingContract.releasableAmount(
        tokenData.tokenContractAddress
      );
      if (releasableAmount === 0n) {
        continue;
      }
      const tx = await vestingContract.release(tokenData.tokenContractAddress);
      await tx.wait();
    }

    setOpenDialog(false);
  } catch (error) {
    let errorMsg;
    if (error.code === "ACTION_REJECTED") {
      errorMsg = "Transaction was cancelled by the user.";
    } else if (error.info && error.info.error) {
      errorMsg = error.info.error.message;
    } else {
      errorMsg = "Error during vesting claim.";
    }
    setErrorMessage(errorMsg);
  }
};
