import EventEmitter from "events";

class RoomState extends EventEmitter {
  constructor(matrixClient, room) {
    super();
    this.matrixClient = matrixClient;
    this.room = room;
    this.roomId = room.roomId;
    this.timeline = null;
    this.canPaginate = true;
    this.INITIAL_LIMIT = 50;
    this.events = [];
    this.reactions = {};
    this.userReactions = {};
    this.liveEventListener = null;
    this.processedEventIds = new Set();
    this.latestInitialTs = 0;
    this.listenForLiveEvents();
  }

  async initialize() {
    const { timeline, events } = await this.fetchInitialTimelineFromRoom(
      this.room
    );
    this.timeline = timeline;
    const initialTimelineEvents = this.sortEvents(events);
    if (initialTimelineEvents.length > 0) {
      this.latestInitialTs = Math.max(
        ...initialTimelineEvents.map((e) => e.getTs())
      );
    }
    this.events = initialTimelineEvents;
    this.processInitialReactions(initialTimelineEvents);
    for (const event of initialTimelineEvents) {
      await this.fetchReactionsForEvent(event.getId());
    }
    return initialTimelineEvents;
  }

  async fetchInitialTimelineFromRoom(room) {
    if (!room) {
      throw new Error("Room not found during initialization");
    }

    const timeline = room.getLiveTimeline();
    let allEvents = [...timeline.getEvents()].filter(
      (event) => !this.processedEventIds.has(event.getId())
    );

    this.canPaginate = room.oldState.paginationToken !== null;

    const addUniqueEvents = (newEvents, existingEvents) => {
      const existingEventIds = new Set(existingEvents.map((e) => e.getId()));
      const uniqueEvents = newEvents.filter(
        (event) =>
          !existingEventIds.has(event.getId()) &&
          !this.processedEventIds.has(event.getId())
      );
      uniqueEvents.forEach((event) =>
        this.processedEventIds.add(event.getId())
      );
      return [...uniqueEvents, ...existingEvents];
    };

    while (this.canPaginate && allEvents.length < this.INITIAL_LIMIT) {
      await this.matrixClient.scrollback(room, 100);
      const newEvents = timeline
        .getEvents()
        .filter((event) => !this.processedEventIds.has(event.getId()));
      allEvents = addUniqueEvents(newEvents, allEvents);
      allEvents.sort((a, b) => a.getTs() - b.getTs());

      const createEventIndex = allEvents.findIndex(
        (event) => event.getType() === "m.room.create"
      );
      if (createEventIndex !== -1) {
        allEvents = allEvents.slice(createEventIndex);
        break;
      }

      this.canPaginate = room.oldState.paginationToken !== null;
    }

    return {
      timeline,
      events: allEvents,
    };
  }

  processInitialReactions(events) {
    events.forEach((event) => {
      if (event.getType() === "m.reaction") {
        this.addReaction(event);
      }
    });
  }

  async fetchReactionsForEvent(eventId) {
    try {
      const relations = await this.matrixClient.getRelations(
        this.roomId,
        eventId,
        "m.reaction",
        ""
      );
      for (const event of relations.events) {
        this.addReaction(event);
      }
    } catch (error) {}
  }

  async fetchOldEvents() {
    const room = this.matrixClient.getRoom(this.roomId);
    if (!room || !this.timeline || !this.canPaginate) {
      return [];
    }

    try {
      const previousEventIds = new Set(this.events.map((e) => e.getId()));
      await this.matrixClient.paginateEventTimeline(this.timeline, {
        backwards: true,
        limit: 200,
      });

      const allEvents = this.timeline.getEvents();
      const oldEvents = allEvents.filter(
        (event) =>
          !previousEventIds.has(event.getId()) &&
          !this.processedEventIds.has(event.getId())
      );

      oldEvents.forEach((event) => this.processedEventIds.add(event.getId()));
      const sortedOldEvents = this.sortEvents(oldEvents);
      this.events = [...this.events, ...sortedOldEvents];
      this.processInitialReactions(sortedOldEvents);

      for (const event of sortedOldEvents) {
        await this.fetchReactionsForEvent(event.getId());
      }

      const createEventIndex = this.events.findIndex(
        (event) => event.getType() === "m.room.create"
      );
      if (createEventIndex !== -1) {
        this.canPaginate = false;
      } else {
        this.canPaginate = room.oldState.paginationToken !== null;
      }

      return sortedOldEvents;
    } catch (error) {
      return [];
    }
  }

  sortEvents(events) {
    return Array.from(events).sort((a, b) => b.getTs() - a.getTs());
  }

  listenForLiveEvents() {
    if (this.liveEventListener) return;

    this.liveEventListener = async (event, room, toStartOfTimeline) => {
      if (room.roomId === this.roomId && !toStartOfTimeline) {
        if (!this.processedEventIds.has(event.getId())) {
          this.processedEventIds.add(event.getId());
          this.events = [event, ...this.events];
          const reactionTypes = ["m.reaction", "m.room.redaction"];
          const isReaction = reactionTypes.includes(event.getType());
          this.processEvents([event]);
          this.emit("newEvent", event, isReaction);
        }
      }
    };

    this.matrixClient.on("Room.timeline", this.liveEventListener);
  }

  processEvents(events) {
    events.forEach((event) => {
      const eventType = event.getType();
      if (eventType === "m.reaction") {
        const isRedaction = event.isRedaction();
        if (isRedaction) {
          this.removeReactionFromState(event);
        } else {
          this.addReaction(event);
        }
      }
    });
  }

  addReaction(event) {
    const content = event.getContent();
    const relatesTo = content["m.relates_to"];
    if (
      relatesTo &&
      relatesTo.rel_type === "m.annotation" &&
      relatesTo.event_id
    ) {
      const targetEventId = relatesTo.event_id;
      const reactionKey = relatesTo.key;
      const senderId = event.getSender();

      if (!this.reactions[targetEventId]) {
        this.reactions[targetEventId] = {};
      }

      if (!this.reactions[targetEventId][reactionKey]) {
        this.reactions[targetEventId][reactionKey] = new Set();
      }

      this.reactions[targetEventId][reactionKey].add(senderId);

      if (!this.userReactions[targetEventId]) {
        this.userReactions[targetEventId] = {};
      }

      if (this.userReactions[targetEventId][senderId]) {
        const previousReaction = this.userReactions[targetEventId][senderId];
        this.reactions[targetEventId][previousReaction.reactionKey].delete(
          senderId
        );

        if (
          this.reactions[targetEventId][previousReaction.reactionKey].size === 0
        ) {
          delete this.reactions[targetEventId][previousReaction.reactionKey];
        }
      }

      this.userReactions[targetEventId][senderId] = {
        reactionKey,
        reactionEventId: event.getId(),
      };
    }
  }

  async removeReaction(targetEvent, reactionKey) {
    const targetEventId = targetEvent.getId();
    const userId = this.matrixClient.getUserId();

    const userReaction = this.userReactions[targetEventId]?.[userId];

    if (userReaction && userReaction.reactionKey === reactionKey) {
      try {
        await this.matrixClient.redactEvent(
          this.roomId,
          userReaction.reactionEventId,
          null,
          {
            reason: "Removing reaction",
          }
        );

        this.removeReactionFromState(targetEventId, reactionKey, userId);
      } catch (error) {
        this.removeReactionFromState(targetEventId, reactionKey, userId);
      }
    }
  }

  removeReactionFromState(targetEventId, reactionKey, userId) {
    if (this.reactions[targetEventId]?.[reactionKey]?.has(userId)) {
      this.reactions[targetEventId][reactionKey].delete(userId);

      if (this.reactions[targetEventId][reactionKey].size === 0) {
        delete this.reactions[targetEventId][reactionKey];
      }

      if (Object.keys(this.reactions[targetEventId]).length === 0) {
        delete this.reactions[targetEventId];
      }
    }

    if (this.userReactions[targetEventId]?.[userId]) {
      delete this.userReactions[targetEventId][userId];

      if (Object.keys(this.userReactions[targetEventId]).length === 0) {
        delete this.userReactions[targetEventId];
      }
    }
  }

  getUserReaction(targetEventId, userId) {
    const userReaction = this.userReactions[targetEventId]?.[userId];
    if (userReaction) {
      return {
        key: userReaction.reactionKey,
        eventId: userReaction.reactionEventId,
      };
    }
    return null;
  }

  getReactions(eventId) {
    const reactions = this.reactions[eventId];
    if (!reactions) return [];

    const reactionList = [];
    for (const [key, userSet] of Object.entries(reactions)) {
      reactionList.push({
        key,
        count: userSet.size,
        users: Array.from(userSet),
      });
    }

    return reactionList;
  }

  getEvents() {
    return this.events;
  }

  async sendReaction(event, reactionKey) {
    const targetEventId = event.getId();
    const content = {
      "m.relates_to": {
        rel_type: "m.annotation",
        event_id: targetEventId,
        key: reactionKey,
      },
    };

    try {
      await this.matrixClient.sendEvent(this.roomId, "m.reaction", content);
    } catch (error) {}
  }
}

export default RoomState;
