import React, { createContext, useContext, useEffect, useState } from "react";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("dark-theme");

  const updateThemeColor = (color) => {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", color);
  };

  const setStatusBarStyle = async (theme) => {
    if (Capacitor.getPlatform() === "web") {
      return;
    }
    if (theme === "dark-theme") {
      await StatusBar.setStyle({ style: Style.Dark });
    } else {
      await StatusBar.setStyle({ style: Style.Light });
    }
  };

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    if (localTheme) {
      setTheme(localTheme);
      document.documentElement.classList.add(localTheme);
      updateThemeColor(localTheme === "dark-theme" ? "#1b1b1b" : "#f9f9f9");
      setStatusBarStyle(localTheme);
    } else {
      document.documentElement.classList.add("dark-theme");
      localStorage.setItem("theme", "dark-theme");
      updateThemeColor("#1b1b1b");
      setStatusBarStyle("dark-theme");
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "dark-theme" ? "light-theme" : "dark-theme";
    document.documentElement.classList.replace(theme, newTheme);
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    updateThemeColor(newTheme === "dark-theme" ? "#1b1b1b" : "#f9f9f9");
    setStatusBarStyle(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
