import initMatrix from "../initMatrix";
import appDispatcher from "../dispatcher";
import cons from "../state/cons";
import { getIdServer } from "../../util/matrixUtil";

/**
 * https://github.com/matrix-org/matrix-react-sdk/blob/1e6c6e9d800890c732d60429449bc280de01a647/src/Rooms.js#L73
 * @param {string} roomId Id of room to add
 * @param {string} userId User id to which dm || undefined to remove
 * @returns {Promise} A promise
 */
function addRoomToMDirect(roomId, userId) {
  const mx = initMatrix.matrixClient;
  const mDirectsEvent = mx.getAccountData("m.direct");
  let userIdToRoomIds = {};

  if (typeof mDirectsEvent !== "undefined")
    userIdToRoomIds = mDirectsEvent.getContent();

  // remove it from the lists of any others users
  // (it can only be a DM room for one person)
  Object.keys(userIdToRoomIds).forEach((thisUserId) => {
    const roomIds = userIdToRoomIds[thisUserId];

    if (thisUserId !== userId) {
      const indexOfRoomId = roomIds.indexOf(roomId);
      if (indexOfRoomId > -1) {
        roomIds.splice(indexOfRoomId, 1);
      }
    }
  });

  // now add it, if it's not already there
  if (userId) {
    const roomIds = userIdToRoomIds[userId] || [];
    if (roomIds.indexOf(roomId) === -1) {
      roomIds.push(roomId);
    }
    userIdToRoomIds[userId] = roomIds;
  }

  return mx.setAccountData("m.direct", userIdToRoomIds);
}

/**
 * Given a room, estimate which of its members is likely to
 * be the target if the room were a DM room and return that user.
 * https://github.com/matrix-org/matrix-react-sdk/blob/1e6c6e9d800890c732d60429449bc280de01a647/src/Rooms.js#L117
 *
 * @param {Object} room Target room
 * @param {string} myUserId User ID of the current user
 * @returns {string} User ID of the user that the room is probably a DM with
 */
function guessDMRoomTargetId(room, myUserId) {
  let oldestMemberTs;
  let oldestMember;

  // Pick the joined user who's been here longest (and isn't us),
  room.getJoinedMembers().forEach((member) => {
    if (member.userId === myUserId) return;

    if (
      typeof oldestMemberTs === "undefined" ||
      (member.events.member && member.events.member.getTs() < oldestMemberTs)
    ) {
      oldestMember = member;
      oldestMemberTs = member.events.member.getTs();
    }
  });
  if (oldestMember) return oldestMember.userId;

  // if there are no joined members other than us, use the oldest member
  room.currentState.getMembers().forEach((member) => {
    if (member.userId === myUserId) return;

    if (
      typeof oldestMemberTs === "undefined" ||
      (member.events.member && member.events.member.getTs() < oldestMemberTs)
    ) {
      oldestMember = member;
      oldestMemberTs = member.events.member.getTs();
    }
  });

  if (typeof oldestMember === "undefined") return myUserId;
  return oldestMember.userId;
}

function convertToDm(roomId) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  return addRoomToMDirect(roomId, guessDMRoomTargetId(room, mx.getUserId()));
}

function convertToRoom(roomId) {
  return addRoomToMDirect(roomId, undefined);
}

/**
 * Attempts to join a room or a direct message session.
 * @param {string} roomIdOrAlias - The room ID or alias to join.
 * @param {boolean} [isDM=false] - Indicates if the join is for a direct message session.
 * @returns {Promise<{roomId: string}>} An object containing the room ID of the joined room.
 */
async function join(roomIdOrAlias, isDM = false) {
  const mx = initMatrix.matrixClient;

  try {
    const resultRoom = await mx.joinRoom(roomIdOrAlias);

    if (isDM) {
      const targetUserId = guessDMRoomTargetId(
        mx.getRoom(resultRoom.roomId),
        mx.getUserId()
      );
      await addRoomToMDirect(resultRoom.roomId, targetUserId);
    }

    return { roomId: resultRoom.roomId };
  } catch (e) {
    console.error(`Error joining room ${roomIdOrAlias}: ${e.message}`);
    throw e;
  }
}

/**
 *
 * @param {string} roomId
 * @param {boolean} isDM
 */
async function leave(roomId) {
  const mx = initMatrix.matrixClient;
  const isDM = initMatrix.roomList.directs.has(roomId);

  try {
    const room = mx.getRoom(roomId);
    if (!room) {
      console.error(`Room ${roomId} not found.`);
      return;
    }
    const isSpace = room.getType() === "m.space";
    if (isSpace) {
      // Fetch all rooms associated with this space
      const associatedRoomIds = [];
      room.currentState
        .getStateEvents("m.space.child")
        .forEach((childEvent) => {
          const childRoomId = childEvent.getStateKey();
          associatedRoomIds.push(childRoomId);
        });

      // Leave associated rooms
      await Promise.all(
        associatedRoomIds.map(async (childRoomId) => {
          try {
            await mx.leave(childRoomId);
          } catch (error) {}
        })
      );
    }

    await mx.leave(roomId);
    appDispatcher.dispatch({
      type: cons.actions.room.LEAVE,
      roomId,
      isDM,
    });
  } catch (error) {
    console.error(`Unable to leave room ${roomId}: ${error}`);
  }
}

async function create(options, isDM = false) {
  const mx = initMatrix.matrixClient;

  try {
    const result = await mx.createRoom(options);
    if (isDM && typeof options.invite?.[0] === "string") {
      await addRoomToMDirect(result.room_id, options.invite[0]);
    }
    return result;
  } catch (e) {
    const errcodes = [
      "M_UNKNOWN",
      "M_BAD_JSON",
      "M_ROOM_IN_USE",
      "M_INVALID_ROOM_STATE",
      "M_UNSUPPORTED_ROOM_VERSION",
    ];
    if (errcodes.includes(e.errcode)) {
      throw new Error(e);
    }
    throw new Error("Something went wrong!");
  }
}

async function createDM(userIdOrIds, isEncrypted = false) {
  const options = {
    is_direct: true,
    invite: Array.isArray(userIdOrIds) ? userIdOrIds : [userIdOrIds],
    visibility: "private",
    preset: "trusted_private_chat",
    initial_state: [],
  };
  if (isEncrypted) {
    options.initial_state.push({
      type: "m.room.encryption",
      state_key: "",
      content: {
        algorithm: "m.megolm.v1.aes-sha2",
      },
    });
  }

  const result = await create(options, true);
  return result;
}

async function createRoom(opts) {
  const {
    name,
    topic,
    joinRule,
    alias,
    parentId,
    isSpace,
    isEncrypted,
    powerLevel,
    spaceSocialTwitter,
    spaceSocialGithub,
    spaceSocialWebsite,
    tokenName,
    tokenSymbol,
    tokenTotalSupply,
    tokenContractAddress,
    tokenNewTokenizedSpace,
    tokenFundingType,
    tokenTokenomics,
    presaleTargetRaiseAmount,
    presaleFundraiserMinContribution,
    presaleFundraiserMaxContribution,
    presaleFundraiserDescription,
    presaleFundraiserStartTimestamp,
    presaleFundraiserEndTimestamp,
    tokenFundraiserCompletionStatus,
    etherscanLink,
    presaleEtherscanLink,
    presaleContractAddress,
  } = opts;

  const mx = initMatrix.matrixClient;
  const visibility = joinRule === "public" ? "public" : "private";

  const options = {
    creation_content: {},
    name,
    topic,
    visibility,
    room_alias_name: alias,
    initial_state: [],
    power_level_content_override: {
      events: {},
      state_default: 0,
    },
  };

  if (isSpace) {
    options.creation_content.type = "m.space";
  }

  if (isEncrypted) {
    options.initial_state.push({
      type: "m.room.encryption",
      state_key: "",
      content: { algorithm: "m.megolm.v1.aes-sha2" },
    });
  }

  if (powerLevel) {
    options.power_level_content_override.users = {
      [mx.getUserId()]: powerLevel,
    };
  }

  options.power_level_content_override.events["m.proposal.start"] = 75;

  if (parentId) {
    options.initial_state.push({
      type: "m.space.parent",
      state_key: parentId,
      content: { canonical: true, via: [getIdServer(mx.getUserId())] },
    });
  }

  const socialLinksContent = {};
  if (spaceSocialTwitter) socialLinksContent.twitter = spaceSocialTwitter;
  if (spaceSocialGithub) socialLinksContent.github = spaceSocialGithub;
  if (spaceSocialWebsite) socialLinksContent.website = spaceSocialWebsite;

  if (Object.keys(socialLinksContent).length > 0) {
    options.initial_state.push({
      type: "custom.social_links",
      content: socialLinksContent,
    });
  }

  const tokenDataContent = {};
  if (tokenName) tokenDataContent.tokenName = tokenName;
  if (tokenSymbol) tokenDataContent.tokenSymbol = tokenSymbol;
  if (tokenTotalSupply) tokenDataContent.tokenTotalSupply = tokenTotalSupply;
  if (tokenContractAddress)
    tokenDataContent.tokenContractAddress = tokenContractAddress;
  if (tokenNewTokenizedSpace)
    tokenDataContent.tokenNewTokenizedSpace = tokenNewTokenizedSpace;
  if (tokenFundingType) tokenDataContent.tokenFundingType = tokenFundingType;
  if (tokenTokenomics) tokenDataContent.tokenTokenomics = tokenTokenomics;
  if (presaleTargetRaiseAmount)
    tokenDataContent.presaleTargetRaiseAmount = presaleTargetRaiseAmount;
  if (presaleFundraiserMinContribution)
    tokenDataContent.presaleFundraiserMinContribution =
      presaleFundraiserMinContribution;
  if (presaleFundraiserMaxContribution)
    tokenDataContent.presaleFundraiserMaxContribution =
      presaleFundraiserMaxContribution;
  if (presaleFundraiserDescription)
    tokenDataContent.presaleFundraiserDescription =
      presaleFundraiserDescription;
  if (presaleFundraiserStartTimestamp)
    tokenDataContent.presaleFundraiserStartTimestamp =
      presaleFundraiserStartTimestamp;
  if (presaleFundraiserEndTimestamp)
    tokenDataContent.presaleFundraiserEndTimestamp =
      presaleFundraiserEndTimestamp;
  if (tokenFundraiserCompletionStatus)
    tokenDataContent.tokenFundraiserCompletionStatus =
      tokenFundraiserCompletionStatus;
  if (etherscanLink) tokenDataContent.etherscanLink = etherscanLink;
  if (presaleEtherscanLink)
    tokenDataContent.presaleEtherscanLink = presaleEtherscanLink;
  if (presaleContractAddress)
    tokenDataContent.presaleContractAddress = presaleContractAddress;

  if (Object.keys(tokenDataContent).length > 0) {
    options.initial_state.push({
      type: "custom.token_data",
      content: tokenDataContent,
    });
  }

  const result = await create(options, false, isSpace);

  if (parentId) {
    const content = {
      name: name,
      join_rule: joinRule,
      fundraising_room: tokenFundingType || "null",
      ...tokenDataContent,
    };

    await mx.sendStateEvent(parentId, "m.space.child", content, result.room_id);
  }

  await new Promise((resolve) => setTimeout(resolve, 1000));

  return result;
}

async function createGovernanceRoom(opts) {
  const { proposalTitle, joinRule, alias, parentId, governanceData } = opts;

  const mx = initMatrix.matrixClient;
  const visibility = joinRule === "public" ? "public" : "private";

  const options = {
    name: proposalTitle,
    visibility,
    room_alias_name: alias,
    initial_state: [],
    guest_access: "can_join",
    power_level_content_override: {
      events: {},
      state_default: 0,
    },
  };

  if (parentId) {
    options.initial_state.push({
      type: "m.space.parent",
      state_key: parentId,
      content: { canonical: true, via: [getIdServer(mx.getUserId())] },
    });
  }

  const governanceDataContent = {};
  if (governanceData.proposalTitle)
    governanceDataContent.proposalTitle = governanceData.proposalTitle;
  if (governanceData.proposalDescription)
    governanceDataContent.proposalDescription =
      governanceData.proposalDescription;
  if (governanceData.proposalDiscussionLink)
    governanceDataContent.proposalDiscussionLink =
      governanceData.proposalDiscussionLink;
  if (governanceData.selectedOption)
    governanceDataContent.selectedOption = governanceData.selectedOption;
  if (governanceData.selectedStrategyOption)
    governanceDataContent.selectedStrategyOption =
      governanceData.selectedStrategyOption;
  if (governanceData.options)
    governanceDataContent.options = governanceData.options;
  if (governanceData.startTime)
    governanceDataContent.startTime = governanceData.startTime;
  if (governanceData.endTime)
    governanceDataContent.endTime = governanceData.endTime;
  if (governanceData.userId)
    governanceDataContent.proposalCreator = governanceData.userId;
  if (governanceData.blockNumber)
    governanceDataContent.proposalBlockNumber = governanceData.blockNumber;

  if (Object.keys(governanceDataContent).length > 0) {
    options.initial_state.push({
      type: "custom.governance_data",
      content: governanceDataContent,
    });
  }

  const result = await create(options, false, false);

  if (parentId) {
    const content = {
      name: proposalTitle,
      join_rule: joinRule,
      ...governanceDataContent,
    };

    await mx.sendStateEvent(parentId, "m.space.child", content, result.room_id);
  }

  await new Promise((resolve) => setTimeout(resolve, 1000));

  return result;
}

async function invite(roomId, userId, reason) {
  const mx = initMatrix.matrixClient;

  const result = await mx.invite(roomId, userId, undefined, reason);
  return result;
}

async function kick(roomId, userId, reason) {
  const mx = initMatrix.matrixClient;

  const result = await mx.kick(roomId, userId, reason);
  return result;
}

async function ban(roomId, userId, reason) {
  const mx = initMatrix.matrixClient;

  const result = await mx.ban(roomId, userId, reason);
  return result;
}

async function unban(roomId, userId) {
  const mx = initMatrix.matrixClient;

  const result = await mx.unban(roomId, userId);
  return result;
}

async function ignore(userIds) {
  const mx = initMatrix.matrixClient;

  let ignoredUsers = mx.getIgnoredUsers().concat(userIds);
  ignoredUsers = [...new Set(ignoredUsers)];
  await mx.setIgnoredUsers(ignoredUsers);
}

async function unignore(userIds) {
  const mx = initMatrix.matrixClient;

  const ignoredUsers = mx.getIgnoredUsers();
  await mx.setIgnoredUsers(ignoredUsers.filter((id) => !userIds.includes(id)));
}

async function setPowerLevel(roomId, userId, powerLevel) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);

  const powerlevelEvent = room.currentState.getStateEvents(
    "m.room.power_levels"
  )[0];

  const result = await mx.setPowerLevel(
    roomId,
    userId,
    powerLevel,
    powerlevelEvent
  );
  return result;
}

async function setMyRoomNick(roomId, nick) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const mEvent = room.currentState.getStateEvents(
    "m.room.member",
    mx.getUserId()
  );
  const content = mEvent?.getContent();
  if (!content) return;
  await mx.sendStateEvent(
    roomId,
    "m.room.member",
    {
      ...content,
      displayname: nick,
    },
    mx.getUserId()
  );
}

async function setMyRoomAvatar(roomId, mxc) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const mEvent = room.currentState.getStateEvents(
    "m.room.member",
    mx.getUserId()
  );
  const content = mEvent?.getContent();
  if (!content) return;
  await mx.sendStateEvent(
    roomId,
    "m.room.member",
    {
      ...content,
      avatar_url: mxc,
    },
    mx.getUserId()
  );
}

export {
  convertToDm,
  convertToRoom,
  join,
  leave,
  createDM,
  createRoom,
  invite,
  kick,
  ban,
  unban,
  ignore,
  unignore,
  setPowerLevel,
  setMyRoomNick,
  setMyRoomAvatar,
  createGovernanceRoom,
};
