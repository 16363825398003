import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import navigation from "../../../client/state/navigation";
import { abbreviateNumber } from "../../../util/common";
import { joinRuleToIconSrc } from "../../../util/matrixUtil";
import IconButton from "../../atoms/button/IconButton";
import RoomSelector from "../../molecules/room-selector/RoomSelector";
import RoomOptions from "../../molecules/room-options/RoomOptions";
import VerticalMenuIC from "../../assets/svg/vertical-menu.svg";
import Modal from "../../atoms/modal/Modal";

function Selector({ roomId, isDM, onClick }) {
  const mx = initMatrix.matrixClient;
  const noti = initMatrix.notifications;
  const room = mx.getRoom(roomId);
  let imageSrc =
    room.getAvatarFallbackMember()?.getAvatarUrl(mx.baseUrl, 28, 28, "crop") ||
    null;
  if (imageSrc === null)
    imageSrc = room.getAvatarUrl(mx.baseUrl, 28, 28, "crop") || null;
  const isMuted = noti.getNotiType(roomId) === cons.notifs.MUTE;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [notificationCount, setNotificationCount] = useState(
    abbreviateNumber(noti.getTotalNoti(roomId))
  );
  const [isAlert, setIsAlert] = useState(noti.getHighlightNoti(roomId) !== 0);

  const handleOpen = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const notiChanged = (id, total, prevTotal) => {
      if (id === roomId && total !== prevTotal) {
        setNotificationCount(abbreviateNumber(noti.getTotalNoti(roomId)));
        setIsAlert(noti.getHighlightNoti(roomId) !== 0);
      }
    };
    noti.on(cons.events.notifications.NOTI_CHANGED, notiChanged);
    noti.on(cons.events.notifications.MUTE_TOGGLED, notiChanged);
    setNotificationCount(abbreviateNumber(noti.getTotalNoti(roomId)));
    setIsAlert(noti.getHighlightNoti(roomId) !== 0);

    return () => {
      noti.off(cons.events.notifications.NOTI_CHANGED, notiChanged);
      noti.off(cons.events.notifications.MUTE_TOGGLED, notiChanged);
    };
  }, [noti, roomId]);

  return (
    <>
      <RoomSelector
        key={roomId}
        name={room.name}
        roomId={roomId}
        imageSrc={isDM ? imageSrc : null}
        iconSrc={
          isDM
            ? null
            : joinRuleToIconSrc(room.getJoinRule(), room.isSpaceRoom())
        }
        isSelected={navigation.selectedRoomId === roomId}
        isUnread={!isMuted && noti.hasNoti(roomId)}
        notificationCount={notificationCount}
        isAlert={isAlert}
        onClick={onClick}
        onContextMenu={handleOpen}
        options={
          <IconButton
            size="extra-small"
            src={VerticalMenuIC}
            onClick={handleOpen}
          />
        }
      />
      <Modal isOpen={isModalOpen} onClose={handleClose}>
        <RoomOptions roomId={roomId} afterOptionSelect={handleClose} />
      </Modal>
    </>
  );
}

Selector.propTypes = {
  roomId: PropTypes.string.isRequired,
  isDM: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Selector;
