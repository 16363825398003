import React, { useEffect } from "react";
import EventItem from "./EventItem";
import Divider from "../../atoms/divider/Divider";
import { isInSameDay } from "../../../util/common";
import dateFormat from "dateformat";
import { useMessagingContext } from "../../contexts/MessagingProviderContext";
import initMatrix from "../../../client/initMatrix";

const TimelineEventWrapper = ({
  event,
  lastReadEventId,
  events,
  eventContext,
  containerRef,
  room,
  roomId,
  loadMoreEvents,
  isKeyboardVisible,
  reactions,
  onReactionSelect,
  mx,
  platform,
}) => {
  const { messageId, actionType, setEventAction } = useMessagingContext();
  const isIOS = platform === "ios";

  // Find the index of the current event
  const eventIndex = events.findIndex((e) => e.getId() === event.getId());

  // Determine previous and next events based on the array order
  const prevEvent =
    eventIndex + 1 < events.length ? events[eventIndex + 1] : null;
  const nextEvent = eventIndex - 1 >= 0 ? events[eventIndex - 1] : null;

  const currentEventDate = event.getDate();
  const prevEventDate = prevEvent?.getDate() ?? null;

  const senderId = event.getSender();
  const isCurrentMessenger = senderId === initMatrix.matrixClient.getUserId();

  const isRedacted = !!event.event.unsigned?.redacted_because;
  const isEditedMessageFallback =
    event.getContent()?.body?.startsWith("*") ?? false;

  if (isRedacted || isEditedMessageFallback) {
    return null;
  }

  const isLastReadEvent = event.getId() === lastReadEventId;

  // Determine if there are unread messages after this event
  const eventsAfterCurrent = events.slice(0, eventIndex);
  const hasUnreadMessagesAfter = eventsAfterCurrent.some(
    (e) => e.getType() === "m.room.message"
  );

  const isDividerVisible = isLastReadEvent && hasUnreadMessagesAfter;

  const handleScrollAndFocus = (eventElement, container) => {
    const containerRect = container.getBoundingClientRect();
    const eventRect = eventElement.getBoundingClientRect();

    const scrollPosition =
      eventRect.top -
      containerRect.top +
      container.scrollTop -
      containerRect.height / 2 +
      eventRect.height / 2;

    if (actionType === "scroll" || actionType === "both") {
      container.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
    setEventAction(null, null);
  };

  const attemptScrollAndFocus = async () => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    let eventElement = document.getElementById(messageId);

    if (!eventElement) {
      await loadMoreEvents();
      eventElement = document.getElementById(messageId);
    }

    if (eventElement) {
      setTimeout(() => {
        handleScrollAndFocus(eventElement, container);
      }, 20);
    }
  };

  useEffect(() => {
    const isLastEvent = event.getId() === events[0]?.getId();
    if (!messageId || !isLastEvent || !containerRef.current) return;
    attemptScrollAndFocus();
  }, [messageId, events, containerRef, loadMoreEvents]);

  const renderEventAndDivider = () => {
    if (isIOS) {
      return (
        <>
          <EventItem
            event={event}
            id={event.getId()}
            eventContext={eventContext}
            prevEvent={prevEvent}
            roomId={roomId}
            room={room}
            isKeyboardVisible={isKeyboardVisible}
            isCurrentMessenger={isCurrentMessenger}
            mx={mx}
            reactions={reactions}
            onReactionSelect={onReactionSelect}
          />
          {isDividerVisible && (
            <Divider
              key={`new-${event.getId()}`}
              variant="positive"
              text="Unread messages"
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {isDividerVisible && (
            <Divider
              key={`new-${event.getId()}`}
              variant="positive"
              text="Unread messages"
            />
          )}
          <EventItem
            event={event}
            id={event.getId()}
            eventContext={eventContext}
            prevEvent={prevEvent}
            roomId={roomId}
            room={room}
            isKeyboardVisible={isKeyboardVisible}
            isCurrentMessenger={isCurrentMessenger}
            mx={mx}
            reactions={reactions}
            onReactionSelect={onReactionSelect}
          />
        </>
      );
    }
  };

  return (
    <>
      {prevEventDate &&
        currentEventDate &&
        !isInSameDay(currentEventDate, prevEventDate) && (
          <Divider
            key={`divider-${event.getId()}`}
            text={`${dateFormat(currentEventDate, "mmmm dd, yyyy")}`}
          />
        )}
      {renderEventAndDivider()}
    </>
  );
};

export default TimelineEventWrapper;
