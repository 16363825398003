import React, { useEffect, useState } from "react";
import "./CastVote.scss";
import { ethers } from "ethers";
import { useEthersContext } from "../../contexts/EthersContext";
import { ReactComponent as Warning } from "../../assets/svg/fillwarning.svg";
import { useLoading } from "../../contexts/LoadingContext";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";
import initMatrix from "../../../client/initMatrix";

const CastVote = ({
  selectedVoteOption,
  userId,
  optionWeights,
  onClose,
  space,
  spaceId,
  roomId,
  setOpenViewDialog,
  data,
  governanceData,
}) => {
  const [votingPower, setVotingPower] = useState(0);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const { fetchSigner } = useEthersContext();
  const mx = initMatrix.matrixClient;
  const [errorMessage, setErrorMessage] = useState("");
  const { showLoading, hideLoading } = useLoading();

  const getRoomStateEvent = (eventType, stateKey = "") =>
    space.currentState.getStateEvents(eventType, stateKey);

  useEffect(() => {
    const getBalance = async () => {
      try {
        const { signer } = await fetchSigner();
        let balance;

        if (governanceData.selectedStrategyOption === "ERC20 Balance") {
          const tokenContract = new ethers.Contract(
            data.tokenContractAddress,
            ["function balanceOf(address) view returns (uint256)"],
            signer
          );

          balance = await tokenContract.balanceOf(signer.getAddress(), {
            blockTag: governanceData.proposalBlockNumber,
          });
          setVotingPower(ethers.formatUnits(balance, 18));
        } else if (governanceData.selectedStrategyOption === "ETH Balance") {
          const address = await signer.getAddress();
          balance = await signer.provider.getBalance(
            address,
            governanceData.proposalBlockNumber
          );
          setVotingPower(ethers.formatEther(balance));
        }

        if (balance && balance > 0) {
          setIsConfirmEnabled(true);
          setErrorMessage("");
        } else {
          setIsConfirmEnabled(false);
          setErrorMessage(
            `Unfortunately, you don't have any voting power at block number ${governanceData.proposalBlockNumber} and cannot participate in this proposal.`
          );
        }
      } catch (error) {
        setIsConfirmEnabled(false);
      } finally {
        setLoading(false);
      }
    };

    getBalance();
  }, [
    fetchSigner,
    governanceData.proposalBlockNumber,
    governanceData.selectedStrategyOption,
    space,
    errorMessage,
  ]);

  const handleVoteSubmit = async () => {
    const { signer } = await fetchSigner();
    setErrorMessage("");

    showLoading(
      [
        "Checking if you have already voted",
        "Validating vote data",
        "Submitting your vote",
      ],
      `Submitting vote for your selected option`
    );

    try {
      const currentStateEvent = getRoomStateEvent("m.space.child", roomId);
      const stateKey = currentStateEvent?.getStateKey();
      let updatedVoteData = currentStateEvent?.getContent() || { votes: [] };
      if (!Array.isArray(updatedVoteData.votes)) {
        updatedVoteData.votes = [];
      }
      const userHasVoted = updatedVoteData.votes.some(
        (vote) => vote.userId === userId
      );
      if (userHasVoted) {
        setErrorMessage("You have already voted on this proposal.");
        hideLoading();
        return;
      }
      const voteData = {
        userId,
        votingPower,
        selectedVoteOption,
        selectedOption: governanceData.selectedOption,
        optionWeights:
          governanceData.selectedOption === "Weighted voting"
            ? optionWeights
            : {},
        timestamp: new Date().toISOString(),
      };
      const messageHash = ethers.hashMessage(JSON.stringify(voteData));
      await signer.signMessage(ethers.getBytes(messageHash));
      updatedVoteData.votes.push(voteData);
      await mx.sendStateEvent(
        spaceId,
        "m.space.child",
        updatedVoteData,
        stateKey
      );
      await updateCommunityMembershipScore(mx, "cast_vote", spaceId);

      hideLoading();
      onClose();
      setOpenViewDialog(false);
    } catch (error) {
      console.error("Error submitting vote: ", error);
      setErrorMessage(
        "An error occurred while submitting your vote. Please try again."
      );
      hideLoading();
    }
  };

  return (
    <div className="cast-vote">
      <span className="cast-vote-title-text">Cast Your Vote</span>
      <div className="cast-vote-details-wrapper">
        <div className="cast-vote-details-choice-container">
          <span className="cast-vote-details-text">Choice</span>
          <div className="cast-vote-choice-container">
            {governanceData.selectedOption === "Weighted voting" ? (
              <div className="weighted-vote-options">
                {Object.entries(optionWeights)
                  .filter(([_, weight]) => weight > 0)
                  .map(([option, weight]) => (
                    <div className="weight-buttons-text-container-cast-vote">
                      <span className="view-proposal-voting-weighted-voting-text">
                        {option}
                      </span>
                      <div className="weight-percentage-container">
                        <span className="voting-percentage-text">
                          {weight.toFixed(1)}%
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <span className="cast-vote-details-selection-text">
                {selectedVoteOption}
              </span>
            )}
          </div>
        </div>
        <div className="cast-vote-details-container">
          <span className="cast-vote-details-text">Voting Type</span>
          <span className="cast-vote-details-selection-text">
            {governanceData.selectedOption}
          </span>
        </div>
        <div className="cast-vote-details-container">
          <span className="cast-vote-details-text">Strategy Option</span>
          <span className="cast-vote-details-selection-text">
            {governanceData.selectedStrategyOption}
          </span>
        </div>
        <div className="cast-vote-details-container">
          <span className="cast-vote-details-text">Block Number</span>
          <span className="cast-vote-details-selection-text">
            {governanceData.proposalBlockNumber}
          </span>
        </div>
        <div className="cast-vote-details-container">
          <span className="cast-vote-details-text">Your Voting Power</span>
          <span className="cast-vote-details-selection-text">
            {parseFloat(votingPower).toFixed(2)}{" "}
            {governanceData.selectedStrategyOption === "ERC20 Balance"
              ? data.tokenSymbol
              : "ETH"}
          </span>
        </div>
      </div>
      {errorMessage && (
        <div className="cast-vote-get-balance-message">
          <div>
            <Warning className="cast-vote-warning-svglogo" />
          </div>
          <span className="cast-vote-warning-text">{errorMessage}</span>
        </div>
      )}
      <div className="vote-action-buttons">
        <div
          className="voting-view-proposal-voting-container "
          onClick={onClose}
        >
          <span className="view-proposal-voting-option-container-text">
            Cancel
          </span>
        </div>
        <div
          className={`view-proposal-cast-vote ${
            isConfirmEnabled ? "active" : "inactive"
          }`}
          onClick={isConfirmEnabled ? handleVoteSubmit : null}
        >
          <span
            className={`view-proposal-cast-vote-text ${
              isConfirmEnabled ? "active" : "inactive"
            }`}
          >
            Confirm
          </span>
        </div>
      </div>
    </div>
  );
};

export default CastVote;
