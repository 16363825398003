import React from "react";
import { useAppConnection } from "../../contexts/AppConnectionContext";
import { Dialog } from "@capacitor/dialog";
import { Capacitor } from "@capacitor/core";
import "./DisconnectButton.scss";

const DisconnectButton = () => {
  const { setIsDisconnecting } = useAppConnection();

  const handleLogout = async () => {
    const message =
      "Are you sure you want to disconnect your wallet and logout from the app?";
    const title = "Confirm Logout";
    if (Capacitor.isNativePlatform()) {
      const { value } = await Dialog.confirm({
        title,
        message,
      });
      if (value) {
        setIsDisconnecting(true);
      }
    } else {
      const confirmed = window.confirm(message);
      if (confirmed) {
        setIsDisconnecting(true);
      }
    }
  };

  return (
    <div className="disconnect-button" onClick={handleLogout}>
      <span className="disconnect-button-text">Disconnect Wallet</span>
    </div>
  );
};

export default DisconnectButton;
