import React from "react";
import "./RightSideContainer.scss";
import WalletConnect from "../wallet-connect/WalletConnect.jsx";

function RightSideContainer({ mx, matrixUser }) {
  return (
    <div className="right-side-container-wrapper">
      <WalletConnect mx={mx} matrixUser={matrixUser}/>
    </div>
  );
}

export default RightSideContainer;
