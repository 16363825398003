import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_APIKEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addEmailAndAddress = async (
  email,
  address,
  acceptTermsAndPrivacy
) => {
  const emailsRef = collection(db, "emails");
  const emailDocRef = doc(emailsRef, email);

  try {
    const timestamp = new Date();
    const data = {
      email,
      timestamp: timestamp,
      acceptTermsAndPrivacy,
    };
    if (address) {
      data.address = address;
    }
    await setDoc(emailDocRef, data, { merge: true });
    return "Email and address added successfully with terms acceptance status!";
  } catch (error) {
    throw new Error("Failed to add email and address: " + error.message);
  }
};
