import React from "react";

const XLogo = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1001 937"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44 0L388.83 516.64L0 936.69H87.51L427.93 568.93L702.98 936.69H1000.78L592.65 390.99L954.57 0H867.06L553.55 338.7L300.24 0H2.44ZM131.13 64.46H267.94L872.07 872.22H735.26L131.13 64.46Z"
        fill={fill}
      />
    </svg>
  );
};

export default XLogo;
