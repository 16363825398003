import { ethers } from "ethers";
import TokenFactoryABI from "./abi/TokenFactoryABI.json";

export const createToken = async ({ signer, spaceData }) => {
  const tokenFactoryAddress = import.meta.env.VITE_TOKEN_FACTORY_ADDRESS;
  const feeWallet = import.meta.env.VITE_FEE_WALLET_ADDRESS;
  const chain = import.meta.env.VITE_DEFAULT_CHAIN;

  const etherscanBaseUrl =
    chain === "mainnet"
      ? "https://etherscan.io"
      : `https://${chain}.etherscan.io`;

  try {
    const feeAmount = ethers.parseEther("0.0001");
    const tokenFactoryContract = new ethers.Contract(
      tokenFactoryAddress,
      TokenFactoryABI,
      signer
    );
    const formattedTotalSupply = BigInt(
      spaceData.tokenTotalSupply.replace(/,/g, "")
    );
    const tx = await tokenFactoryContract.createToken(
      spaceData.tokenName,
      spaceData.tokenSymbol,
      formattedTotalSupply,
      feeWallet,
      { value: feeAmount }
    );
    const txReceipt = await tx.wait();
    const tokenContractAddress = txReceipt.logs[0]?.address;

    if (!tokenContractAddress) {
      const errorMsg = "Failed to create token.";
      return { error: new Error(errorMsg) };
    }
    const createTokenResult = {
      tokenContractAddress: tokenContractAddress,
      etherscanLink: `${etherscanBaseUrl}/token/${tokenContractAddress}`,
    };
    return createTokenResult;
  } catch (error) {
    let errorMsg;
    if (error.info && error.info.error) {
      errorMsg = error.info.error.message;
    } else {
      errorMsg = "An error occurred during token creation.";
    }
    return { error: new Error(errorMsg) };
  }
};
