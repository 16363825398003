import React from "react";
import "./LineProgress.scss";

const LineProgress = ({ progress }) => {
  return (
    <div className="line-progress">
      <div
        className="line-progress__bar"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default LineProgress;
