import React from "react";
import "./ViewProposal.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { formatDisplayName } from "../../../util/formatDisplayName";

const VoteEntry = ({
  userId,
  selectedVoteOption,
  votingPower,
  optionWeights,
  mx,
  selectedStrategyOption,
  tokenSymbol,
}) => {
  const userVoter = mx.getUser(userId);
  const avatarSrc = mx.mxcUrlToHttp(userVoter.avatarUrl, 36, 36, "crop");
  const displayName = userVoter.displayName;

  return (
    <div className="view-proposal-vote-entry-container">
      <div className="view-proposal-voter-data">
        <UserAvatar size={24} userId={userId} imageSrc={avatarSrc} />
        <span className="view-proposal-voter-text">
          {formatDisplayName(displayName)}
        </span>
        <span className="view-proposal-votingpower-text">
          {parseFloat(votingPower).toFixed(2)}{" "}
          {selectedStrategyOption === "ERC20 Balance" ? tokenSymbol : "ETH"}
        </span>
      </div>

      {optionWeights && Object.keys(optionWeights).length > 0 ? (
        Object.entries(optionWeights)
          .filter(([_, weight]) => weight > 0)
          .map(([option, weight]) => (
            <div className="vote-entry-weighted-vote-display" key={option}>
              <span className="vote-entry-weighted-vote-display-text">
                {option}
              </span>
              <div className="weight-percentage-container">
                <span className="voting-percentage-text">
                  {weight.toFixed(1)}%
                </span>
              </div>
            </div>
          ))
      ) : (
        <span className="view-proposal-voting-option-text">
          {selectedVoteOption}
        </span>
      )}
    </div>
  );
};

export default VoteEntry;
