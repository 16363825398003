import React, { useState, useEffect, useRef } from "react";
import "./Mentions.scss";
import initMatrix from "../../../client/initMatrix";
import {
  getUsernameOfRoomMember,
  getPowerLabel,
} from "../../../util/matrixUtil";
import PeopleSelector from "../../molecules/people-selector/PeopleSelector";

function Mentions({
  roomId,
  mentionQuery,
  onClick,
  setMentionsContainerHeight,
}) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const [members, setMembers] = useState([]);
  const mentionsRef = useRef(null);

  useEffect(() => {
    const normalizeMembers = (members) => {
      return members.map((member) => ({
        userId: member.userId,
        name: getUsernameOfRoomMember(member),
        displayName: member.rawDisplayName,
        peopleRole: getPowerLabel(member.powerLevel).label,
      }));
    };

    const loadMembers = () => {
      const memberList = room
        .getMembersWithMembership("join")
        .map((member) => normalizeMembers([member])[0]);
      setMembers(memberList);
    };

    loadMembers();

    const updateMembersOnEvent = () => loadMembers();
    mx.on("RoomMember.membership", updateMembersOnEvent);
    mx.on("RoomMember.powerLevel", updateMembersOnEvent);

    return () => {
      mx.removeListener("RoomMember.membership", updateMembersOnEvent);
      mx.removeListener("RoomMember.powerLevel", updateMembersOnEvent);
    };
  }, [roomId]);

  const filteredMembers = members.filter((member) =>
    member.name.toLowerCase().includes(mentionQuery.toLowerCase())
  );

  useEffect(() => {
    const handleResize = () => {
      if (mentionsRef.current) {
        const mentionsHeight = mentionsRef.current.scrollHeight;
        setMentionsContainerHeight(`${Math.min(mentionsHeight, 190)}px`);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [members, mentionQuery]);

  return (
    <div className="mentions-list" ref={mentionsRef}>
      {filteredMembers.map((member) => (
        <PeopleSelector
          key={member.userId}
          onClick={() => onClick(member.userId, member.displayName)}
          userId={member.userId}
          name={member.name}
          peopleRole={member.peopleRole}
          roomId={roomId}
        />
      ))}
      {filteredMembers.length === 0 && (
        <div className="no-mentions-text">No members found</div>
      )}
    </div>
  );
}

export default Mentions;
