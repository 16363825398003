import axios from "axios";
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const isValidReferral = async (referralCode, walletAddress) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/validate-referral`,
      { referralCode, walletAddress },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.isValid;
  } catch (error) {
    console.error("isValidReferral error:", error);
    return false;
  }
};

export const fetchReferralCode = async (accessToken) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/my-referral-code`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data.referralCode;
  } catch (error) {
    console.error("fetchReferralCode error:", error);
    return null;
  }
};

export const generateReferralCode = async (accessToken) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/generate-referral-code`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.referralCode;
  } catch (error) {
    console.error("generateReferralCode error:", error);
    throw error.response?.data?.error || "Failed to generate referral code.";
  }
};

export const incrementActionCount = async (
  actionType,
  stateKey,
  accessToken,
  useStateKey = false
) => {
  try {
    const data = { actionType, stateKey, useStateKey };

    const response = await axios.post(
      `${API_BASE_URL}/api/increment-action-count`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("incrementActionCount error:", error);
    throw error.response?.data?.error || "Failed to increment action count.";
  }
};
