import React from "react";
import PropTypes from "prop-types";
import "./PinnedSpaces.scss";
import NotificationBadge from "../../atoms/badge/NotificationBadge";

const PinnedSpaces = React.forwardRef(
  (
    {
      onClick,
      avatar,
      roomName,
      notificationCount,
      isAlert,
      imageSrc,
      tokenSymbol,
    },
    ref
  ) => {
    return (
      <div className="pinned-spaces-avatar-mobile-container" onClick={onClick}>
        <div
          className="pinned-spaces-banner-class-generated"
          style={
            imageSrc ? {} : { backgroundColor: "var(--color-hover-tertiary)" }
          }
        >
          {imageSrc && <img src={imageSrc} alt="Room Banner" />}
        </div>
        <div className="pinned-spaces-text-container">
          <span className="pinned-spaces-space-name-text">{roomName}</span>
          <div className="pinned-spaces-notifications-container">
            {notificationCount ? (
              <NotificationBadge alert={isAlert} content={notificationCount} />
            ) : null}
          </div>
        </div>
        <div className="pinned-spaces-avatar">{avatar}</div>
        <div className="pinned-spaces-token-symbol">
          <span className="pinned-spaces-token-symbol-text">
            ${tokenSymbol}
          </span>
        </div>
      </div>
    );
  }
);

PinnedSpaces.propTypes = {
  className: PropTypes.string,
  tokenSymbol: PropTypes.string,
  roomName: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  avatar: PropTypes.node.isRequired,
  isAlert: PropTypes.bool,
  imageSrc: PropTypes.string,
  generatedSvg: PropTypes.string,
};

export default PinnedSpaces;
