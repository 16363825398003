import React from "react";
import { useSwipeable } from "react-swipeable";
import { abbreviateNumber } from "../../../util/common";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import "./SpaceItem.scss";
import { formatDisplayName } from "../../../util/formatDisplayName";
import NotificationBadge from "../../atoms/badge/NotificationBadge";
import MembersDisplay from "../members-display/MembersDisplay";

const SpaceItem = ({ space, onSpaceClick, onMoreClick }) => {
  const [isSwiped, setIsSwiped] = React.useState(false);
  console.log(space);

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsSwiped(true),
    onSwipedRight: () => setIsSwiped(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleOptionClick = (option) => {
    if (option === "more") {
      onMoreClick(space.id);
    } else if (option === "read") {
      // Handle read/unread toggle
    }
    setIsSwiped(false);
  };

  // Since members already have userId and avatarUrl, no need to transform further
  const membersData = space.members.map((member) => ({
    userId: member.userId,
    avatarUrl: member.avatarUrl,
  }));

  return (
    <div className={`chat-room ${isSwiped ? "swiped" : ""}`} {...handlers}>
      <div className="chat-content" onClick={() => onSpaceClick(space.id)}>
        <div>
          <RoomAvatar
            roomId={space.id}
            imageSrc={space.avatar}
            text={space.name}
            size={48}
            borderRadius={99}
          />
        </div>
        <div className="space-info">
          <div className="space-info-wrapper">
            <div className="chat-info-header">
              <span className="space-name-text">
                {formatDisplayName(space.name)}
              </span>
              {space.unreadCount > 0 && (
                <NotificationBadge
                  content={
                    space.unreadCount !== 0
                      ? abbreviateNumber(space.unreadCount)
                      : null
                  }
                />
              )}
            </div>
            <span className="chat-message-text">{space.topic}</span>
          </div>
          <div>
            <MembersDisplay members={membersData} />
          </div>
          <span className="space-item-members-text">
            {space.joinedMemberCount}{" "}
            {space.joinedMemberCount === 1 ? "member" : "members"}
          </span>
        </div>
      </div>
      <div className="chat-options">
        <div
          onClick={() => handleOptionClick("more")}
          className="chat-options-container"
        >
          <span className="chat-more-text">More</span>
        </div>
      </div>
    </div>
  );
};

export default SpaceItem;
