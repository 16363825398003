import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import initMatrix from "../../../client/initMatrix";
import { useTypingStatusUpdater } from "../../hooks/useTypingStatusUpdater";
import "./RoomInput.scss";
import { ReactComponent as SendMessage } from "../../assets/svg/send-message.svg";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as GalleryIcon } from "../../assets/svg/mediagallery.svg";
import { ReactComponent as CameraIcon } from "../../assets/svg/mediacamera.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-circle.svg";
import { ReactComponent as MediaIcon } from "../../assets/svg/mediagallery.svg";

import {
  handleFiles,
  pickMediaFromCameraRoll,
  takeMediaFromCamera,
} from "../../../util/fileHandlers";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import Loader from "../../atoms/loader/Loader";
import { useMessagingOptions } from "../../contexts/MessagingOptionsContext";
import { MessageHeader } from "../../molecules/message/Message";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";
import { useRoomSelect } from "../../contexts/RoomSelectContext";

const RoomInput = forwardRef(
  (
    {
      roomId,
      room,
      setContainerHeight,
      textareaRef,
      sendButtonRef,
      uploadMediaButtonRef,
      uploadGalleryButtonRef,
      uploadCameraButtonRef,
      uploadFolderButtonRef,
      removeMediaButtonRef,
      cancelReplyRef,
      setIsMentionModalVisible,
      setMentionQuery,
      selectedMention,
      setSelectedMention,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("");
    const [rows, setRows] = useState(1);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [mediaPreviews, setMediaPreviews] = useState([]);
    const [loadingStates, setLoadingStates] = useState([]);
    const [mediaToggle, setMediaToggle] = useState(false);
    const mx = initMatrix && initMatrix.matrixClient;
    const roomName = room ? formatDisplayName(room.name) : "room";
    const sendTypingStatus = useTypingStatusUpdater(mx, roomId);
    const {
      isReplying,
      replyData,
      stopReplying,
      isEditing,
      editData,
      stopEditing,
    } = useMessagingOptions();
    const [submittedMentions, setSubmittedMentions] = useState([]);
    const { spaceId } = useRoomSelect();
    const userId = replyData?.userId;
    const user = userId ? mx.getUser(userId) : null;

    const handleChange = (e) => {
      const textareaLineHeight = 22;
      const previousRows = e.target.rows;
      e.target.rows = 1;

      const currentRows = Math.floor(
        e.target.scrollHeight / textareaLineHeight
      );

      if (currentRows === previousRows) {
        e.target.rows = currentRows;
      }

      if (currentRows >= 5) {
        e.target.rows = 5;
        e.target.scrollTop = e.target.scrollHeight;
      }

      const newValue = e.target.value;
      setInputValue(newValue);
      setRows(currentRows < 5 ? currentRows : 5);

      const atSymbolIndex = newValue.lastIndexOf("@");
      const textAfterAtSymbol = newValue.slice(atSymbolIndex + 1);

      if (atSymbolIndex !== -1) {
        setIsMentionModalVisible(true);

        if (
          textAfterAtSymbol.trim().length > 0 &&
          !textAfterAtSymbol.startsWith(" ")
        ) {
          setMentionQuery(textAfterAtSymbol.trim());
        } else {
          setMentionQuery("");
        }

        if (textAfterAtSymbol.includes(" ")) {
          setIsMentionModalVisible(false);
        }
      } else {
        setIsMentionModalVisible(false);
        setMentionQuery("");
      }

      sendTypingStatus(newValue.trim().length > 0);
      setMediaToggle(false);
      setSubmittedMentions((prevMentions) => {
        const mentionsInText = prevMentions.filter((mention) =>
          newValue.includes(mention.displayName)
        );
        return mentionsInText;
      });
    };

    useEffect(() => {
      if (selectedMention.displayName) {
        const formattedDisplayName = formatDisplayName(
          selectedMention.displayName
        );

        setInputValue((prevValue) => {
          const atSymbolIndex = prevValue.lastIndexOf("@");
          if (atSymbolIndex !== -1) {
            const updatedValue = `${prevValue.substring(
              0,
              atSymbolIndex + 1
            )}${formattedDisplayName} `;
            return updatedValue;
          }
          return prevValue;
        });

        setSubmittedMentions((prev) => {
          if (
            prev.some((mention) => mention.userId === selectedMention.userId)
          ) {
            return prev;
          }
          return [
            ...prev,
            {
              displayName: formattedDisplayName,
              userId: selectedMention.userId,
            },
          ];
        });

        setSelectedMention({ displayName: "", userId: "" });
      }
    }, [selectedMention, setInputValue]);

    const handleFilesCallback = handleFiles(
      mx,
      roomId,
      setMediaPreviews,
      setLoadingStates,
      setMediaFiles
    );

    const pickMedia = () => {
      pickMediaFromCameraRoll(handleFilesCallback);
    };

    const takeMedia = () => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
      takeMediaFromCamera(handleFilesCallback);
    };

    const handleSubmit = useCallback(async () => {
      if (!inputValue.trim() && mediaFiles.length === 0) {
        return;
      }

      const isReplying = replyData.mEvent !== null;

      if (inputValue.trim()) {
        const body = inputValue;
        const formattedBody = inputValue.replace(/\n/g, "<br />");

        const content = {
          msgtype: "m.text",
          body,
          format: "org.matrix.custom.html",
          formatted_body: formattedBody,
          "m.mentions": {
            user_ids: submittedMentions.map((mention) => mention.userId),
          },
        };

        if (isReplying) {
          content["m.relates_to"] = {
            "m.in_reply_to": {
              event_id: replyData.mEvent.getId(),
            },
          };
          content["reply_info"] = {
            reply_to_sender: replyData.mEvent.getSender(),
            reply_to_content: replyData.mEvent.getContent(),
          };
        }

        stopReplying();
        setInputValue("");
        setRows(1);

        try {
          await mx.sendMessage(roomId, content);
          await updateCommunityMembershipScore(mx, "message", spaceId);
          setSubmittedMentions([]);
        } catch (error) {
          console.error("Failed to send message:", error);
        }
      }

      if (mediaFiles.length > 0) {
        mediaFiles.forEach(async (mediaContent) => {
          if (!mediaContent.body) {
            mediaContent.body = "Media";
          }

          const mediaMessage = {
            ...mediaContent,
            body: mediaContent.body,
          };

          if (isReplying) {
            mediaMessage["m.relates_to"] = {
              "m.in_reply_to": {
                event_id: replyData.mEvent.getId(),
              },
            };
            mediaMessage["reply_info"] = {
              reply_to_sender: replyData.mEvent.getSender(),
              reply_to_content: replyData.mEvent.getContent(),
            };
          }

          stopReplying();
          setInputValue("");
          setRows(1);

          try {
            await mx.sendMessage(roomId, mediaMessage);
            await updateCommunityMembershipScore(mx, "message", spaceId);
            setSubmittedMentions([]);
          } catch (error) {
            console.error("Failed to send message:", error);
          }
        });
      }

      setMediaFiles([]);
      setMediaPreviews([]);
      sendTypingStatus(false);

      if (textareaRef.current) {
        textareaRef.current.focus();
      }

      setMediaToggle(false);
    }, [
      mx,
      roomId,
      inputValue,
      sendTypingStatus,
      mediaFiles,
      replyData,
      stopReplying,
      submittedMentions,
      setSubmittedMentions,
    ]);

    const handleEditSubmit = useCallback(async () => {
      if (!inputValue.trim()) {
        return;
      }

      const body = inputValue;
      const formattedBody = inputValue.replace(/\n/g, "<br />");

      const content = {
        body: `* ${body}`,
        msgtype: "m.text",
        "m.new_content": {
          body: body,
          msgtype: "m.text",
          format: "org.matrix.custom.html",
          formatted_body: formattedBody,
        },
        "m.relates_to": {
          rel_type: "m.replace",
          event_id: editData.mEvent.getId(),
        },
      };

      try {
        await mx.sendEvent(roomId, "m.room.message", content);
      } catch (error) {
        console.error("Failed to send edited message:", error);
      }

      stopEditing();
      setInputValue("");
      setRows(1);
      sendTypingStatus(false);
    }, [
      mx,
      roomId,
      inputValue,
      editData,
      stopEditing,
      sendTypingStatus,
      textareaRef,
    ]);

    const handleFocus = () => {
      setMediaToggle(false);
      if (Capacitor.getPlatform() === "ios") {
        setContainerHeight("calc(100% - 336px - env(safe-area-inset-bottom))");
      } else if (Capacitor.getPlatform() === "android") {
        setContainerHeight("100vh");
      }
    };

    const handleBlur = (e) => {
      if (e.relatedTarget === sendButtonRef.current) {
        e.preventDefault();
        return;
      }
      if (Capacitor.getPlatform() === "ios") {
        setContainerHeight("100%");
      } else if (Capacitor.getPlatform() === "android") {
        setContainerHeight("100vh");
      }
    };

    useEffect(() => {
      if (isReplying && cancelReplyRef.current) {
        textareaRef.current.focus();
        cancelReplyRef.current.classList.add("open");
      } else if (cancelReplyRef.current) {
        cancelReplyRef.current.classList.remove("open");
      }
    }, [isReplying, textareaRef]);

    useEffect(() => {
      if (isEditing && editData && editData.body) {
        setInputValue(editData.body);
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }
    }, [isEditing, editData, textareaRef]);

    useEffect(() => {
      if (!isEditing) {
        setInputValue("");
        setRows(1);

        if (textareaRef.current) {
          textareaRef.current.blur();
        }
      }
    }, [isEditing, textareaRef]);

    return (
      <div className="editor-container-outer">
        <div className="room-input-message-reply-wrapper" ref={cancelReplyRef}>
          {isReplying && replyData && (
            <>
              <div className="room-input-message-reply-container">
                {typeof replyData.body === "string" ? (
                  <div className="room-input-message-reply-text-container">
                    {user && (
                      <MessageHeader user={user} userId={userId} mx={mx} />
                    )}
                    <span className="room-input-reply-text">
                      {replyData.body}
                    </span>
                  </div>
                ) : (
                  <div className="room-input-message-reply-media-container">
                    <div className="room-input-message-reply-text-container">
                      {user && (
                        <MessageHeader user={user} userId={userId} mx={mx} />
                      )}
                      <div className="room-input-message-reply-media-container-wrapper ">
                        <MediaIcon className="room-input-reply-close-svglogo" />
                        <span className="room-input-reply-media-text">
                          Photo
                        </span>
                      </div>
                    </div>
                    <div className="room-input-reply-media-container">
                      {replyData.body}
                    </div>
                  </div>
                )}
              </div>
              <div onClick={stopReplying}>
                <CloseIcon className="room-input-reply-close-svglogo" />
              </div>
            </>
          )}
        </div>
        <div
          ref={removeMediaButtonRef}
          className="editor-media-previews-wrapper"
        >
          {mediaPreviews.length > 0 && (
            <div className="editor-media-previews">
              {mediaPreviews.map((preview, index) => (
                <div key={index} className="editor-media-preview">
                  <div
                    className={`editor-media-preview-image-wrapper ${
                      loadingStates[index] ? "blurred" : ""
                    }`}
                  >
                    <img
                      src={preview}
                      alt={`Media preview ${index}`}
                      className="editor-media-preview-image"
                    />
                    {loadingStates[index] && (
                      <div className="editor-media-loading-overlay"></div>
                    )}
                  </div>
                  {loadingStates[index] && (
                    <div className="editor-media-loading-spinner">
                      <Loader
                        size="16px"
                        dotSize="4px"
                        color="var(--light)"
                        multiplier={1.4}
                      />
                    </div>
                  )}
                  {!loadingStates[index] && (
                    <div
                      className="editor-media-preview-remove"
                      onClick={() => {
                        setMediaPreviews((prev) =>
                          prev.filter((_, i) => i !== index)
                        );
                        setMediaFiles((prev) =>
                          prev.filter((_, i) => i !== index)
                        );
                        setLoadingStates((prev) =>
                          prev.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <Close className="editor-media-preview-remove-svglogo" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="editor-container">
          <div className="editor-container-media">
            <div
              className={`media-toggle ${mediaToggle ? "closed" : "open"}`}
              ref={uploadMediaButtonRef}
            >
              {isEditing ? (
                <div
                  onClick={stopEditing}
                  className="editor-container-file-upload"
                >
                  <CloseIcon className="room-input-edit-close-svglogo" />
                </div>
              ) : (
                <div
                  className="editor-container-file-upload"
                  onClick={pickMedia}
                >
                  <Plus className="editor-container-file-upload-icon" />
                </div>
              )}
            </div>
            <div className={`media-toggle ${mediaToggle ? "open" : "closed"}`}>
              <div className="media-toggle-media-types-container">
                <div
                  className="editor-container-file-upload"
                  onClick={pickMedia}
                  ref={uploadGalleryButtonRef}
                >
                  <GalleryIcon className="editor-container-media-icon" />
                </div>
                <div
                  className="editor-container-file-upload"
                  ref={uploadCameraButtonRef}
                  onClick={takeMedia}
                >
                  <CameraIcon className="editor-container-media-icon" />
                </div>
              </div>
            </div>
          </div>
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleChange}
            placeholder={`Message ${roomName}`}
            className="editor-input"
            autoCapitalize="sentences"
            autoComplete="off"
            autoCorrect="on"
            spellCheck="true"
            rows={rows}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <div
            ref={sendButtonRef}
            className={`editor-send ${
              inputValue.trim() || mediaFiles.length ? "has-text" : "no-text"
            }`}
            onClick={isEditing ? handleEditSubmit : handleSubmit}
          >
            <SendMessage
              className={`send-stroke-icon-class ${
                inputValue.trim() || mediaFiles.length ? "has-text" : "no-text"
              }`}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default RoomInput;
