import React, { useState, useEffect, useRef, useMemo } from "react";
import "./ViewPost.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { renderMedia } from "../../../util/MediaUtils";
import { formatDisplayName } from "../../../util/formatDisplayName";
import usernameColorMXID from "../../../util/usernameColorMXID";
import { formatTimeAgo } from "../../../util/formatTimeAgo";
import { ReactComponent as ArrowBottom } from "../../assets/svg/vote.svg";
import initMatrix from "../../../client/initMatrix";
import useKeyboard from "../../hooks/useKeyboard";
import { ReactComponent as SendMessage } from "../../assets/svg/send-message.svg";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";

const ViewPost = ({ post, globalFeed, user, avatarUrl, mx }) => {
  const [commentInput, setCommentInput] = useState("");
  const [sortBy, setSortBy] = useState("best");
  const [allComments, setAllComments] = useState(
    globalFeed.getComments(globalFeed.getOriginalEventId(post))
  );
  const eventId = globalFeed.getOriginalEventId(post);
  const sender = post.getSender();
  const timestamp = formatTimeAgo(post.getTs());
  const content = post.getContent().body;
  const mediaElement = renderMedia(post, initMatrix.matrixClient);
  const displayName = formatDisplayName(
    initMatrix.matrixClient.getUser(sender)?.displayName || sender
  );
  const { keyboardHeight } = useKeyboard();
  const getViewportHeight = () => window.innerHeight;
  const newContainerHeight =
    keyboardHeight === 0
      ? `calc(${
          getViewportHeight() * 0.9
        }px - ${keyboardHeight}px - 41px - 54px - env(safe-area-inset-bottom))`
      : `calc(${
          getViewportHeight() * 0.9
        }px - ${keyboardHeight}px - 41px - 54px)`;

  const colorStyle = usernameColorMXID(sender);
  const isGradient = colorStyle.startsWith("linear-gradient");
  const textStyle = isGradient
    ? {
        background: colorStyle,
        WebkitBackgroundClip: "text",
        color: "transparent",
      }
    : { color: colorStyle };

  const upvoteCount = globalFeed.getUpvoteCount(eventId);
  const downvoteCount = globalFeed.getDownvoteCount(eventId);
  const combinedCount = upvoteCount - downvoteCount;
  const userVote = globalFeed.getUserVote(eventId, user.userId);
  const [rows, setRows] = useState(1);
  const textareaRef = useRef(null);

  const feedPostRef = useRef(null);

  const [feedPostHeight, setFeedPostHeight] = useState(0);

  useEffect(() => {
    if (feedPostRef.current) {
      setFeedPostHeight(feedPostRef.current.offsetHeight);
    }
  }, []);

  const preventKeyboardClose = (e) => {
    e.preventDefault();
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const mediaSelectionStyle = {
    position: "absolute",
    bottom: keyboardHeight === 0 ? "0" : `${keyboardHeight}px`,
    left: 0,
    right: 0,
  };

  const commentContainerStyle = {
    padding:
      keyboardHeight === 0
        ? `8px 12px calc(12px + env(safe-area-inset-bottom)) 12px`
        : "8px 12px 12px 12px",
  };

  const handleVote = async (eventId, type, targetType = "posts") => {
    const userId = user.userId;
    const success = await globalFeed.handleVote(
      eventId,
      type,
      userId,
      targetType
    );

    if (success) {
      if (targetType === "comments") {
        const postEventId = globalFeed.getOriginalEventId(post);
        setAllComments([...globalFeed.getComments(postEventId, sortBy)]);
      } else if (targetType === "posts") {
        setAllComments((prevComments) => [...prevComments]);
      }
      await updateCommunityMembershipScore(mx, "vote");
    }
  };

  const handleAddComment = async () => {
    if (!commentInput.trim()) return;
    const success = await globalFeed.addComment(eventId, commentInput);
    if (success) {
      setCommentInput("");
      const updatedComments = globalFeed.getComments(eventId);
      setAllComments([...updatedComments]);
    }
  };

  useEffect(() => {
    const handleLiveEvent = (event) => {
      const eventType = event.getType();
      const relatedEventId = event.getContent()["m.relates_to"]?.event_id;
      const postEventId = globalFeed.getOriginalEventId(post);

      if (
        (eventType === "m.comment" || eventType === "m.vote") &&
        (relatedEventId === postEventId ||
          globalFeed.comments[postEventId]?.some(
            (comment) => comment.getId() === event.getId()
          ))
      ) {
        const updatedComments = globalFeed.getComments(postEventId);
        setAllComments([...updatedComments]);
      }
    };

    initMatrix.matrixClient.on("Room.timeline", handleLiveEvent);

    return () => {
      initMatrix.matrixClient.removeListener("Room.timeline", handleLiveEvent);
    };
  }, [post, globalFeed]);

  const sortedComments = useMemo(() => {
    if (sortBy === "best") {
      return [...allComments].sort((a, b) => {
        const aScore =
          globalFeed.getUpvoteCount(a.getId()) -
          globalFeed.getDownvoteCount(a.getId());
        const bScore =
          globalFeed.getUpvoteCount(b.getId()) -
          globalFeed.getDownvoteCount(b.getId());
        return bScore - aScore;
      });
    } else if (sortBy === "recent") {
      return [...allComments].sort((a, b) => b.getTs() - a.getTs());
    }
    return allComments;
  }, [allComments, sortBy, globalFeed]);

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
  };

  const handleInputChange = (e) => {
    const textareaLineHeight = 22;
    const previousRows = e.target.rows;
    e.target.rows = 1;

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= 5) {
      e.target.rows = 5;
      e.target.scrollTop = e.target.scrollHeight;
    }

    setCommentInput(e.target.value);
    setRows(currentRows < 5 ? currentRows : 5);
  };

  return (
    <div className="view-post-container" style={{ height: newContainerHeight }}>
      <div className="view-post" ref={feedPostRef}>
        <div className="post-header">
          <div>
            <UserAvatar size={36} userId={sender} imageSrc={avatarUrl} />
          </div>
          <div className="post-header-content-container">
            <div className="post-header-text-container">
              <span className="post-author" style={textStyle}>
                {displayName}
              </span>
              <span className="post-timestamp">{timestamp}</span>
            </div>
            <div className="post-content-container">
              <span className="post-content">{content}</span>
              {mediaElement && (
                <div className="media-content">
                  {mediaElement.type === "image" && (
                    <img
                      src={mediaElement.src}
                      alt="Image"
                      width={mediaElement.info?.w}
                      height={mediaElement.info?.h}
                      loading="lazy"
                    />
                  )}
                  {mediaElement.type === "video" && (
                    <video
                      controls
                      width={mediaElement.info?.w}
                      height={mediaElement.info?.h}
                      src={mediaElement.src}
                    ></video>
                  )}
                  {mediaElement.type === "file" && (
                    <a href={mediaElement.src} download={mediaElement.filename}>
                      Download {mediaElement.filename}
                    </a>
                  )}
                </div>
              )}
            </div>
            <div className="post-actions">
              <div className="vote-options">
                <div
                  className={`vote-button ${
                    userVote !== null && userVote !== "upvote" ? "disabled" : ""
                  }`}
                  onClick={() => handleVote(eventId, "upvote", "posts")}
                >
                  <ArrowBottom
                    className={`upvote-svg ${
                      userVote === "upvote" ? "selected" : ""
                    }`}
                  />
                </div>
                <span className="vote-count">{combinedCount}</span>
                <div
                  className={`vote-button ${
                    userVote !== null && userVote !== "downvote"
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => handleVote(eventId, "downvote", "posts")}
                >
                  <ArrowBottom
                    className={`downvote-svg ${
                      userVote === "downvote" ? "selected" : ""
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="view-post-comments">
        <div className="comment-sorting">
          <div
            className={`discovery-toggle-spaces-sales-div ${
              sortBy === "best" ? "selected" : ""
            }`}
            onClick={() => handleSortChange("best")}
          >
            <span className="discovery-toggle-div-text">Best</span>
          </div>
          <div
            className={`discovery-toggle-spaces-sales-div ${
              sortBy === "recent" ? "selected" : ""
            }`}
            onClick={() => handleSortChange("recent")}
          >
            <span className="discovery-toggle-div-text">Recent</span>
          </div>
        </div>
        <div className="view-post-comments-wrapper">
          {sortedComments.length > 0 ? (
            sortedComments.map((comment) => {
              const commentId = comment.getId();
              const commentContent = comment.getContent().body;
              const commentSender = comment.getSender();
              const commentTimestamp = formatTimeAgo(comment.getTs());
              const commentDisplayName = formatDisplayName(
                initMatrix.matrixClient.getUser(commentSender)?.displayName ||
                  commentSender
              );
              const commentColorStyle = usernameColorMXID(commentSender);
              const commentIsGradient =
                commentColorStyle.startsWith("linear-gradient");
              const commentTextStyle = commentIsGradient
                ? {
                    background: commentColorStyle,
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }
                : { color: commentColorStyle };

              const commentUpvoteCount = globalFeed.getUpvoteCount(
                commentId,
                "comments"
              );
              const commentDownvoteCount = globalFeed.getDownvoteCount(
                commentId,
                "comments"
              );
              const commentCombinedCount =
                commentUpvoteCount - commentDownvoteCount;
              const commentUserVote = globalFeed.getUserVote(
                commentId,
                user.userId,
                "comments"
              );

              return (
                <div key={commentId} className="comment-container-viewpost">
                  <UserAvatar
                    size={30}
                    userId={commentSender}
                    imageSrc={avatarUrl}
                  />
                  <div className="comment-content-container">
                    <div className="comment-header-text-container">
                      <div className="comment-header-container">
                        <span className="post-author" style={commentTextStyle}>
                          {commentDisplayName}
                        </span>
                        <span className="post-timestamp">
                          {commentTimestamp}
                        </span>
                      </div>
                      <div>
                        <span className="comment-content">
                          {commentContent}
                        </span>
                      </div>
                    </div>
                    <div className="comment-actions">
                      <div className="vote-options">
                        <div
                          className={`vote-button ${
                            commentUserVote !== null &&
                            commentUserVote !== "upvote"
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() =>
                            handleVote(commentId, "upvote", "comments")
                          }
                        >
                          <ArrowBottom
                            className={`upvote-svg ${
                              commentUserVote === "upvote" ? "selected" : ""
                            }`}
                          />
                        </div>
                        <span className="vote-count">
                          {commentCombinedCount}
                        </span>
                        <div
                          className={`vote-button ${
                            commentUserVote !== null &&
                            commentUserVote !== "downvote"
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() =>
                            handleVote(commentId, "downvote", "comments")
                          }
                        >
                          <ArrowBottom
                            className={`downvote-svg ${
                              commentUserVote === "downvote" ? "selected" : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <span className="comment-content">
              Nothing here yet, say something :)
            </span>
          )}
        </div>
        <div className="comment-container-input" style={mediaSelectionStyle}>
          <div
            className="comment-container-input-editor-container"
            style={commentContainerStyle}
          >
            <textarea
              className="editor-input"
              autoCapitalize="sentences"
              autoComplete="off"
              autoCorrect="on"
              spellCheck="true"
              placeholder="Add a comment..."
              value={commentInput}
              onChange={handleInputChange}
              rows={rows}
              ref={textareaRef}
            />
            <div
              onMouseDown={preventKeyboardClose}
              onClick={handleAddComment}
              className={`editor-send ${
                commentInput.trim() ? "has-text" : "no-text"
              }`}
            >
              <SendMessage
                className={`send-stroke-icon-class ${
                  commentInput.trim() ? "has-text" : "no-text"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPost;
