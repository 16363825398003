import React from "react";

const CloseIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4231 3.57689C20.7123 3.8661 20.7123 4.33501 20.4231 4.62422L4.62425 20.4231C4.33504 20.7123 3.86613 20.7123 3.57692 20.4231C3.28771 20.1339 3.28771 19.665 3.57692 19.3758L19.3758 3.57689C19.665 3.28768 20.1339 3.28768 20.4231 3.57689Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1212 3.32235C19.551 2.89255 20.2479 2.89255 20.6777 3.32235C21.1074 3.75215 21.1074 4.44899 20.6777 4.87879L4.87879 20.6777C4.44899 21.1074 3.75215 21.1074 3.32235 20.6777C2.89255 20.2479 2.89255 19.551 3.32235 19.1212L19.1212 3.32235ZM20.1685 3.83147C20.0199 3.68285 19.7789 3.68285 19.6303 3.83147L3.83147 19.6303C3.68285 19.7789 3.68285 20.0199 3.83147 20.1685C3.98009 20.3172 4.22105 20.3172 4.36967 20.1685L20.1685 4.36967C20.3172 4.22105 20.3172 3.98009 20.1685 3.83147Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57692 3.57689C3.86613 3.28768 4.33504 3.28768 4.62425 3.57689L20.4231 19.3758C20.7123 19.665 20.7123 20.1339 20.4231 20.4231C20.1339 20.7123 19.665 20.7123 19.3758 20.4231L3.57692 4.62422C3.28771 4.33501 3.28771 3.8661 3.57692 3.57689Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.32235 3.32235C3.75215 2.89255 4.44899 2.89255 4.87879 3.32235L20.6777 19.1212C21.1074 19.551 21.1074 20.2479 20.6777 20.6777C20.2479 21.1074 19.551 21.1074 19.1212 20.6777L3.32235 4.87879C2.89255 4.44899 2.89255 3.75215 3.32235 3.32235ZM4.36967 3.83147C4.22105 3.68285 3.98009 3.68285 3.83147 3.83147C3.68285 3.98009 3.68285 4.22105 3.83147 4.36967L19.6303 20.1685C19.7789 20.3172 20.0199 20.3172 20.1685 20.1685C20.3172 20.0199 20.3172 19.7789 20.1685 19.6303L4.36967 3.83147Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
