import React, { useState } from "react";
import DateTimePicker from "../../atoms/date-time/DateTimePicker";
import DateTimePicker1 from "../../atoms/date-time/DateTimePicker1";

const TimingTactComponent = ({ presaleData, setPresaleData }) => {
  const [startDateTime, setStartDateTime] = useState(
    presaleData.presaleFundraiserStartTimestamp || ""
  );
  const [endDateTime, setEndDateTime] = useState(
    presaleData.presaleFundraiserEndTimestamp || ""
  );

  const handleStartDateTimeChange = (newValue) => {
    setStartDateTime(newValue);
    setPresaleData((prevData) => ({
      ...prevData,
      presaleFundraiserStartTimestamp: newValue,
    }));
  };

  const handleEndDateTimeChange = (newValue) => {
    setEndDateTime(newValue);
    setPresaleData((prevData) => ({
      ...prevData,
      presaleFundraiserEndTimestamp: newValue,
    }));
  };

  return (
    <div className="presale-form-content">
      <span className="presale-primary-text">Timing Tactics</span>
      <span className="presale-secondary-text">
        Time it right: Your project's debut must be perfectly timed. It's not
        just about setting dates, but aligning your launch with market trends
        and global attention, making your entry not just noticed, but impactful.
      </span>
      <div className="presale-tokenomics-container">
        <div className="presale-fundraising-timing-container">
          <div className="spaceview-tokenomics-timer-container">
            <span className="presale-tokenomics-input-header-text">
              Start Time
            </span>
            <DateTimePicker onDateTimeChange={handleStartDateTimeChange} />
          </div>
          <div className="spaceview-tokenomics-timer-container">
            <span className="presale-tokenomics-input-header-text">
              End Time
            </span>
            <DateTimePicker1 onDateTimeChange={handleEndDateTimeChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimingTactComponent;
