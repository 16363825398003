import React from "react";
import "./SubParagraph.scss";

function SubParagraph({ secondaryText }) {
  return (
    <div className="blog-subpara-component">
      <span className="blog-subpara-secondary-text">{secondaryText}</span>
    </div>
  );
}

export default SubParagraph;
