import { KJUR } from "jsrsasign";

export function generateJWTToken(walletAddress, jwtSecret) {
  const lowerCaseWalletAddress = walletAddress.toLowerCase();
  const header = { alg: "HS256", typ: "JWT" };
  const payload = {
    sub: lowerCaseWalletAddress,
    iat: KJUR.jws.IntDate.get("now"),
    exp: KJUR.jws.IntDate.get("now + 1hour"),
  };
  return KJUR.jws.JWS.sign(header.alg, header, payload, { utf8: jwtSecret });
}

export function validateToken(token, jwtSecret) {
  try {
    const isValid = KJUR.jws.JWS.verify(token, jwtSecret, ["HS256"]);
    if (!isValid) return null;

    const payload = token.split(".")[1];
    const decodedPayload = readSafeJSONString(b64utoutf8(payload));

    return decodedPayload;
  } catch (err) {
    console.error(err);
    return null;
  }
}
