import React from "react";
import "./Header.scss";

function Header({ text, tagText, secondaryText, marginBottom }) {
  return (
    <div
      className={`blog-header-component ${
        marginBottom ? "margin-36px" : "margin-0px"
      }`}
    >
      <div className="blog-header-tag">
        <span className="blog-header-tag-text">{tagText}</span>
      </div>
      <span className="blog-header-primary-text">{text}</span>
      <span className="blog-header-secondary-text">{secondaryText}</span>
    </div>
  );
}

export default Header;
