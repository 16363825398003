import React from "react";

const BackgroundHeaderGradient = ({
  width,
  height,
  fillOpacity,
  fillOpacityWhite,
  fill,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1800 1800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ overflow: "visible" }}
    >
      <g filter="url(#filter0_f_2558_8692)">
        <circle
          cx="900"
          cy="900"
          r="300"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      </g>
      <g filter="url(#filter1_f_2558_8692)">
        <circle
          cx="900"
          cy="900"
          r="300"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      </g>
      <g filter="url(#filter2_f_2558_8692)">
        <ellipse
          cx="900"
          cy="900"
          rx="300"
          ry="300"
          fill="#69E469"
          fillOpacity={fillOpacity}
        />
      </g>
      <g filter="url(#filter3_f_2558_8692)">
        <ellipse
          cx="900"
          cy="900"
          rx="300"
          ry="300"
          fill="white"
          fillOpacity={fillOpacityWhite}
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2558_8692"
          x="-6000"
          y="-6000"
          width="50000"
          height="50000" // Modified to expand blur area
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur
            stdDeviation="3000"
            result="effect1_foregroundBlur_2558_8692"
          />
        </filter>
        <filter
          id="filter1_f_2558_8692"
          x="-300"
          y="-300"
          width="50000"
          height="50000" // Modified to expand blur area
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur
            stdDeviation="150"
            result="effect1_foregroundBlur_2558_8692"
          />
        </filter>
        <filter
          id="filter2_f_2558_8692"
          x="-200"
          y="-200"
          width="50000"
          height="50000" // Modified to expand blur area
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur
            stdDeviation="200"
            result="effect1_foregroundBlur_2558_8692"
          />
        </filter>
        <filter
          id="filter3_f_2558_8692"
          x="-200"
          y="-200"
          width="50000"
          height="50000" // Modified to expand blur area
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur
            stdDeviation="200"
            result="effect1_foregroundBlur_2558_8692"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BackgroundHeaderGradient;
