import React from "react";
import "./MembersDisplay.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";

const MembersDisplay = ({ members }) => {
  console.log(members);
  const uniqueMembers = [
    ...new Map(members.map((member) => [member.userId, member])).values(),
  ];
  const limitedMembers = uniqueMembers.slice(0, 5);

  return (
    <div className="members-display-wrapper">
      <div className="members-display-container">
        {limitedMembers.map((member, index) => (
          <div
            className="members-avatar-wrapper"
            key={`${member.userId}-${index}`}
          >
            <UserAvatar
              size={28}
              userId={member.userId}
              imageSrc={member.avatarUrl}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembersDisplay;
