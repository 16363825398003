import React from "react";

const WaivlengthLogo = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 73"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3674 0C11.8051 0 0 11.8051 0 26.3674V35.1566C0 35.4759 0 35.6355 0.0181971 35.7695C0.139209 36.6605 0.839882 37.3611 1.73082 37.4821C1.8648 37.5003 2.02446 37.5003 2.34377 37.5003H14.0656V64.2193C14.0656 68.8793 17.8432 72.6569 22.5032 72.6569H27.9944C29.869 72.6569 31.6547 71.8577 32.9037 70.4596L34.8666 68.2623L38.7924 63.8678L46.644 55.0786L54.4956 46.2895L58.4215 41.8949L58.7691 41.5058C59.1523 41.0769 59.5632 40.6788 59.9979 40.3135V64.2193C59.9979 68.8793 63.7755 72.6569 68.4355 72.6569H73.9267C75.8013 72.6569 77.587 71.8577 78.8359 70.4596L80.7989 68.2623L84.7247 63.8678L92.5763 55.0786L100.428 46.2895L104.354 41.8949L104.701 41.5058C106.978 38.9573 110.233 37.5003 113.651 37.5003H115.998C118.208 37.5003 119.998 35.7095 119.998 33.5003V3.16498C119.998 1.41701 118.581 0 116.833 0C105.439 0 94.597 4.91322 87.0851 13.4815L74.0661 28.3312V3.16498C74.0661 1.41701 72.6491 0 70.9012 0C59.5063 0 48.6647 4.91322 41.1528 13.4815L28.1253 28.341V1.75783C28.1253 0.787006 27.3382 0 26.3674 0Z"
      />
    </svg>
  );
};

export default WaivlengthLogo;
