import React from "react";
import "./NotificationBadge.scss";

function NotificationBadge({ alert, content }) {
  const notificationClass = alert ? " notification-badge--alert" : "";
  const shouldDisplayContent = content && content !== 0;

  if (!shouldDisplayContent) {
    return null;
  }

  return (
    <div className={`notification-badge${notificationClass}`}>
      <span className="notification-badge-text">{content}</span>
    </div>
  );
}

export default NotificationBadge;
