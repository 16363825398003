import React, { useState } from "react";
import "./WalletComponent.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { getCommunityMembershipScore } from "../../../util/membershipScore";
import CommunityScoreIcon from "../../assets/svg/CommunityScoreIcon";
import usernameColorMXID from "../../../util/usernameColorMXID";
import MembershipScore from "../../organisms/membership-score/MembershipScore";
import Popover from "../../atoms/popover/Popover";

const WalletComponent = ({ handleDialogOpen, mx, matrixUser }) => {
  const avatarSrc = mx.mxcUrlToHttp(matrixUser.avatarUrl, 36, 36, "crop");
  const [nestedCommunityScoreDialog, setNestedCommunityScoreDialog] =
    useState(false);

  const { totalScore } = getCommunityMembershipScore(mx);
  const colorStyle = usernameColorMXID(matrixUser.userId);
  const isGradient = colorStyle.startsWith("linear-gradient");
  const textStyle = isGradient
    ? {
        background: colorStyle,
        WebkitBackgroundClip: "text",
        color: "transparent",
      }
    : { color: colorStyle };

  return (
    <div className="wallet-component-wrapper">
      {totalScore !== null && (
        <div
          className="wallet-component-container"
          onClick={() => setNestedCommunityScoreDialog(true)}
        >
          <CommunityScoreIcon colorStyle={colorStyle} height={28} width={28} />
          <span className="wallet-component-user-score-text" style={textStyle}>
            {totalScore}
          </span>
        </div>
      )}
      <div className="wallet-component-container" onClick={handleDialogOpen}>
        <UserAvatar size={28} userId={matrixUser.userId} imageSrc={avatarSrc} />
        <span className="wallet-component-text">
          {formatDisplayName(matrixUser.displayName)}
        </span>
      </div>
      {nestedCommunityScoreDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedCommunityScoreDialog}
          headerText="Community Membership Score"
        >
          <MembershipScore
            totalScore={totalScore}
            mx={mx}
            matrixUser={matrixUser}
          />
        </Popover>
      )}
    </div>
  );
};

export default WalletComponent;
