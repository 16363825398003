import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import { roomIdByActivity } from "../../../util/sort";
import RoomsCategory from "./RoomsCategory";
import "./Directs.scss";

function Directs({ size }) {
  const mx = initMatrix.matrixClient;
  const { roomList } = initMatrix;
  const [directIds, setDirectIds] = useState([]);

  useEffect(
    () => setDirectIds([...roomList.directs].sort(roomIdByActivity)),
    [size]
  );

  useEffect(() => {
    const handleTimeline = (room, data) => {
      if (!roomList.directs.has(room.roomId)) return;
      if (!data.liveEvent) return;
      if (directIds[0] === room.roomId) return;
      const newDirectIds = [room.roomId];
      directIds.forEach((id) => {
        if (id === room.roomId) return;
        newDirectIds.push(id);
      });
      setDirectIds(newDirectIds);
    };
    mx.on("Room.timeline", handleTimeline);
    return () => {
      mx.removeListener("Room.timeline", handleTimeline);
    };
  }, [directIds]);

  return (
    <div className="directs">
      <RoomsCategory name="People" roomIds={directIds} />
    </div>
  );
}
Directs.propTypes = {
  size: PropTypes.number.isRequired,
};

export default Directs;
