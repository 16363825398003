import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import initMatrix from "../../../client/initMatrix";
import { generateWaivicon1 } from "waivicon";
import { formatDisplayName } from "../../../util/formatDisplayName";
import "./ProfileEditor.scss";
import { formatUserId } from "../../../util/formatUserId";
import Modal from "../../atoms/modal/Modal";
import HighlightsContainer from "../../atoms/highlights/HighlightsContainer";
import bgImage from "../../assets/svg/bg1.svg";
import SettingButton from "../../atoms/button/SettingsButton";
import { ReactComponent as DeleteProfile } from "../../assets/svg/fillprofiledelete.svg";
import { ReactComponent as Wallet } from "../../assets/svg/fillwallet.svg";
import { ReactComponent as DisplayNameIcon } from "../../assets/svg/filldisplayname.svg";
import Popover from "../../atoms/popover/Popover";
import AccountDeletion from "../../pages/account-deletion/AccountDeletion";
import DisplayName from "../../molecules/display-name/DisplayName";
import Toggle from "../../atoms/button/Toggle";
import { ReactComponent as NotificationBing } from "../../assets/svg/notification-bing.svg";
import settings from "../../../client/state/settings";
import { toggleNotifications } from "../../../client/action/settings";
import {
  registerDevice,
  requestPermissions,
} from "../../../util/pushNotificationSetup";
import { Dialog } from "@capacitor/dialog";
import { App } from "@capacitor/app";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import WalletSettings from "../../molecules/wallet-settings/WalletSettings";

function ProfileEditor({ userId, closeAllDialogs }) {
  const mx = initMatrix.matrixClient;
  const user = mx.getUser(mx.getUserId());
  const [isEditing, setIsEditing] = useState(false);
  const [username, setUsername] = useState("");
  const [originalUsername, setOriginalUsername] = useState("");
  const [displayName, setDisplayName] = useState(user.displayName);
  const [avatarSrc, setAvatarSrc] = useState(
    user.avatarUrl ? mx.mxcUrlToHttp(user.avatarUrl, 80, 80, "crop") : null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uploadImageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadPromise, setUploadPromise] = useState(null);
  const svg = generateWaivicon1(userId.toUpperCase(), 80);
  const [nestedAccountDeletionDialog, setNestedAccountDeletionDialog] =
    useState(false);
  const [nestedDisplayNameDialog, setNestedDisplayNameDialog] = useState(false);
  const [nestedWalletDialog, setNestedWalletDialog] = useState(false);
  const walletArray =
    JSON.parse(localStorage.getItem("waivlength_wallets")) || [];
  const walletExists = walletArray.some(
    (wallet) =>
      wallet.createdWallet || wallet.importedWalletSP || wallet.importedWalletPK
  );

  const closeProfileEditor = () => {
    setNestedWalletDialog(false);
    closeAllDialogs();
  };

  useEffect(() => {
    let isMounted = true;
    mx.getProfileInfo(mx.getUserId()).then((info) => {
      if (!isMounted) return;
      setAvatarSrc(
        info.avatar_url
          ? mx.mxcUrlToHttp(info.avatar_url, 80, 80, "crop")
          : null
      );
      setUsername(info.displayname);
      setOriginalUsername(info.displayname);
      setDisplayName(info.displayname);
    });
    return () => {
      isMounted = false;
    };
  }, [userId]);

  const handleAvatarUpload = async (url) => {
    if (url === null) {
      mx.setAvatarUrl("");
      setAvatarSrc(null);
      return;
    }
    mx.setAvatarUrl(url);
    setAvatarSrc(mx.mxcUrlToHttp(url, 80, 80, "crop"));
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const uploadImage = async (e) => {
    const file = e.target.files.item(0);
    if (file === null) return;
    setIsLoading(true);
    try {
      const uPromise = mx.uploadContent(file);
      setUploadPromise(uPromise);

      const res = await uPromise;
      if (typeof res?.content_uri === "string") {
        handleAvatarUpload(res.content_uri);
        setAvatarSrc(mx.mxcUrlToHttp(res.content_uri, 80, 80, "crop"));
      }
      setUploadPromise(null);
    } catch {
      setUploadPromise(null);
    }
    uploadImageRef.current.value = null;
  };

  const [showNotifications, setShowNotifications] = useState(
    settings._showNotifications
  );

  const checkPermissions = useCallback(async () => {
    try {
      await requestPermissions();
      setShowNotifications(true);
    } catch (error) {
      setShowNotifications(false);
    }
  }, []);

  useEffect(() => {
    checkPermissions();
    const handleAppState = async (state) => {
      if (state.isActive) {
        checkPermissions();
      }
    };

    const appStateListener = App.addListener("appStateChange", handleAppState);

    return () => {
      appStateListener.remove();
    };
  }, [checkPermissions]);

  const handleToggle = async () => {
    if (showNotifications) {
      const { value } = await Dialog.confirm({
        title: "Disable Notifications",
        message:
          "Please disable notifications in your device settings to completely turn off notifications. Would you like to go to settings now?",
        okButtonTitle: "Yes",
        cancelButtonTitle: "No",
      });

      if (value) {
        await NativeSettings.open({
          optionAndroid: AndroidSettings.AppNotification,
          optionIOS: IOSSettings.App,
        });
      }
    } else {
      try {
        await registerDevice();
        setShowNotifications(true);
        toggleNotifications();
      } catch (error) {
        const { value } = await Dialog.confirm({
          title: "Enable Notifications",
          message:
            "You've blocked notifications. Please enable them for this app in your device settings. Would you like to go to settings now?",
          okButtonTitle: "Yes",
          cancelButtonTitle: "No",
        });

        if (value) {
          await NativeSettings.open({
            optionAndroid: AndroidSettings.AppNotification,
            optionIOS: IOSSettings.App,
          });
        }

        setShowNotifications(false);
      }
    }
  };

  return (
    <div className="profile-editor-view-wrapper">
      <div className="profile-editor-view-profiler">
        <div className="profile-editor-view-avatar-container">
          <div
            className="user-avatar-container"
            style={{
              width: "80px",
              height: "80px",
              backgroundImage: avatarSrc
                ? `url(${avatarSrc})`
                : `url("data:image/svg+xml,${encodeURIComponent(svg)}")`,
              backgroundSize: "cover",
            }}
          />
        </div>
        <div className="edit-media-container-profile" onClick={toggleModal}>
          <span className="edit-media-container-text-profile">Edit Media</span>
        </div>
        <span className="profile-editor-view-secondary-text">Display Name</span>
        <SettingButton
          primaryText={formatDisplayName(user.displayName)}
          Icon={() => (
            <DisplayNameIcon className="wallet-connected-svglogo-fill" />
          )}
          onClick={() => setNestedDisplayNameDialog(true)}
        />
        {nestedDisplayNameDialog && (
          <Popover
            direction="right"
            setOpenDialog={setNestedDisplayNameDialog}
            headerText="Display Name"
          >
            <DisplayName />
          </Popover>
        )}
        <div className="account-management-settings">
          <span className="profile-editor-view-secondary-text">
            Account Management
          </span>
          <div className="wallet-connected-notification-settings-container">
            <div className="wallet-connected-icon">
              <NotificationBing className="wallet-connected-svglogo-fill" />
            </div>
            <div className="wallet-connected-settings-text-container-notifications">
              <span className="wallet-connected-settings-primary-text">
                Notifications
              </span>
            </div>
            <Toggle isActive={showNotifications} onToggle={handleToggle} />
          </div>
          {walletExists && (
            <SettingButton
              primaryText={"Wallet"}
              Icon={() => <Wallet className="wallet-connected-svglogo-fill" />}
              onClick={() => setNestedWalletDialog(true)}
            />
          )}
          {nestedWalletDialog && (
            <Popover
              direction="right"
              setOpenDialog={setNestedWalletDialog}
              headerText="Wallet Settings"
            >
              <WalletSettings closeAllDialogs={closeProfileEditor} />
            </Popover>
          )}
          <SettingButton
            primaryText={"Account Deletion"}
            Icon={() => (
              <DeleteProfile className="wallet-connected-svglogo-fill" />
            )}
            onClick={() => setNestedAccountDeletionDialog(true)}
          />
          {nestedAccountDeletionDialog && (
            <Popover
              direction="right"
              setOpenDialog={setNestedAccountDeletionDialog}
              headerText="Account Deactivation"
            >
              <AccountDeletion isPopover={true} />
            </Popover>
          )}
        </div>
        <HighlightsContainer
          text="Get ready to claim your .waiv username soon and build out your customizable profile."
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: "-200px -200px",
            backgroundSize: "200%",
          }}
        />
        <Modal isOpen={isModalOpen} onClose={toggleModal}>
          <div className="create-space-media-modal-content">
            <input
              ref={uploadImageRef}
              id="imageInput"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={uploadImage}
            />
            <div
              onClick={() => document.getElementById("imageInput").click()}
              className="create-space-media-button-upload"
            >
              <span className="create-space-media-button-text-upload">
                Upload Avatar
              </span>
            </div>
            {avatarSrc && (
              <div
                onClick={() => handleAvatarUpload(null)}
                className="create-space-media-button"
              >
                <span className="create-space-media-button-text">
                  Remove Avatar
                </span>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}

ProfileEditor.propTypes = {
  userId: PropTypes.string,
};

export default ProfileEditor;
