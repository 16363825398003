import React from "react";
import "./SidebarAvatarMobile.scss";

const SidebarAvatarMobile = React.forwardRef(
  ({ footer, onClick, iconSrc, notificationBadge }) => {
    return (
      <div className="sidebar-avatar-mobile" onClick={onClick}>
        {iconSrc}
        {footer}
        <div className="sidebar-avatar-mobile-notification-badge">
          {notificationBadge}
        </div>
      </div>
    );
  }
);

export default SidebarAvatarMobile;
