import React from "react";
import { twemojify } from "../../../util/twemojify";
import initMatrix from "../../../client/initMatrix";
import { getUsername, getUsernameOfRoomMember } from "../../../util/matrixUtil";
import { formatDisplayName } from "../../../util/formatDisplayName";

function getTimelineJSXMessages() {
  return {
    join(user) {
      return (
        <span className="chat-message-content">
          {twemojify(user)} joined the room
        </span>
      );
    },
    leave(user, reason) {
      const reasonMsg = typeof reason === "string" ? `: ${reason}` : "";
      return (
        <span className="chat-message-content">
          {twemojify(user)} left the room {twemojify(reasonMsg)}
        </span>
      );
    },
    invite(inviter, user) {
      return (
        <span className="chat-message-content">
          {twemojify(inviter)} invited {twemojify(user)}
        </span>
      );
    },
    cancelInvite(inviter, user) {
      return (
        <span className="chat-message-content">
          {twemojify(inviter)} canceled {twemojify(user)}'s invite
        </span>
      );
    },
    rejectInvite(user) {
      return (
        <span className="chat-message-content">
          {twemojify(user)} rejected the invitation
        </span>
      );
    },
    nameSets(user, newName) {
      return (
        <span className="chat-message-content">
          {twemojify(user)} set display name to {twemojify(newName)}
        </span>
      );
    },
    nameChanged(user, newName) {
      return (
        <span className="chat-message-content">
          {twemojify(user)} changed their display name to {twemojify(newName)}
        </span>
      );
    },
  };
}

function getUsersActionJsx(roomId, userIds, actionStr) {
  const room = initMatrix.matrixClient.getRoom(roomId);
  const getUserDisplayName = (userId) => {
    if (room?.getMember(userId))
      return getUsernameOfRoomMember(room.getMember(userId));
    return getUsername(userId);
  };
  const getUserJSX = (userId) => (
    <span className="timeline-change-text">
      {twemojify(getUserDisplayName(userId))}
    </span>
  );
  if (!Array.isArray(userIds)) return "Idle";
  if (userIds.length === 0) return "Idle";
  const MAX_VISIBLE_COUNT = 3;

  const u1Jsx = getUserJSX(userIds[0]);
  // eslint-disable-next-line react/jsx-one-expression-per-line
  if (userIds.length === 1)
    return (
      <>
        {u1Jsx} is {actionStr}
      </>
    );

  const u2Jsx = getUserJSX(userIds[1]);
  // eslint-disable-next-line react/jsx-one-expression-per-line
  if (userIds.length === 2)
    return (
      <>
        {u1Jsx} and {u2Jsx} are {actionStr}
      </>
    );

  const u3Jsx = getUserJSX(userIds[2]);
  if (userIds.length === 3) {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return (
      <>
        {u1Jsx}, {u2Jsx} and {u3Jsx} are {actionStr}
      </>
    );
  }

  const othersCount = userIds.length - MAX_VISIBLE_COUNT;
  // eslint-disable-next-line react/jsx-one-expression-per-line
  return (
    <>
      {u1Jsx}, {u2Jsx}, {u3Jsx} and {othersCount} others are {actionStr}
    </>
  );
}

function parseTimelineChange(mEvent) {
  const tJSXMsgs = getTimelineJSXMessages();
  const makeReturnObj = (variant, content) => ({
    variant,
    content,
  });
  const content = mEvent.getContent();
  const prevContent = mEvent.getPrevContent();
  const sender = mEvent.getSender();
  const senderName = formatDisplayName(getUsername(sender));
  const userName = formatDisplayName(getUsername(mEvent.getStateKey()));

  switch (content.membership) {
    case "invite":
      return makeReturnObj("invite", tJSXMsgs.invite(senderName, userName));
    case "join":
      if (prevContent.membership === "join") {
        if (content.displayname !== prevContent.displayname) {
          if (typeof content.displayname === "undefined")
            return makeReturnObj(
              "avatar",
              tJSXMsgs.nameRemoved(
                sender,
                formatDisplayName(prevContent.displayname)
              )
            );
          if (typeof prevContent.displayname === "undefined")
            return makeReturnObj(
              "avatar",
              tJSXMsgs.nameSets(sender, formatDisplayName(content.displayname))
            );
          return makeReturnObj(
            "avatar",
            tJSXMsgs.nameChanged(
              formatDisplayName(prevContent.displayname),
              formatDisplayName(content.displayname)
            )
          );
        }
        return null;
      }
      return makeReturnObj("join", tJSXMsgs.join(senderName));
    case "leave":
      if (sender === mEvent.getStateKey()) {
        switch (prevContent.membership) {
          case "invite":
            return makeReturnObj(
              "invite-cancel",
              tJSXMsgs.rejectInvite(senderName)
            );
          default:
            return makeReturnObj(
              "leave",
              tJSXMsgs.leave(senderName, content.reason)
            );
        }
      }
      switch (prevContent.membership) {
        case "invite":
          return makeReturnObj(
            "invite-cancel",
            tJSXMsgs.cancelInvite(senderName, userName)
          );
      }
    default:
      return null;
  }
}

export { getTimelineJSXMessages, getUsersActionJsx, parseTimelineChange };
