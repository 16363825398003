import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "./RoomVisibility.scss";
import initMatrix from "../../../client/initMatrix";
import { ReactComponent as HashGlobeIC } from "../../assets/svg/hash-nostroke.svg";
import { ReactComponent as HashLockIC } from "../../assets/svg/lock.svg";
import { ReactComponent as SpaceLockIC } from "../../assets/svg/lock.svg";
import { ReactComponent as SpaceGlobeIC } from "../../assets/svg/hash-nostroke.svg";

const visibility = {
  INVITE: "invite",
  PUBLIC: "public",
};

function setJoinRule(roomId, type) {
  const mx = initMatrix.matrixClient;
  let allow;
  if (type === visibility.RESTRICTED) {
    const { currentState } = mx.getRoom(roomId);
    const mEvent = currentState.getStateEvents("m.space.parent")[0];
    if (!mEvent) return Promise.resolve(undefined);

    allow = [
      {
        room_id: mEvent.getStateKey(),
        type: "m.room_membership",
      },
    ];
  }

  return mx.sendStateEvent(roomId, "m.room.join_rules", {
    join_rule: type,
    allow,
  });
}

function useVisibility(roomId) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);

  const [activeType, setActiveType] = useState(room.getJoinRule());
  useEffect(() => setActiveType(room.getJoinRule()), [roomId]);

  const setNotification = useCallback(
    (item) => {
      if (item.type === activeType.type) return;
      setActiveType(item.type);
      setJoinRule(roomId, item.type);
    },
    [activeType, roomId]
  );

  return [activeType, setNotification];
}

function RoomVisibility({ roomId }) {
  const [activeType, setVisibility] = useVisibility(roomId);
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const isSpace = room.isSpaceRoom();
  const { currentState } = room;

  const noSpaceParent =
    currentState.getStateEvents("m.space.parent").length === 0;
  const mCreate = currentState.getStateEvents("m.room.create")[0]?.getContent();
  const roomVersion = Number(mCreate?.room_version ?? 0);

  const myPowerlevel = room.getMember(mx.getUserId())?.powerLevel || 0;
  const canChange = room.currentState.hasSufficientPowerLevelFor(
    "state_default",
    myPowerlevel
  );

  const items = [
    {
      iconSrc: isSpace ? SpaceLockIC : HashLockIC,
      text: "Private Room",
      type: visibility.INVITE,
      unsupported: false,
    },
    {
      iconSrc: isSpace ? SpaceGlobeIC : HashGlobeIC,
      text: "Public Room",
      type: visibility.PUBLIC,
      unsupported: false,
    },
  ];

  return (
    <div className="room-visibility-button-wrapper">
      {items.map((item) => {
        const IconSrc =
          typeof item.iconSrc === "string" ? (
            <img src={item.iconSrc} alt="" className="icon-style" />
          ) : (
            item.iconSrc
          );
        return (
          <div
            className="room-visibility-room-type-selector"
            onClick={() => setVisibility(item)}
            key={item.type}
          >
            <IconSrc className="icon-style" />
            <span className="room-visibility-text">{item.text}</span>
            <div
              className={`create-room-selector-outer-circle ${
                activeType === item.type ? "" : "inactive"
              }`}
            >
              <div
                className={`create-room-selector-inner-circle ${
                  activeType === item.type ? "" : "inactive"
                }`}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

RoomVisibility.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default RoomVisibility;
