import React, { useState, useEffect, useCallback } from "react";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import ChatRoom from "./ChatRoom";
import ChatFilter from "./ChatFilter";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import Modal from "../../atoms/modal/Modal";
import RoomOptions from "../room-options/RoomOptions";
import "./Chats.scss";
import { parseTimelineChange } from "../../organisms/room/common";
import ReferralScoreIcon from "../../assets/svg/ReferralScoreIcon";
import NoChatIcon from "../../assets/svg/NoChat";
import usernameColorMXID from "../../../util/usernameColorMXID";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import CreateNewRoom from "../create-new-room/CreateNewRoom";
import Popover from "../../atoms/popover/Popover";
import SearchBarMobile from "../../components/navbar/SearchBarMobile";
import CreateRoom from "../../organisms/create-room/CreateRoom";

const Chats = ({ matrixUser }) => {
  const [allChatList, setAllChatList] = useState([]);
  const [chatList, setChatList] = useState([]);

  const [isRoomOptionsModalOpen, setIsRoomOptionsModalOpen] = useState(false);
  const [isCreateNewRoomModalOpen, setIsCreateNewRoomModalOpen] =
    useState(false);
  const [isDMSearchPopoverOpen, setIsDMSearchPopoverOpen] = useState(false);
  const [isCreateRoomPopoverOpen, setIsCreateRoomPopoverOpen] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const mx = initMatrix.matrixClient;
  const { notifications, roomList } = initMatrix;
  const { setRoomId, setDmRoomId } = useRoomSelect();

  const colorStyle = usernameColorMXID(matrixUser.userId);

  const getLatestMessageEvent = (room) => {
    const events = room.timeline;
    for (let i = events.length - 1; i >= 0; i--) {
      const event = events[i];
      const eventType = event.getType();

      if (eventType === "m.room.message" || eventType === "m.room.member") {
        const isRedacted = !!event.event.unsigned?.redacted_because;
        const isEditedMessageFallback = event
          .getContent()
          ?.body?.startsWith("*");

        if (isRedacted || isEditedMessageFallback) {
          continue;
        }

        return event;
      }
    }
    return null;
  };

  const createChatData = useCallback(
    (room) => {
      const roomId = room.roomId;
      const latestEvent = getLatestMessageEvent(room);
      const isDM = roomList.directs.has(roomId);

      let avatarUrl = room.getAvatarUrl
        ? room.getAvatarUrl(mx.baseUrl, 40, 40, "crop")
        : null;

      let user = null;
      if (isDM && room.summaryHeroes && room.summaryHeroes.length > 0) {
        const userId = room.summaryHeroes[0];
        user = mx.getUser(userId);
      }

      if (isDM && !avatarUrl) {
        avatarUrl = room
          .getAvatarFallbackMember()
          ?.getAvatarUrl(mx.baseUrl, 40, 40, "crop");
      }

      let latestMessage = null;
      let senderDisplayName = "Unknown"; // Default value

      if (latestEvent) {
        const eventType = latestEvent.getType(); // Get event type
        const timestamp = latestEvent.getDate();
        const senderId = latestEvent.getSender();

        // Fetch the user using senderId and get the display name
        const senderUser = mx.getUser(senderId);
        if (senderUser) {
          senderDisplayName = senderUser.displayName || senderId;
        }

        if (eventType === "m.room.message") {
          latestMessage = {
            content: latestEvent.getContent().body,
            sender: senderDisplayName, // Use display name here
            timestamp: timestamp,
            eventType: eventType, // Add eventType to the message object
          };
        } else if (eventType === "m.room.member") {
          const timelineChange = parseTimelineChange(latestEvent);
          if (timelineChange && timelineChange.content) {
            latestMessage = {
              content: timelineChange.content,
              sender: senderDisplayName, // Use display name here
              timestamp: timestamp,
              eventType: eventType, // Add eventType to the message object
            };
          }
        }
      }

      return {
        id: roomId,
        name: room.name,
        displayName: isDM && user ? user.displayName : room.name,
        avatar: avatarUrl,
        user: user,
        latestMessage: latestMessage,
        unreadCount: notifications.getTotalNoti(roomId),
        isDM: isDM,
        isRoom: roomList.rooms.has(roomId),
      };
    },
    [mx, notifications, roomList.directs, roomList.rooms]
  );

  const updateChatList = useCallback(() => {
    const directs = Array.from(roomList.directs);
    const rooms = Array.from(roomList.rooms);

    const filterOrphanRooms = (roomId) => roomList.isOrphan(roomId);

    const excludeGlobalFeedRoom = (roomId) => {
      return roomId !== import.meta.env.VITE_GLOBAL_FEED_ROOM_ID;
    };

    const allRooms = [...directs, ...rooms]
      .filter(filterOrphanRooms)
      .filter(excludeGlobalFeedRoom)
      .map((roomId) => mx.getRoom(roomId))
      .filter((room) => room != null);

    const allChatData = allRooms.map(createChatData);

    // Update the complete chat list
    setAllChatList(allChatData);

    // Apply the selected filter to create the filtered chat list
    let filteredChatData = [...allChatData];
    if (selectedFilter === "Unread") {
      filteredChatData = filteredChatData.filter(
        (chat) => chat.unreadCount > 0
      );
    } else if (selectedFilter === "Rooms") {
      filteredChatData = filteredChatData.filter((chat) => chat.isRoom);
    } else if (selectedFilter === "DMs") {
      filteredChatData = filteredChatData.filter((chat) => chat.isDM);
    }

    // Sort the filtered chat data
    filteredChatData.sort((a, b) => {
      const aUnread = a.unreadCount > 0 ? 1 : 0;
      const bUnread = b.unreadCount > 0 ? 1 : 0;
      if (aUnread !== bUnread) {
        return bUnread - aUnread;
      }
      const aTime = a.latestMessage?.timestamp?.getTime() || 0;
      const bTime = b.latestMessage?.timestamp?.getTime() || 0;
      return bTime - aTime;
    });

    setChatList(filteredChatData);
  }, [createChatData, mx, roomList, selectedFilter]);

  useEffect(() => {
    updateChatList();

    const handleRoomTimeline = (event, room, toStartOfTimeline) => {
      if (toStartOfTimeline) return;
      if (
        event.getType() === "m.room.message" ||
        event.getType() === "m.room.member"
      ) {
        updateChatList();
      }
    };

    const handleNewRoom = (room) => {
      updateChatList();
    };

    mx.on("Room.timeline", handleRoomTimeline);
    mx.on("Room", handleNewRoom);

    const handleNotiChanged = (roomId) => {
      updateChatList();
    };

    const handleRoomListUpdate = () => {
      updateChatList();
    };

    notifications.on(cons.events.notifications.NOTI_CHANGED, handleNotiChanged);
    notifications.on(cons.events.notifications.MUTE_TOGGLED, handleNotiChanged);
    roomList.on(cons.events.roomList.ROOMLIST_UPDATED, handleRoomListUpdate);

    return () => {
      mx.off("Room.timeline", handleRoomTimeline);
      mx.off("Room", handleNewRoom);
      notifications.off(
        cons.events.notifications.NOTI_CHANGED,
        handleNotiChanged
      );
      notifications.off(
        cons.events.notifications.MUTE_TOGGLED,
        handleNotiChanged
      );
      roomList.off(cons.events.roomList.ROOMLIST_UPDATED, handleRoomListUpdate);
    };
  }, [updateChatList, mx, notifications, roomList]);

  // Re-run updateChatList whenever the selectedFilter changes
  useEffect(() => {
    updateChatList();
  }, [selectedFilter, updateChatList]);

  const handleChatClick = (roomId) => {
    const isDM = roomList.directs.has(roomId);

    if (isDM) {
      setDmRoomId(roomId);
    } else {
      setRoomId(roomId);
    }
  };

  const handleMoreClick = (roomId) => {
    setSelectedRoomId(roomId);
    setIsRoomOptionsModalOpen(true);
  };

  const handleCloseRoomOptionsModal = () => {
    setIsRoomOptionsModalOpen(false);
  };

  const handleCreateNewRoomClick = () => {
    setIsCreateNewRoomModalOpen(true);
  };

  const handleCloseCreateNewRoomModal = () => {
    setIsCreateNewRoomModalOpen(false);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  // Functions to control popovers
  const handleOpenDMSearchPopover = () => {
    setIsDMSearchPopoverOpen(true);
  };

  const handleCloseDMSearchPopover = () => {
    setIsDMSearchPopoverOpen(false);
  };

  const handleOpenCreateRoomPopover = () => {
    setIsCreateRoomPopoverOpen(true);
  };

  const handleCloseCreateRoomPopover = () => {
    setIsCreateRoomPopoverOpen(false);
  };

  return (
    <div className="chats-container">
      <div className="chat-header-container">
        <span className="chat-header-text">Chats</span>
        {allChatList.length !== 0 && (
          <div className="add-chat" onClick={handleCreateNewRoomClick}>
            <Plus className="add-chat-icon" />
          </div>
        )}
      </div>
      <div className="chat-filters">
        {["All", "Unread", "Rooms", "DMs"].map((filter) => (
          <ChatFilter
            key={filter}
            label={filter}
            isActive={selectedFilter === filter}
            onClick={() => handleFilterClick(filter)}
          />
        ))}
      </div>
      <div className="chat-list">
        {allChatList.length === 0 ? (
          <div className="no-chat-container">
            <div className="no-chat-icon-container">
              <ReferralScoreIcon
                colorStyle={colorStyle}
                height={56}
                width={56}
              />
            </div>
            <span className="no-chat-title-text">
              Start a chat and start talking with friends
            </span>
            <div className="create-new-chat" onClick={handleCreateNewRoomClick}>
              <span className="create-new-chat-text">Start Chat</span>
            </div>
          </div>
        ) : chatList.length === 0 ? (
          <div className="no-chat-container">
            <div className="no-chat-icon-container">
              <NoChatIcon colorStyle={colorStyle} height={56} width={56} />
            </div>
            <span className="no-chat-title-text">No chats here.</span>
          </div>
        ) : (
          chatList.map((chat) => (
            <ChatRoom
              key={chat.id}
              chat={chat}
              onChatClick={handleChatClick}
              onMoreClick={handleMoreClick}
            />
          ))
        )}
      </div>
      <Modal
        isOpen={isRoomOptionsModalOpen}
        onClose={handleCloseRoomOptionsModal}
      >
        <RoomOptions
          roomId={selectedRoomId}
          afterOptionSelect={handleCloseRoomOptionsModal}
        />
      </Modal>
      <Modal
        isOpen={isCreateNewRoomModalOpen}
        onClose={handleCloseCreateNewRoomModal}
        zIndex={99}
      >
        <CreateNewRoom
          afterOptionSelect={handleCloseCreateNewRoomModal}
          onOpenDMSearchPopover={handleOpenDMSearchPopover}
          onOpenCreateRoomPopover={handleOpenCreateRoomPopover}
        />
      </Modal>
      {isDMSearchPopoverOpen && (
        <Popover
          direction="right"
          setOpenDialog={setIsDMSearchPopoverOpen}
          headerText="Search"
        >
          <SearchBarMobile onClose={handleCloseDMSearchPopover} />
        </Popover>
      )}
      {isCreateRoomPopoverOpen && (
        <Popover
          direction="right"
          setOpenDialog={setIsCreateRoomPopoverOpen}
          headerText="Create Room"
        >
          <CreateRoom
            setOpenDialog={handleCloseCreateRoomPopover}
            parentId={null}
            isSpace={false}
          />
        </Popover>
      )}
    </div>
  );
};

export default Chats;
