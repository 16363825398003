import React from "react";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { formatTimeAgo } from "../../../util/formatTimeAgo";
import { formatDisplayName } from "../../../util/formatDisplayName";
import usernameColorMXID from "../../../util/usernameColorMXID";
import { ReactComponent as ArrowBottom } from "../../assets/svg/vote.svg";

const PostComments = ({
  topComments,
  avatarUrl,
  user,
  globalFeed,
  handleVote,
  getDisplayName,
}) => {
  return (
    <div className="post-comments">
      {topComments.map((comment) => {
        const commentId = comment.getId();
        const commentContent = comment.getContent().body;
        const commentSender = comment.getSender();
        const commentTimestamp = formatTimeAgo(comment.getTs());
        const commentDisplayName = formatDisplayName(
          getDisplayName(commentSender)
        );
        const commentColorStyle = usernameColorMXID(commentSender);
        const commentIsGradient =
          commentColorStyle.startsWith("linear-gradient");
        const commentTextStyle = commentIsGradient
          ? {
              background: commentColorStyle,
              WebkitBackgroundClip: "text",
              color: "transparent",
            }
          : { color: commentColorStyle };

        const commentUpvoteCount = globalFeed.getUpvoteCount(
          commentId,
          "comments"
        );
        const commentDownvoteCount = globalFeed.getDownvoteCount(
          commentId,
          "comments"
        );
        const commentCombinedCount = commentUpvoteCount - commentDownvoteCount;
        const commentUserVote = globalFeed.getUserVote(
          commentId,
          user.userId,
          "comments"
        );

        return (
          <div key={commentId} className="comment-container">
            <div>
              <UserAvatar
                size={22}
                userId={commentSender}
                imageSrc={avatarUrl}
              />
            </div>
            <div className="comment-content-container">
              <div className="comment-header-text-container">
                <div className="comment-header-container">
                  <span className="post-author-comment" style={commentTextStyle}>
                    {commentDisplayName}
                  </span>
                  <span className="post-timestamp">{commentTimestamp}</span>
                </div>
                <div>
                  <span className="comment-content">{commentContent}</span>
                </div>
              </div>
              <div className="comment-actions">
                <div className="vote-options">
                  <div
                    className={`vote-button ${
                      commentUserVote !== null && commentUserVote !== "upvote"
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => handleVote(commentId, "upvote", "comments")}
                  >
                    <ArrowBottom
                      className={`upvote-svg ${
                        commentUserVote === "upvote" ? "selected" : ""
                      }`}
                    />
                  </div>
                  <span className="vote-count">{commentCombinedCount}</span>
                  <div
                    className={`vote-button ${
                      commentUserVote !== null && commentUserVote !== "downvote"
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() =>
                      handleVote(commentId, "downvote", "comments")
                    }
                  >
                    <ArrowBottom
                      className={`downvote-svg ${
                        commentUserVote === "downvote" ? "selected" : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PostComments;
