import React, { useRef, useEffect, forwardRef, useState } from "react";
import "./CustomInput.scss";
import { ReactComponent as Markdown } from "../../assets/svg/markdown.svg";

const CustomInput = forwardRef(
  (
    {
      type = "text",
      placeholder = "",
      value = "",
      onChange,
      autoFocus = false,
      resizable = false,
      textTransform = false,
      maxHeight,
      minHeight,
      height,
      info = false,
      scrollToInput,
      ...props
    },
    ref
  ) => {
    const textareaRef = useRef(null);
    const inputRef = useRef(null);
    const [hasFocused, setHasFocused] = useState(false);

    useEffect(() => {
      if (resizable && textareaRef.current) {
        const textarea = textareaRef.current;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }, [value, resizable]);

    useEffect(() => {
      if (!hasFocused) {
        if (Array.isArray(autoFocus) && autoFocus[0] && inputRef.current) {
          const timer = setTimeout(() => {
            inputRef.current.focus();
            setHasFocused(true);
          }, autoFocus[1]);
          return () => clearTimeout(timer);
        } else if (autoFocus && inputRef.current) {
          inputRef.current.focus();
          setHasFocused(true);
        }
      }
    }, [autoFocus, inputRef, hasFocused]);

    const handleInputChange = (e) => {
      const { value } = e.target;
      onChange({
        ...e,
        target: {
          ...e.target,
          value: textTransform ? value.toUpperCase() : value,
        },
      });
    };

    const handleInputFocus = () => {
      if (scrollToInput && inputRef.current) {
        scrollToInput(inputRef.current);
      }
    };

    const handleTextAreaFocus = () => {
      if (scrollToInput && textareaRef.current) {
        scrollToInput(textareaRef.current);
      }
    };

    return (
      <div className="custom-input-container">
        {info ? (
          <div className="custom-input-info-container">
            <textarea
              ref={textareaRef}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={handleInputChange}
              onFocus={handleTextAreaFocus}
              className="custom-input-info"
              style={{ maxHeight, minHeight, height }}
              {...props}
            />
            <div className="custom-input-info-footer">
              <span className="custom-input-info-footer-text">Markdown</span>
              <Markdown className="custom-input-info-footer-svg-icon" />
            </div>
          </div>
        ) : resizable ? (
          <textarea
            ref={textareaRef}
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
            onFocus={handleTextAreaFocus}
            className="custom-textarea resizable"
            style={{ maxHeight, minHeight, height }}
            {...props}
          />
        ) : (
          <input
            ref={inputRef}
            onFocus={handleInputFocus}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
            className="custom-input"
            style={{ maxHeight, minHeight, height }}
            {...props}
          />
        )}
      </div>
    );
  }
);

export default CustomInput;
