import { useState, useEffect } from "react";
import { ethers } from "ethers";

const useBalance = (walletAddress, network = "mainnet") => {
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const alchemyApiKey = import.meta.env.VITE_ALCHEMY_API;

    const networks = {
      mainnet: `https://eth-mainnet.g.alchemy.com/v2/${alchemyApiKey}`,
      sepolia: `https://eth-sepolia.g.alchemy.com/v2/${alchemyApiKey}`,
    };

    const providerUrl = networks[network];
    if (!providerUrl) {
      setError("Unsupported network");
      return;
    }

    const provider = new ethers.JsonRpcProvider(providerUrl);

    const fetchBalance = async () => {
      try {
        const balance = await provider.getBalance(walletAddress);
        const balanceInEther = ethers.formatEther(balance);
        setBalance(balanceInEther);
      } catch (err) {
        setError(err.message);
      }
    };

    if (walletAddress) {
      fetchBalance();
    }
  }, [walletAddress, network]);

  return { balance, error };
};

export default useBalance;
