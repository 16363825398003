import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "./RoomMembers.scss";
import initMatrix from "../../../client/initMatrix";
import {
  getUsernameOfRoomMember,
  getPowerLabel,
} from "../../../util/matrixUtil";
import AsyncSearch from "../../../util/AsyncSearch";
import { memberByAtoZ, memberByPowerLevel } from "../../../util/sort";
import CustomInput from "../../atoms/input/CustomInput";
import PeopleSelector from "../people-selector/PeopleSelector";
import ProfileViewerSettings from "../../organisms/profile-viewer/ProfileViewerSettings";
import Modal from "../../atoms/modal/Modal";

const PER_PAGE_MEMBER = 50;

function normalizeMembers(members) {
  const mx = initMatrix.matrixClient;
  return members.map((member) => ({
    userId: member.userId,
    name: getUsernameOfRoomMember(member),
    username: member.userId.slice(1, member.userId.indexOf(":")),
    avatarSrc: member.getAvatarUrl(mx.baseUrl, 24, 24, "crop"),
    peopleRole: getPowerLabel(member.powerLevel).label,
    powerLevel: member.powerLevel,
  }));
}

function RoomMembers({ roomId, setNestedMembersDialog, setOpenRoomSettingsDialog }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const [joinMembers, setJoinMembers] = useState([]);
  const [banMembers, setBanMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMembers, setSearchMembers] = useState({ join: null, ban: null });
  const [asyncSearch] = useState(new AsyncSearch());
  const [itemCount, setItemCount] = useState(PER_PAGE_MEMBER);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    let isMounted = true;
    let isLoadingMembers = false;

    const updateMemberList = () => {
      if (isLoadingMembers) return;
      isLoadingMembers = true;
      room.loadMembersIfNeeded().then(() => {
        if (!isMounted) return;
        isLoadingMembers = false;
        const joinMemberList = normalizeMembers(
          room
            .getMembersWithMembership("join")
            .sort(memberByAtoZ)
            .sort(memberByPowerLevel)
        );
        const banMemberList = normalizeMembers(
          room
            .getMembersWithMembership("ban")
            .sort(memberByAtoZ)
            .sort(memberByPowerLevel)
        );
        setJoinMembers(joinMemberList);
        setBanMembers(banMemberList);
      });
    };

    updateMemberList();

    mx.on("RoomMember.membership", updateMemberList);
    mx.on("RoomMember.powerLevel", updateMemberList);
    return () => {
      isMounted = false;
      mx.removeListener("RoomMember.membership", updateMemberList);
      mx.removeListener("RoomMember.powerLevel", updateMemberList);
    };
  }, [roomId]);

  useEffect(() => {
    asyncSearch.setup([...joinMembers, ...banMembers], {
      keys: ["name", "username", "userId"],
      limit: PER_PAGE_MEMBER,
    });
    if (searchTerm) {
      asyncSearch.search(searchTerm);
    }
  }, [joinMembers, banMembers, searchTerm]);

  useEffect(() => {
    const handleSearchData = (data, term) => {
      const joinData = data.filter((member) => joinMembers.includes(member));
      const banData = data.filter((member) => banMembers.includes(member));
      setSearchMembers({
        join: { data: joinData, term },
        ban: { data: banData, term },
      });
    };
    asyncSearch.on(asyncSearch.RESULT_SENT, handleSearchData);
    return () => {
      asyncSearch.removeListener(asyncSearch.RESULT_SENT, handleSearchData);
    };
  }, [asyncSearch, joinMembers, banMembers]);

  const handleSearch = (e) => {
    const term = e.target.value.trim();
    setSearchTerm(term);
    if (term === "" || term === undefined) {
      setSearchMembers({ join: null, ban: null });
    } else {
      asyncSearch.search(term);
    }
  };

  useEffect(() => {
    setItemCount(PER_PAGE_MEMBER);
  }, [searchMembers]);

  const loadMorePeople = () => {
    setItemCount(itemCount + PER_PAGE_MEMBER);
  };

  const toggleModal = (member) => {
    setSelectedMember(member);
  };

  const renderMemberList = (members, membershipType) => {
    const mList =
      searchMembers[membershipType]?.data || members.slice(0, itemCount);
    const myPowerLevel = room.getMember(mx.getUserId())?.powerLevel || 0;
    const canBanAnyone = room.currentState.hasSufficientPowerLevelFor(
      "ban",
      myPowerLevel
    );
    if (membershipType === "ban" && !canBanAnyone) {
      return null;
    }
    return (
      <>
        <div className="general-settings-room-options">
          <span className="room-members-text-header-text">
            {`${membershipType === "join" ? "Joined" : "Banned"} Member${
              searchMembers[membershipType]
                ? mList.length !== 1
                  ? "s"
                  : ""
                : members.length !== 1
                ? "s"
                : ""
            } - ${
              searchMembers[membershipType]
                ? `${mList.length} member${mList.length !== 1 ? "s" : ""}`
                : `${members.length} member${members.length !== 1 ? "s" : ""}`
            }`}
          </span>
        </div>
        <div>
          {mList.map((member) => (
            <div key={member.userId}>
              <PeopleSelector
                onClick={() => toggleModal(member)}
                userId={member.userId}
                name={member.name}
                peopleRole={member.peopleRole}
                roomId={roomId}
              />
            </div>
          ))}
          {mList.length === 0 && (
            <div className="general-settings-room-options">
              <span className="space-landing-space-data-title-text">
                {searchMembers[membershipType]
                  ? `No results found for "${searchMembers[membershipType].term}"`
                  : "No members to display"}
              </span>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="search-bar-search-results-container">
      <div className="search-bar-mobile-results">
        <span className="search-bar-mobile-results-header">Search Member</span>
      </div>
      <CustomInput
        placeholder="Search for member"
        onChange={handleSearch}
        autoFocus={[true, 250]} 
        value={searchTerm}
      />
      {renderMemberList(joinMembers, "join")}
      {renderMemberList(banMembers, "ban")}
      {(joinMembers.length > itemCount || banMembers.length > itemCount) &&
        searchMembers.join === null &&
        searchMembers.ban === null && (
          <div
            onClick={loadMorePeople}
            className="room-search-search-container-button-load"
          >
            <span className="room-search-search-text">Load More</span>
          </div>
        )}
      {selectedMember && (
        <Modal
          isOpen={!!selectedMember}
          onClose={() => setSelectedMember(null)}
        >
          <ProfileViewerSettings
            userId={selectedMember.userId}
            roomId={roomId}
            onClose={() => setSelectedMember(null)}
            setOpenRoomSettingsDialog={setOpenRoomSettingsDialog}
            setNestedMembersDialog={setNestedMembersDialog}
          />
        </Modal>
      )}
    </div>
  );
}

RoomMembers.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default RoomMembers;
