import React, { createContext, useState, useEffect, useContext } from "react";
import initMatrix from "../../client/initMatrix";
import cons from "../../client/state/cons";
import { useSelectedTab } from "../hooks/useSelectedTab";
import { useAppConnection } from "./AppConnectionContext";

const DiscoverSpacesSalesContext = createContext();

export const DiscoverSpacesSalesProvider = ({ children }) => {
  const [spaces, setSpaces] = useState([]);
  const [saleRooms, setSaleRooms] = useState([]);
  const mx = initMatrix.matrixClient;
  const { isAppConnected } = useAppConnection();
  const [selectedTab] = useSelectedTab();

  const fetchBannerUrl = (roomInstance) => {
    const bannerEvent = roomInstance?.currentState.getStateEvents(
      "custom.space_banner",
      ""
    );
    return bannerEvent
      ? mx.mxcUrlToHttp(bannerEvent.getContent().url, 42, 42, "crop")
      : null;
  };

  const fetchTokenData = async (roomId) => {
    try {
      const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
      const response = await fetch(
        `${baseUrl}/_synapse/client/custom_token_data?room_id=${roomId}`
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok for room ID: ${roomId}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const fetchSaleData = async (roomId) => {
    try {
      const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
      const response = await fetch(
        `${baseUrl}/_synapse/client/custom_sale_data?room_id=${roomId}`
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok for room ID: ${roomId}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const fetchSpaces = async () => {
    const allSpaces = [];

    const fetchSpaces = async (since = null) => {
      try {
        const result = await mx.publicRooms({ limit: 100, since });

        allSpaces.push(...result.chunk);

        if (result.next_batch) {
          await fetchSpaces(result.next_batch);
        }
      } catch (error) {
        console.error(error);
      }
    };

    await fetchSpaces();

    const filteredSpaces = allSpaces.filter(
      (space) => space.room_type === "m.space"
    );

    const spacesWithTokenData = await Promise.all(
      filteredSpaces.map(async (space) => {
        const tokenData = await fetchTokenData(space.room_id);
        const spaceInstance = mx.getRoom(space.room_id);
        const avatarEvent = spaceInstance?.currentState.getStateEvents(
          "m.room.avatar",
          ""
        );
        const avatarURL = avatarEvent ? avatarEvent.getContent().url : null;
        const topicEvent = spaceInstance?.currentState.getStateEvents(
          "m.room.topic",
          ""
        );
        const topic = topicEvent ? topicEvent.getContent().topic : "No Topic";
        const bannerUrl = fetchBannerUrl(spaceInstance);

        return {
          id: space.room_id,
          name: space.name,
          tokenName: tokenData?.tokenName,
          tokenSymbol: tokenData?.tokenSymbol,
          memberCount: tokenData ? tokenData.memberCount : 0,
          tokenNewTokenizedSpace: tokenData
            ? tokenData.tokenNewTokenizedSpace
            : false,
          avatarURL: avatarURL,
          topic: topic,
          bannerUrl: bannerUrl,
        };
      })
    );

    // Filter out spaces without a tokenName
    const filteredSpacesWithTokenData = spacesWithTokenData.filter(
      (space) => space.tokenName
    );

    const fundraisingRoomsWithinSpace = await Promise.all(
      filteredSpaces.map(async (space) => {
        const saleData = await fetchSaleData(space.room_id);
        return (saleData.rooms || []).map((room) => ({
          ...room,
          spaceId: space.room_id,
        }));
      })
    );

    const nonEmptyFundraisingRooms = fundraisingRoomsWithinSpace.filter(
      (rooms) => rooms.length > 0
    );

    setSpaces(filteredSpacesWithTokenData);
    setSaleRooms(nonEmptyFundraisingRooms);
  };

  useEffect(() => {
    if ((mx && isAppConnected) || selectedTab === cons.tabs.DISCOVERY) {
      fetchSpaces();
    }
  }, [mx, isAppConnected, selectedTab]);

  return (
    <DiscoverSpacesSalesContext.Provider value={{ spaces, saleRooms }}>
      {children}
    </DiscoverSpacesSalesContext.Provider>
  );
};

export const useDiscoverSpacesSales = () => {
  return useContext(DiscoverSpacesSalesContext);
};
