import React from "react";
import NavigationLegal from "../../components/navigation/NavigationLegal";
import Header from "../../atoms/blog/Header";
import "./PrivacyPolicy.scss";
import Paragraph from "../../atoms/blog/Paragraph";

const PrivacyPolicy = ({ isPopover }) => {
  return (
    <div
      className={isPopover ? "popover-privacy-app-wrapper" : "legal-wrapper"}
    >
      {!isPopover && <NavigationLegal />}
      <Header
        text="Waivlength App - Privacy Policy"
        tagText="PRIVACY POLICY"
        secondaryText="Last update: 9 May 2024"
        marginBottom={true}
      />
      <Paragraph
        text="1. Who We Are"
        primaryText="Welcome to Waivlength, operated by Max Lambda Limited ('Waivlength,' 'we,' 'our,' or 'us'). This Privacy Policy describes how we process personal information that we collect through our Progressive Web App (PWA) available at waivlength.app (the 'App'), all related subdomains, and any other products and services made available by us that link to this Privacy Policy (collectively, the 'Product'). By accessing or using our Product, you agree to this Privacy Policy. If you do not agree with any part of this Privacy Policy, please do not use our Product."
      />
      <Paragraph
        text="2. Personal Information We Collect"
        primaryText="We collect various types of information in connection with your use of our Product. This includes information you provide to us, such as your public Ethereum address, profile picture, and display name when you create an account. We also collect communication information when you contact us through the Product, transactional data related to your blockchain transactions, and user-generated content data. Additionally, we may collect information from third parties like public sources, data providers, service providers, and third-party services that you link to your account. Information is also collected automatically, including device data, online activity data, and communication interaction data. Please note that while we allow for account deactivation, certain data associated with your account may remain within the app ecosystem."
      />
      <Paragraph
        text="3. Tracking Technologies"
        primaryText="We use cookies and similar technologies to facilitate the automatic collection of data described above. Cookies are small text files stored on user devices to record browsing activities and remember preferences. Local storage technologies like HTML5 provide cookie-equivalent functionality and can store larger amounts of data on your device. These technologies help us understand user activity, enhance the performance and functionality of our services, and ensure the technical operation of the Product."
      />
      <Paragraph
        text="4. How We Use Your Personal Information"
        primaryText="We use your personal information for various purposes. This includes providing, maintaining, and improving our Product, enabling security features, establishing and maintaining user profiles, facilitating social features, communicating with you, and providing support. We personalize your experience with the Product, analyze usage, and develop new products and services. We may send you direct communications, administer promotions and contests, and use your information for compliance and protection purposes. Additionally, we may create aggregated, de-identified, and/or anonymized data for business purposes. Please note that while we allow for account deactivation, it does not equate to the deletion of all your data from our systems."
      />
      <Paragraph
        text="5. How We Share Your Personal Information"
        primaryText="We may share your personal information with affiliates, service providers, third parties designated by you, professional advisors, authorities, and others for compliance and protection purposes. In the context of business transactions, such as mergers or acquisitions, we may disclose personal information. Your profile and other user-generated content data may be visible to other users of the Product and the public, allowing them to see, collect, and use this information."
      />
      <Paragraph
        text="6. Your Choices"
        primaryText="You have choices regarding your personal information. You can review and update your account information by logging into your account. You may opt out of marketing-related emails by following the opt-out instructions in the email. If you choose not to provide certain information, we may not be able to provide specific services. You can also limit the information we receive from third-party platforms through your account settings on those platforms. Additionally, you can request to deactivate your account. By deactivating your account, you will prevent future logins after a 90-day grace period. However, please be aware that deactivation does not remove all data associated with your account from our systems."
      />
      <Paragraph
        text="7. Other Sites and Services"
        primaryText="Our Product may contain links to websites, mobile applications, and other online services operated by third parties. These links and integrations are not endorsements, and we are not responsible for the actions of third-party services. We encourage you to read the privacy policies of other websites, mobile applications, and online services you use."
      />
      <Paragraph
        text="8. Security"
        primaryText="We employ technical, organizational, and physical safeguards to protect your personal information. However, security risk is inherent in all internet and information technologies, and we cannot guarantee the absolute security of your personal information."
      />
      <Paragraph
        text="9. Wallet and Private Key Management"
        primaryText="When you create a wallet using our application, we collect your public wallet address. Your private key is encrypted with a user-provided password and securely stored in your device's OS keychain (iOS) or keystore (Android). We do not store your private key on our servers. Your private key is held in the application's state only during active sessions and is not saved persistently anywhere else. It is your responsibility to back up your security phrase (mnemonic) to ensure you can restore your wallet if you lose your device, get a new one, or delete the app. You are solely responsible for maintaining the confidentiality of your private key and password. If you suspect that your private key has been compromised, it is your responsibility to take immediate action to secure your wallet. We employ advanced security measures to protect your private key, but we cannot guarantee absolute security."
      />
      <Paragraph
        text="10. International Data Transfers"
        primaryText="Your personal information may be transferred to and processed in countries other than your country of residence. We take steps to ensure your information is treated securely and in accordance with this Privacy Policy, regardless of where it is processed."
      />
      <Paragraph
        text="11. Age Restriction"
        primaryText="Our Product is not intended for use by anyone under 18 years of age. If we learn that we have collected personal information from a child without the required consent, we will delete the information in compliance with applicable laws."
      />
      <Paragraph
        text="12. Changes to This Privacy Policy"
        primaryText="We reserve the right to modify this Privacy Policy at any time. Changes will be posted on the Website and effective immediately upon posting. We encourage users to frequently check this page for any changes."
      />
      <Paragraph
        text="13. Contact Information"
        primaryText="If you have any questions about this Privacy Policy, please contact us at contact@waivlength.io."
      />
    </div>
  );
};

export default PrivacyPolicy;
