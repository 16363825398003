class GlobalFeedClass {
  constructor(matrixClient) {
    this.matrixClient = matrixClient;
    this.roomId = import.meta.env.VITE_GLOBAL_FEED_ROOM_ID;
    this.timeline = null;
    this.eventsMap = new Map();
    this.canPaginate = true;
    this.repostThreshold = 2;
    this.repostTimeframe = 24 * 60 * 60 * 1000;
    this.INITIAL_LIMIT = 200;
    this.POSTS_THRESHOLD = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
    this.events = [];
    this.newEvents = [];
    this.myEvents = [];
    this.lastRepostTimes = {};
    this.votes = {
      posts: {},
      comments: {},
    };
    this.comments = {};
    this.liveEventListener = null;
    this.processedEventIds = new Set();
    this.latestInitialTs = 0;
    this.originalEventIds = new Set();
  }

  async initGlobalFeed() {
    try {
      await this.ensureUserJoinedRoom();
      const { timeline, events } = await this.fetchInitialTimeline();
      this.timeline = timeline;
      const processedEvents = this.processEvents(events);
      const initialTimelineEvents = this.sortEvents(processedEvents);
      if (initialTimelineEvents.length > 0) {
        this.latestInitialTs = Math.max(
          ...initialTimelineEvents.map((e) => e.getTs())
        );
      }
      this.newEvents = [];
      this.events = initialTimelineEvents;
      return initialTimelineEvents;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async ensureUserJoinedRoom() {
    const room = this.matrixClient.getRoom(this.roomId);
    const membershipStatus = room?.getMyMembership();

    if (membershipStatus !== "join") {
      try {
        await this.matrixClient.joinRoom(this.roomId);
      } catch (error) {
        console.error(error);
      }
    }
  }

  async fetchInitialTimeline() {
    const room = this.matrixClient.getRoom(this.roomId);
    if (!room) {
      throw new Error("Room not found");
    }

    const timeline = room.getLiveTimeline();
    let allEvents = [...timeline.getEvents()];

    this.canPaginate = room.oldState.paginationToken !== null;

    const addUniqueEvents = (newEvents, existingEvents) => {
      const existingEventIds = new Set(existingEvents.map((e) => e.getId()));
      const uniqueEvents = newEvents.filter(
        (event) => !existingEventIds.has(event.getId())
      );
      return [...uniqueEvents, ...existingEvents];
    };

    while (
      this.canPaginate &&
      (allEvents.length < this.INITIAL_LIMIT || allEvents.length < 150)
    ) {
      await this.matrixClient.scrollback(room, 100);
      const newEvents = timeline.getEvents();
      allEvents = addUniqueEvents(newEvents, allEvents);
      allEvents.sort((a, b) => a.getTs() - b.getTs());

      const createEventIndex = allEvents.findIndex(
        (event) => event.getType() === "m.room.create"
      );
      if (createEventIndex !== -1) {
        allEvents = allEvents.slice(createEventIndex);
        break;
      }

      this.canPaginate = room.oldState.paginationToken !== null;
    }

    return {
      timeline,
      events: allEvents,
    };
  }

  getNewEvents() {
    const currentNewEvents = [...this.newEvents];
    const processedEvents = this.processEvents(currentNewEvents);
    const sortedNewEvents = this.sortEvents(processedEvents);
    this.events = [...sortedNewEvents, ...this.events];
    this.newEvents = [];
    return sortedNewEvents;
  }

  async fetchOldEvents() {
    const room = this.matrixClient.getRoom(this.roomId);
    if (!room || !this.timeline || !this.canPaginate) {
      return [];
    }

    try {
      const previousEventIds = new Set(this.events.map((e) => e.getId()));
      await this.matrixClient.paginateEventTimeline(this.timeline, {
        backwards: true,
        limit: 100,
      });

      const allEvents = this.timeline.getEvents();
      const oldEvents = allEvents.filter(
        (event) => !previousEventIds.has(event.getId())
      );

      const processedEvents = this.processEvents(oldEvents);
      const sortedOldEvents = this.sortEvents(processedEvents);

      this.events = [...this.events, ...sortedOldEvents];

      const createEventIndex = this.events.findIndex(
        (event) => event.getType() === "m.room.create"
      );
      if (createEventIndex !== -1) {
        this.events = this.events.slice(createEventIndex);
        this.canPaginate = false;
      } else {
        this.canPaginate = room.oldState.paginationToken !== null;
      }

      return sortedOldEvents;
    } catch (error) {
      console.error("Error fetching more events:", error);
      return [];
    }
  }

  processEvents(events) {
    let processedEvents = [];
    events.forEach((event) => {
      const eventId = event.getId();
      if (this.processedEventIds.has(eventId)) {
        return;
      }

      this.processedEventIds.add(eventId);
      const eventType = event.getType();
      let originalEventId;

      if (eventType === "m.room.message") {
        originalEventId = eventId;
        if (this.originalEventIds.has(originalEventId)) {
          return; // Skip duplicate original event
        }
        this.originalEventIds.add(originalEventId);
        this.eventsMap.set(eventId, event);
        processedEvents.push(event);
      } else if (eventType === "m.repost") {
        originalEventId = event.getContent()["m.relates_to"]?.event_id;
        const originalEvent = this.eventsMap.get(originalEventId);
        if (originalEvent) {
          if (this.originalEventIds.has(originalEventId)) {
            // Replace the original event with the repost in processedEvents
            const index = processedEvents.findIndex(
              (e) => this.getOriginalEventId(e) === originalEventId
            );
            if (index !== -1) {
              processedEvents.splice(index, 1);
            }
          }
          this.originalEventIds.add(originalEventId);
          this.eventsMap.set(eventId, event); // Map the repost event by its own ID
          processedEvents.push(event);
        }
      }

      // Handle votes
      if (eventType === "m.vote") {
        const {
          voteType,
          eventId: originalEventId,
          targetType,
        } = event.getContent();
        const voterId = event.getSender();
        const voteTarget = targetType === "comments" ? "comments" : "posts";

        if (!this.votes[voteTarget][originalEventId]) {
          this.votes[voteTarget][originalEventId] = {
            upvotes: new Set(),
            downvotes: new Set(),
          };
        }
        this.votes[voteTarget][originalEventId].upvotes.delete(voterId);
        this.votes[voteTarget][originalEventId].downvotes.delete(voterId);
        if (voteType === "upvote") {
          this.votes[voteTarget][originalEventId].upvotes.add(voterId);
        } else if (voteType === "downvote") {
          this.votes[voteTarget][originalEventId].downvotes.add(voterId);
        }
      }

      // Handle comments
      if (eventType === "m.comment") {
        const originalEventId = event.getContent()["m.relates_to"]?.event_id;
        if (!this.comments[originalEventId]) {
          this.comments[originalEventId] = [];
        }
        this.comments[originalEventId].push(event);
      }
    });

    return processedEvents;
  }

  // THIS CAN BE UPDATED FOR MY ALGO FOR SORTING
  sortEvents(events) {
    return Array.from(events).sort((a, b) => {
      // Get original events
      const originalEventA = this.getOriginalEvent(a);
      const originalEventB = this.getOriginalEvent(b);

      // Sort by votes first
      const aVotes = this.getVoteScore(originalEventA.getId());
      const bVotes = this.getVoteScore(originalEventB.getId());
      if (bVotes !== aVotes) {
        return bVotes - aVotes;
      }

      // Sort by original timestamp if votes are equal
      const aTimestamp = originalEventA.getTs();
      const bTimestamp = originalEventB.getTs();
      if (bTimestamp !== aTimestamp) {
        return bTimestamp - aTimestamp;
      }
    });
  }

  getOriginalEventId(event) {
    if (event.getType() === "m.repost") {
      return event.getContent()["m.relates_to"]?.event_id;
    } else {
      return event.getId();
    }
  }

  getEvents() {
    return this.events;
  }

  getVoteScore(eventId, targetType = "posts") {
    const votes = this.votes[targetType][eventId];
    if (!votes) return 0;
    return votes.upvotes.size - votes.downvotes.size;
  }

  async handleVote(eventId, type, userId, targetType = "posts") {
    const votes = this.votes[targetType][eventId] || {
      upvotes: new Set(),
      downvotes: new Set(),
    };
    if (votes.upvotes.has(userId) || votes.downvotes.has(userId)) {
      return false;
    }

    const content = {
      voteType: type,
      eventId: eventId,
      targetType: targetType,
    };

    try {
      await this.matrixClient.sendEvent(this.roomId, "m.vote", content);
      if (!this.votes[targetType][eventId]) {
        this.votes[targetType][eventId] = {
          upvotes: new Set(),
          downvotes: new Set(),
        };
      }
      if (type === "upvote") {
        this.votes[targetType][eventId].upvotes.add(userId);
      } else if (type === "downvote") {
        this.votes[targetType][eventId].downvotes.add(userId);
      }

      if (targetType === "posts") {
        const combinedVotes =
          this.votes[targetType][eventId].upvotes.size +
          this.votes[targetType][eventId].downvotes.size;

        if (combinedVotes % this.repostThreshold === 0) {
          await this.repostEvent(eventId);
        }
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async addComment(eventId, commentBody) {
    const content = {
      body: commentBody,
      msgtype: "m.text",
      "m.relates_to": {
        rel_type: "m.reference",
        event_id: eventId,
      },
    };

    try {
      await this.matrixClient.sendEvent(this.roomId, "m.comment", content);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async repostEvent(eventId) {
    const originalEvent = this.eventsMap.get(eventId);
    if (!originalEvent) return;

    const currentTime = Date.now();
    const lastRepostTime = this.lastRepostTimes[eventId] || 0;

    if (currentTime - lastRepostTime >= this.repostTimeframe) {
      const repostContent = {
        "m.relates_to": {
          rel_type: "m.reference",
          event_id: eventId,
        },
        "m.repost": {
          original_event: originalEvent.event,
        },
      };

      try {
        await this.matrixClient.sendEvent(
          this.roomId,
          "m.repost",
          repostContent
        );
        this.lastRepostTimes[eventId] = currentTime;
      } catch (error) {
        console.error(error);
      }
    }
  }

  getOriginalEvent(event) {
    if (event.getType() === "m.repost") {
      const originalEventId = event.getContent()["m.relates_to"]?.event_id;
      return this.eventsMap.get(originalEventId) || null;
    }
    return event;
  }

  listenForLiveEvents(callback, currentUserId) {
    if (this.liveEventListener) {
      this.matrixClient.removeListener("Room.timeline", this.liveEventListener);
    }

    this.liveEventListener = (event, room, toStartOfTimeline) => {
      if (room.roomId === this.roomId && !toStartOfTimeline) {
        const eventType = event.getType();
        const senderId = event.getSender();

        if (event.getTs() > this.latestInitialTs) {
          if (senderId === currentUserId && eventType === "m.room.message") {
            this.processEvents([event]);
            this.myEvents.push(event);
            callback({ type: "myEvent" });
          } else if (
            eventType === "m.room.message" ||
            eventType === "m.repost"
          ) {
            this.newEvents.push(event);
            const newPostsCount = this.newEvents.length;

            if (newPostsCount >= this.POSTS_THRESHOLD) {
              const lastThreeSenders = this.newEvents
                .slice(-3)
                .map((e) => ({
                  userId: e.getSender(),
                  avatarUrl: this.matrixClient.getUser(e.getSender())
                    ?.avatarUrl,
                }))
                .filter(
                  (sender, index, self) =>
                    index === self.findIndex((s) => s.userId === sender.userId)
                );
              callback({
                type: "newPosts",
                newPostsCount,
                senders: lastThreeSenders,
              });
              this.latestInitialTs = event.getTs();
            }
          } else if (eventType === "m.vote" || eventType === "m.comment") {
            this.processEvents([event]);
            callback({ type: "update" });
          }
        }
      }
    };

    this.matrixClient.on("Room.timeline", this.liveEventListener);
  }

  getMyEvents() {
    const currentMyEvents = [...this.myEvents];
    this.events = [...currentMyEvents, ...this.events];
    this.myEvents = [];
    return currentMyEvents;
  }

  removeLiveEventsListener() {
    if (this.liveEventListener) {
      this.matrixClient.removeListener("Room.timeline", this.liveEventListener);
      this.liveEventListener = null;
    }
  }

  getUserVote(eventId, userId, targetType = "posts") {
    const votes = this.votes[targetType][eventId];
    if (!votes) return null;
    if (votes.upvotes.has(userId)) return "upvote";
    if (votes.downvotes.has(userId)) return "downvote";
    return null;
  }

  getUpvoteCount(eventId, targetType = "posts") {
    const votes = this.votes[targetType][eventId];
    return votes ? votes.upvotes.size : 0;
  }

  getDownvoteCount(eventId, targetType = "posts") {
    const votes = this.votes[targetType][eventId];
    return votes ? votes.downvotes.size : 0;
  }

  getComments(eventId, sortBy = "best") {
    const comments = this.comments[eventId] || [];
    if (sortBy === "recent") {
      return comments.sort((a, b) => b.getTs() - a.getTs());
    } else {
      return comments.sort((a, b) => {
        const aScore = this.getVoteScore(a.getId(), "comments");
        const bScore = this.getVoteScore(b.getId(), "comments");
        return bScore - aScore;
      });
    }
  }

  getTopComments(eventId, limit = 3) {
    const comments = this.getComments(eventId, "best");
    return comments.slice(0, limit);
  }
}

export default GlobalFeedClass;
