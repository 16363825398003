import React, { useState, useEffect } from "react";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import useBounceInUpAnimation from "../../hooks/useBounceInUpAnimation";
import useScrollOpacity from "../../hooks/useScrollOpacity";
import "./Hero.scss";
import AppStore from "../../atoms/app-store/AppStore";

const Hero = () => {
  const { style: landingContainerStyle } = useScrollOpacity();
  const [dimensions, setDimensions] = useState({ width: 140, height: 80 });

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setDimensions({ width: 80, height: 50 });
      } else {
        setDimensions({ width: 140, height: 80 });
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { style } = useBounceInUpAnimation(
    0,
    "translate(0px, 60px)",
    "translate(0px, -5px)",
    "translate(0px, 0px)",
    600,
    600
  );
  const { style: textStyle } = useBounceInUpAnimation(
    0,
    "translate(0px, 80px)",
    "translate(0px, -8px)",
    "translate(0px, 0px)",
    1000,
    600
  );
  const { style: textStyle1 } = useBounceInUpAnimation(
    0,
    "translate(0px, 80px)",
    "translate(0px, -8px)",
    "translate(0px, 0px)",
    1150,
    600
  );
  const { style: textStyle2 } = useBounceInUpAnimation(
    0,
    "translate(0px, 80px)",
    "translate(0px, -8px)",
    "translate(0px, 0px)",
    1300,
    600
  );
  const { style: landingPageStyle } = useBounceInUpAnimation(
    0,
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    2000,
    800
  );

  return (
    <div style={landingContainerStyle} className="app-landing-page-container">
      <div className="app-landing-page-hero-section">
        <div className="app-landing-page-hero-first">
          <div style={textStyle} className="app-landing-page-text-container">
            <span className="app-landing-hero-primary-text">Redefining</span>
          </div>
          <div className="app-landing-page-logo-text-container">
            <div style={style} className="app-landing-page-logo-container">
              <WaivlengthLogo
                fill="var(--color-bg-primary-flip)"
                {...dimensions}
              />
            </div>
            <div style={textStyle1} className="app-landing-page-text-container">
              <span className="app-landing-hero-primary-text">Web3</span>
            </div>
          </div>
        </div>
        <div className="app-landing-page-hero-first">
          <div style={textStyle2} className="app-landing-page-text-container">
            <span className="app-landing-hero-primary-text">Collaboration</span>
          </div>
        </div>
      </div>
      <span
        style={landingPageStyle}
        className="app-landing-hero-primary-subtext"
      >
        Unlock your communities potential, build your tribe today.
      </span>
      <AppStore />
    </div>
  );
};

export default Hero;
