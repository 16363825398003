export default function usernameColorMXID(userId) {
  const palette = [
    "linear-gradient(270deg, #FDE1BC 0%, #FFAEAE 100%)",
    "linear-gradient(270deg, #FBCEFF 0%, #FFD1D1 100%)",
    "linear-gradient(270deg, #F0D6FF 0%, #D3CDFF 100%)",
    "linear-gradient(270deg, #C3C2FF 0%, #9DBEFF 100%)",
    "linear-gradient(270deg, #B8F8FF 0%, #AFD4FF 100%)",
    "linear-gradient(270deg, #D0F9BE 0%, #ADE9F1 100%)",
    "linear-gradient(270deg, #FFF7E3 0%, #DAFFBC 100%)",
    // "linear-gradient(73deg, #DC79FF 0%, #256BFA 100%)",
    // "linear-gradient(90deg, #8D2DE3 0%, #FF2497 100%)",
    // "linear-gradient(90deg, #F7BE2B 0%, #F537F9 100%)",
    // "linear-gradient(90deg, #DC36FA 0%, #A167EF 25%, #609FE4 50%, #3ABFDE 75%, #04D18E 100%)",
    // "linear-gradient(90deg, #1C45A6 0%, #44B5A2 50%, #D4FBB5 100%)",
    // "linear-gradient(90deg, #80D0F2 0%, #5C82FF 50%, #C480FF 100%)",
    // "linear-gradient(90deg, #4AB1F1 0%, #566CEC 32.81%, #D749AF 65.63%, #FF7C51 100%)",
  ];

  let hash = 0;
  let i;
  let chr;
  if (userId.length === 0) {
    return palette[0]; // Default color if userId is empty
  }
  for (i = 0; i < userId.length; i += 1) {
    chr = userId.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  const colorNumber = Math.abs(hash) % palette.length;
  return palette[colorNumber];
}
