import React from "react";
import RoomView from "./RoomView";
import initMatrix from "../../../client/initMatrix";
import "./Room.scss";

function Room({ roomId }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);

  return (
    <div className="room">
      {room && mx && <RoomView room={room} roomId={roomId} mx={mx} />}
    </div>
  );
}

export default Room;
