import React from "react";
import "./Navbar.scss";
import LeftSideContainer from "./LeftSideContainer";
import RightSideContainer from "./RightSideContainer";

function Navbar({ mx, matrixUser }) {
  return (
    <div className="navbar-container">
      <LeftSideContainer />
      <RightSideContainer mx={mx} matrixUser={matrixUser} />
    </div>
  );
}

export default Navbar;
