import React, { useState, useEffect, useRef } from "react";
import initMatrix from "../../../client/initMatrix";
import "./TokenFundraisingDetails.scss";
import usePresaleStatus from "../../hooks/usePresaleStatus";
import { parseTimeLeft } from "../../../util/timeUtils";
import useFetchPresaleData from "../../hooks/useFetchPresaleData";
import { ethers } from "ethers";
import { contributeToPresale } from "../../../util/contributeToPresale";
import { finalizePresale } from "../../../util/finalizePresale";
import { claimPresale } from "../../../util/claimPresale";
import { claimRefund } from "../../../util/claimRefund";
import { claimReclaim } from "../../../util/claimReclaim";
import useFetchVestingData from "../../hooks/useFetchVestingData";
import { claimVesting } from "../../../util/claimVesting";
import { useLoading } from "../../contexts/LoadingContext";
import { ReactComponent as Goal } from "../../assets/svg/fillgoal.svg";
import { ReactComponent as Min } from "../../assets/svg/fillmin.svg";
import { ReactComponent as Max } from "../../assets/svg/fillmax.svg";
import { ReactComponent as Price } from "../../assets/svg/fillprice.svg";
import { ReactComponent as Contributions } from "../../assets/svg/fillcontributions.svg";
import { ReactComponent as Tokens } from "../../assets/svg/filltokens.svg";
import { ReactComponent as Info } from "../../assets/svg/fillinfo.svg";
import { ReactComponent as Contributors } from "../../assets/svg/fillmembers.svg";
import Popover from "../../atoms/popover/Popover";
import CustomInput from "../../atoms/input/CustomInput";
import LineProgress from "../../atoms/progress-line/LineProgress";
import { validateTransaction } from "../../../util/txnValidation";
import Password from "../wallet-settings/Password";
import { useEthersContext } from "../../contexts/EthersContext";
import { useAccount } from "wagmi";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";

const TokenFundraisingDetails = ({
  roomId,
  setOpenDialog,
  spaceId,
  provider,
}) => {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const getStateEvent = (eventType) =>
    room.currentState.getStateEvents(eventType, "");
  const tokenDataEvent = getStateEvent("custom.token_data");
  const tokenData = tokenDataEvent ? tokenDataEvent.getContent() : null;
  const { address: accountAddress } = useAccount();
  const address =
    accountAddress || localStorage.getItem("waivlength_wallet_address");
  const { presaleData, userContribution, isUserOwner } = useFetchPresaleData(
    address,
    tokenData,
    provider
  );
  const [presaleDetailsDialog, setPresaleDetailsDialog] = useState(false);
  const { fetchSigner, handlePasswordSubmit } = useEthersContext();

  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  const handlePasswordInput = (password) => {
    handlePasswordSubmit(password);
    setOpenPasswordDialog(false);
  };

  const startUnix = tokenData.presaleFundraiserStartTimestamp;
  const endUnix = tokenData.presaleFundraiserEndTimestamp;
  const weiToEther = (wei) => wei / 1e18;
  const { timeLeft, result, status } = usePresaleStatus(
    startUnix,
    endUnix,
    tokenData,
    provider,
    address
  );
  const [activeStep, setActiveStep] = useState(
    status === "Live" ? 1 : status === "Ended" ? 2 : 0
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight,
        10
      );
      const maxHeight = lineHeight * 2;
      setIsOverflowing(textRef.current.scrollHeight > maxHeight);
    }
  }, [tokenData.presaleFundraiserDescription]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const { showLoading, hideLoading } = useLoading();

  const {
    vesting,
    vestingTimeLeft,
    vestingPool,
    vestingAddress,
    vestingUnclaimedPool,
  } = useFetchVestingData(tokenData, provider);

  useEffect(() => {
    setActiveStep(status === "Live" ? 1 : status === "Ended" ? 2 : 0);
  }, [status]);

  const timeComponents = parseTimeLeft(timeLeft);
  const presaleTokenomics = tokenData.tokenTokenomics.find(
    (tokenomic) => tokenomic.Pool === "Presale"
  );
  const presaleTokens = presaleTokenomics.Tokens;
  const presaleTokensInWei = BigInt(
    Math.round(parseFloat(presaleTokens) * 1e18)
  );

  const presaleTokenAllocation =
    (presaleTokensInWei.toString() / presaleData.totalDeposits) * 100;

  const pricePerToken =
    presaleData.targetRaiseAmount /
    (presaleTokens * 1e18 * (presaleTokenAllocation / 100));

  const formattedTargetRaiseAmount = ethers.formatEther(
    presaleData.targetRaiseAmount
  );

  const totalTokensReserved =
    presaleTokens *
    (presaleData.totalRaised / presaleData.targetRaiseAmount) *
    (presaleTokenAllocation / 100);

  const reservedTokens = userContribution / pricePerToken;

  const [amountInputValue, setAmountInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const tokenSaleProgress =
    (presaleData.totalRaised / presaleData.targetRaiseAmount) * 100;

  const handleContributeClick = async () => {
    showLoading(
      [
        "Preparing your contribution",
        "Processing your input",
        "Almost done with your contribution",
      ],
      "Contributing to presale"
    );
    const requiredBalance = 0;
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );
    if (txnValidationMsg) {
      setErrorMessage(txnValidationMsg);
      hideLoading();
      return;
    }
    const { signer, requirePassword } = await fetchSigner();
    setErrorMessage("");
    if (requirePassword) {
      setOpenPasswordDialog(true);
      hideLoading();
      return;
    }
    try {
      await contributeToPresale({
        signer,
        presaleData,
        amountInputValue,
        setErrorMessage,
        tokenData,
        setOpenDialog,
      });
    } catch (error) {
      console.error("Error during contribution", error);
    } finally {
      hideLoading();
    }
  };

  const handleFinalizeClick = async () => {
    showLoading(
      [
        "Getting ready to finalize",
        "Working on finalization",
        "Finishing the finalization process",
      ],
      "Finalizing the presale"
    );
    const requiredBalance = 0;
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );
    if (txnValidationMsg) {
      setErrorMessage(txnValidationMsg);
      hideLoading();
      return;
    }
    const { signer, requirePassword } = await fetchSigner();
    setErrorMessage("");
    if (requirePassword) {
      setOpenPasswordDialog(true);
      hideLoading();
      return;
    }
    try {
      await finalizePresale({
        signer,
        presaleData,
        setErrorMessage,
        tokenData,
        setOpenDialog,
        address,
        spaceId,
      });
      await updateCommunityMembershipScore(mx, "finalize_presale", spaceId);
    } catch (error) {
      console.error("Error during finalization", error);
    } finally {
      hideLoading();
    }
  };

  const handleVestingClick = async () => {
    showLoading(
      [
        "Starting your vesting claim",
        "Setting up vesting claim",
        "Getting your vesting claim ready",
      ],
      "Claiming vesting"
    );
    const requiredBalance = 0;
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );
    if (txnValidationMsg) {
      setErrorMessage(txnValidationMsg);
      hideLoading();
      return;
    }
    const { signer, requirePassword } = await fetchSigner();
    setErrorMessage("");
    if (requirePassword) {
      setOpenPasswordDialog(true);
      hideLoading();
      return;
    }
    try {
      await claimVesting({
        signer,
        setErrorMessage,
        tokenData,
        setOpenDialog,
        vestingAddress,
      });
    } catch (error) {
      console.error("Error during vesting claim", error);
    } finally {
      hideLoading();
    }
  };

  const handleClaimClick = async () => {
    showLoading(
      [
        "Initiating presale claim",
        "Processing your presale claim",
        "Wrapping up presale claim",
      ],
      "Claiming presale tokens"
    );
    const requiredBalance = 0;
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );
    if (txnValidationMsg) {
      setErrorMessage(txnValidationMsg);
      return;
    }
    const { signer, requirePassword } = await fetchSigner();
    setErrorMessage("");
    if (requirePassword) {
      setOpenPasswordDialog(true);
      hideLoading();
      return;
    }
    try {
      await claimPresale({
        signer,
        presaleData,
        setErrorMessage,
        tokenData,
        setOpenDialog,
      });
    } catch (error) {
      console.error("Error during presale claim", error);
    } finally {
      hideLoading();
    }
  };

  const handleRefundClick = async () => {
    const requiredBalance = 0;
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );
    if (txnValidationMsg) {
      setErrorMessage(txnValidationMsg);
      hideLoading();
      return;
    }
    const { signer, requirePassword } = await fetchSigner();
    setErrorMessage("");
    if (requirePassword) {
      setOpenPasswordDialog(true);
      hideLoading();
      return;
    }
    showLoading(
      [
        "Preparing your refund",
        "Working on refund",
        "Almost done with your refund",
      ],
      "Claiming refund"
    );
    try {
      await claimRefund({
        signer,
        setErrorMessage,
        tokenData,
        setOpenDialog,
      });
    } catch (error) {
      console.error("Error during refund claim", error);
    } finally {
      hideLoading();
    }
  };

  const handleReclaimClick = async () => {
    const requiredBalance = 0;
    const txnValidationMsg = await validateTransaction(
      provider,
      address,
      requiredBalance
    );

    if (txnValidationMsg) {
      setErrorMessage(txnValidationMsg);
      hideLoading();
      return;
    }

    const { signer, requirePassword } = await fetchSigner();

    setErrorMessage("");
    if (requirePassword) {
      setOpenPasswordDialog(true);
      hideLoading();
      return;
    }
    showLoading(
      [
        "Getting ready to reclaim",
        "Processing reclaim request",
        "Finalizing your reclaim",
      ],
      "Reclaiming tokens"
    );
    try {
      await claimReclaim({
        signer,
        setErrorMessage,
        tokenData,
        setOpenDialog,
      });
    } catch (error) {
      console.error("Error during reclaim claim", error);
    } finally {
      hideLoading();
    }
  };

  const stepDetails = [
    {
      header: activeStep === 0 ? "Launching Soon" : "Launched",
    },
    {
      header:
        activeStep === 1
          ? "Contribution Period Open"
          : "Contribution Period Closed",
    },
    { header: "Completion Status" },
    {
      header:
        result === "Success"
          ? presaleData.isSuccessful
            ? "Claimable Period"
            : "Awaiting Finalization"
          : presaleData.isSuccessful
          ? "Presale Failed"
          : "Presale Failed",
    },
  ];

  const PresaleStat = ({ icon: Icon, primary, secondary }) => {
    return (
      <div className="presale-stats-wrapper">
        <div className="presale-stats-svglogo-container">
          <Icon className="presale-stats-svglogo" />
        </div>
        <span className="presale-stats-primary-text">{primary}</span>
        <span className="presale-stats-secondary-text">{secondary}</span>
      </div>
    );
  };

  const StepperMobile = () => (
    <div className="token-fundraising-custom-stepper-mobile">
      {stepDetails.map((detail, i) => {
        if (i === activeStep) {
          return (
            <div key={i} className="token-fundraising-step">
              <div className="token-fundraising-details">
                <span className="token-offering-summary-header-text">
                  {detail.header}
                </span>
                {i === 0 && (
                  <div className="token-fundraising-timer-component">
                    {timeComponents.map((component, index) => (
                      <React.Fragment key={index}>
                        <div className="token-fundraising-timer-format-wrapper">
                          <div className="token-fundraising-timer-format-container">
                            <span className="token-fundraising-completion-status-text-timer">
                              {component.value}
                            </span>
                          </div>
                          <span className="token-fundraising-screen-main-text">
                            {component.label}
                          </span>
                        </div>
                        {index < timeComponents.length - 1 && (
                          <div className="token-fundraising-symbol-separator">
                            <span className="step-label-header-text">:</span>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {i === 1 && (
                  <>
                    <div className="token-fundraising-timer-component">
                      {timeComponents.map((component, index) => (
                        <React.Fragment key={index}>
                          <div className="token-fundraising-timer-format-wrapper">
                            <div className="token-fundraising-timer-format-container">
                              <span className="token-fundraising-completion-status-text-timer">
                                {component.value}
                              </span>
                            </div>
                            <span className="token-fundraising-screen-main-text">
                              {component.label}
                            </span>
                          </div>
                          {index < timeComponents.length - 1 && (
                            <div className="token-fundraising-symbol-separator">
                              <span className="step-label-header-text">:</span>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                )}
                {i === 2 &&
                isUserOwner === true &&
                result === "Success" &&
                presaleData.isSuccessful === false ? (
                  <div className="token-fundraising-completion-success-finalization">
                    <span className="step-label-header-text-v1">
                      It's time to finalize your presale for{" "}
                      {tokenData.tokenName}. Lets get your tokens distributed,
                      funding withdrawn and liquidity added!
                    </span>
                    <div
                      className="token-fundraising-completion-status-swap-container"
                      onClick={handleFinalizeClick}
                    >
                      <span className="token-fundraising-completion-status-text">
                        Finalize
                      </span>
                    </div>
                  </div>
                ) : null}
                {i === 2 &&
                  result === "Success" &&
                  presaleData.isSuccessful === true && (
                    <div className="token-fundraising-final-step-result-container">
                      <span className="step-label-header-text-v1">
                        {presaleData.hasClaimed ? (
                          <>
                            You have already claimed your {tokenData.tokenName}{" "}
                            tokens from the {tokenData.tokenFundingType}.
                          </>
                        ) : (
                          <>
                            Claim your {tokenData.tokenName} tokens now that the{" "}
                            {tokenData.tokenFundingType} is finalized.
                          </>
                        )}
                      </span>
                      {!presaleData.hasClaimed && (
                        <div
                          className="token-fundraising-completion-status-swap-container"
                          onClick={handleClaimClick}
                        >
                          <span className="token-fundraising-completion-status-text">
                            Claim
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                {i === 2 &&
                  isUserOwner === true &&
                  result === "Failed" &&
                  presaleData.isSuccessful === false &&
                  !presaleData.hasReclaimed && (
                    <div className="token-fundraising-final-step-result-container">
                      <span className="step-label-header-text-v1">
                        Presale failed, reclaim your deposited tokens.
                      </span>
                      <div
                        className="token-fundraising-completion-status-swap-container"
                        onClick={handleReclaimClick}
                      >
                        <span className="token-fundraising-completion-status-text">
                          Reclaim Tokens
                        </span>
                      </div>
                    </div>
                  )}
                {i === 2 &&
                  result === "Failed" &&
                  presaleData.isSuccessful === false && (
                    <div className="token-fundraising-final-step-result-container">
                      <span className="step-label-header-text-v2">
                        Presale failed.
                      </span>
                    </div>
                  )}
                {i === 2 && isUserOwner && result === "Success" && vesting && (
                  <div className="token-fundraising-final-step-result-container">
                    <span className="token-offering-summary-header-text-vesting">
                      Vesting Pool Claim
                    </span>
                    {vestingUnclaimedPool && (
                      <span className="step-label-header-text-v1">
                        Unclaimed vesting token pools. Claim your tokens now for{" "}
                        {vestingUnclaimedPool} pools
                      </span>
                    )}
                    {vestingTimeLeft !== "None" && (
                      <span className="step-label-header-text-v1">
                        Next available unlock. Your vesting tokens for{" "}
                        {vestingPool} will come available to claim in{" "}
                        {vestingTimeLeft}.
                      </span>
                    )}
                    {vestingUnclaimedPool && (
                      <div
                        className="token-fundraising-completion-status-swap-container"
                        onClick={handleVestingClick}
                      >
                        <span className="token-fundraising-completion-status-text">
                          Claim Vesting Tokens
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {i === 2 && result === "Failed" && userContribution > 0 && (
                  <div className="token-fundraising-final-step-result-container">
                    <span className="step-label-header-text-v1">
                      Presale failed to meet hard cap requirements. Claim your
                      refund now.
                    </span>
                    <div
                      className="token-fundraising-completion-status-swap-container"
                      onClick={handleRefundClick}
                    >
                      <span className="token-fundraising-completion-status-text">
                        Claim Refund
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );

  const PresaleDetails = () => {
    return (
      <div className="token-fundraising-primary-details-container">
        <div className="token-fundraising-primary-details">
          <span className="token-fundraising-details-text">
            {tokenData.tokenName}
          </span>
          <div className="token-fundraising-symbol-container">
            <span className="token-fundraising-symbol-container-text">
              ${tokenData.tokenSymbol}
            </span>
          </div>
        </div>
        <span className="token-fundraising-secondary-text-desc">
          Presale Description
        </span>
        <div className="token-fundraising-desc-container">
          <span
            ref={textRef}
            className={`token-fundraising-text-desc ${
              isExpanded ? "expanded" : ""
            }`}
            style={{ marginBottom: isOverflowing ? "0px" : "12px" }}
          >
            {tokenData.presaleFundraiserDescription}
          </span>
          {isOverflowing && (
            <span
              className="token-fundraising-read-more"
              onClick={toggleExpand}
            >
              {isExpanded ? "Read less" : "Read more"}
            </span>
          )}
        </div>
        <span className="token-fundraising-secondary-text-desc">
          Presale Stats
        </span>
        <PresaleStat
          icon={Goal}
          primary="Fundraising Goal"
          secondary={`${formattedTargetRaiseAmount} ETH`}
        />
        <PresaleStat
          icon={Price}
          primary={`Price per ${tokenData.tokenSymbol}`}
          secondary={`${pricePerToken.toFixed(6)} ETH`}
        />
        <PresaleStat
          icon={Min}
          primary="Min Contribution"
          secondary={`${tokenData.presaleFundraiserMinContribution} ETH`}
        />
        <PresaleStat
          icon={Max}
          primary="Max Contribution"
          secondary={`${tokenData.presaleFundraiserMaxContribution} ETH`}
        />
      </div>
    );
  };

  return (
    <div className="token-fundraising-details-dialog">
      {activeStep === 0 && <PresaleDetails />}
      {activeStep !== 0 && (
        <div onClick={() => setPresaleDetailsDialog(true)}>
          <div className="token-fundraising-primary-details-header">
            <span className="token-fundraising-details-text">
              {tokenData.tokenName}
            </span>
            <div className="token-fundraising-symbol-container">
              <span className="token-fundraising-symbol-container-text">
                ${tokenData.tokenSymbol}
              </span>
            </div>
            <div className="presale-stats-svglogo-container">
              <Info className="token-fundraising-primary-details-svglogo" />
            </div>
          </div>
        </div>
      )}
      {presaleDetailsDialog && (
        <Popover
          direction="right"
          setOpenDialog={setPresaleDetailsDialog}
          headerText="Presale Details"
        >
          <PresaleDetails />
        </Popover>
      )}
      <StepperMobile />
      {activeStep === 1 && (
        <>
          <span className="presale-stats-primary-text-contribute">
            Contribute
          </span>
          <CustomInput
            value={amountInputValue}
            onChange={(e) => setAmountInputValue(e.target.value)}
            placeholder="Input amount"
          />
          <div
            className="token-fundraising-completion-status-swap-container"
            onClick={handleContributeClick}
          >
            <span className="token-fundraising-completion-status-text">
              Contribute
            </span>
          </div>
        </>
      )}
      {activeStep === 1 && (
        <div className="token-fundraising-live-status-highlights-content">
          <span className="token-offering-summary-header-text">
            Presale Overview
          </span>
          <PresaleStat
            icon={Contributions}
            primary="Your Contributions"
            secondary={`${userContribution} ETH`}
          />
          <PresaleStat
            icon={Tokens}
            primary="Your Reserved Tokens"
            secondary={`${reservedTokens} ${tokenData.tokenSymbol}`}
          />
          <PresaleStat
            icon={Contributors}
            primary="Contributors"
            secondary={`${presaleData.contributorCount}`}
          />
          <div className="token-fundraising-live-progress-container">
            <div className="token-fundraising-live-progress-wrapper">
              <span className="token-offering-summary-text-header">
                Progress
              </span>
              <span className="token-fundraising-details-text-progress">{`${tokenSaleProgress.toFixed(
                0
              )}%`}</span>
            </div>
            <div className="token-fundraising-live-progress-wrapper-bar">
              <LineProgress progress={tokenSaleProgress} />
            </div>
          </div>
        </div>
      )}
      {errorMessage && (
        <div>
          <span className="token-offering-summary-text-header-error">
            {errorMessage}
          </span>
        </div>
      )}
      {activeStep === 2 && (
        <div className="token-fundraising-live-status-highlights-content">
          <span className="token-offering-summary-header-text">
            Presale Overview
          </span>
          <PresaleStat
            icon={Contributions}
            primary="Total Contributions"
            secondary={`${weiToEther(presaleData.totalRaised)} ETH`}
          />
          <PresaleStat
            icon={Tokens}
            primary="Tokens Allocated"
            secondary={`${totalTokensReserved} ${tokenData.tokenSymbol}`}
          />
          <div className="token-fundraising-live-progress-container">
            <div className="token-fundraising-live-progress-wrapper">
              <span className="token-offering-summary-text-header">
                Progress
              </span>
              <span className="token-fundraising-details-text-progress">{`${tokenSaleProgress.toFixed(
                0
              )}%`}</span>
            </div>
            <div className="token-fundraising-live-progress-wrapper-bar">
              <LineProgress progress={tokenSaleProgress} />
            </div>
          </div>
        </div>
      )}
      {openPasswordDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenPasswordDialog}
          headerText="Enter Password"
        >
          <Password onSubmit={handlePasswordInput} />
        </Popover>
      )}
    </div>
  );
};

export default TokenFundraisingDetails;
