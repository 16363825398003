import React from "react";
import PropTypes from "prop-types";
import "./RoomAvatar.scss";
import { getRandomGradient } from "../../../util/gradients";

const RoomAvatar = React.forwardRef(
  ({ size, roomId, imageSrc, text, borderRadius, borderStyle }, ref) => {
    const gradientInfo = roomId && !imageSrc ? getRandomGradient(roomId) : null;

    const getFormattedText = (text) => {
      return text && text.length ? text.charAt(0).toUpperCase() : "";
    };

    const formattedText = getFormattedText(text);

    // Define the border based on the borderStyle prop
    const border =
      borderStyle === true
        ? "3px solid var(--color-bg-secondary)"
        : borderStyle === false
        ? "none"
        : ""; // default case

    return (
      <div
        ref={ref}
        className="room-avatar"
        style={{
          backgroundImage: gradientInfo ? gradientInfo.gradient : "none",
          backgroundColor: imageSrc ? "transparent" : undefined,
          width: `${size}px`,
          height: `${size}px`,
          display: "inline-flex",
          position: "relative",
          overflow: "hidden",
          borderRadius: `${borderRadius}px`,
          alignItems: "center",
          justifyContent: "center",
          border, // Apply the border style here
        }}
      >
        {imageSrc ? (
          <img
            draggable="false"
            src={imageSrc}
            alt=""
            style={{
              width: `${size}px`,
              height: `${size}px`,
              borderRadius: `${borderRadius}px`,
            }}
          />
        ) : (
          <span
            className="room-avatar-text"
            style={{
              color: gradientInfo ? gradientInfo.textColor : undefined,
            }}
          >
            {formattedText}
          </span>
        )}
      </div>
    );
  }
);

RoomAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  roomId: PropTypes.string,
  imageSrc: PropTypes.string,
  text: PropTypes.string,
  borderRadius: PropTypes.number,
  borderStyle: PropTypes.oneOfType([
    PropTypes.bool, // true or false
    PropTypes.string, // optional default string if needed
  ]),
};

export default RoomAvatar;
