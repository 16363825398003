import React from "react";
import PropTypes from "prop-types";
import "./PeopleSelector.scss";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { ReactComponent as Verify } from "../../assets/svg/verify.svg";
import { usePowerLevelTags } from "../../hooks/usePowerLevelTags";
import { formatDisplayName } from "../../../util/formatDisplayName";
import initMatrix from "../../../client/initMatrix";

function PeopleSelector({ userId, name, peopleRole, onClick, roomId }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const roomMember = room ? room.getMember(userId) : null;
  const avatarMxc =
    roomMember?.getMxcAvatarUrl?.() || mx.getUser(userId)?.avatarUrl;
  const avatarUrl =
    avatarMxc && avatarMxc !== "null"
      ? mx.mxcUrlToHttp(avatarMxc, 80, 80, "crop")
      : null;
  const getPowerLevelTag = usePowerLevelTags();
  const tagIcons = {
    Founder: <Verify className="MembersDrawerVerifyFounder" />,
    Admin: <Verify className="PowerSelectorVerifyAdmin" />,
    Governor: <Verify className="PowerSelectorVerifyGovernor" />,
    Moderator: <Verify className="PowerSelectorVerifyModerator" />,
    TrustedMember: <Verify className="PowerSelectorVerifyTrustedMember" />,
    Default: <Verify className="PowerSelectorVerifyDefault" />,
  };
  const powerLevel = roomMember?.powerLevel || 0;
  const powerLevelTag = getPowerLevelTag(powerLevel);
  const powerLevelTagName = powerLevelTag.name;
  const tagIcon = tagIcons[powerLevelTagName];

  return (
    <div className="people-selector" onClick={onClick}>
      <div>
        <UserAvatar size={28} userId={userId} imageSrc={avatarUrl} />
      </div>
      <span className="people-selector-text-color">
        {formatDisplayName(name)}
      </span>
      <span className="people-selector-text-color-v1">{peopleRole}</span>
      <div>{tagIcon}</div>
    </div>
  );
}

export default PeopleSelector;
