import React from "react";
import { IonDatetime } from "@ionic/react";
import "./DateTimePicker.scss";

function toUnixTimestampLocal(localDateString) {
  const date = new Date(localDateString);
  return Math.floor(date.getTime() / 1000);
}

function DateTimePicker1({ onDateTimeChange }) {
  const handleDateTimeChange = (e) => {
    const newLocalDateTimeValue = e.detail.value;
    if (onDateTimeChange) {
      const utcTimestamp = toUnixTimestampLocal(newLocalDateTimeValue);
      onDateTimeChange(utcTimestamp);
    }
  };

  return (
    <>
      <IonDatetime
        id="endDatetime"
        preferWheel={true}
        onIonChange={handleDateTimeChange}
      ></IonDatetime>
    </>
  );
}

export default DateTimePicker1;
