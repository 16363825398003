import React from "react";
import "./Paragraph.scss";
import reactStringReplace from "react-string-replace";

function Paragraph({
  text,
  subsections = [],
  primaryText,
  secondaryTexts = [],
}) {
  const linkedPrimaryText = reactStringReplace(
    primaryText,
    /(https?:\/\/[^\s]+)/g,
    (match, i) => (
      <a
        href={match}
        key={i}
        className="underlined-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {match}
      </a>
    )
  );

  return (
    <div className="blog-para-component">
      <span className="blog-para-text">{text}</span>
      <span className="blog-para-primary-text">{linkedPrimaryText}</span>

      {Array.isArray(subsections) &&
        subsections.map((sub, index) => (
          <div className="blog-para-section" key={index}>
            <span className="blog-para-subsection-text">{sub}</span>
            <span className="blog-para-secondary-text">
              {secondaryTexts[index]}
            </span>
          </div>
        ))}
    </div>
  );
}

export default Paragraph;
