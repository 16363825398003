import * as roomActions from "./room";
import { ethers } from "ethers";
import PresaleFactoryABI from "../../util/abi/PresaleFactoryABI.json";
import PresaleABI from "../../util/abi/PresaleABI.json";

export const handleCreateFundraiser = async (
  parentId,
  updatedPresaleData,
  signer,
  address
) => {
  const startTime = updatedPresaleData.presaleFundraiserStartTimestamp;
  const endTime = updatedPresaleData.presaleFundraiserEndTimestamp;
  const presaleFactoryAddress = import.meta.env.VITE_PRESALE_FACTORY_ADDRESS;
  const feeWallet = import.meta.env.VITE_FEE_WALLET_ADDRESS;
  const chain = import.meta.env.VITE_DEFAULT_CHAIN;

  const etherscanBaseUrl =
    chain === "mainnet"
      ? "https://etherscan.io"
      : `https://${chain}.etherscan.io`;

  let nonfungiblePositionManager, WETHAddress;

  if (chain === "sepolia") {
    nonfungiblePositionManager = import.meta.env
      .VITE_NONFUNGIBLE_POSITION_MANAGER_ADDRESS_SEPOLIA;
    WETHAddress = import.meta.env.VITE_WETH_ADDRESS_SEPOLIA;
  } else if (chain === "mainnet") {
    nonfungiblePositionManager = import.meta.env
      .VITE_NONFUNGIBLE_POSITION_MANAGER_ADDRESS_MAINNET;
    WETHAddress = import.meta.env.VITE_WETH_ADDRESS_MAINNET;
  } else {
    const errorMsg = "Please connect to the Sepolia or Mainnet network.";
    console.error(errorMsg);
    return { error: errorMsg };
  }

  const liquidityTokenPercentage = parseInt(
    import.meta.env.VITE_LIQUIDITY_TOKEN_PERCENTAGE
  );
  const liquidityEthPercentage = parseInt(
    import.meta.env.VITE_LIQUIDITY_ETH_PERCENTAGE
  );
  const projectWalletEthPercentage = parseInt(
    import.meta.env.VITE_PROJECT_WALLET_ETH_PERCENTAGE
  );
  const feeWalletEthPercentage = parseInt(
    import.meta.env.VITE_FEE_WALLET_ETH_PERCENTAGE
  );

  const presaleParams = {
    token: updatedPresaleData.tokenContractAddress,
    startTime,
    endTime,
    minContribution: ethers.parseEther(
      updatedPresaleData.presaleFundraiserMinContribution
    ),
    maxContribution: ethers.parseEther(
      updatedPresaleData.presaleFundraiserMaxContribution
    ),
    targetRaiseAmount: ethers.parseEther(
      updatedPresaleData.presaleTargetRaiseAmount
    ),
    wallet: address,
    feeWallet,
    liquidityTokenPercentage,
    liquidityEthPercentage,
    projectWalletEthPercentage,
    feeWalletEthPercentage,
    nonfungiblePositionManager,
    WETHAddress,
  };

  try {
    const presaleContractAddress = await createPresale(
      presaleFactoryAddress,
      presaleParams,
      signer
    );

    if (!presaleContractAddress) {
      return { error: "Failed to create presale." };
    }

    const requiredAmount = ethers.parseUnits(
      updatedPresaleData.tokenTotalSupply.toString(),
      18
    );

    const approvalResult = await approveTokenTransfer(
      signer,
      address,
      updatedPresaleData.tokenContractAddress,
      presaleContractAddress,
      requiredAmount
    );
    if (approvalResult.error) {
      return approvalResult;
    }

    const depositResult = await depositTokens(
      signer,
      presaleContractAddress,
      requiredAmount
    );
    if (depositResult.error) {
      return depositResult;
    }

    const presaleEtherscanLink = `${etherscanBaseUrl}/address/${presaleContractAddress}`;

    const newRoom = await roomActions.createRoom({
      name: presaleContractAddress,
      joinRule: "public",
      isEncrypted: false,
      powerLevel: 101,
      isSpace: false,
      parentId,
      tokenName: updatedPresaleData.tokenName,
      tokenSymbol: updatedPresaleData.tokenSymbol,
      tokenTotalSupply: updatedPresaleData.tokenTotalSupply,
      tokenContractAddress: updatedPresaleData.tokenContractAddress,
      tokenFundingType: updatedPresaleData.tokenFundingType,
      tokenTokenomics: updatedPresaleData.tokenTokenomics,
      presaleTargetRaiseAmount: updatedPresaleData.presaleTargetRaiseAmount,
      presaleFundraiserMinContribution:
        updatedPresaleData.presaleFundraiserMinContribution,
      presaleFundraiserMaxContribution:
        updatedPresaleData.presaleFundraiserMaxContribution,
      presaleFundraiserDescription:
        updatedPresaleData.presaleFundraiserDescription,
      presaleFundraiserStartTimestamp:
        updatedPresaleData.presaleFundraiserStartTimestamp,
      presaleFundraiserEndTimestamp:
        updatedPresaleData.presaleFundraiserEndTimestamp,
      presaleContractAddress: presaleContractAddress,
      presaleEtherscanLink: presaleEtherscanLink,
    });

    if (newRoom && parentId) {
      const mx = initMatrix.matrixClient;

      let currentSaleData = { rooms: [] };
      try {
        const currentSaleDataEvent = await mx.getStateEvent(
          parentId,
          "custom.sale_data",
          ""
        );
        currentSaleData = currentSaleDataEvent?.content || { rooms: [] };
      } catch (error) {
        console.warn(
          "No existing sale data event found. Initializing new data."
        );
      }

      if (!Array.isArray(currentSaleData.rooms)) {
        currentSaleData.rooms = [];
      }

      currentSaleData.rooms.push({
        roomId: newRoom.room_id,
        tokenName: updatedPresaleData.tokenName,
        tokenSymbol: updatedPresaleData.tokenSymbol,
        tokenTotalSupply: updatedPresaleData.tokenTotalSupply,
        tokenContractAddress: updatedPresaleData.tokenContractAddress,
        tokenFundingType: updatedPresaleData.tokenFundingType,
        tokenTokenomics: updatedPresaleData.tokenTokenomics,
        presaleTargetRaiseAmount: updatedPresaleData.presaleTargetRaiseAmount,
        presaleFundraiserMinContribution:
          updatedPresaleData.presaleFundraiserMinContribution,
        presaleFundraiserMaxContribution:
          updatedPresaleData.presaleFundraiserMaxContribution,
        presaleFundraiserDescription:
          updatedPresaleData.presaleFundraiserDescription,
        presaleFundraiserStartTimestamp:
          updatedPresaleData.presaleFundraiserStartTimestamp,
        presaleFundraiserEndTimestamp:
          updatedPresaleData.presaleFundraiserEndTimestamp,
        presaleContractAddress: presaleContractAddress,
        presaleEtherscanLink: presaleEtherscanLink,
      });

      await mx.sendStateEvent(
        parentId,
        "custom.sale_data",
        { rooms: currentSaleData.rooms },
        ""
      );
    }

    return { success: true };
  } catch (error) {
    const errorMessage = error.toString();
    console.error("Error creating presale:", errorMessage);
    return { error: errorMessage };
  }
};

const createPresale = async (presaleFactoryAddress, presaleParams, signer) => {
  try {
    const presaleFactoryContract = new ethers.Contract(
      presaleFactoryAddress,
      PresaleFactoryABI,
      signer
    );

    const createPresaleTx = await presaleFactoryContract.createPresale(
      presaleParams,
      {
        value: ethers.parseEther("0.001"),
      }
    );

    const createPresaleReceipt = await createPresaleTx.wait();
    const tokenContractAddress = createPresaleReceipt.logs[0]?.address;

    return tokenContractAddress;
  } catch (error) {
    const errorMessage = error.message || error.toString();
    console.error("Error creating presale:", errorMessage);
    return { error: errorMessage };
  }
};

const approveTokenTransfer = async (
  signer,
  address,
  tokenContractAddress,
  presaleContractAddress,
  requiredAmount
) => {
  try {
    const standardERC20ABI = [
      "function balanceOf(address owner) view returns (uint256)",
      "function allowance(address owner, address spender) view returns (uint256)",
      "function approve(address spender, uint256 amount) returns (bool)",
    ];
    const tokenContract = new ethers.Contract(
      tokenContractAddress,
      standardERC20ABI,
      signer
    );

    const ownerBalance = await tokenContract.balanceOf(address);
    if (ownerBalance < requiredAmount) {
      const errorMsg = "Insufficient token balance for deposit.";
      console.error(errorMsg);
      return { error: errorMsg };
    }

    const allowance = await tokenContract.allowance(
      address,
      presaleContractAddress
    );
    if (allowance < requiredAmount) {
      const approveTx = await tokenContract.approve(
        presaleContractAddress,
        requiredAmount
      );
      await approveTx.wait();
    }
    return { success: true };
  } catch (error) {
    const errorMessage = error.message || error.toString();
    console.error("Failed to approve token transfer:", errorMessage);
    return { error: errorMessage };
  }
};

const depositTokens = async (
  signer,
  presaleContractAddress,
  requiredAmount
) => {
  try {
    const depositContract = new ethers.Contract(
      presaleContractAddress,
      PresaleABI,
      signer
    );
    const depositTx = await depositContract.depositTokens(requiredAmount);
    await depositTx.wait();
    return { success: true };
  } catch (error) {
    const errorMessage = error.message || error.toString();
    console.error("Failed to deposit tokens:", errorMessage);
    return { error: errorMessage };
  }
};
