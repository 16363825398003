import React from "react";
import PropTypes from "prop-types";
import "./UserAvatar.scss";
import initMatrix from "../../../client/initMatrix";
import { generateWaivicon1 } from "waivicon";

const UserAvatar = React.forwardRef(({ size, userId }, ref) => {
  const user = initMatrix.matrixClient.getUser(userId);
  const desiredSize = Math.max(size, 128);
  const avatarSrc =
    user && user.avatarUrl
      ? initMatrix.matrixClient.mxcUrlToHttp(
          user.avatarUrl,
          desiredSize,
          desiredSize,
          "crop"
        )
      : null;

  if (avatarSrc) {
    return (
      <div
        ref={ref}
        className="user-avatar-container"
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <img
          draggable="false"
          src={avatarSrc}
          alt=""
          style={{
            width: `${size}px`,
            height: `${size}px`,
            objectFit: "cover",
          }}
        />
      </div>
    );
  } else {
    const svg = generateWaivicon1(userId.toUpperCase(), size);
    return (
      <div
        ref={ref}
        className="user-avatar-container"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
            svg
          )}")`,
          backgroundSize: "cover",
        }}
      />
    );
  }
});

UserAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
};

export default UserAvatar;
