import React, { useState } from "react";
import "./SetupWallet.scss";
import Toggle from "../../atoms/button/Toggle";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";
import { useAppConnection } from "../../contexts/AppConnectionContext";
import { Capacitor } from "@capacitor/core";
import { encrypt } from "../../../util/encryption";
import SecureStoragePluginIOS from "../../../util/secureStoragePluginIOS";
import SecureStoragePluginAndroid from "../../../util/secureStoragePluginAndroid";
import { isValidReferral } from "../../../client/referrals/api";

const SetupWallet = ({
  walletData,
  setOpenDialog,
  isFaceIdAvailable,
  scrollToInput,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [useFaceId, setUseFaceId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { handleWalletSetup } = useAppConnection();
  const platform = Capacitor.getPlatform();

  const handleToggle = () => {
    setUseFaceId(!useFaceId);
  };

  const handleCreateAccount = async () => {
    setLoading(true);

    // Validate password length
    if (password.length < 10) {
      setErrorMessage("Password must be 10 characters or longer");
      setLoading(false);
      return;
    }

    // Validate password match
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setLoading(false);
      return;
    }

    // Validate referral code if not in development
    if (import.meta.env.VITE_ENVIRONMENT !== "development") {
      if (referralCode === "waivref1357-admin") {
      } else {
        const isValid = await isValidReferral(
          referralCode,
          walletData?.address
        );
        if (!isValid) {
          setErrorMessage("Invalid referral code");
          setLoading(false);
          return;
        }
      }
    } else {
    }

    setErrorMessage("");

    try {
      // Encrypt private key
      const encryptedPrivateKey = encrypt(walletData.privateKey, password);

      const encryptedMnemonic = walletData.mnemonic
        ? encrypt(walletData.mnemonic, password)
        : ""; // Passing an empty string instead of "null"

      // Determine the platform-specific save functions
      const saveWithBiometrics =
        platform === "ios"
          ? SecureStoragePluginIOS.saveWithFaceId
          : SecureStoragePluginAndroid.saveWithFaceId;

      const save =
        platform === "ios"
          ? SecureStoragePluginIOS.save
          : SecureStoragePluginAndroid.save;

      // Save data with or without biometrics
      if (useFaceId) {
        await saveWithBiometrics({
          key: walletData.address,
          value: encryptedPrivateKey,
          mnemonic: encryptedMnemonic, // Pass encryptedMnemonic or null
          password: password,
        });
      } else {
        await save({
          key: walletData.address,
          value: encryptedPrivateKey,
          mnemonic: encryptedMnemonic, // Pass encryptedMnemonic or null
          password: password,
        });
      }

      // Handle wallet setup after saving
      handleWalletSetup(
        walletData.address,
        walletData.privateKey,
        useFaceId,
        walletData.createdWallet,
        walletData.importedWalletSP,
        walletData.importedWalletPK
      );
      setOpenDialog(false);
    } catch (error) {
      if (error.message.includes("No strong biometrics enrolled")) {
        setErrorMessage("Please enable biometrics in your device settings.");
      } else {
        setErrorMessage("Error creating account");
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrorMessage("");
  };

  const handleReferralCodeChange = (e) => {
    setReferralCode(e.target.value);
  };

  return (
    <div className="setup-wallet-container">
      <span className="create-space-secondary-text">
        Wallet Created Successfully
      </span>
      <div className="setup-wallet-address-container">
        <span className="setup-wallet-address-text">{walletData?.address}</span>
      </div>
      <span className="create-space-secondary-text">Enter referral</span>
      <CustomInput
        placeholder="Referral Code"
        onChange={handleReferralCodeChange}
        autoFocus={[true, 300]}
        value={referralCode}
        scrollToInput={scrollToInput}
      />
      <span className="create-space-secondary-text">Set up password</span>
      <div className="setup-wallet-password-container">
        <CustomInput
          placeholder="Create Password"
          type="password"
          onChange={handlePasswordChange}
          value={password}
          scrollToInput={scrollToInput}
        />
        <CustomInput
          placeholder="Confirm Password"
          type="password"
          onChange={handleConfirmPasswordChange}
          value={confirmPassword}
        />
      </div>
      {isFaceIdAvailable && (
        <div className="setup-wallet-toggle-faceid">
          <span className="setup-wallet-toggle-faceid-text">
            {platform === "ios" ? "Enable Face ID" : "Enable Biometrics"}
          </span>
          <Toggle isActive={useFaceId} onToggle={handleToggle} />
        </div>
      )}
      {errorMessage && (
        <div className="create-room-error-text">{errorMessage}</div>
      )}
      <div className="setup-wallet-container-button-wrapper">
        <div
          className="create-container-button-create"
          onClick={handleCreateAccount}
          disabled={loading}
        >
          {loading ? (
            <Loader
              size="24px"
              dotSize="6px"
              color="var(--dark)"
              multiplier={1.4}
            />
          ) : (
            <span className="create-room-button-text">Create Account</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetupWallet;
