import * as sdk from "matrix-js-sdk";
import cons from "../state/cons";
import { generateJWTToken } from "./jwtHandler";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function updateLocalStore(accessToken, deviceId, userId, baseUrl) {
  localStorage.setItem(cons.secretKey.ACCESS_TOKEN, accessToken);
  localStorage.setItem(cons.secretKey.DEVICE_ID, deviceId);
  localStorage.setItem(cons.secretKey.USER_ID, userId);
  localStorage.setItem(cons.secretKey.BASE_URL, baseUrl);
}

async function fetchAccountDeletionStatus(baseUrl, userId) {
  const response = await fetch(
    `${baseUrl}/_synapse/client/account_deletion_status?user_id=${userId}`
  );
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Network response was not ok: ${errorText}`);
  }
  return await response.json();
}

async function web3login(baseUrl, walletAddress, jwtSecret) {
  if (!walletAddress) {
    throw new Error("Wallet address is null");
  }
  const lowercaseWalletAddress = walletAddress.toLowerCase();
  const generatedJwtToken = generateJWTToken(
    lowercaseWalletAddress,
    jwtSecret,
    baseUrl
  );

  const jsonResponse = await access(baseUrl, generatedJwtToken);
  const accessToken = jsonResponse.access_token;
  const deviceId = jsonResponse.device_id;
  const userId = jsonResponse.user_id;

  const deletionStatus = await fetchAccountDeletionStatus(baseUrl, userId);

  if (deletionStatus.status === "active") {
    updateLocalStore(accessToken, deviceId, userId, baseUrl);
    return { accessToken, deviceId, userId, baseUrl };
  } else if (deletionStatus.status === "pending_deletion") {
    const deletionTimestamp = dayjs(deletionStatus.deletion_timestamp);
    const currentTime = dayjs().utc();

    if (currentTime.isAfter(deletionTimestamp)) {
      throw new Error(
        "Account has been deactivated and cannot be logged into."
      );
    } else {
      updateLocalStore(accessToken, deviceId, userId, baseUrl);
      return { accessToken, deviceId, userId, baseUrl };
    }
  } else {
    console.error("Unexpected account status:", deletionStatus.status);
    throw new Error(
      "Cannot log in with account status: " + deletionStatus.status
    );
  }
}

async function access(baseUrl, generatedJwtToken) {
  const response = await fetch(`${baseUrl}/_matrix/client/r0/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      type: "org.matrix.login.jwt",
      token: generatedJwtToken,
      initial_device_display_name: cons.DEVICE_DISPLAY_NAME,
    }),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return jsonResponse;
}

export { web3login };
