import cons from "../../../client/state/cons";
import * as roomActions from "../../../client/action/room";
import { selectTab } from "../../../client/action/navigation";

const NOTIFICATION_EVENT_TYPE = "my.custom.notifications";

export const handleInvite = async (
  mxClient,
  roomId,
  isDM,
  parentRoomId = null,
  setDmRoomId,
  setSpaceId,
  setRoomId
) => {
  const isJoined = mxClient.getRoom(roomId)?.getMyMembership() === "join";
  if (isJoined) {
    await processInvite(
      mxClient,
      roomId,
      isDM,
      parentRoomId,
      setDmRoomId,
      setSpaceId,
      setRoomId
    );
  } else {
    await joinInviteRoom(
      mxClient,
      roomId,
      isDM,
      parentRoomId, 
      setDmRoomId,
      setSpaceId,
      setRoomId
    );
  }
};

export const joinInviteRoom = async (
  mxClient,
  roomId,
  isDM,
  parentRoomId = null,
  setDmRoomId,
  setSpaceId,
  setRoomId
) => {
  try {
    const { roomId: joinedRoomId } = await roomActions.join(roomId, isDM);
    const room = mxClient.getRoom(joinedRoomId);
    const parentEvent = room?.currentState.events
      .get("m.space.parent")
      ?.values()
      .next().value;
    const newParentRoomId = parentEvent ? parentEvent.getStateKey() : null;

    if (newParentRoomId && newParentRoomId !== parentRoomId) {
      await joinInviteRoom(
        mxClient,
        newParentRoomId,
        false,
        newParentRoomId,
        setDmRoomId,
        setSpaceId,
        setRoomId
      );
      await handleInvite(
        mxClient,
        joinedRoomId,
        isDM,
        newParentRoomId,
        setDmRoomId,
        setSpaceId,
        setRoomId
      );
    } else {
      await processInvite(
        mxClient,
        joinedRoomId,
        isDM,
        parentRoomId,
        setDmRoomId,
        setSpaceId,
        setRoomId
      );
    }
  } catch (error) {
    console.error("Failed to join room:", error);
  }
};

export const processInvite = async (
  mxClient,
  roomId,
  isDM,
  parentRoomId = null, 
  setDmRoomId,
  setSpaceId,
  setRoomId
) => {
  const room = mxClient.getRoom(roomId);
  if (!room) return;

  const isSpace = room?.getType() === "m.space";

  if (isDM) {
    selectTab(cons.tabs.DIRECTS);
    setDmRoomId(roomId);
  } else if (isSpace) {
    selectTab(cons.tabs.HOME);
    setSpaceId(roomId);
  } else if (parentRoomId) {
    selectTab(cons.tabs.HOME);
    setSpaceId(parentRoomId);
    setRoomId(roomId);
  } else {
    selectTab(cons.tabs.DIRECTS);
    setSpaceId(null);
    setRoomId(roomId);
  }
};

export const addNotificationToHistory = async (mxClient, notification) => {
  const accountDataEvent = mxClient.getAccountData(NOTIFICATION_EVENT_TYPE);
  const accountDataRaw = accountDataEvent
    ? accountDataEvent.getContent()
    : null;
  const notifications = accountDataRaw?.notifications || [];
  notifications.unshift(notification);
  await mxClient.setAccountData(NOTIFICATION_EVENT_TYPE, {
    notifications,
  });
};

export const fetchNotificationsHistory = (mxClient, limit = 20, from = 0) => {
  const accountDataEvent = mxClient.getAccountData(NOTIFICATION_EVENT_TYPE);
  const accountDataRaw = accountDataEvent
    ? accountDataEvent.getContent()
    : null;
  const notifications = accountDataRaw?.notifications || [];
  return notifications.slice(from, from + limit);
};
