import { useCallback } from "react";
import { uploadMedia } from "./uploadMedia";
import {
  getImageMsgContent,
  getVideoMsgContent,
  getAudioMsgContent,
  getFileMsgContent,
} from "./msgContent";
import { encryptFiles } from "./encryptFile";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import { safeFile } from "../app/utils/mimeTypes";

const resizeImage = (blob) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const maxDim = 400;

      let width = img.width;
      let height = img.height;
      let offsetX = 0;
      let offsetY = 0;

      if (width > height) {
        offsetX = (width - height) / 2;
        width = height;
      } else {
        offsetY = (height - width) / 2;
        height = width;
      }

      canvas.width = maxDim;
      canvas.height = maxDim;

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(img, offsetX, offsetY, width, height, 0, 0, maxDim, maxDim);

      canvas.toBlob(
        (resultBlob) => {
          if (resultBlob) {
            resolve(URL.createObjectURL(resultBlob));
          } else {
            reject(new Error("Blob conversion failed"));
          }
        },
        blob.type,
        1
      );
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const ensureDirectoryExists = async (dirPath) => {
  try {
    await Filesystem.mkdir({
      path: dirPath,
      directory: Directory.Documents,
      recursive: true,
    });
  } catch (e) {
    if (e.message !== "Directory exists") {
      throw e;
    }
  }
};

const checkPermissionsAndRequestIfNecessary = async () => {
  const permissions = await Camera.checkPermissions();

  if (permissions.camera !== "granted" || permissions.photos !== "granted") {
    const requestResult = await Camera.requestPermissions({
      permissions: ["camera", "photos"],
    });

    if (
      requestResult.camera === "denied" ||
      requestResult.photos === "denied"
    ) {
      return false;
    }
  }

  return true;
};

const promptToOpenSettings = async () => {
  const confirmed = confirm(
    "This app requires access to your camera and photo library. Please enable the permissions in settings."
  );

  if (confirmed) {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  }
};

export const pickMediaFromCameraRoll = async (onSelect) => {
  const hasPermissions = await checkPermissionsAndRequestIfNecessary();

  if (!hasPermissions) {
    promptToOpenSettings();
    return;
  }

  try {
    const photos = await Camera.pickImages({
      quality: 90,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
    });

    const mediaPromises = photos.photos.map(async (photo) => {
      const readFile = await Filesystem.readFile({
        path: photo.path,
      });

      const fileName = photo.path.split("/").pop();
      const dirPath = "media";
      const newPath = `${dirPath}/${fileName}`;

      await ensureDirectoryExists(dirPath);

      await Filesystem.writeFile({
        path: newPath,
        data: readFile.data,
        directory: Directory.Documents,
      });

      const blob = b64toBlob(readFile.data, `image/${photo.format}`);

      return {
        blob,
        webPath: photo.webPath,
      };
    });

    const media = await Promise.all(mediaPromises);

    if (media && media.length > 0) {
      onSelect(media);
    }
  } catch (error) {
    console.error("Message:", error.message);
  }
};

export const takeMediaFromCamera = async (onSelect) => {
  const hasPermissions = await checkPermissionsAndRequestIfNecessary();

  if (!hasPermissions) {
    promptToOpenSettings();
    return;
  }

  try {
    const photo = await Camera.getPhoto({
      quality: 90,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
    });

    const readFile = await Filesystem.readFile({
      path: photo.path,
    });

    const fileName = photo.path.split("/").pop();
    const dirPath = "media";
    const newPath = `${dirPath}/${fileName}`;

    await ensureDirectoryExists(dirPath);

    await Filesystem.writeFile({
      path: newPath,
      data: readFile.data,
      directory: Directory.Documents,
    });

    const blob = b64toBlob(readFile.data, `image/${photo.format}`);

    if (onSelect) {
      onSelect([{ blob, webPath: photo.webPath }]);
    }
  } catch (error) {
    console.error("Error taking media:", error.message);
  }
};

export const handleFiles = (
  mx,
  roomId,
  setMediaPreviews,
  setLoadingStates,
  setMediaFiles
) =>
  useCallback(
    async (files) => {
      try {
        if (!files || !files.length) {
          throw new Error("No files received");
        }

        const blobs = files.map((file) => file.blob);

        const previews = await Promise.all(
          blobs.map((blob) => resizeImage(blob))
        );

        setLoadingStates(previews.map(() => true));

        setMediaPreviews((prev) => [...prev, ...previews]);

        const safeFiles = blobs.map((blob) => safeFile(blob));

        let fileItems = [];

        if (mx.isRoomEncrypted(roomId)) {
          const encryptedFiles = await encryptFiles(safeFiles);
          fileItems = encryptedFiles;
        } else {
          fileItems = safeFiles;
        }

        const mediaContents = await Promise.all(
          fileItems.map(async (file, index) => {
            const mxcUri = await uploadMedia(mx, file);
            let mediaContent;
            const originalFile = files[index].blob;

            if (file.type.startsWith("image")) {
              mediaContent = await getImageMsgContent(
                mx,
                { file, originalFile, encInfo: null },
                mxcUri
              );
            } else if (file.type.startsWith("video")) {
              mediaContent = await getVideoMsgContent(
                mx,
                { file, originalFile, encInfo: null },
                mxcUri
              );
            } else if (file.type.startsWith("audio")) {
              mediaContent = await getAudioMsgContent(
                mx,
                { file, originalFile, encInfo: null },
                mxcUri
              );
            } else {
              mediaContent = await getFileMsgContent(
                mx,
                { file, originalFile, encInfo: null },
                mxcUri
              );
            }

            // Update loading state for the specific media
            setLoadingStates((prev) => {
              const newLoadingStates = [...prev];
              newLoadingStates[index] = false;
              return newLoadingStates;
            });

            return mediaContent;
          })
        );

        // Append new media files to the existing ones
        setMediaFiles((prev) => [...prev, ...mediaContents]);
      } catch (error) {
        console.error("Error handling files:", error);
      }
    },
    [mx, roomId, setMediaPreviews, setLoadingStates, setMediaFiles]
  );
