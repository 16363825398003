import { useState, useEffect } from "react";

function useScrollOpacity() {
  const [style, setStyle] = useState({
    opacity: 1,
    transform: "scale(1) translateY(0px)",
    transformOrigin: "top center",
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scale = Math.max(0.8, 1 - 0.2 * (scrollPosition / 800));
      let opacity = 0.3 + (0.7 * (scale - 0.8)) / 0.2;
      let translateY = 0;

      if (scrollPosition > 800) {
        opacity = Math.max(0, 0.3 * (1 - (scrollPosition - 800) / 100));
        translateY = -Math.min(100, scrollPosition - 800);
      }

      setStyle({
        opacity: opacity,
        transform: `scale(${scale}) translateY(${translateY}px)`,
        transformOrigin: "top center",
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { style };
}

export default useScrollOpacity;
