export const validateCreateSpace = (dataToValidate) => {
  const errors = [];
  const upperCaseRegex = /^[A-Z]+$/;
  const twitterUrlRegex =
    /^(https?:\/\/)?(www\.)?(x|twitter)\.com\/[a-zA-Z0-9_]+$/;
  const githubUrlRegex = /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9_-]+$/;
  const websiteUrlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\/[a-zA-Z0-9.-]*)*$/;
  const tokenTotalSupplyRegex = /^(?:\d{1,3}(?:,\d{3})*|\d+)$/;

  if (!dataToValidate.spaceName) {
    errors.push("Space Name is missing");
  } else if (dataToValidate.spaceName.length === 0) {
    errors.push("Space Name cannot be empty");
  } else if (dataToValidate.spaceName.length > 32) {
    errors.push("Space Name cannot be longer than 32 characters");
  }

  // Validate Space Description
  if (!dataToValidate.spaceDesc) {
    errors.push("Space Description is missing");
  } else if (dataToValidate.spaceDesc.length > 320) {
    errors.push("Space Description exceeds 320 characters");
  }

  // Validate Token Name
  if (
    !dataToValidate.tokenName ||
    typeof dataToValidate.tokenName !== "string"
  ) {
    errors.push("Token Name is missing or not valid format");
  }

  // Validate Token Symbol
  if (
    !dataToValidate.tokenSymbol ||
    typeof dataToValidate.tokenSymbol !== "string"
  ) {
    errors.push("Token Symbol is missing");
  } else if (
    !upperCaseRegex.test(dataToValidate.tokenSymbol) ||
    dataToValidate.tokenSymbol.length > 10
  ) {
    errors.push(
      "Token Symbol must be in uppercase and not exceed 10 characters"
    );
  }

  // Validate Token Total Supply
  if (!dataToValidate.tokenTotalSupply) {
    errors.push("Token Total Supply is missing");
  } else if (typeof dataToValidate.tokenTotalSupply !== "string") {
    dataToValidate.tokenTotalSupply =
      dataToValidate.tokenTotalSupply.toString();
  }
  if (!tokenTotalSupplyRegex.test(dataToValidate.tokenTotalSupply)) {
    errors.push("Token Total Supply must be a valid number");
  }

  // Validate Twitter URL
  if (
    dataToValidate.spaceSocialTwitter &&
    !twitterUrlRegex.test(dataToValidate.spaceSocialTwitter)
  ) {
    errors.push("Twitter URL is not in valid format");
  }

  // Validate Github URL
  if (
    dataToValidate.spaceSocialGithub &&
    !githubUrlRegex.test(dataToValidate.spaceSocialGithub)
  ) {
    errors.push("Github URL is not in valid format");
  }

  // Validate Website URL
  if (
    dataToValidate.spaceSocialWebsite &&
    !websiteUrlRegex.test(dataToValidate.spaceSocialWebsite)
  ) {
    errors.push("Website URL is not in valid format");
  }

  return errors.join(", ");
};
