const domain = import.meta.env.VITE_WAIVLENGTH_APP_DOMAIN;

export function formatUserId(userId) {
  if (userId.startsWith("@") && userId.endsWith(`:${domain}`)) {
    const walletAddress = userId.slice(1, userId.indexOf(`:${domain}`));
    const truncatedAddress = `${walletAddress.slice(
      0,
      6
    )}...${walletAddress.slice(-4)}`;
    return truncatedAddress.toUpperCase();
  }
  return userId.toUpperCase();
}
