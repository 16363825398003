import React from "react";
import "./HighlightsContainer.scss";

const HighlightsContainer = ({ text, subtext, Icon, iconFill }) => {
  return (
    <div className="highlights">
      {Icon && <Icon className="highlights-icon" style={{ fill: iconFill }} />}
      <span className="highlights-text">{text}</span>
      <span className="highlights-subtext">{subtext}</span>
    </div>
  );
};

export default HighlightsContainer;
