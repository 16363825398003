import React from "react";
import { ReactComponent as ArrowBottom } from "../../assets/svg/vote.svg";
import { ReactComponent as CommentIcon } from "../../assets/svg/comments.svg";

const PostActions = ({
  userVote,
  handleVote,
  eventId,
  combinedCount,
  commentsCount,
  openViewPost,
  event,
}) => {
  return (
    <div className="post-actions">
      <div className="vote-options">
        <div
          className={`vote-button ${
            userVote !== null && userVote !== "upvote" ? "disabled" : ""
          }`}
          onClick={() => handleVote(eventId, "upvote", "posts")}
        >
          <ArrowBottom
            className={`upvote-svg ${userVote === "upvote" ? "selected" : ""}`}
          />
        </div>
        <span className="vote-count">{combinedCount}</span>
        <div
          className={`vote-button ${
            userVote !== null && userVote !== "downvote" ? "disabled" : ""
          }`}
          onClick={() => handleVote(eventId, "downvote", "posts")}
        >
          <ArrowBottom
            className={`downvote-svg ${
              userVote === "downvote" ? "selected" : ""
            }`}
          />
        </div>
      </div>
      <div
        className="post-actions-container"
        onClick={() => openViewPost(event)}
      >
        <CommentIcon className="comment-svg" />
        <span className="vote-count">{commentsCount}</span>
      </div>
    </div>
  );
};

export default PostActions;
