import React, { useState } from "react";
import "./WalletSettings.scss";
import CustomInput from "../../atoms/input/CustomInput";
import Loader from "../../atoms/loader/Loader";
import { Capacitor } from "@capacitor/core";
import SecureStoragePluginIOS from "../../../util/secureStoragePluginIOS";
import { decrypt } from "../../../util/encryption";
import Popover from "../../atoms/popover/Popover";
import SecretPhrase from "./SecretPhrase";
import SecureStoragePluginAndroid from "../../../util/secureStoragePluginAndroid";

const PasswordAccess = ({ walletAddress, saveManualButton }) => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const platform = Capacitor.getPlatform();
  const [mnemonic, setMnemonic] = useState("");
  const [secretPhraseDialog, setSecretPhraseDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const handleShowSecretPhrase = async () => {
    setLoading(true);

    try {
      const retrievedData = await (platform === "ios"
        ? SecureStoragePluginIOS.retrieve({ key: walletAddress })
        : SecureStoragePluginAndroid.retrieve({ key: walletAddress }));

      if (retrievedData) {
        try {
          const decryptedMnemonic = decrypt(retrievedData.mnemonic, password);
          setMnemonic(decryptedMnemonic);
          setSecretPhraseDialog(true);
        } catch (decryptionError) {
          setErrorMessage("Incorrect password. Please try again.");
          console.error("Decryption error:", decryptionError);
        }
      }
    } catch (error) {
      console.error("Error retrieving mnemonic:", error);
    } finally {
      setLoading(false);
      setPassword("");
    }
  };

  return (
    <div className="create-wallet-container">
      <span className="create-space-secondary-text">Wallet Address</span>
      <div className="create-wallet-address-container">
        <span className="create-wallet-address-text">{walletAddress}</span>
      </div>
      <span className="create-space-secondary-text">Enter Password</span>
      <CustomInput
        placeholder="Password"
        type="password"
        onChange={handlePasswordChange}
        autoFocus={[true, 300]}
        value={password}
      />
      <div
        disabled={loading}
        onClick={handleShowSecretPhrase}
        className="create-container-button-create"
      >
        {!loading && (
          <span className="create-room-button-text">Show Secret Phrase</span>
        )}
        {loading && (
          <Loader
            size="24px"
            dotSize="6px"
            color="var(--dark)"
            multiplier={1.4}
          />
        )}
      </div>
      {errorMessage && (
        <div className="create-room-error-text">{errorMessage}</div>
      )}
      {secretPhraseDialog && (
        <Popover
          direction="right"
          setOpenDialog={setSecretPhraseDialog}
          headerText="Secret Phrase"
        >
          <SecretPhrase
            mnemonic={mnemonic}
            setOpenDialog={setSecretPhraseDialog}
            saveManualButton={saveManualButton}
          />
        </Popover>
      )}
    </div>
  );
};

export default PasswordAccess;
