export const uploadMedia = async (mx, file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await mx.uploadContent(file, {
      includeFilename: true,
      type: file.type,
    });

    if (!response) {
      throw new Error("Failed to upload media");
    }

    return response.content_uri;
  } catch (error) {
    console.error("Failed to upload media:", error);
    return null;
  }
};
