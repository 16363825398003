import { ethers } from "ethers";
import PresaleABI from "./abi/PresaleABI.json";

export const claimReclaim = async ({
  signer,
  setErrorMessage,
  tokenData,
  setOpenDialog,
}) => {
  setErrorMessage("");
  try {
    const presaleContract = new ethers.Contract(
      tokenData.presaleContractAddress,
      PresaleABI,
      signer
    );

    const tx = await presaleContract.reclaimTokens();
    await tx.wait();
    setOpenDialog(false);
  } catch (error) {
    let errorMsg;
    if (error.code === "ACTION_REJECTED") {
      errorMsg = "Transaction was cancelled by the user.";
    } else if (error.info && error.info.error) {
      errorMsg = error.info.error.message;
    } else {
      errorMsg = "Failed to reclaim tokens.";
    }
    setErrorMessage(errorMsg);
  }
};
