import React, { createContext, useContext, useState } from "react";
const MessagingContext = createContext();

export const MessagingProvider = ({ children }) => {
  const [messageId, setMessageId] = useState(null);
  const [actionType, setActionType] = useState(null);

  const setEventAction = (id, action) => {
    setMessageId(id);
    setActionType(action);
  };

  return (
    <MessagingContext.Provider
      value={{ messageId, actionType, setEventAction }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export const useMessagingContext = () => useContext(MessagingContext);
