import { ethers } from "ethers";
import PresaleABI from "./abi/PresaleABI.json";

export const contributeToPresale = async ({
  signer,
  presaleData,
  amountInputValue,
  setErrorMessage,
  tokenData,
  setOpenDialog,
}) => {
  setErrorMessage("");

  try {
    const totalRaised = ethers.parseEther(presaleData.totalRaised.toString());
    const targetRaiseAmount = ethers.parseEther(
      presaleData.targetRaiseAmount.toString()
    );

    if (totalRaised >= targetRaiseAmount) {
      setErrorMessage(
        "The target fundraising amount has already been reached."
      );
      return;
    }

    if (
      !amountInputValue ||
      isNaN(amountInputValue) ||
      Number(amountInputValue) <= 0
    ) {
      setErrorMessage(
        "Please enter a valid contribution amount greater than 0."
      );
      return;
    }

    const amountToContribute = ethers.parseEther(amountInputValue);
    const minContribution = ethers.parseEther(
      tokenData.presaleFundraiserMinContribution.toString()
    );
    const maxContribution = ethers.parseEther(
      tokenData.presaleFundraiserMaxContribution.toString()
    );
    const totalContributionAfterNewContribution =
      amountToContribute + totalRaised;

    if (amountToContribute < minContribution) {
      setErrorMessage(
        `Contribution must be at least ${tokenData.presaleFundraiserMinContribution} ETH.`
      );
      return;
    }

    if (amountToContribute > maxContribution) {
      setErrorMessage(
        `Individual contributions cannot exceed ${tokenData.presaleFundraiserMaxContribution} ETH.`
      );
      return;
    }

    if (totalContributionAfterNewContribution > targetRaiseAmount) {
      setErrorMessage(
        `This contribution would exceed the target fundraising amount of ${presaleData.targetRaiseAmount} ETH.`
      );
      return;
    }

    if (totalContributionAfterNewContribution >= targetRaiseAmount) {
      setErrorMessage(
        `This contribution would exceed the target fundraising amount of ${tokenData.targetRaiseAmount} ETH.`
      );
      return;
    }

    const presaleContract = new ethers.Contract(
      tokenData.presaleContractAddress,
      PresaleABI,
      signer
    );

    const tx = await presaleContract.contribute({
      value: amountToContribute.toString(),
    });
    await tx.wait();
    setOpenDialog(false);
  } catch (error) {
    let errorMsg;
    if (error.code === "ACTION_REJECTED") {
      errorMsg = "Transaction was cancelled by the user.";
    } else if (error.info && error.info.error) {
      errorMsg = error.info.error.message;
    } else {
      errorMsg = "Error during contribution to presale.";
    }
    setErrorMessage(errorMsg);
  }
};
