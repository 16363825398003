import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Settings.scss";
import initMatrix from "../../../client/initMatrix";
import RoomSearch from "../../molecules/room-search/RoomSearch";
import RoomPermissions from "../../molecules/room-permissions/RoomPermissions";
import RoomMembers from "../../molecules/room-members/RoomMembers";
import { ReactComponent as Lock } from "../../assets/svg/filllock.svg";
import { ReactComponent as ShieldEmpty } from "../../assets/svg/fillshield.svg";
import { ReactComponent as Search } from "../../assets/svg/fillsearch.svg";
import { ReactComponent as Members } from "../../assets/svg/fillmembers.svg";
import { ReactComponent as ArrowBottom } from "../../assets/svg/arrow-bottom.svg";
import { ReactComponent as RoomVisibilityIcon } from "../../assets/svg/fillvisibility.svg";
import GeneralSettings from "./GeneralSettings";
import Popover from "../../atoms/popover/Popover";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import RoomDetails from "./RoomDetails";
import SecuritySettings from "./SecuritySettings";
import SettingButton from "../../atoms/button/SettingsButton";
import RoomVisibility from "../../molecules/room-visibility/RoomVisibility";
import InviteUserSettings from "./InviteUserSettings";

function RoomSettings({ roomId, setOpenRoomSettingsDialog }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const isDM = initMatrix.roomList.directs.has(roomId);
  const canChangePermission = room.currentState.maySendStateEvent(
    "m.room.power_levels",
    mx.getUserId()
  );
  const isSpace =
    initMatrix.matrixClient.getRoom(roomId)?.getType() === "m.space";
  const roomNameFormatted = initMatrix.matrixClient.getRoom(roomId).name;
  const [nestedProfileDialog, setNestedProfileDialog] = useState(false);
  const [nestedSearchDialog, setNestedSearchDialog] = useState(false);
  const [nestedMembersDialog, setNestedMembersDialog] = useState(false);
  const [nestedPermissionsDialog, setNestedPermissionsDialog] = useState(false);
  const [nestedSecurityDialog, setNestedSecurityDialog] = useState(false);
  const [nestedVisibilityDialog, setNestedVisibilityDialog] = useState(false);

  const roomTopicFormatted = initMatrix.matrixClient
    .getRoom(roomId)
    .currentState.getStateEvents("m.room.topic")[0]
    ?.getContent().topic;
  const avatarUrl = initMatrix.matrixClient
    .getRoom(roomId)
    .getAvatarUrl(initMatrix.matrixClient.baseUrl, 42, 42, "crop");

  return (
    <div className="room-settings-wrapper">
      {!isDM && (
        <span className="wallet-connected-secondary-text">
          {isSpace ? "Space Details" : "Room Details"}
        </span>
      )}
      {!isDM && (
        <div
          className="wallet-connected-profile-settings"
          onClick={() => setNestedProfileDialog(true)}
        >
          <div>
            <RoomAvatar
              roomId={roomId}
              imageSrc={avatarUrl}
              size={48}
              borderRadius={99}
            />
          </div>
          <div className="wallet-connected-profile-names">
            <span className="wallet-connected-primary-text">
              {roomNameFormatted}
            </span>
            <span className="wallet-connected-tertiary-text">
              {roomTopicFormatted}
            </span>
          </div>
          <ArrowBottom className="wallet-connected-svglogo" />
        </div>
      )}
      <InviteUserSettings roomId={roomId} />
      {nestedProfileDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedProfileDialog}
          headerText={isSpace ? "Space Details" : "Room Details"}
        >
          <RoomDetails roomId={roomId} />
        </Popover>
      )}
      <span className="wallet-connected-secondary-text">
        {isSpace ? "Space Management" : "Room Management"}
      </span>

      {!isSpace && (
        <SettingButton
          primaryText={"Search Messages"}
          Icon={() => <Search className="wallet-connected-svglogo-fill" />}
          onClick={() => setNestedSearchDialog(true)}
        />
      )}
      {!isSpace && nestedSearchDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedSearchDialog}
          headerText={"Search Messages"}
        >
          <RoomSearch
            roomId={roomId}
            setNestedSearchDialog={setNestedSearchDialog}
            setOpenRoomSettingsDialog={setOpenRoomSettingsDialog}
          />
        </Popover>
      )}
      {!isDM && (
        <SettingButton
          primaryText={isSpace ? "Space Members" : "Room Members"}
          Icon={() => <Members className="wallet-connected-svglogo-fill" />}
          onClick={() => setNestedMembersDialog(true)}
        />
      )}
      {nestedMembersDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedMembersDialog}
          headerText={"Members"}
        >
          <RoomMembers
            roomId={roomId}
            setNestedMembersDialog={setNestedMembersDialog}
            setOpenRoomSettingsDialog={setOpenRoomSettingsDialog}
          />
        </Popover>
      )}
      {!isDM && canChangePermission && (
        <SettingButton
          primaryText="Permissions"
          Icon={() => <Lock className="wallet-connected-svglogo-fill" />}
          onClick={() => setNestedPermissionsDialog(true)}
        />
      )}
      {nestedPermissionsDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedPermissionsDialog}
          headerText={"Permission Settings"}
        >
          <RoomPermissions roomId={roomId} />
        </Popover>
      )}
      {!isDM && (
        <SettingButton
          primaryText={"Security"}
          Icon={() => <ShieldEmpty className="wallet-connected-svglogo-fill" />}
          onClick={() => setNestedSecurityDialog(true)}
        />
      )}
      {nestedSecurityDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedSecurityDialog}
          headerText={"Security Settings"}
        >
          <SecuritySettings roomId={roomId} />
        </Popover>
      )}
      {canChangePermission && !isDM && !isSpace && (
        <SettingButton
          primaryText="Room Visibility"
          Icon={() => (
            <RoomVisibilityIcon className="wallet-connected-svglogo-fill" />
          )}
          onClick={() => setNestedVisibilityDialog(true)}
        />
      )}
      {nestedVisibilityDialog && (
        <Popover
          direction="right"
          setOpenDialog={setNestedVisibilityDialog}
          headerText={"Room Visibility"}
        >
          <RoomVisibility roomId={roomId} />
        </Popover>
      )}
      <div className="wallet-connected-disconnect-container">
        <GeneralSettings roomId={roomId} />
      </div>
    </div>
  );
}

RoomSettings.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default RoomSettings;
