import React from "react";
import NavigationLegal from "../../components/navigation/NavigationLegal";
import Header from "../../atoms/blog/Header";
import "./AccountDeletion.scss";
import Paragraph from "../../atoms/blog/Paragraph";

const AccountDeletionUnconnected = ({ isPopover }) => {
  return (
    <div
      className={isPopover ? "popover-privacy-app-wrapper" : "legal-wrapper"}
    >
      {!isPopover && <NavigationLegal />}
      <div className="account-deactivation-wrapper">
        <Header
          text="Account Deactivation and Data Management"
          tagText="ACCOUNT DEACTIVATION"
          marginBottom={false}
        />
        <Paragraph
          text="Account Deactivation Process:"
          primaryText="By deactivating your account, you are initiating a process that will prevent you from logging in after a 90-day grace period. During this period, you can reactivate your account by logging back in and revoking the deactivation. If you do not log in within 90 days, your account will be permanently deactivated, and you will not be able to log in again. Please note that to initiate this process, you must be logged in to your account."
        />
        <Paragraph
          text="Important Notes:"
          primaryText="Account deactivation will remove your ability to log in but does not necessarily delete all associated data across the app. The data associated with your account, such as messages and other interactions, will remain in the app's ecosystem unless specified otherwise in our privacy policy. For further details, please refer to our privacy policy."
        />
      </div>
    </div>
  );
};

export default AccountDeletionUnconnected;
