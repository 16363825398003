import React, { useEffect, useState, useCallback } from "react";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import "./Home.scss";
import usernameColorMXID from "../../../util/usernameColorMXID";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import ReferralScoreIcon from "../../assets/svg/ReferralScoreIcon";
import NoChatIcon from "../../assets/svg/NoChat";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import ChatFilter from "../../molecules/chats/ChatFilter";
import SpaceItem from "../../molecules/space-item/SpaceItem";
import Modal from "../../atoms/modal/Modal";
import RoomOptions from "../../molecules/room-options/RoomOptions";
import Popover from "../../atoms/popover/Popover";
import CreateSpace from "../create-space/CreateSpace";
import SearchBarMobile from "../../components/navbar/SearchBarMobile";

const Home = ({ matrixUser }) => {
  const [allSpaceList, setAllSpaceList] = useState([]);
  const [spaceList, setSpaceList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [isRoomOptionsModalOpen, setIsRoomOptionsModalOpen] = useState(false);
  const [openSpaceDialog, setOpenSpaceDialog] = useState(false);
  const [isDMSearchPopoverOpen, setIsDMSearchPopoverOpen] = useState(false);

  const mx = initMatrix.matrixClient;
  const { notifications, roomList, accountData } = initMatrix;
  const { setSpaceId } = useRoomSelect();

  const createSpaceData = useCallback(
    (spaceRoom) => {
      const roomId = spaceRoom.roomId;
      let avatarUrl = spaceRoom.getAvatarUrl
        ? spaceRoom.getAvatarUrl(mx.baseUrl, 40, 40, "crop")
        : null;

      const unreadCount = notifications.getTotalNoti(roomId);
      const membersMap = spaceRoom.currentState?.members;
      let membersArray = [];
      if (membersMap) {
        if (typeof membersMap.values === "function") {
          membersArray = Array.from(membersMap.values());
        } else {
          membersArray = Object.values(membersMap);
        }
      }
      const firstFiveMembers = membersArray.slice(0, 5);
      const joinedMemberCount = spaceRoom.getJoinedMemberCount();
      let tokenSymbol = null;
      if (
        spaceRoom.currentState &&
        typeof spaceRoom.currentState.getStateEvents === "function"
      ) {
        const tokenDataEvent = spaceRoom.currentState.getStateEvents(
          "custom.token_data",
          ""
        );
        if (tokenDataEvent) {
          const tokenData = tokenDataEvent.getContent();
          tokenSymbol = tokenData?.tokenSymbol || null;
        }
      }
      let roomTopic = null;
      if (
        spaceRoom.currentState &&
        typeof spaceRoom.currentState.getStateEvents === "function"
      ) {
        const topicEvent = spaceRoom.currentState.getStateEvents(
          "m.room.topic",
          ""
        );
        if (topicEvent) {
          const topicContent = topicEvent.getContent();
          roomTopic = topicContent?.topic || null;
        }
      }
      const enhancedMembers = firstFiveMembers.map((member) => {
        let memberAvatarUrl = null;
        if (member.getAvatarUrl) {
          memberAvatarUrl = member.getAvatarUrl(mx.baseUrl, 40, 40, "crop");
        } else if (member.avatarUrl) {
          memberAvatarUrl = member.avatarUrl;
        }

        return {
          userId: member.userId,
          avatarUrl: memberAvatarUrl,
        };
      });

      return {
        id: roomId,
        name: spaceRoom.name,
        avatar: avatarUrl,
        unreadCount: unreadCount,
        members: enhancedMembers,
        joinedMemberCount: joinedMemberCount,
        tokenSymbol: tokenSymbol,
        topic: roomTopic, 
      };
    },
    [mx.baseUrl, notifications]
  );

  const updateSpaceList = useCallback(() => {
    const spaceIds = roomList
      .getOrphanSpaces()
      .filter((id) => !accountData.spaceShortcut.has(id));

    const spaceRooms = spaceIds
      .map((roomId) => mx.getRoom(roomId))
      .filter((room) => room != null);

    const allSpaceData = spaceRooms.map(createSpaceData);

    setAllSpaceList(allSpaceData);

    let filteredSpaceData = [...allSpaceData];
    if (selectedFilter === "Unread") {
      filteredSpaceData = filteredSpaceData.filter(
        (space) => space.unreadCount > 0
      );
    }

    filteredSpaceData.sort((a, b) => {
      const aUnread = a.unreadCount > 0 ? 1 : 0;
      const bUnread = b.unreadCount > 0 ? 1 : 0;
      if (aUnread !== bUnread) {
        return bUnread - aUnread;
      }
      return a.name.localeCompare(b.name);
    });

    setSpaceList(filteredSpaceData);
  }, [accountData.spaceShortcut, createSpaceData, roomList, selectedFilter]);

  useEffect(() => {
    updateSpaceList();

    const handleNotiChanged = (roomId) => {
      updateSpaceList();
    };

    const handleRoomListUpdate = () => {
      updateSpaceList();
    };

    notifications.on(cons.events.notifications.NOTI_CHANGED, handleNotiChanged);
    notifications.on(cons.events.notifications.MUTE_TOGGLED, handleNotiChanged);
    roomList.on(cons.events.roomList.ROOMLIST_UPDATED, handleRoomListUpdate);

    return () => {
      notifications.off(
        cons.events.notifications.NOTI_CHANGED,
        handleNotiChanged
      );
      notifications.off(
        cons.events.notifications.MUTE_TOGGLED,
        handleNotiChanged
      );
      roomList.off(cons.events.roomList.ROOMLIST_UPDATED, handleRoomListUpdate);
    };
  }, [updateSpaceList, notifications, roomList]);

  useEffect(() => {
    updateSpaceList();
  }, [selectedFilter, updateSpaceList]);

  const handleSpaceClick = (roomId) => {
    setSpaceId(roomId);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const handleMoreClick = (roomId) => {
    setSelectedRoomId(roomId);
    setIsRoomOptionsModalOpen(true);
  };

  const handleCloseRoomOptionsModal = () => {
    setIsRoomOptionsModalOpen(false);
  };

  const handleCreateNewSpaceClick = () => {
    setOpenSpaceDialog(true);
  };

  const handleDiscoverNewSpaceClick = () => {
    setIsDMSearchPopoverOpen(true);
  };

  const handleCloseDMSearchPopover = () => {
    setIsDMSearchPopoverOpen(false);
  };

  const colorStyle = usernameColorMXID(matrixUser.userId);

  return (
    <div className="chats-container">
      <div className="chat-header-container">
        <span className="chat-header-text">Spaces</span>
        {allSpaceList.length !== 0 && (
          <div className="add-chat" onClick={handleCreateNewSpaceClick}>
            <Plus className="add-chat-icon" />
          </div>
        )}
      </div>
      <div className="chat-filters">
        {["All", "Unread"].map((filter) => (
          <ChatFilter
            key={filter}
            label={filter}
            isActive={selectedFilter === filter}
            onClick={() => handleFilterClick(filter)}
          />
        ))}
      </div>
      <div className="chat-list">
        {allSpaceList.length === 0 ? (
          <div className="no-chat-container">
            <div className="no-chat-icon-container">
              <ReferralScoreIcon
                colorStyle={colorStyle}
                height={56}
                width={56}
              />
            </div>
            <span className="no-chat-title-text">
              Create or join a space to connect with your community.
            </span>
            <div
              className="create-new-chat"
              onClick={handleCreateNewSpaceClick}
            >
              <span className="create-new-chat-text">Create Space</span>
            </div>
            <div
              className="create-new-chat-button"
              onClick={handleDiscoverNewSpaceClick}
            >
              <span className="create-new-chat-text-button">
                Discover Space
              </span>
            </div>
          </div>
        ) : spaceList.length === 0 ? (
          <div className="no-chat-container">
            <div className="no-chat-icon-container">
              <NoChatIcon colorStyle={colorStyle} height={56} width={56} />
            </div>
            <span className="no-chat-title-text">No spaces here.</span>
          </div>
        ) : (
          spaceList.map((space) => (
            <SpaceItem
              key={space.id}
              space={space}
              onSpaceClick={handleSpaceClick}
              onMoreClick={handleMoreClick}
            />
          ))
        )}
      </div>
      <Modal
        isOpen={isRoomOptionsModalOpen}
        onClose={handleCloseRoomOptionsModal}
      >
        <RoomOptions
          roomId={selectedRoomId}
          afterOptionSelect={handleCloseRoomOptionsModal}
        />
      </Modal>
      {openSpaceDialog && (
        <Popover
          direction="right"
          setOpenDialog={setOpenSpaceDialog}
          headerText="Create Space"
        >
          <CreateSpace setOpenSpaceDialog={setOpenSpaceDialog} />
        </Popover>
      )}
      {isDMSearchPopoverOpen && (
        <Popover
          direction="right"
          setOpenDialog={setIsDMSearchPopoverOpen}
          headerText="Search"
        >
          <SearchBarMobile onClose={handleCloseDMSearchPopover} />
        </Popover>
      )}
    </div>
  );
};

export default Home;
