import initMatrix from "../client/initMatrix";

/**
 * Waits for a room to be ready and returns the room object.
 *
 * @param {string} roomId - The ID of the room to wait for.
 * @returns {Promise<object|null>} - Resolves to the room object if the room is ready, otherwise null.
 */
export default async function waitForRoom(roomId) {
  const mx = initMatrix.matrixClient;
  const timeout = 100;
  const maxTry = -1;

  const isRoomReady = () => mx.getRoom(roomId) !== null;
  const getRoom = () => mx.getRoom(roomId);

  /**
   * @param {() => boolean} callback - The callback function to check if the condition is met.
   * @param {number} timeout - The timeout in milliseconds for each callback attempt.
   * @param {number} maxTry - The maximum number of callback attempts.
   * @returns {Promise<boolean>} - Resolves to true if the condition is met, otherwise false.
   */
  async function waitFor(callback, timeout, maxTry) {
    if (maxTry === 0) return false;
    const isOver = async () =>
      new Promise((resolve) => {
        setTimeout(() => resolve(callback()), timeout);
      });

    if (await isOver()) return true;
    return waitFor(callback, timeout, maxTry - 1);
  }

  const isReady = await waitFor(isRoomReady, timeout, maxTry);
  return isReady ? getRoom() : null;
}
