export const parseTimeLeft = (timeLeftString) => {
  const timeStructure = {
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  };

  const regex = /(\d+)\s*(d|h|m|s|days?|hours?|mins?|secs?)/g;
  let matches;

  while ((matches = regex.exec(timeLeftString)) !== null) {
    const [_, value, unit] = matches;
    const parsedValue = parseInt(value, 10);
    switch (unit[0]) {
      case "d":
        timeStructure.days += parsedValue;
        break;
      case "h":
        timeStructure.hours += parsedValue;
        break;
      case "m":
        timeStructure.mins += parsedValue;
        break;
      case "s":
        timeStructure.secs += parsedValue;
        break;
      default:
        break;
    }
  }

  // Handle overflow for hours to days
  if (timeStructure.hours >= 24) {
    const extraDays = Math.floor(timeStructure.hours / 24);
    timeStructure.days += extraDays;
    timeStructure.hours = timeStructure.hours % 24;
  }

  // Handle overflow for mins and secs, if needed
  if (timeStructure.mins >= 60) {
    const extraHours = Math.floor(timeStructure.mins / 60);
    timeStructure.hours += extraHours;
    timeStructure.mins = timeStructure.mins % 60;
  }

  if (timeStructure.secs >= 60) {
    const extraMins = Math.floor(timeStructure.secs / 60);
    timeStructure.mins += extraMins;
    timeStructure.secs = timeStructure.secs % 60;
  }

  const formatValue = (value) =>
    value === 0 ? "0" : String(value).padStart(2, "0");

  return [
    { label: "days", value: formatValue(timeStructure.days) },
    { label: "hours", value: formatValue(timeStructure.hours) },
    { label: "mins", value: formatValue(timeStructure.mins) },
    { label: "secs", value: formatValue(timeStructure.secs) },
  ];
};
