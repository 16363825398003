import { Capacitor } from "@capacitor/core";

const targetAddresses = [
  "0xD1bFE30Fb4aB6619bE03c7e41C7E9A00222ECE9c",
  "0x9e7D5961Da5618CE8dB7Dc704dAf8Ae06530B538",
  "0x9B4f4B0A543b40Afa00C94d0278152a4045e0D5f",
  "0xEEf5C00322A27a765dA0620353328031237f49cD",
  "0x99beD6B7485EAc1C6925a6493d38328e3C12486d",
  "0x4470460Ac5e9Eb71A459fA9e07D42685F8C4cE4C",
  "0xDBfed8e44918d69C873778be7d528086cEB4c9b5",
  "0x88c6B17CeA6669B3df072ffbbaf99534E1E54660",
];

const isValidAddress = (address) => {
  const platform = Capacitor.getPlatform();

  if (platform === "ios" || platform === "android") {
    return true;
  }

  if (!address) return false;
  const lowerCaseAddress = address.toLowerCase();
  return targetAddresses.some(
    (target) => lowerCaseAddress === target.toLowerCase()
  );
};

export default isValidAddress;
