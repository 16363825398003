import { ethers } from "ethers";
import PresaleABI from "./abi/PresaleABI.json";

export const claimPresale = async ({
  signer,
  presaleData,
  setErrorMessage,
  tokenData,
  setOpenDialog,
}) => {
  setErrorMessage("");
  try {
    const totalRaised = BigInt(
      ethers.parseEther(presaleData.totalRaised.toString())
    );
    const halfOfTargetRaiseAmount = BigInt(
      ethers.parseEther((presaleData.targetRaiseAmount / 2).toString())
    );

    if (totalRaised < halfOfTargetRaiseAmount) {
      throw new Error(
        "Cannot finalize the presale yet: the minimum fundraising threshold has not been met."
      );
    }

    const presaleContract = new ethers.Contract(
      tokenData.presaleContractAddress,
      PresaleABI,
      signer
    );

    const tx = await presaleContract.claimTokens();
    await tx.wait();
    setOpenDialog(false);
  } catch (error) {
    let errorMsg;
    if (error.code === "ACTION_REJECTED") {
      errorMsg = "Transaction was cancelled by the user.";
    } else if (error.info && error.info.error) {
      errorMsg = error.info.error.message;
    } else {
      errorMsg = "Error during claim.";
    }
    setErrorMessage(errorMsg);
  }
};
