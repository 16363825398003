// util/formatTimestamp.js

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();

  // Helper to check if two dates are the same day
  const isSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  // Helper to check if a date is yesterday
  const isYesterday = (d) => {
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    return isSameDay(d, yesterday);
  };

  // Helper to check if the date is within the last 6 days (excluding today and yesterday)
  const isWithinLastWeek = (d) => {
    const sixDaysAgo = new Date(now);
    sixDaysAgo.setDate(now.getDate() - 6);
    return d > sixDaysAgo && !isSameDay(d, now) && !isYesterday(d);
  };

  // Return formatted time for today
  if (isSameDay(date, now)) {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  // Return "Yesterday" if it happened yesterday
  if (isYesterday(date)) {
    return "Yesterday";
  }

  // Return day of the week for events within the last 6 days
  if (isWithinLastWeek(date)) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }

  // Otherwise, return the full date in DD/MM/YYYY format
  return date.toLocaleDateString("en-GB");
};
