import React from "react";
import PropTypes from "prop-types";
import "./Divider.scss";

function Divider({ text, variant, align }) {
  const dividerClass = ` divider--${variant} divider--${align}`;
  return (
    <div className={`divider${dividerClass}`}>
      {text !== null && <span className="divider__text">{text}</span>}
    </div>
  );
}

Divider.defaultProps = {
  text: null,
  variant: "surface",
  align: "center",
};

Divider.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf([
    "surface",
    "primary",
    "positive",
    "caution",
    "danger",
  ]),
  align: PropTypes.oneOf(["left", "center", "right"]),
};

export default Divider;
