import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as RemoveRow } from "../../assets/svg/minus.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import CustomInput from "../../atoms/input/CustomInput";

const SpaceTokenomicsComponent = ({
  tokenTotalSupply,
  initialTokenomics,
  setPresaleData,
  scrollToInput,
}) => {
  const [pools, setPools] = useState([]);

  const DynamicFontSizeText = ({ text }) => {
    const textRef = useRef(null);

    useEffect(() => {
      const adjustFontSize = () => {
        if (textRef.current) {
          let fontSize = 26;
          const maxWidth = textRef.current.parentNode.offsetWidth;
          textRef.current.style.fontSize = `${fontSize}px`;

          while (textRef.current.scrollWidth > maxWidth && fontSize > 10) {
            fontSize--;
            textRef.current.style.fontSize = `${fontSize}px`;
          }
        }
      };

      adjustFontSize();
      window.addEventListener("resize", adjustFontSize);

      return () => {
        window.removeEventListener("resize", adjustFontSize);
      };
    }, [text]);

    return (
      <span ref={textRef} className="spaceview-tokenomics-input-tokens-text">
        {text}
      </span>
    );
  };

  useEffect(() => {
    const presaleRow = initialTokenomics.find(
      (row) => row.Pool === "Presale"
    ) || {
      Pool: "Presale",
      "Supply (%)": "",
      Tokens: 0,
      "Vesting (m)": 0,
      "Lock Up (m)": 0,
    };
    const otherRows = initialTokenomics.filter((row) => row.Pool !== "Presale");
    setPools([presaleRow, ...otherRows]);
  }, [initialTokenomics]);

  const handleAddPool = () => {
    setPools((currentPools) => {
      const updatedPools = [...currentPools, {}];
      setPresaleData((prevData) => ({
        ...prevData,
        tokenTokenomics: updatedPools,
      }));
      return updatedPools;
    });
  };

  const handleRemovePool = () => {
    setPools((currentPools) => {
      const updatedPools = currentPools.slice(0, -1);
      setPresaleData((prevData) => ({
        ...prevData,
        tokenTokenomics: updatedPools,
      }));
      return updatedPools;
    });
  };

  const handleInputChange = (index, column, value) => {
    setPools((currentPools) => {
      const newPools = [...currentPools];
      if (
        index === 0 &&
        ["Pool", "Vesting (m)", "Lock Up (m)"].includes(column)
      ) {
        return currentPools;
      } else {
        newPools[index] = { ...newPools[index], [column]: value };
        if (column === "Supply (%)" && value !== "") {
          newPools[index].Tokens = (
            tokenTotalSupply *
            (parseFloat(value) / 100)
          ).toFixed(2);
        }
        setPresaleData((prevData) => ({
          ...prevData,
          tokenTokenomics: newPools,
        }));
        return newPools;
      }
    });
  };

  return (
    <div className="presale-form-content">
      <span className="presale-primary-text">Tokenomics</span>
      <span className="presale-secondary-text">
        Configure your tokens tokenomics here. Distribute them to various pools
        and set up their vesting schedules.
      </span>
      <div className="presale-add-button-container">
        <div onClick={handleAddPool} className="presale-button-svg">
          <Plus className="presale-button-svg-icon" />
        </div>
        <div
          onClick={handleRemovePool}
          style={{ display: pools.length <= 2 ? "none" : "flex" }}
          className="presale-button-svg"
        >
          <RemoveRow className="presale-button-svg-icon" />
        </div>
      </div>
      <div className="presale-tokenomics-container">
        {pools.map((pool, index) => (
          <div
            key={index}
            className="presale-tokenomics-container-pool-component"
          >
            <div className="presale-tokenomics-container-level">
              <div className="presale-tokenomics-container-level-pool">
                <span className="presale-tokenomics-input-header-text">
                  Pool Name
                </span>
                <CustomInput
                  type="text"
                  placeholder="Pool Name"
                  disabled={index === 0}
                  value={pool.Pool}
                  onChange={(e) =>
                    handleInputChange(index, "Pool", e.target.value)
                  }
                  scrollToInput={scrollToInput}
                />
              </div>
              <div className="presale-tokenomics-container-level-supply">
                <span className="presale-tokenomics-input-header-text">
                  % Supply
                </span>
                <CustomInput
                  placeholder="%"
                  type="number"
                  value={pool["Supply (%)"]}
                  onChange={(e) =>
                    handleInputChange(index, "Supply (%)", e.target.value)
                  }
                  scrollToInput={scrollToInput}
                />
              </div>
            </div>
            <div className="presale-tokenomics-container-level">
              <div className="presale-tokenomics-container-level-tokens">
                <span className="presale-tokenomics-input-header-text">
                  Tokens
                </span>
                <div className="presale-tokenomics-container-level-tokens-container">
                  <DynamicFontSizeText text={pool.Tokens || "-"} />
                </div>
              </div>
              <div className="presale-tokenomics-container-level-vesting">
                <span className="presale-tokenomics-input-header-text">
                  Vesting (m)
                </span>
                <CustomInput
                  type="number"
                  placeholder="#"
                  value={pool["Vesting (m)"]}
                  disabled={index === 0}
                  onChange={(e) =>
                    handleInputChange(index, "Vesting (m)", e.target.value)
                  }
                  scrollToInput={scrollToInput}
                />
              </div>
              <div className="presale-tokenomics-container-level-lockup">
                <span className="presale-tokenomics-input-header-text">
                  Lock Up (m)
                </span>
                <CustomInput
                  type="number"
                  placeholder="#"
                  value={pool["Lock Up (m)"]}
                  disabled={index === 0}
                  onChange={(e) =>
                    handleInputChange(index, "Lock Up (m)", e.target.value)
                  }
                  scrollToInput={scrollToInput}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpaceTokenomicsComponent;
