/* eslint-disable import/prefer-default-export */
import React, { lazy, Suspense } from "react";

import linkifyHtml from "linkify-html";
import parse from "html-react-parser";
import { sanitizeText } from "./sanitize";

const Math = lazy(() => import("../app/atoms/math/Math"));

const mathOptions = {
  replace: (node) => {
    const maths = node.attribs?.["data-mx-maths"];
    if (maths) {
      return (
        <Suspense fallback={<code>{maths}</code>}>
          <Math
            content={maths}
            throwOnError={false}
            errorColor="var(--tc-danger-normal)"
            displayMode={node.name === "div"}
          />
        </Suspense>
      );
    }
    return null;
  },
};

export function twemojify(
  text,
  opts,
  linkify = false,
  sanitize = true,
  maths = false,
  isCurrentMessenger = false // Added parameter
) {
  if (typeof text !== "string") return text;
  let content = text;

  if (sanitize) {
    content = sanitizeText(content);
  }

  // Linkify text manually by converting URLs to <a> tags
  if (linkify) {
    content = content.replace(/https?:\/\/[^\s]+|www\.[^\s]+/g, (url) => {
      const href = url.startsWith("http") ? url : `http://${url}`;
      // Add an additional class based on isCurrentMessenger
      const className = `underline-link${isCurrentMessenger ? " sender" : ""}`;
      return `<a href="${href}" class="${className}" target="_blank" rel="noreferrer noopener">${url}</a>`;
    });
  }

  return parse(content, maths ? mathOptions : null);
}
