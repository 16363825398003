import React from "react";
import "./CustomKeyboard.scss";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";

const CustomKeyboard = ({ onInput }) => {
  const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"];

  const handleKeyPress = (key) => {
    onInput(key);
  };

  return (
    <div className="custom-keyboard no-touch">
      {keys.map((key) => (
        <div
          key={key}
          onClick={() => handleKeyPress(key)}
          className="keyboard-key no-touch"
        >
          <span className="keyboard-key-text no-touch"> {key}</span>
        </div>
      ))}
      <div
        className="swap-component-backspace no-touch"
        onClick={() => handleKeyPress("backspace")}
      >
        <ArrowDown className="arrow-back-svglogo" />
      </div>
    </div>
  );
};

export default CustomKeyboard;
