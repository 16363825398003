import React from "react";

const AppleAppStoreIcon = ({ width, height, fill, fill2 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 189 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2529_9882)">
        <path
          d="M173.728 0.00020475H15.2827C14.7052 0.00020475 14.1346 0.00020475 13.5585 0.00335475C13.0764 0.00650475 12.598 0.0156555 12.1112 0.0233573C11.0536 0.0358026 9.99851 0.128845 8.95506 0.301676C7.91308 0.478269 6.90373 0.811197 5.96117 1.2892C5.01976 1.77125 4.15957 2.39762 3.4118 3.14559C2.6601 3.89146 2.0335 4.75358 1.5561 5.69881C1.07738 6.64215 0.745434 7.65303 0.571726 8.69654C0.396367 9.73872 0.302005 10.7929 0.289486 11.8497C0.27487 12.3326 0.273327 12.8171 0.265625 13.3V49.705C0.273327 50.194 0.27487 50.6678 0.289486 51.157C0.302009 52.2137 0.396371 53.2679 0.571726 54.31C0.744954 55.3541 1.07692 56.3656 1.5561 57.3093C2.03329 58.2514 2.65997 59.1101 3.4118 59.8518C4.15673 60.6031 5.01748 61.2299 5.96117 61.7082C6.90373 62.1875 7.91296 62.5224 8.95506 62.7017C9.99868 62.8732 11.0537 62.9663 12.1112 62.9802C12.598 62.9909 13.0764 62.9971 13.5585 62.9971C14.1346 63.0002 14.7052 63.0002 15.2827 63.0002H173.728C174.294 63.0002 174.869 63.0002 175.435 62.9971C175.915 62.9971 176.407 62.9909 176.887 62.9802C177.943 62.967 178.996 62.8739 180.037 62.7017C181.083 62.5212 182.095 62.1863 183.043 61.7082C183.985 61.2296 184.845 60.6028 185.59 59.8518C186.34 59.1072 186.968 58.2492 187.451 57.3093C187.926 56.3649 188.255 55.3535 188.426 54.31C188.601 53.2677 188.699 52.2138 188.718 51.157C188.724 50.6678 188.724 50.194 188.724 49.705C188.737 49.1328 188.737 48.5638 188.737 47.9823V15.0196C188.737 14.4428 188.737 13.8707 188.724 13.3C188.724 12.8171 188.724 12.3326 188.718 11.8496C188.699 10.7928 188.601 9.7388 188.426 8.69647C188.255 7.65357 187.926 6.64277 187.451 5.69874C186.479 3.80414 184.937 2.26194 183.043 1.28906C182.095 0.812223 181.083 0.479383 180.037 0.301534C178.996 0.127942 177.943 0.034867 176.887 0.0231368C176.407 0.0154508 175.915 0.00622125 175.435 0.00315C174.869 0 174.294 0 173.728 0V0.00020475Z"
          fill={fill}
        />
        <path
          d="M13.5662 61.6217C13.0864 61.6217 12.6181 61.6155 12.142 61.6048C11.1557 61.592 10.1717 61.5061 9.19809 61.3479C8.29024 61.1916 7.41079 60.9007 6.58872 60.485C5.7742 60.0727 5.03129 59.5321 4.38845 58.8839C3.73631 58.2433 3.19337 57.5003 2.78115 56.6844C2.36438 55.8631 2.07596 54.9829 1.92592 54.0743C1.76389 53.098 1.67623 52.1107 1.66368 51.1212C1.6537 50.789 1.64062 49.683 1.64062 49.683V13.2996C1.64062 13.2996 1.65455 12.2107 1.66376 11.8907C1.67577 10.9027 1.76293 9.91703 1.92447 8.94223C2.07479 8.03112 2.36344 7.14833 2.78042 6.32442C3.19113 5.50907 3.73105 4.76553 4.37927 4.12265C5.02675 3.47366 5.77204 2.93026 6.58795 2.51226C7.40813 2.09785 8.28592 1.80906 9.19193 1.65555C10.1687 1.4958 11.1561 1.40942 12.1459 1.39716L13.567 1.37793H175.427L176.866 1.39793C177.846 1.40959 178.825 1.49519 179.793 1.65401C180.708 1.80944 181.595 2.10025 182.424 2.51688C184.059 3.35926 185.389 4.69199 186.228 6.32827C186.638 7.14649 186.922 8.02208 187.071 8.92531C187.234 9.90812 187.326 10.9015 187.345 11.8977C187.349 12.3437 187.349 12.8228 187.349 13.2996C187.362 13.8903 187.362 14.4524 187.362 15.0192V47.9819C187.362 48.5541 187.362 49.1124 187.349 49.6753C187.349 50.1875 187.349 50.6567 187.343 51.1396C187.325 52.1179 187.235 53.0936 187.074 54.0588C186.927 54.974 186.64 55.8611 186.223 56.6891C185.808 57.4962 185.268 58.2327 184.624 58.8716C183.98 59.5233 183.236 60.0671 182.42 60.482C181.592 60.901 180.707 61.1929 179.793 61.3479C178.819 61.507 177.835 61.5928 176.849 61.6048C176.387 61.6155 175.904 61.6217 175.435 61.6217L173.728 61.6248L13.5662 61.6217Z"
          fill={fill2}
        />
        <path
          d="M39.2784 31.9739C39.2954 30.6593 39.6445 29.3704 40.2935 28.227C40.9424 27.0836 41.87 26.123 42.99 25.4344C42.2785 24.4183 41.3399 23.582 40.2487 22.9921C39.1575 22.4022 37.9438 22.0748 36.7039 22.0361C34.0591 21.7584 31.4951 23.6187 30.1474 23.6187C28.7735 23.6187 26.6985 22.0636 24.464 22.1096C23.0186 22.1563 21.61 22.5766 20.3754 23.3295C19.1408 24.0825 18.1223 25.1424 17.4191 26.406C14.3731 31.6797 16.6452 39.4304 19.563 43.6933C21.0229 45.7807 22.729 48.1124 24.9615 48.0298C27.1462 47.9392 27.9621 46.6367 30.5991 46.6367C33.2116 46.6367 33.9771 48.0298 36.2549 47.9772C38.5991 47.9391 40.0761 45.8805 41.4847 43.7733C42.5336 42.2859 43.3408 40.6421 43.8762 38.9026C42.5143 38.3266 41.3521 37.3624 40.5345 36.1303C39.7169 34.8982 39.28 33.4526 39.2784 31.9739V31.9739Z"
          fill={fill}
        />
        <path
          d="M34.9766 19.2321C36.2548 17.6977 36.8845 15.7255 36.732 13.7344C34.7792 13.9395 32.9755 14.8728 31.68 16.3483C31.0467 17.0691 30.5616 17.9077 30.2525 18.8161C29.9434 19.7245 29.8164 20.6849 29.8787 21.6425C30.8554 21.6525 31.8217 21.4408 32.7047 21.0233C33.5878 20.6058 34.3646 19.9933 34.9766 19.2321Z"
          fill={fill}
        />
        <path
          d="M66.8953 42.7446H59.4402L57.6499 48.031H54.4922L61.5535 28.4727H64.8343L71.8956 48.031H68.6841L66.8953 42.7446ZM60.2123 40.3052H66.1217L63.2086 31.7257H63.1271L60.2123 40.3052Z"
          fill={fill}
        />
        <path
          d="M87.147 40.9015C87.147 45.3328 84.7753 48.1798 81.1961 48.1798C80.2895 48.2272 79.3878 48.0183 78.5943 47.5771C77.8009 47.1359 77.1477 46.4802 76.7096 45.685H76.6418V52.7479H73.7148V33.771H76.548V36.1427H76.6019C77.0601 35.3513 77.7244 34.699 78.5239 34.2551C79.3234 33.8112 80.2283 33.5923 81.1423 33.6218C84.7614 33.6218 87.147 36.4826 87.147 40.9015ZM84.1385 40.9015C84.1385 38.0146 82.6465 36.1166 80.3702 36.1166C78.1338 36.1166 76.6296 38.0545 76.6296 40.9015C76.6296 43.7747 78.1338 45.6988 80.3702 45.6988C82.6466 45.6988 84.1385 43.8147 84.1385 40.9015Z"
          fill={fill}
        />
        <path
          d="M102.842 40.9015C102.842 45.3328 100.471 48.1798 96.8915 48.1798C95.9848 48.2272 95.0831 48.0183 94.2896 47.5771C93.4962 47.1359 92.843 46.4801 92.4049 45.685H92.3371V52.7478H89.4102V33.771H92.2433V36.1427H92.2971C92.7554 35.3513 93.4196 34.699 94.2191 34.2551C95.0187 33.8111 95.9236 33.5923 96.8376 33.6218C100.457 33.6218 102.842 36.4826 102.842 40.9015ZM99.8338 40.9015C99.8338 38.0146 98.3419 36.1165 96.0655 36.1165C93.8291 36.1165 92.3249 38.0545 92.3249 40.9015C92.3249 43.7747 93.8291 45.6988 96.0655 45.6988C98.3419 45.6988 99.8338 43.8147 99.8338 40.9015H99.8338Z"
          fill={fill}
        />
        <path
          d="M113.215 42.5815C113.432 44.521 115.316 45.7945 117.891 45.7945C120.358 45.7945 122.133 44.521 122.133 42.7722C122.133 41.2541 121.062 40.3451 118.528 39.7221L115.993 39.1115C112.401 38.244 110.734 36.5644 110.734 33.8389C110.734 30.4644 113.675 28.1465 117.851 28.1465C121.984 28.1465 124.817 30.4644 124.912 33.8389H121.957C121.781 31.8871 120.167 30.7089 117.809 30.7089C115.451 30.7089 113.838 31.901 113.838 33.6359C113.838 35.0187 114.868 35.8323 117.389 36.4552L119.544 36.9843C123.557 37.9333 125.224 39.5452 125.224 42.406C125.224 46.0651 122.31 48.3569 117.674 48.3569C113.336 48.3569 110.408 46.119 110.219 42.5814L113.215 42.5815Z"
          fill={fill}
        />
        <path
          d="M131.538 30.3965V33.771H134.249V36.0889H131.538V43.9501C131.538 45.1713 132.081 45.7404 133.273 45.7404C133.594 45.7348 133.916 45.7122 134.235 45.6727V47.9768C133.699 48.0769 133.155 48.1222 132.61 48.1121C129.723 48.1121 128.597 47.0278 128.597 44.2623V36.0889H126.523V33.771H128.597V30.3965H131.538Z"
          fill={fill}
        />
        <path
          d="M135.82 40.9016C135.82 36.415 138.463 33.5957 142.583 33.5957C146.718 33.5957 149.348 36.415 149.348 40.9016C149.348 45.4005 146.731 48.2075 142.583 48.2075C138.437 48.2075 135.82 45.4005 135.82 40.9016ZM146.365 40.9016C146.365 37.8239 144.955 36.0074 142.583 36.0074C140.212 36.0074 138.803 37.8377 138.803 40.9016C138.803 43.9916 140.212 45.7943 142.583 45.7943C144.955 45.7943 146.365 43.9916 146.365 40.9016H146.365Z"
          fill={fill}
        />
        <path
          d="M151.762 33.7718H154.553V36.1989H154.621C154.81 35.4409 155.254 34.771 155.879 34.3018C156.503 33.8326 157.27 33.5928 158.051 33.6226C158.388 33.6214 158.725 33.6581 159.054 33.7318V36.4696C158.628 36.3395 158.184 36.2797 157.739 36.2927C157.313 36.2755 156.89 36.3504 156.496 36.5125C156.103 36.6745 155.749 36.9198 155.459 37.2315C155.169 37.5432 154.95 37.9139 154.817 38.3181C154.684 38.7224 154.641 39.1506 154.689 39.5734V48.0314H151.762L151.762 33.7718Z"
          fill={fill}
        />
        <path
          d="M172.551 43.8424C172.157 46.431 169.636 48.2075 166.41 48.2075C162.262 48.2075 159.688 45.4282 159.688 40.9693C159.688 36.4965 162.276 33.5957 166.287 33.5957C170.233 33.5957 172.714 36.3058 172.714 40.6294V41.6322H162.642V41.8091C162.596 42.3339 162.661 42.8625 162.835 43.36C163.008 43.8574 163.286 44.3123 163.649 44.6943C164.011 45.0763 164.451 45.3767 164.939 45.5757C165.427 45.7746 165.952 45.8675 166.478 45.8481C167.17 45.9129 167.864 45.7527 168.457 45.3913C169.051 45.03 169.511 44.4867 169.771 43.8424L172.551 43.8424ZM162.656 39.5865H169.785C169.811 39.1147 169.74 38.6425 169.575 38.1996C169.41 37.7567 169.155 37.3526 168.827 37.0128C168.499 36.673 168.103 36.4047 167.666 36.2248C167.229 36.0449 166.76 35.9573 166.287 35.9674C165.811 35.9646 165.338 36.0562 164.897 36.2369C164.456 36.4176 164.055 36.6839 163.718 37.0204C163.38 37.3568 163.112 37.7568 162.93 38.1973C162.748 38.6377 162.655 39.1099 162.656 39.5866V39.5865Z"
          fill={fill}
        />
        <path
          d="M59.8438 13.7509C60.4574 13.7069 61.0732 13.7996 61.6467 14.0223C62.2201 14.2451 62.737 14.5923 63.16 15.039C63.5831 15.4858 63.9017 16.0208 64.0929 16.6055C64.2841 17.1903 64.3431 17.8102 64.2658 18.4205C64.2658 21.4229 62.6431 23.1487 59.8438 23.1487H56.4492V13.7509H59.8438ZM57.9089 21.8196H59.6807C60.1192 21.8458 60.558 21.7739 60.9652 21.6092C61.3724 21.4444 61.7378 21.1909 62.0346 20.8671C62.3315 20.5434 62.5525 20.1575 62.6814 19.7376C62.8103 19.3176 62.844 18.8743 62.78 18.4397C62.8394 18.0068 62.8023 17.5662 62.6714 17.1493C62.5404 16.7325 62.3189 16.3497 62.0227 16.0286C61.7265 15.7074 61.363 15.4557 60.9581 15.2915C60.5532 15.1273 60.117 15.0547 59.6807 15.079H57.9089V21.8196Z"
          fill={fill}
        />
        <path
          d="M65.9143 19.5997C65.8697 19.1336 65.923 18.6634 66.0708 18.2191C66.2186 17.7749 66.4576 17.3664 66.7725 17.0199C67.0874 16.6735 67.4713 16.3966 67.8994 16.2072C68.3276 16.0178 68.7906 15.9199 69.2588 15.9199C69.727 15.9199 70.1901 16.0178 70.6182 16.2072C71.0464 16.3966 71.4302 16.6735 71.7451 17.0199C72.06 17.3664 72.2991 17.7749 72.4469 18.2191C72.5947 18.6634 72.648 19.1336 72.6034 19.5997C72.6488 20.0663 72.5961 20.5372 72.4487 20.9821C72.3013 21.4271 72.0625 21.8363 71.7475 22.1835C71.4325 22.5306 71.0483 22.808 70.6197 22.9979C70.1912 23.1877 69.7276 23.2858 69.2588 23.2858C68.7901 23.2858 68.3265 23.1877 67.8979 22.9979C67.4693 22.808 67.0852 22.5306 66.7702 22.1835C66.4552 21.8363 66.2163 21.4271 66.0689 20.9821C65.9215 20.5372 65.8688 20.0663 65.9143 19.5997V19.5997ZM71.1637 19.5997C71.1637 18.0624 70.4731 17.1634 69.2611 17.1634C68.0445 17.1634 67.3601 18.0624 67.3601 19.5997C67.3601 21.1494 68.0446 22.0414 69.2611 22.0414C70.4732 22.0414 71.1637 21.1432 71.1637 19.5997H71.1637Z"
          fill={fill}
        />
        <path
          d="M81.4944 23.1488H80.0425L78.5767 17.9254H78.466L77.0063 23.1488H75.5682L73.6133 16.0566H75.0329L76.3034 21.4683H76.408L77.8661 16.0566H79.2089L80.667 21.4683H80.7777L82.042 16.0566H83.4417L81.4944 23.1488Z"
          fill={fill}
        />
        <path
          d="M85.0859 16.057H86.4333V17.1836H86.5379C86.7153 16.779 87.0146 16.4398 87.394 16.2133C87.7733 15.9868 88.2139 15.8843 88.6543 15.9201C88.9994 15.8941 89.3459 15.9462 89.6681 16.0723C89.9904 16.1985 90.2801 16.3955 90.5158 16.6488C90.7516 16.9022 90.9273 17.2053 91.03 17.5357C91.1327 17.8662 91.1597 18.2155 91.1091 18.5579V23.149H89.7094V18.9094C89.7094 17.7696 89.2142 17.2028 88.179 17.2028C87.9447 17.1919 87.7109 17.2318 87.4934 17.3197C87.276 17.4077 87.0801 17.5416 86.9193 17.7123C86.7584 17.883 86.6364 18.0865 86.5615 18.3087C86.4867 18.531 86.4608 18.7669 86.4856 19.0001V23.1491H85.0859L85.0859 16.057Z"
          fill={fill}
        />
        <path
          d="M93.3398 13.2881H94.7395V23.1488H93.3398V13.2881Z"
          fill={fill}
        />
        <path
          d="M96.6838 19.5998C96.6392 19.1337 96.6925 18.6635 96.8404 18.2192C96.9882 17.7749 97.2273 17.3664 97.5422 17.02C97.8572 16.6735 98.241 16.3967 98.6692 16.2072C99.0974 16.0178 99.5605 15.9199 100.029 15.9199C100.497 15.9199 100.96 16.0178 101.388 16.2072C101.816 16.3967 102.2 16.6735 102.515 17.02C102.83 17.3664 103.069 17.7749 103.217 18.2192C103.365 18.6635 103.418 19.1337 103.374 19.5998C103.419 20.0664 103.366 20.5373 103.219 20.9823C103.071 21.4273 102.832 21.8365 102.517 22.1836C102.202 22.5308 101.818 22.8082 101.39 22.998C100.961 23.1878 100.497 23.2859 100.029 23.2859C99.5599 23.2859 99.0963 23.1878 98.6677 22.998C98.2391 22.8082 97.855 22.5308 97.5399 22.1836C97.2249 21.8365 96.986 21.4273 96.8386 20.9823C96.6911 20.5373 96.6384 20.0664 96.6838 19.5998V19.5998ZM101.933 19.5998C101.933 18.0625 101.243 17.1635 100.031 17.1635C98.814 17.1635 98.1296 18.0625 98.1296 19.5999C98.1296 21.1495 98.814 22.0416 100.031 22.0416C101.243 22.0416 101.933 21.1433 101.933 19.5998H101.933Z"
          fill={fill}
        />
        <path
          d="M104.848 21.1433C104.848 19.8666 105.798 19.1307 107.485 19.0261L109.407 18.9153V18.3032C109.407 17.5541 108.911 17.1312 107.955 17.1312C107.173 17.1312 106.632 17.418 106.476 17.9194H105.121C105.264 16.7013 106.41 15.9199 108.019 15.9199C109.797 15.9199 110.8 16.8051 110.8 18.3032V23.1489H109.453V22.1522H109.342C109.117 22.5098 108.802 22.8012 108.427 22.9968C108.053 23.1925 107.634 23.2853 107.212 23.2658C106.914 23.2967 106.613 23.265 106.328 23.1725C106.044 23.0801 105.781 22.929 105.559 22.729C105.336 22.529 105.158 22.2846 105.035 22.0115C104.913 21.7383 104.849 21.4426 104.848 21.1433V21.1433ZM109.407 20.5372V19.9443L107.675 20.0551C106.698 20.1204 106.255 20.4526 106.255 21.0779C106.255 21.7162 106.809 22.0876 107.57 22.0876C107.793 22.1102 108.019 22.0877 108.233 22.0214C108.447 21.9551 108.646 21.8464 108.817 21.7017C108.988 21.5571 109.129 21.3795 109.23 21.1794C109.331 20.9793 109.391 20.761 109.407 20.5372V20.5372Z"
          fill={fill}
        />
        <path
          d="M112.641 19.5996C112.641 17.3587 113.793 15.939 115.585 15.939C116.028 15.9186 116.468 16.0247 116.853 16.2451C117.238 16.4654 117.552 16.7908 117.759 17.1832H117.864V13.2881H119.264V23.1488H117.922V22.0282H117.812C117.589 22.418 117.263 22.7394 116.871 22.9575C116.478 23.1756 116.033 23.2822 115.585 23.2656C113.78 23.2657 112.641 21.846 112.641 19.5996ZM114.086 19.5996C114.086 21.1039 114.796 22.0091 115.981 22.0091C117.161 22.0091 117.89 21.0908 117.89 19.6058C117.89 18.1277 117.153 17.1964 115.981 17.1964C114.803 17.1964 114.086 18.1077 114.086 19.5996H114.086Z"
          fill={fill}
        />
        <path
          d="M125.055 19.5997C125.01 19.1336 125.064 18.6634 125.211 18.2191C125.359 17.7749 125.598 17.3664 125.913 17.0199C126.228 16.6735 126.612 16.3966 127.04 16.2072C127.468 16.0178 127.931 15.9199 128.399 15.9199C128.868 15.9199 129.331 16.0178 129.759 16.2072C130.187 16.3966 130.571 16.6735 130.886 17.0199C131.201 17.3664 131.44 17.7749 131.587 18.2191C131.735 18.6634 131.789 19.1336 131.744 19.5997C131.789 20.0663 131.737 20.5372 131.589 20.9821C131.442 21.4271 131.203 21.8363 130.888 22.1835C130.573 22.5306 130.189 22.808 129.76 22.9979C129.332 23.1877 128.868 23.2858 128.399 23.2858C127.931 23.2858 127.467 23.1877 127.039 22.9979C126.61 22.808 126.226 22.5306 125.911 22.1835C125.596 21.8363 125.357 21.4271 125.21 20.9821C125.062 20.5372 125.009 20.0663 125.055 19.5997V19.5997ZM130.304 19.5997C130.304 18.0624 129.614 17.1634 128.402 17.1634C127.185 17.1634 126.501 18.0624 126.501 19.5997C126.501 21.1494 127.185 22.0414 128.402 22.0414C129.614 22.0414 130.304 21.1432 130.304 19.5997Z"
          fill={fill}
        />
        <path
          d="M133.621 16.057H134.968V17.1836H135.073C135.25 16.779 135.55 16.4398 135.929 16.2133C136.308 15.9868 136.749 15.8843 137.189 15.9201C137.535 15.8941 137.881 15.9462 138.203 16.0723C138.526 16.1985 138.815 16.3955 139.051 16.6488C139.287 16.9022 139.462 17.2053 139.565 17.5357C139.668 17.8662 139.695 18.2155 139.644 18.5579V23.149H138.245V18.9094C138.245 17.7696 137.749 17.2028 136.714 17.2028C136.48 17.1919 136.246 17.2318 136.029 17.3197C135.811 17.4077 135.615 17.5416 135.454 17.7123C135.294 17.883 135.172 18.0865 135.097 18.3087C135.022 18.531 134.996 18.7669 135.021 19.0001V23.1491H133.621V16.057Z"
          fill={fill}
        />
        <path
          d="M147.554 14.291V16.089H149.091V17.268H147.554V20.9148C147.554 21.6577 147.86 21.983 148.557 21.983C148.736 21.9824 148.914 21.9716 149.091 21.9507V23.1165C148.84 23.1615 148.585 23.1854 148.33 23.1881C146.773 23.1881 146.153 22.6405 146.153 21.2731V17.2679H145.027V16.089H146.153V14.291H147.554Z"
          fill={fill}
        />
        <path
          d="M151.004 13.2881H152.391V17.1964H152.502C152.688 16.7879 152.996 16.4468 153.382 16.2195C153.769 15.9921 154.217 15.8896 154.664 15.9259C155.008 15.9072 155.351 15.9645 155.67 16.0938C155.988 16.223 156.274 16.4209 156.508 16.6734C156.741 16.926 156.916 17.2269 157.019 17.5547C157.123 17.8825 157.153 18.2291 157.107 18.5699V23.1488H155.706V18.9152C155.706 17.7824 155.178 17.2087 154.189 17.2087C153.949 17.1889 153.707 17.222 153.48 17.3056C153.254 17.3891 153.048 17.5211 152.878 17.6924C152.708 17.8637 152.578 18.07 152.496 18.297C152.414 18.5241 152.382 18.7663 152.404 19.0067V23.1487H151.004L151.004 13.2881Z"
          fill={fill}
        />
        <path
          d="M165.266 21.2341C165.076 21.8823 164.664 22.443 164.102 22.8181C163.54 23.1931 162.865 23.3588 162.193 23.2859C161.726 23.2983 161.262 23.2088 160.832 23.0237C160.403 22.8385 160.019 22.5623 159.708 22.214C159.396 21.8657 159.164 21.4537 159.027 21.0067C158.891 20.5597 158.853 20.0883 158.917 19.6253C158.855 19.1608 158.893 18.6885 159.029 18.2401C159.165 17.7917 159.396 17.3779 159.706 17.0265C160.017 16.6752 160.398 16.3946 160.826 16.2037C161.254 16.0128 161.718 15.916 162.187 15.92C164.16 15.92 165.351 17.2682 165.351 19.4953V19.9837H160.343V20.0621C160.321 20.3224 160.354 20.5843 160.439 20.8312C160.524 21.0781 160.66 21.3045 160.838 21.4957C161.016 21.687 161.231 21.8391 161.471 21.9421C161.711 22.0451 161.97 22.0968 162.232 22.0939C162.566 22.1341 162.906 22.0738 163.206 21.9207C163.506 21.7676 163.755 21.5286 163.919 21.2341L165.266 21.2341ZM160.343 18.9485H163.925C163.943 18.7105 163.91 18.4714 163.83 18.2466C163.75 18.0219 163.623 17.8164 163.459 17.6433C163.294 17.4703 163.096 17.3336 162.875 17.242C162.655 17.1503 162.418 17.1058 162.179 17.1113C161.937 17.1083 161.697 17.1537 161.473 17.245C161.248 17.3362 161.045 17.4715 160.874 17.6427C160.702 17.8139 160.567 18.0177 160.476 18.2419C160.385 18.4662 160.34 18.7065 160.343 18.9485H160.343Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2529_9882">
          <rect
            width="188.471"
            height="63"
            fill="white"
            transform="translate(0.265625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppleAppStoreIcon;
