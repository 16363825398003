import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Settings.scss";
import initMatrix from "../../../client/initMatrix";
import InviteUser from "../invite-user/InviteUser";
import Popover from "../../atoms/popover/Popover";

function InviteUserSettings({ roomId }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const canInvite = room.canInvite(mx.getUserId());
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isDM = initMatrix.roomList.directs.has(roomId);
  const isSpace = room?.getType() === "m.space";
  let spaceParentId = null;
  if (!isSpace) {
    const spaceParentEventMap = room.currentState.events.get("m.space.parent");
    spaceParentId = spaceParentEventMap
      ? Array.from(spaceParentEventMap.values())[0]?.getStateKey()
      : null;
  }

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <div className="general-settings-wrapper">
      {canInvite && !isDM && (
        <div
          className="general-settings-invite-button"
          onClick={handleOpenDialog}
        >
          <span className="general-settings-invite-button-text">Invite</span>
        </div>
      )}
      {isDialogOpen && (
        <Popover
          direction="right"
          setOpenDialog={setIsDialogOpen}
          headerText="Invite Users"
        >
          <InviteUser roomId={roomId} isDialogOpen={isDialogOpen} />
        </Popover>
      )}
    </div>
  );
}

InviteUserSettings.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default InviteUserSettings;
