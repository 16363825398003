import React from "react";
import useBounceInUpAnimation from "../../hooks/useBounceInUpAnimation";
import LightningIcon from "../../assets/svg/lightning-icon";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import "./Navigation.scss";
import { useDialog } from "../../contexts/DialogContext";

const Navigation = () => {
  const { style: landingPageStyle } = useBounceInUpAnimation(
    0,
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    2000,
    800
  );
  const { toggleDialog } = useDialog();

  return (
    <div className="landing-page-navigation" style={landingPageStyle}>
      <div className="landing-page-navigation-logo-container">
        <WaivlengthLogo
          fill="var(--color-bg-primary-flip)"
          height={26}
          width={36}
        />
        <span className="landing-page-nav-logo-text">waivlength</span>
      </div>
      <div className="landing-page-navigation-sections"></div>
      <button
        className="landing-page-navigation-signup-button-container"
        onClick={toggleDialog}
      >
        <LightningIcon
          stroke="var(--color-bg-primary)"
          strokeWidth={3}
          height={16}
          width={16}
        />
        <span className="landing-page-nav-signup-text">
          Sign up for early access
        </span>
        <span className="landing-page-nav-signup-text-shortened">Sign up</span>
      </button>
    </div>
  );
};

export default Navigation;
