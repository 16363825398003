import React, { useState, useEffect } from "react";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import { selectTab } from "../../../client/action/navigation";
import * as roomActions from "../../../client/action/room";
import "./SearchBarMobile.scss";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import { hasDMWith } from "../../../util/matrixUtil";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { formatUserId } from "../../../util/formatUserId";
import CustomInput from "../../atoms/input/CustomInput";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { Dialog } from "@capacitor/dialog";
import { Capacitor } from "@capacitor/core";

const SEARCH_LIMIT = 100;

function SearchBarMobile({ onClose }) {
  const [users, updateUsers] = useState([]);
  const [procUsers, updateProcUsers] = useState(new Set());
  const [createdDM, updateCreatedDM] = useState(new Map());
  const [roomIdToUserId, updateRoomIdToUserId] = useState(new Map());
  const [isSearching, updateIsSearching] = useState(false);
  const [publicRooms, updatePublicRooms] = useState([]);
  const [searchQuery, updateSearchQuery] = useState({});
  const [joiningRooms, updateJoiningRooms] = useState(new Set());
  const { setSpaceId, setDmRoomId } = useRoomSelect();
  const [searchTerm, setSearchTerm] = useState("");
  const mx = initMatrix.matrixClient;

  const userId = initMatrix?.matrixClient?.getUserId?.();

  function getMapCopy(myMap) {
    const newMap = new Map();
    myMap.forEach((data, key) => {
      newMap.set(key, data);
    });
    return newMap;
  }

  function deleteUserFromProc(userId) {
    procUsers.delete(userId);
    updateProcUsers(new Set(Array.from(procUsers)));
  }

  function onDMCreated(newRoomId) {
    const myDMPartnerId = roomIdToUserId.get(newRoomId);
    if (typeof myDMPartnerId === "undefined") return;

    createdDM.set(myDMPartnerId, newRoomId);
    roomIdToUserId.delete(newRoomId);

    deleteUserFromProc(myDMPartnerId);
    updateCreatedDM(getMapCopy(createdDM));
    updateRoomIdToUserId(getMapCopy(roomIdToUserId));
    if (onClose) onClose();
  }

  async function showPendingInvitationDialog() {
    const message = "You have a pending invitation from this user.";
    const title = "Pending Invitation";

    if (Capacitor.isNativePlatform()) {
      await Dialog.alert({
        title,
        message,
      });
    } else {
      window.alert(message);
    }
  }

  async function getPendingInvitation(userId) {
    const rooms = initMatrix.matrixClient.getRooms();
    for (const room of rooms) {
      const members = room.getMembers();
      let inviteExists = false;
      let leaveExistsForOtherUser = false;
      for (const member of members) {
        if (member.userId === userId && member.membership === "invite") {
          inviteExists = true;
        }
        if (member.userId !== userId && member.membership === "leave") {
          leaveExistsForOtherUser = true;
        }
      }
      if (inviteExists && !leaveExistsForOtherUser) {
        return room.roomId;
      }
    }
    return null;
  }

  async function createDM(userId) {
    const dmRoomId = hasDMWith(userId);
    const pendingInvitationRoomId = await getPendingInvitation(userId);

    if (dmRoomId) {
      selectTab(cons.tabs.DIRECTS);
      setDmRoomId(dmRoomId);
      if (onClose) onClose();
      return;
    }

    if (pendingInvitationRoomId) {
      await showPendingInvitationDialog();
      return;
    }

    try {
      const result = await roomActions.createDM(userId, false);
      roomIdToUserId.set(result.room_id, userId);
      updateRoomIdToUserId(getMapCopy(roomIdToUserId));
      selectTab(cons.tabs.DIRECTS);
      setDmRoomId(result.room_id);
      if (onClose) onClose();
    } catch (e) {
      console.error("Error creating DM:", e);
    }
  }

  useEffect(() => {
    if (initMatrix && initMatrix.roomList) {
      initMatrix.roomList.on(cons.events.roomList.ROOM_CREATED, onDMCreated);

      return () => {
        initMatrix.roomList.removeListener(
          cons.events.roomList.ROOM_CREATED,
          onDMCreated
        );
      };
    }
  }, [procUsers, createdDM, roomIdToUserId]);

  function handleOnRoomAdded(roomId) {
    if (joiningRooms.has(roomId)) {
      joiningRooms.delete(roomId);
      updateJoiningRooms(new Set(Array.from(joiningRooms)));
    }
  }

  useEffect(() => {
    if (initMatrix && initMatrix.roomList) {
      initMatrix.roomList.on(
        cons.events.roomList.ROOM_JOINED,
        handleOnRoomAdded
      );

      return () => {
        initMatrix.roomList.removeListener(
          cons.events.roomList.ROOM_JOINED,
          handleOnRoomAdded
        );
      };
    }
  }, [joiningRooms]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      resetSearch();
    } else {
      searchUser(searchTerm);
      searchRooms(searchTerm);
    }
  }, [searchTerm]);

  function handleInputChange(e) {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
  }

  function resetSearch() {
    updatePublicRooms([]);
    updateUsers([]);
    updateSearchQuery({});
  }

  async function searchUser(username) {
    const inputUsername = username.trim();
    if (
      isSearching ||
      inputUsername === "" ||
      inputUsername === searchQuery.username
    )
      return;

    const isInputUserId =
      inputUsername[0] === "@" && inputUsername.indexOf(":") > 1;
    updateIsSearching(true);
    updateSearchQuery({ username: inputUsername });

    try {
      const result = isInputUserId
        ? await mx.getProfileInfo(inputUsername)
        : await mx.searchUserDirectory({ term: inputUsername, limit: 20 });

      const users = isInputUserId
        ? [
            {
              user_id: inputUsername,
              display_name: result.displayname,
              avatar_url: result.avatar_url,
            },
          ]
        : result.results;

      updateUsers(users.length ? users : []);
      updateSearchQuery(
        users.length
          ? {}
          : { error: `No matches found for "${inputUsername}"!` }
      );
    } catch (e) {
      updateSearchQuery({ error: "Something went wrong!" });
    }
    updateIsSearching(false);
  }

  async function searchRooms(query) {
    const inputRoomName = query.trim().toLowerCase();
    const isInputAlias =
      inputRoomName[0] === "#" && inputRoomName.indexOf(":") > 1;
    const hsFromAlias = isInputAlias
      ? inputRoomName.slice(inputRoomName.indexOf(":") + 1)
      : null;
    const inputHs = hsFromAlias || userId.slice(userId.indexOf(":") + 1);

    if (
      isSearching ||
      (inputRoomName === searchQuery.name && inputHs === searchQuery.homeserver)
    )
      return;

    updateSearchQuery({ name: inputRoomName, homeserver: inputHs });
    updateIsSearching(true);

    try {
      const result = await initMatrix.matrixClient.publicRooms({
        server: inputHs,
        limit: SEARCH_LIMIT,
        filter: { generic_search_term: inputRoomName },
        include_all_networks: true,
      });
      const firstLetter = inputRoomName[0];

      const filteredRooms = result.chunk.filter((room) => {
        const roomNameLower = room.name.toLowerCase();
        return (
          (roomNameLower.startsWith(firstLetter) ||
            roomNameLower.includes(firstLetter)) &&
          room.room_type === "m.space"
        );
      });

      const sortedRooms = filteredRooms.sort((a, b) => {
        const aStartsWith = a.name.toLowerCase().startsWith(firstLetter);
        const bStartsWith = b.name.toLowerCase().startsWith(firstLetter);

        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;
        return 0;
      });

      const totalRooms = sortedRooms;
      updatePublicRooms(totalRooms);

      if (!totalRooms.length) {
        updateSearchQuery({
          error: `No result found for "${inputRoomName}" on ${inputHs}`,
          alias: isInputAlias ? inputRoomName : null,
        });
      }
    } catch (e) {
      updatePublicRooms([]);
      const err =
        e?.httpStatus >= 400 && e?.httpStatus < 500
          ? e.message
          : "Something went wrong!";
      updateSearchQuery({
        error: err,
        alias: isInputAlias ? inputRoomName : null,
      });
    }
    updateIsSearching(false);
  }

  function renderUserList() {
    const sortedUsers = [...users].sort((a, b) =>
      a.user_id.localeCompare(b.user_id)
    );
    const maxUsers = sortedUsers.slice(0, 10);
    return maxUsers.map((user) => {
      const userId = user.user_id;
      const name = formatDisplayName(user.display_name);

      return (
        <div
          className="search-bar-mobile-results-spaces"
          onClick={() => createDM(userId)}
          key={userId}
        >
          <UserAvatar size={40} userId={userId} />
          <div className="search-bar-mobile-results-spaces-inner">
            <div className="search-bar-mobile-results-spaces-end">
              <span className="search-bar-mobile-results-inner-text-name">
                {name}
              </span>
            </div>
            <span className="search-bar-mobile-results-inner-text-topic">
              {formatUserId(userId)}
            </span>
          </div>
        </div>
      );
    });
  }

  function renderRoomList(rooms) {
    const sortedRooms = [...rooms].sort(
      (a, b) => b.num_joined_members - a.num_joined_members
    );
    const maxRooms = sortedRooms.slice(0, 10);
    return maxRooms.map((room) => {
      const roomName = room.name;
      const memberCount = room.num_joined_members;
      const roomTopic = room.topic;
      const avatarUrl =
        typeof room.avatar_url === "string"
          ? initMatrix.matrixClient.mxcUrlToHttp(
              room.avatar_url,
              40,
              40,
              "crop"
            )
          : null;
      const roomId = room.room_id;

      async function handleRoomAction(roomId) {
        const mx = initMatrix.matrixClient;
        const room = mx.getRoom(roomId);
        const isJoined = room?.getMyMembership() === "join";
        const getParentRoomId = (room) => {
          if (!room) return null;
          const spaceParentEventMap =
            room.currentState.events.get("m.space.parent");
          return spaceParentEventMap
            ? Array.from(spaceParentEventMap.values())[0]?.getStateKey()
            : null;
        };

        const afterJoinViewLogic = async (
          joinedRoomId,
          parentRoomId = null
        ) => {
          const joinedRoom = mx.getRoom(joinedRoomId);
          const isSpace = joinedRoom?.getType() === "m.space";
          selectTab(cons.tabs.HOME);
          setSpaceId(joinedRoomId);
          if (onClose) onClose();
        };

        if (isJoined) {
          const parentRoomId = getParentRoomId(room);
          await afterJoinViewLogic(roomId, parentRoomId);
        } else {
          try {
            const { roomId: joinedRoomId, parentRoomId: joinParentRoomId } =
              await roomActions.join(roomId, false);
            const parentRoomId =
              joinParentRoomId || getParentRoomId(mx.getRoom(joinedRoomId));
            await afterJoinViewLogic(joinedRoomId, parentRoomId);
          } catch (error) {
            console.error("Error joining room:", error.message, error.stack);
          }
        }
      }

      return (
        <div
          className="search-bar-mobile-results-spaces"
          key={roomName}
          onClick={() => handleRoomAction(roomId)}
        >
          <div>
            <RoomAvatar
              roomId={roomId}
              imageSrc={avatarUrl}
              text={roomName}
              size={40}
              borderRadius={6}
            />
          </div>
          <div className="search-bar-mobile-results-spaces-inner">
            <div className="search-bar-mobile-results-spaces-end">
              <span className="search-bar-mobile-results-inner-text-name">
                {roomName}
              </span>
              <span className="search-bar-mobile-results-inner-text-members">
                {memberCount} {memberCount === 1 ? "Member" : "Members"}
              </span>
            </div>
            <span className="search-bar-mobile-results-inner-text-topic">
              {roomTopic}
            </span>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="search-bar-search-results-container">
      <CustomInput
        placeholder="Search spaces and users"
        value={searchTerm}
        onChange={(e) => handleInputChange(e)}
        autoFocus={[true, 300]}
      />
      <div className="search-bar-mobile-results-container">
        <div className="search-bar-mobile-results">
          <span className="search-bar-mobile-results-header">
            Public Spaces
          </span>
        </div>
        {publicRooms.length === 0 && (
          <div className="search-bar-mobile-results">
            <span className="search-bar-mobile-results-footer">
              No public rooms matched
            </span>
          </div>
        )}
        {publicRooms.length !== 0 && renderRoomList(publicRooms)}
        <div className="search-bar-mobile-results">
          <span className="search-bar-mobile-results-header">Users</span>
        </div>
        {users.length === 0 && (
          <div className="search-bar-mobile-results">
            <span className="search-bar-mobile-results-footer">
              No users matched
            </span>
          </div>
        )}
        {users.length !== 0 && renderUserList()}
      </div>
    </div>
  );
}

export default SearchBarMobile;
