import React, { useState, useEffect, useRef } from "react";
import "./ProfileViewerSettings.scss";
import initMatrix from "../../../client/initMatrix";
import cons from "../../../client/state/cons";
import { selectRoom, selectTab } from "../../../client/action/navigation";
import * as roomActions from "../../../client/action/room";
import {
  getUsername,
  getUsernameOfRoomMember,
  hasDMWith,
} from "../../../util/matrixUtil";
import PowerLevelSelector from "../../molecules/power-level-selector/PowerLevelSelector";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { ReactComponent as Verify } from "../../assets/svg/verify.svg";
import { usePowerLevelTags } from "../../hooks/usePowerLevelTags";
import { Dialog } from "@capacitor/dialog";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { Capacitor } from "@capacitor/core";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import { ReactComponent as Copy } from "../../assets/svg/fillcopy.svg";
import { ReactComponent as CopySuccess } from "../../assets/svg/fillcopysuccess.svg";
import Loader from "../../atoms/loader/Loader";
import { getUserPresenceStatus } from "../../../util/getUserPresenceStatus";

function ModerationTools({ roomId, userId, onClose }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const roomMember = room.getMember(userId);
  const myPowerLevel = room.getMember(mx.getUserId())?.powerLevel || 0;
  const powerLevel = roomMember?.powerLevel || 0;
  const canIKick =
    roomMember?.membership === "join" &&
    room.currentState.hasSufficientPowerLevelFor("kick", myPowerLevel) &&
    powerLevel < myPowerLevel;
  const canIBan =
    ["join", "leave"].includes(roomMember?.membership) &&
    room.currentState.hasSufficientPowerLevelFor("ban", myPowerLevel) &&
    powerLevel < myPowerLevel;
  const member = room.getMember(userId);
  const isBanned = member?.membership === "ban";

  const handleKick = () => {
    roomActions.kick(roomId, userId);
    onClose();
  };

  const handleBan = () => {
    roomActions.ban(roomId, userId);
    onClose();
  };

  const handleUnban = () => {
    roomActions.unban(roomId, userId);
    onClose();
  };

  return (
    (canIKick || canIBan) && (
      <div>
        {canIKick && (
          <div
            className="profile-viewer-profile-message-unban-kick"
            onClick={handleKick}
          >
            <span className="profile-viewer-profile-message-unban-kick-text">
              Kick Member
            </span>
          </div>
        )}
        {canIBan && (
          <div
            className="profile-viewer-profile-message-ban"
            onClick={handleBan}
          >
            <span className="profile-viewer-profile-message-ban-text">
              Ban Member
            </span>
          </div>
        )}
        {isBanned && (
          <div
            className="profile-viewer-profile-message-unban-kick"
            onClick={handleUnban}
          >
            <span className="profile-viewer-profile-message-unban-kick-text">
              Unban Member
            </span>
          </div>
        )}
      </div>
    )
  );
}

function useRerenderOnProfileChange(roomId, userId) {
  const mx = initMatrix.matrixClient;
  const [, forceUpdate] = useForceUpdate();
  useEffect(() => {
    const handleProfileChange = (mEvent, member) => {
      if (
        mEvent.getRoomId() === roomId &&
        (member.userId === userId || member.userId === mx.getUserId())
      ) {
        forceUpdate();
      }
    };
    mx.on("RoomMember.powerLevel", handleProfileChange);
    mx.on("RoomMember.membership", handleProfileChange);
    return () => {
      mx.removeListener("RoomMember.powerLevel", handleProfileChange);
      mx.removeListener("RoomMember.membership", handleProfileChange);
    };
  }, [roomId, userId]);
}

function ProfileViewerSettings({
  roomId,
  userId,
  onClose,
  setOpenRoomSettingsDialog,
  setNestedMembersDialog,
}) {
  useRerenderOnProfileChange(roomId, userId);
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  const getPowerLevelTag = usePowerLevelTags();
  const roomMember = room.getMember(userId);
  const username = roomMember
    ? getUsernameOfRoomMember(roomMember)
    : getUsername(userId);
  const avatarMxc =
    roomMember?.getMxcAvatarUrl?.() || mx.getUser(userId)?.avatarUrl;
  const avatarUrl =
    avatarMxc && avatarMxc !== "null"
      ? mx.mxcUrlToHttp(avatarMxc, 80, 80, "crop")
      : null;

  const currentUserId = mx.getUserId();
  const isSameUser = currentUserId === userId;
  const isDM = initMatrix.roomList.directs.has(roomId);
  const { status, timeSinceLastActive } = getUserPresenceStatus(roomMember);

  const powerLevel = roomMember?.powerLevel || 0;
  const myPowerLevel = room.getMember(mx.getUserId())?.powerLevel || 0;

  const canChangeRole =
    room.currentState.maySendEvent("m.room.power_levels", mx.getUserId()) &&
    (powerLevel < myPowerLevel || userId === mx.getUserId());

  const handleChangePowerLevel = async (newPowerLevel) => {
    if (newPowerLevel === powerLevel) return;

    const isSharedPower = newPowerLevel === myPowerLevel;
    const isDemotingMyself = userId === mx.getUserId();

    const shouldConfirm = isSharedPower || isDemotingMyself;
    const message = isSharedPower
      ? "You will not be able to undo this change as you are promoting the user to have the same power level as yourself. Are you sure?"
      : "You will not be able to undo this change as you are demoting yourself. Are you sure?";

    if (shouldConfirm) {
      if (Capacitor.isNativePlatform()) {
        const { value } = await Dialog.confirm({
          title: "Change power level",
          message,
        });

        if (value) {
          roomActions.setPowerLevel(roomId, userId, newPowerLevel);
        }
      } else {
      }
    } else {
      roomActions.setPowerLevel(roomId, userId, newPowerLevel);
    }
  };

  const tagIcons = {
    Founder: <Verify className="MembersDrawerVerifyFounder" />,
    Admin: <Verify className="PowerSelectorVerifyAdmin" />,
    Governor: <Verify className="PowerSelectorVerifyGovernor" />,
    Moderator: <Verify className="PowerSelectorVerifyModerator" />,
    TrustedMember: <Verify className="PowerSelectorVerifyTrustedMember" />,
    Default: <Verify className="PowerSelectorVerifyDefault" />,
  };

  const powerLevelTag = getPowerLevelTag(powerLevel);
  const powerLevelTagName = powerLevelTag.name;
  const tagIcon = tagIcons[powerLevelTagName];
  const [isCreatingDM, setIsCreatingDM] = useState(false);
  const isMountedRef = useRef(true);
  const { setDmRoomId, setRoomId, setSpaceId } = useRoomSelect();
  const [roomIdToUserId, updateRoomIdToUserId] = useState(new Map());

  const onCreated = (dmRoomId) => {
    setIsCreatingDM(false);
    selectRoom(dmRoomId);
  };

  useEffect(() => {
    const { roomList } = initMatrix;
    roomList.on(cons.events.roomList.ROOM_CREATED, onCreated);
    return () => {
      isMountedRef.current = false;
      roomList.removeListener(cons.events.roomList.ROOM_CREATED, onCreated);
    };
  }, []);

  const openDM = async () => {
    const dmRoomId = hasDMWith(userId);
    if (dmRoomId) {
      onClose();
      setRoomId(null);
      setSpaceId(null);
      selectTab(cons.tabs.DIRECTS);
      setDmRoomId(dmRoomId);
      setNestedMembersDialog(false);
      setOpenRoomSettingsDialog(false);
      setSpaceId(null);
      return;
    }

    try {
      setIsCreatingDM(true);
      const result = await roomActions.createDM(userId, false);
      roomIdToUserId.set(result.room_id, userId);
      updateRoomIdToUserId(new Map(roomIdToUserId));
      onClose();
      setRoomId(null);
      setSpaceId(null);
      selectTab(cons.tabs.DIRECTS);
      setDmRoomId(result.room_id);
      setIsCreatingDM(false);
    } catch (error) {
      setIsCreatingDM(false);
      console.error(
        "Failed to create DM:",
        JSON.stringify(error, Object.getOwnPropertyNames(error))
      );
    }
  };

  const [copySuccess, setCopySuccess] = useState(false);

  const formatName = (userId) => {
    return userId.split("@")[1].split(":")[0];
  };

  const handleCopy = () => {
    const formattedUserId = formatName(userId);

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(formattedUserId)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = formattedUserId;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="profile-viewer-container no-touch">
      <div className="profile-viewer-header">
        <div className="profile-viewer-profile">
          <UserAvatar size={64} userId={userId} imageSrc={avatarUrl} />
          <div className="profile-viewer-profile-username-membership">
            <span className="ProfileViewerSettingsTextColor">
              {formatDisplayName(username)}
            </span>
            <div className="profile-viewer-profile-tag-icon">{tagIcon}</div>
          </div>
          <div className="profile-viewer-profile-address" onClick={handleCopy}>
            <span className="profile-viewer-secondary-text">
              {formatDisplayName(userId)}
            </span>
            {copySuccess ? (
              <CopySuccess className="profile-viewer-profile-address-svglogo-fill" />
            ) : (
              <Copy className="profile-viewer-profile-address-svglogo-stroke" />
            )}
          </div>
          <div className="profile-viewer-profile-status-container">
            <div
              className={`profile-viewer-profile-status ${
                status === "Online"
                  ? "profile-viewer-profile-status-online"
                  : "profile-viewer-profile-status-offline"
              }`}
            >
              <span
                className={`profile-viewer-tertiary-text ${
                  status === "Online"
                    ? "profile-viewer-tertiary-text-online"
                    : "profile-viewer-tertiary-text-offline"
                }`}
              >
                {status}
              </span>
            </div>
            {timeSinceLastActive && (
              <span className="profile-viewer-last-online-text">
                {timeSinceLastActive}
              </span>
            )}
          </div>
        </div>
        {!isDM && canChangeRole && powerLevel !== 101 && (
          <div>
            <div className="profile-viewer-profile-membership-options">
              <span className="space-landing-space-data-title-text">
                Membership Role
              </span>
            </div>
            <PowerLevelSelector
              value={powerLevel}
              max={myPowerLevel}
              onSelect={(pl) => {
                handleChangePowerLevel(pl);
              }}
            />
          </div>
        )}
      </div>
      {!isDM && !isSameUser && (
        <div
          className="profile-viewer-settings-invite-button"
          onClick={openDM}
          disabled={isCreatingDM}
        >
          {isCreatingDM && (
            <Loader
              size="20px"
              dotSize="6px"
              color="var(--dark)"
              multiplier={1.6}
            />
          )}
          {!isCreatingDM && (
            <span className="general-settings-invite-button-text">
              Direct Message
            </span>
          )}
        </div>
      )}
      <ModerationTools roomId={roomId} userId={userId} onClose={onClose} />
    </div>
  );
}

export default ProfileViewerSettings;
