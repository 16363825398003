import React from "react";
import { ReactComponent as ArrowBottom } from "../../assets/svg/arrow-bottom.svg";
import "./SettingButton.scss";

const SettingButton = ({ primaryText, secondaryText, Icon, onClick }) => (
  <div
    className="wallet-connected-profile-settings-container"
    onClick={onClick}
  >
    <div className="wallet-connected-icon">
      <Icon />
    </div>
    <div className="wallet-connected-settings-text-container">
      <span className="wallet-connected-settings-primary-text">
        {primaryText}
      </span>
      <span className="wallet-connected-settings-secondary-text">
        {secondaryText}
      </span>
    </div>
    <ArrowBottom className="wallet-connected-svglogo" />
  </div>
);

export default SettingButton;
