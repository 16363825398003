import React, { useState } from "react";
import "./CreateRoom.scss";
import * as roomActions from "../../../client/action/room";
import { v4 as uuidv4 } from "uuid";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import CustomInput from "../../atoms/input/CustomInput";
import { Capacitor } from "@capacitor/core";
import Loader from "../../atoms/loader/Loader";
import initMatrix from "../../../client/initMatrix";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";

const CreateRoom = ({ setOpenDialog, parentId, isSpace }) => {
  const [joinRule, setJoinRule] = useState("public");
  const [isCreatingRoom, setIsCreatingRoom] = useState(false);
  const { setRoomId } = useRoomSelect();
  const [roomName, setRoomName] = useState("");
  const [error, setError] = useState("");
  const isWeb = Capacitor.getPlatform() === "web";
  const mx = initMatrix.matrixClient;

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!roomName.trim()) {
      setError("Room name must be filled out.");
      return;
    }
    if (roomName.length > 24) {
      return "Room name must not exceed 24 characters.";
    }
    setIsCreatingRoom(true);
    const name = roomName;
    const roomAlias = uuidv4();
    const powerLevel = 101;

    try {
      const result = await roomActions.createRoom({
        name,
        joinRule,
        alias: roomAlias,
        isEncrypted: false,
        powerLevel,
        isSpace,
        parentId,
      });
      await updateCommunityMembershipScore(mx, "create_room");
      setIsCreatingRoom(false);
      setOpenDialog(false);
      setRoomId(result.room_id);
    } catch (e) {
      console.error("Error creating room:", e.message);
      setIsCreatingRoom(false);
    }
  };

  const joinRules = ["invite", "public"];
  const joinRuleText = ["Private", "Public"];

  return (
    <div className="create-room">
      <span className="create-room-secondary-text">Room name</span>
      <CustomInput
        placeholder="Enter room name"
        value={roomName}
        onChange={(e) => {
          setRoomName(e.target.value);
          setError("");
        }}
      />
      <span className="create-room-secondary-text">Room visibility</span>
      <div
        className="create-room-button-wrapper"
        style={{ flexGrow: isWeb ? 0 : 1 }}
      >
        <div className="create-room-selector-container-wrapper">
          {joinRules.map((rule, index) => (
            <div
              key={rule}
              className={`create-room-room-type-selector${
                index === 0 ? "-1" : ""
              }`}
              onClick={() => setJoinRule(rule)}
            >
              <span className="create-room-room-type-selector-text">
                {joinRuleText[index]}
              </span>
              <div
                className={`create-room-selector-outer-circle${
                  joinRule === rule ? "" : " inactive"
                }`}
              >
                <div
                  className={`create-room-selector-inner-circle${
                    joinRule === rule ? "" : " inactive"
                  }`}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {error && <span className="create-room-error-text">{error}</span>}
      <div className="create-proposal-flex-container-button-wrapper">
        {" "}
        <div
          disabled={isCreatingRoom}
          onClick={handleSubmit}
          className="create-container-button-create"
        >
          {!isCreatingRoom && (
            <span className="create-room-button-text">Create Room</span>
          )}
          {isCreatingRoom && (
            <Loader
              size="24px"
              dotSize="6px"
              color="var(--dark)"
              multiplier={1.4}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateRoom;
