import React, { useRef, useEffect } from "react";

const DynamicFontSizeInput = ({ value, caret = true, inputChanged }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const adjustFontSize = () => {
      if (inputRef.current) {
        let fontSize = 40;
        const maxWidth = inputRef.current.parentNode.offsetWidth;
        inputRef.current.style.fontSize = `${fontSize}px`;

        while (inputRef.current.scrollWidth > maxWidth && fontSize > 10) {
          fontSize--;
          inputRef.current.style.fontSize = `${fontSize}px`;
        }
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [value]);

  return (
    <div className="swap-component-dynamic-input-container">
      {value !== undefined &&
      value !== null &&
      value !== "0" &&
      value !== "" ? (
        <>
          <span
            ref={inputRef}
            className={`swap-component-input ${inputChanged ? "active" : ""}`}
            style={{ overflow: "hidden" }}
          >
            {value}
          </span>
          {caret && <span className="caret" />}
        </>
      ) : (
        <>
          {caret && <span className="caret-left" />}
          <span
            className={`swap-component-input-placeholder ${
              inputChanged ? "active" : ""
            }`}
          >
            0
          </span>
        </>
      )}
    </div>
  );
};

export default DynamicFontSizeInput;
