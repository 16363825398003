import React, { useState } from "react";
import "./WalletSettings.scss";
import { ReactComponent as Tick } from "../../assets/svg/fillcircletick.svg";
import { ReactComponent as Copy } from "../../assets/svg/fillcopy.svg";
import { ReactComponent as CopySuccess } from "../../assets/svg/fillcopysuccess.svg";
import Loader from "../../atoms/loader/Loader";

const SecretPhrase = ({ mnemonic, setOpenDialog, saveManualButton }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCopy = () => {
    const copyToClipboard = async (text) => {
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(text);
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "fixed";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };

    copyToClipboard(mnemonic);
  };

  const saveSecretPhrase = async () => {
    setLoading(true);
    try {
      const storedWalletAddress = localStorage.getItem(
        "waivlength_wallet_address"
      );
      let walletArray =
        JSON.parse(localStorage.getItem("waivlength_wallets")) || [];
      const walletIndex = walletArray.findIndex(
        (wallet) => wallet.walletAddress === storedWalletAddress
      );
      if (walletIndex >= 0) {
        walletArray[walletIndex].isBackedUp = true;
        const updatedWalletData = JSON.stringify(walletArray);
        localStorage.setItem("waivlength_wallets", updatedWalletData);
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving secret phrase:", error);
    } finally {
      setLoading(false);
    }
  };

  const splitMnemonic = mnemonic.split(" ");

  return (
    <div className="wallet-backup-container">
      <div className="wallet-backup-wrapper">
        <div className="wallet-backup-notification">
          <Tick className="wallet-secret-phrase-svglogo-fill" />
          <span className="wallet-sp-text-primary">
            Save your Secret Phrase
          </span>
          <span className="wallet-backup-text-secondary">
            These words are the keys to your wallet. Make sure you save them in
            a secure location.
          </span>
          <div onClick={handleCopy} className="wallet-copy-container">
            {copySuccess ? (
              <CopySuccess className="wallet-copy-container-svglogo-fill" />
            ) : (
              <Copy className="wallet-copy-container-svglogo-stroke" />
            )}
            {copySuccess ? (
              <span className="wallet-sp-text-secondary">Copied</span>
            ) : (
              <span className="wallet-sp-text-secondary">
                Copy to clipboard
              </span>
            )}
          </div>
          <div className="mnemonic-grid">
            {splitMnemonic.map((word, index) => (
              <div key={index} className="mnemonic-word">
                <span className="mnemonic-word-text-primary">{index + 1}.</span>
                <span className="mnemonic-word-text-primary">{word}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {saveManualButton && (
        <div
          onClick={saveSecretPhrase}
          className="create-container-button-create"
        >
          {!loading && (
            <span className="create-room-button-text">
              I have saved my Secret Phrase
            </span>
          )}
          {loading && (
            <Loader
              size="24px"
              dotSize="6px"
              color="var(--dark)"
              multiplier={1.4}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SecretPhrase;
