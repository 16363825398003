import React, { useState, useEffect } from "react";
import "./SwapSettings.scss";
import { ReactComponent as RemoveRow } from "../../assets/svg/minus.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";

const SwapSettings = ({
  afterOptionSelect,
  initialSlippage,
  onSlippageChange,
}) => {
  const [inputSlippage, setInputSlippage] = useState(initialSlippage);
  const [errorMessage, setErrorMessage] = useState("");
  const defaultSlippage = 5.0;

  useEffect(() => {
    setInputSlippage(initialSlippage);
  }, [initialSlippage]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputSlippage(value);

    if (isNaN(value) || parseFloat(value) <= 0) {
      setErrorMessage("Enter a value larger than 0");
    } else {
      setErrorMessage("");
    }
  };

  const handleClose = () => {
    if (!isNaN(inputSlippage) && parseFloat(inputSlippage) > 0) {
      onSlippageChange(parseFloat(inputSlippage));
    }
    afterOptionSelect();
  };

  const handleAuto = () => {
    setInputSlippage(defaultSlippage);
    onSlippageChange(defaultSlippage);
    setErrorMessage("");
  };

  const incrementSlippage = () => {
    const newSlippage = parseFloat(inputSlippage) + 0.5;
    setInputSlippage(newSlippage);
    onSlippageChange(newSlippage);
    setErrorMessage("");
  };

  const decrementSlippage = () => {
    const newSlippage = parseFloat(inputSlippage) - 0.5;
    if (newSlippage > 0) {
      setInputSlippage(newSlippage);
      onSlippageChange(newSlippage);
      setErrorMessage("");
    } else {
      setErrorMessage("Enter a value larger than 0");
    }
  };

  return (
    <div className="swap-settings">
      <span className="swap-component-slippage-setting-text">
        Slippage settings
      </span>
      <span className="swap-component-slippage-setting-subtext">
        If the price changes too much, your transaction won't go through.
      </span>
      <div className="swap-settings-container">
        <div onClick={decrementSlippage} className="swap-settings-button-svg">
          <RemoveRow className="swap-settings-button-svg-icon" />
        </div>
        <div className="swap-settings-slippage-container">
          <span
            className="swap-settings-slippage-auto no-touch"
            onClick={handleAuto}
          >
            Auto
          </span>
          <input
            id="slippage"
            type="number"
            value={inputSlippage.toFixed(2)}
            onChange={handleInputChange}
            className="slippage-component-input"
          />
          <span className="swap-settings-slippage-percentage">%</span>
        </div>
        <div
          onClick={incrementSlippage}
          className="swap-settings-button-svg no-touch"
        >
          <Plus className="swap-settings-button-svg-icon" />
        </div>
      </div>
      {errorMessage && <span className="error-message">{errorMessage}</span>}

      <div onClick={handleClose} className="swap-settings-close">
        <span className="swap-settings-close-text">Close</span>
      </div>
    </div>
  );
};

export default SwapSettings;
