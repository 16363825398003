import React from "react";
import RoomView from "./RoomView";
import initMatrix from "../../../client/initMatrix";
import "./Room.scss";

function DMRoom({ dmRoomId }) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(dmRoomId);

  return (
    <div className="room">
      {mx && room && <RoomView room={room} roomId={dmRoomId} mx={mx} />}
    </div>
  );
}

export default DMRoom;
