import React, { useState } from "react";
import MediaPopover from "../../atoms/media-popover/MediaPopover";

const PostContent = ({ content, mediaElement }) => {
  const [isMediaPopoverOpen, setIsMediaPopoverOpen] = useState(false);

  const handleMediaClick = () => {
    setIsMediaPopoverOpen(true);
  };

  return (
    <div className="post-content-container">
      <span className="post-content">{content}</span>
      {mediaElement && (
        <>
          <div
            className="media-content"
            onClick={handleMediaClick}
            onContextMenu={(e) => e.preventDefault()}
          >
            {mediaElement.type === "image" && (
              <img
                src={mediaElement.src}
                alt="Image"
                width={mediaElement.info?.w}
                height={mediaElement.info?.h}
                loading="lazy"
              />
            )}
          </div>

          {isMediaPopoverOpen && (
            <MediaPopover
              isOpen={isMediaPopoverOpen}
              onClose={() => setIsMediaPopoverOpen(false)}
              body={mediaElement}
            >
              {mediaElement.type === "image" && (
                <img
                  src={mediaElement.src}
                  alt="Image in popover"
                  width="100%"
                  height="auto"
                />
              )}
            </MediaPopover>
          )}
        </>
      )}
    </div>
  );
};

export default PostContent;
