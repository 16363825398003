// SecuritySettings.jsx
import React from "react";
import PropTypes from "prop-types";
import "./Settings.scss";
import RoomHistoryVisibility from "../../molecules/room-history-visibility/RoomHistoryVisibility";

function SecuritySettings({ roomId }) {
  return (
    <div className="room-permissions-container">
      <div className="general-settings-room-options">
        <span className="space-landing-space-data-title-text">
          Message history visibility
        </span>
      </div>
      <RoomHistoryVisibility roomId={roomId} />
    </div>
  );
}

SecuritySettings.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default SecuritySettings;
