import React from "react";
import { useSwipeable } from "react-swipeable";
import { abbreviateNumber } from "../../../util/common";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import "./ChatRoom.scss";
import { formatDisplayName } from "../../../util/formatDisplayName";
import { formatTimestamp } from "../../../util/formatTimestamp";
import NotificationBadge from "../../atoms/badge/NotificationBadge";

const ChatRoom = ({ chat, onChatClick, onMoreClick }) => {
  const [isSwiped, setIsSwiped] = React.useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsSwiped(true),
    onSwipedRight: () => setIsSwiped(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleOptionClick = (option) => {
    if (option === "more") {
      onMoreClick(chat.id);
    } else if (option === "read") {
      // Handle read/unread toggle
    }
    setIsSwiped(false);
  };

  return (
    <div className={`chat-room ${isSwiped ? "swiped" : ""}`} {...handlers}>
      <div className="chat-content" onClick={() => onChatClick(chat.id)}>
        <div>
          {chat.isDM ? (
            <UserAvatar
              size={48}
              userId={chat.user?.userId}
              imageSrc={chat.avatar}
            />
          ) : (
            <RoomAvatar
              roomId={chat.id}
              imageSrc={chat.avatar}
              text={chat.name}
              size={48}
              borderRadius={99}
            />
          )}
        </div>
        <div className="chat-info">
          <div className="chat-info-header">
            <span className="chat-name">
              {formatDisplayName(chat.displayName)}
            </span>
            <span
              className={`chat-timestamp ${
                chat.unreadCount > 0 ? "unread" : ""
              }`}
            >
              {chat.latestMessage?.timestamp
                ? formatTimestamp(chat.latestMessage.timestamp)
                : ""}
            </span>
          </div>
          <div className="chat-info-header">
            <div className="chat-message-container">
              <span className="chat-message-text">
                {chat.latestMessage?.eventType === "m.room.message" && (
                  <span className="chat-message-sender">
                    {formatDisplayName(chat.latestMessage?.sender)}:
                  </span>
                )}
                <span className="chat-message-content">
                  {chat.latestMessage?.content}
                </span>
              </span>
            </div>
            <div>
              {chat.unreadCount > 0 && (
                <NotificationBadge
                  content={
                    chat.unreadCount !== 0
                      ? abbreviateNumber(chat.unreadCount)
                      : null
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="chat-options">
        <div
          onClick={() => handleOptionClick("more")}
          className="chat-options-container"
        >
          <span className="chat-more-text">More</span>
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
