import { useEffect, useState } from "react";

const useBounceInUpAnimation = (
  initialOpacity,
  initialTransform,
  midTransform,
  endTransform,
  delay,
  transitionDuration
) => {
  const [hasAnimated, setHasAnimated] = useState(() => {
    // Check if the animation has already been played
    return sessionStorage.getItem("hasAnimated") === "true";
  });

  const [style, setStyle] = useState({
    opacity: hasAnimated ? 1 : initialOpacity,
    transform: hasAnimated ? endTransform : initialTransform,
    transition: "none",
  });

  useEffect(() => {
    if (!hasAnimated) {
      const halfTransition = transitionDuration / 2;
      const timeout1 = setTimeout(() => {
        setStyle((s) => ({
          ...s,
          opacity: 1,
          transform: midTransform,
          transition: `opacity ${halfTransition}ms ease-in-out, transform ${halfTransition}ms ease-in-out`,
        }));
      }, delay);

      const timeout2 = setTimeout(() => {
        setStyle((s) => ({
          ...s,
          transform: endTransform,
          transition: `transform ${halfTransition}ms ease-out`,
        }));
        sessionStorage.setItem("hasAnimated", "true");
        setHasAnimated(true);
      }, delay + halfTransition);

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      };
    }
  }, [hasAnimated, delay, transitionDuration, midTransform, endTransform]);

  return { style };
};

export default useBounceInUpAnimation;
