// src/utils/MediaUtils.js

import initMatrix from "../client/initMatrix";
import { Camera, CameraResultType } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";

/**
 * Converts a base64 string to a Blob.
 * @param {string} b64Data - The base64 data.
 * @param {string} contentType - The MIME type of the data.
 * @returns {Blob} - The resulting Blob.
 */
const b64toBlob = (b64Data, contentType = "") => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

/**
 * Checks and requests necessary permissions.
 * @returns {Promise<boolean>} - Returns true if permissions are granted.
 */
const checkPermissionsAndRequestIfNecessary = async () => {
  const permissions = await Camera.checkPermissions();

  if (permissions.photos !== "granted") {
    const requestResult = await Camera.requestPermissions({
      permissions: ["photos"],
    });

    if (requestResult.photos !== "granted") {
      return false;
    }
  }

  return true;
};

/**
 * Prompts the user to open app settings to grant permissions.
 */
const promptToOpenSettings = async () => {
  const confirmed = window.confirm(
    "This app requires access to your photo library. Please enable the permissions in settings."
  );

  if (confirmed) {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  }
};

/**
 * Picks an image from the camera roll using Capacitor plugins.
 * @returns {Promise<File|null>} - Returns the selected image as a File object.
 */
export const pickMedia = async () => {
  try {
    const hasPermissions = await checkPermissionsAndRequestIfNecessary();

    if (!hasPermissions) {
      await promptToOpenSettings();
      return null;
    }

    // Use Camera plugin to pick images
    const photos = await Camera.pickImages({
      quality: 90,
      resultType: CameraResultType.Uri,
      limit: 1,
    });

    if (photos && photos.photos.length > 0) {
      const photo = photos.photos[0];

      // Read the file from the device
      const readFile = await Filesystem.readFile({
        path: photo.path,
      });

      // Convert base64 data to blob
      const blob = b64toBlob(readFile.data, `image/${photo.format}`);

      // Create a File object (name is necessary)
      const fileName = photo.path.split("/").pop();
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    }
  } catch (error) {
    console.error("Error picking image:", error);
  }

  return null;
};

/**
 * Uploads media to the Matrix content repository.
 * @param {File} file - The media file to upload.
 * @returns {Promise<string>} - Returns the MXC URL of the uploaded media.
 */
export const uploadMedia = async (file) => {
  const matrixClient = initMatrix.matrixClient;
  try {
    const uploadResult = await matrixClient.uploadContent(file, {
      onlyContentUri: false,
      type: file.type,
    });
    const mediaUrl = uploadResult.content_uri;
    return mediaUrl;
  } catch (error) {
    console.error("Failed to upload media:", error);
    return null;
  }
};

/**
 * Retrieves media information such as dimensions.
 * @param {File} file - The media file.
 * @returns {Promise<object>} - Returns an object containing media info.
 */
export const getMediaInfo = async (file) => {
  const info = {
    mimetype: file.type,
    size: file.size,
  };

  const img = new Image();
  img.src = URL.createObjectURL(file);
  await new Promise((resolve, reject) => {
    img.onload = () => {
      info.w = img.width;
      info.h = img.height;
      resolve();
    };
    img.onerror = (error) => {
      console.error("Error loading image:", error);
      reject(error);
    };
  });

  return info;
};

/**
 * Prepares the message content for sending, including text and media.
 * @param {string} body - The text content of the message.
 * @param {string|null} mediaUrl - The MXC URL of the media.
 * @param {object|null} mediaInfo - The media information object.
 * @param {string|null} mediaType - The MIME type of the media.
 * @param {string|null} fileName - The name of the media file.
 * @returns {object} - Returns the message content object.
 */
export const prepareMessageContent = (
  body,
  mediaUrl,
  mediaInfo,
  mediaType,
  fileName
) => {
  let content = {
    msgtype: "m.text",
    body: body || fileName || "Image",
  };

  if (mediaUrl) {
    content.msgtype = "m.image";
    content.url = mediaUrl;
    content.info = mediaInfo;

    if (body) {
      content.body = body;
    } else {
      content.body = fileName || "Image";
    }
  }

  return content;
};

/**
 * Renders media elements based on the message event.
 * @param {MatrixEvent} event - The Matrix event containing the message.
 * @param {MatrixClient} matrixClient - The Matrix client instance.
 * @returns {object|null} - Returns an object describing the media element or null.
 */
export const renderMedia = (event, matrixClient) => {
  const content = event.getContent();
  const msgtype = content.msgtype;
  const mediaUrl = content.url;
  const info = content.info;
  if (!mediaUrl) return null;

  const httpUrl = matrixClient.mxcUrlToHttp(mediaUrl);
  const mediaElement = {};

  if (msgtype === "m.image") {
    mediaElement.type = "image";
    mediaElement.src = httpUrl;
    mediaElement.info = info;
  } else {
    mediaElement.type = "unknown";
  }

  return mediaElement;
};
