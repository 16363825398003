import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./Modal.scss";
import useKeyboard from "../../hooks/useKeyboard";
import LoadingComponent from "../loading/Loading";
import { Capacitor } from "@capacitor/core";

const Modal = ({
  isOpen,
  children,
  onClose,
  shouldResize = false,
  shouldScroll = false,
  zIndex = 1000,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const startY = useRef(null);
  const currentY = useRef(0);
  const modalContentRef = useRef(null);
  const backgroundRef = useRef(null);
  const { keyboardVisible, keyboardHeight } = useKeyboard();

  useEffect(() => {
    if (modalContentRef.current) {
      const platform = Capacitor.getPlatform();

      if (keyboardVisible && shouldResize) {
        if (platform === "ios") {
          modalContentRef.current.style.height = `calc(100% - ${keyboardHeight}px + env(safe-area-inset-bottom) + 92px)`;
        } else if (platform === "android") {
          modalContentRef.current.style.height = `calc(100%)`;
        }
      }
    }
  }, [keyboardVisible, keyboardHeight]);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsActive(true), 10);
    } else {
      setIsActive(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleTouchStart = (e) => {
    if (shouldScroll) {
      const childScrollContainer = modalContentRef.current.querySelector(
        ".scrollable-list-container"
      );
      const isChildScrollable =
        childScrollContainer.scrollHeight > childScrollContainer.clientHeight;
      const isAtTop = childScrollContainer.scrollTop <= 0;
      startY.current = e.touches[0].clientY;
      currentY.current = startY.current;
      if (isChildScrollable && !isAtTop) {
        return;
      }
      modalContentRef.current.style.transition = "none";
      if (backgroundRef.current) {
        backgroundRef.current.style.transition = "none";
      }
    } else {
      startY.current = e.touches[0].clientY;
      currentY.current = startY.current;
      modalContentRef.current.style.transition = "none";
      if (backgroundRef.current) {
        backgroundRef.current.style.transition = "none";
      }
    }
  };

  const handleTouchMove = (e) => {
    if (!startY.current) return;

    if (shouldScroll) {
      const childScrollContainer = modalContentRef.current.querySelector(
        ".scrollable-list-container"
      );
      const isChildScrollable =
        childScrollContainer.scrollHeight > childScrollContainer.clientHeight;
      const isAtTop = childScrollContainer.scrollTop <= 0;
      currentY.current = e.touches[0].clientY;
      const deltaY = currentY.current - startY.current;
      if (isChildScrollable && !isAtTop) {
        return;
      }
      if (deltaY > 0) {
        const opacity = 1 - deltaY / (window.innerHeight / 2);
        modalContentRef.current.style.transform = `translateY(${deltaY}px)`;
        if (backgroundRef.current) {
          backgroundRef.current.style.opacity = Math.max(opacity, 0);
        }
      }
    } else {
      currentY.current = e.touches[0].clientY;
      const deltaY = currentY.current - startY.current;
      if (deltaY > 0) {
        const opacity = 1 - deltaY / (window.innerHeight / 2);
        modalContentRef.current.style.transform = `translateY(${deltaY}px)`;
        if (backgroundRef.current) {
          backgroundRef.current.style.opacity = Math.max(opacity, 0);
        }
      }
    }
  };

  const handleTouchEnd = () => {
    if (!startY.current) return;

    const deltaY = currentY.current - startY.current;

    if (shouldScroll) {
      const childScrollContainer = modalContentRef.current.querySelector(
        ".scrollable-list-container"
      );
      const isAtTop = childScrollContainer.scrollTop <= 0;
      modalContentRef.current.style.transition =
        "transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)";
      if (backgroundRef.current) {
        backgroundRef.current.style.transition =
          "opacity 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)";
      }
      if (deltaY > 120 && isAtTop) {
        modalContentRef.current.style.transform = "translateY(100%)";
        if (backgroundRef.current) {
          backgroundRef.current.style.opacity = "0";
        }
        setTimeout(onClose, 300);
      } else {
        modalContentRef.current.style.transform = "translateY(0)";
        if (backgroundRef.current) {
          backgroundRef.current.style.opacity = "1";
        }
      }
    } else {
      modalContentRef.current.style.transition =
        "transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)";
      if (backgroundRef.current) {
        backgroundRef.current.style.transition =
          "opacity 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)";
      }
      if (deltaY > window.innerHeight / 4 || deltaY > 50) {
        modalContentRef.current.style.transform = "translateY(100%)";
        if (backgroundRef.current) {
          backgroundRef.current.style.opacity = "0";
        }
        setTimeout(onClose, 300);
      } else {
        modalContentRef.current.style.transform = "translateY(0)";
        if (backgroundRef.current) {
          backgroundRef.current.style.opacity = "1";
        }
      }
    }

    startY.current = null;
    currentY.current = 0;
  };

  if (!isVisible && !isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div
        className={`custom-modal-background-container ${
          isActive ? "fade-in" : "fade-out"
        }`}
        ref={backgroundRef}
        style={{ zIndex }}
      ></div>
      <div className="modal-overlay" onClick={onClose} style={{ zIndex }}>
        <div
          className={`custom-modal-container ${
            isActive ? "slide-up" : "slide-down"
          }`}
          onClick={(e) => e.stopPropagation()}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          ref={modalContentRef}
          style={{ zIndex: zIndex + 1 }} // Ensure modal content is above overlay
        >
          <div className="custom-modal-divider">
            <div className="custom-modal-divider-component"></div>
          </div>
          {children}
        </div>
      </div>
      <LoadingComponent />
    </>,
    document.getElementById("custom-modal-root")
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  shouldResize: PropTypes.bool,
  shouldScroll: PropTypes.bool,
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  shouldResize: false,
  shouldScroll: false,
  zIndex: 1000,
};

export default Modal;
