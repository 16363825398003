import RoomState from "./RoomState";

class RoomsState {
  constructor(matrixClient) {
    this.matrixClient = matrixClient;
    this.directMessages = new Set();
    this.rooms = new Set();
    this.spaces = new Set();
    this.invitedDirectMessages = new Set();
    this.invitedRooms = new Set();
    this.invitedSpaces = new Set();
    this.spaceChildrenMap = new Map();
    this.allRooms = new Set();
    this.roomStates = new Map();
  }

  async initRoomsState() {
    try {
      const rooms = await this.fetchUserRooms();
      if (!rooms || rooms.length === 0) {
        return;
      }
      await Promise.all(rooms.map((room) => this.categorizeRoom(room)));
      await this.initializeRoomStates(rooms);
    } catch (error) {
      console.error("Error initializing RoomsState:", error);
    }
  }

  fetchUserRooms() {
    return this.matrixClient.getRooms();
  }

  async categorizeRoom(room) {
    const roomId = room.roomId;
    const membership = room.getMyMembership();
    const isDM = this.isDirectMessage(roomId);
    const isSpace = room.isSpaceRoom();

    this.allRooms.add(roomId);

    if (membership === "join") {
      const belongsToSpace = await this.isRoomBelongingToSpace(room);
      if (belongsToSpace) return;

      if (isDM) {
        this.directMessages.add(roomId);
      } else if (isSpace) {
        this.spaces.add(roomId);
        await this.mapChildRooms(room);
      } else {
        this.rooms.add(roomId);
      }
    } else if (membership === "invite") {
      if (isDM) {
        this.invitedDirectMessages.add(roomId);
      } else if (isSpace) {
        this.invitedSpaces.add(roomId);
      } else {
        this.invitedRooms.add(roomId);
      }
    }

    this.ensureExclusiveCategorization(roomId);
  }

  async initializeRoomStates(rooms) {
    await Promise.all(
      rooms.map(async (room) => {
        const roomState = new RoomState(this.matrixClient, room);
        await roomState.initialize();
        this.roomStates.set(room.roomId, roomState);
      })
    );
  }

  async initializeNewRoomState(room) {
    try {
      const roomId = room.roomId;
      if (this.roomStates.has(roomId)) {
        return this.roomStates.get(roomId);
      }
      await this.categorizeRoom(room);
      const roomState = new RoomState(this.matrixClient, room);
      await roomState.initialize();
      this.roomStates.set(roomId, roomState);
      return roomState;
    } catch (error) {
      console.error(
        `Error initializing state for new room ${room.roomId}:`,
        error
      );
      throw error;
    }
  }

  ensureExclusiveCategorization(roomId) {
    const isInSpaceChildren = Array.from(this.spaceChildrenMap.values()).some(
      (set) => set.has(roomId)
    );
    if (isInSpaceChildren && this.rooms.has(roomId)) {
      this.rooms.delete(roomId);
    }
  }

  isDirectMessage(roomId) {
    const mDirect = this.matrixClient.getAccountData("m.direct")?.getContent();
    return Object.values(mDirect || {}).some((dmRoomIds) =>
      dmRoomIds.includes(roomId)
    );
  }

  async mapChildRooms(spaceRoom) {
    const spaceId = spaceRoom.roomId;
    const childRoomIds = await this.getSpaceChildRoomIds(spaceRoom);
    this.spaceChildrenMap.set(spaceId, new Set(childRoomIds || []));
    childRoomIds.forEach((childRoomId) => this.allRooms.add(childRoomId));
  }

  async getSpaceChildRoomIds(spaceRoom) {
    try {
      const spaceChildEvents =
        spaceRoom.currentState.getStateEvents("m.space.child");
      return Array.from(spaceChildEvents.values()).map((event) =>
        event.getStateKey()
      );
    } catch (error) {
      console.error(
        `Error fetching child rooms for space ${spaceRoom.roomId}:`,
        error
      );
      return [];
    }
  }

  async isRoomBelongingToSpace(room) {
    try {
      const parentSpaceEvents =
        room.currentState.getStateEvents("m.space.parent");
      return Array.isArray(parentSpaceEvents) && parentSpaceEvents.length > 0;
    } catch (error) {
      console.error(
        `Error checking parent space event for room ${room.roomId}:`,
        error
      );
      return false;
    }
  }

  getRoomState(roomId) {
    return this.roomStates.get(roomId);
  }
}

export default RoomsState;
