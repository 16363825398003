import React from "react";
import { MobileProvider } from "./MobileContext.jsx";
import { RoomSelectProvider } from "./RoomSelectContext.jsx";
import { Web3ModalProvider } from "./Web3ModalContext.jsx";
import { LoadingProvider } from "./LoadingContext.jsx";
import { DialogProvider } from "./DialogContext.jsx";
import { AppConnectionProvider } from "./AppConnectionContext.jsx";
import { EthersProvider } from "./EthersContext.jsx";
import { MessagingProvider } from "./MessagingProviderContext.jsx";
import { MessagingOptionsProvider } from "./MessagingOptionsContext.jsx";

export const AppContextProvider = ({ children }) => {
  return (
    <Web3ModalProvider>
      <DialogProvider>
        <AppConnectionProvider>
          <RoomSelectProvider>
            <LoadingProvider>
              <EthersProvider>
                <MessagingProvider>
                  <MessagingOptionsProvider>
                    <MobileProvider>{children}</MobileProvider>
                  </MessagingOptionsProvider>
                </MessagingProvider>
              </EthersProvider>
            </LoadingProvider>
          </RoomSelectProvider>
        </AppConnectionProvider>
      </DialogProvider>
    </Web3ModalProvider>
  );
};
