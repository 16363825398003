import React, { useEffect } from "react";
import { SplashScreen } from "@capacitor/splash-screen";
import { Capacitor } from "@capacitor/core";
import ConnectedContent from "./ConnectedContent";
import "./Appbody.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "../privacy-policy/PrivacyPolicy";
import TermsOfUse from "../terms-of-use/TermsOfUse";
import MobileLanding from "../mobile-landing/MobileLanding";
import Landing from "../landing-page/Landing";
import LoadingDisplay from "../loading-display/LoadingDisplay";
import SignUpDialog from "../../components/sign-up-dialog/SignUpDialog";
import { useAppConnection } from "../../contexts/AppConnectionContext";
import AccountDeletionUnconnected from "../account-deletion/AccountDeletionUnconnected";
import initMatrix from "../../../client/initMatrix";
import { App } from "@capacitor/app";

const AppBodyDisplay = ({ isAppLoading, renderContent }) => {
  return (
    <>
      {isAppLoading ? <LoadingDisplay /> : renderContent()} <SignUpDialog />
    </>
  );
};

const AppBody = () => {
  const { isAppLoading, isAppConnected } = useAppConnection();
  const isWeb = Capacitor.getPlatform() === "web";
  const mx = initMatrix.matrixClient;

  useEffect(() => {
    if (!isWeb && !isAppLoading) {
      SplashScreen.hide();
    }
  }, [isWeb, isAppLoading]);

  useEffect(() => {
    const handleAppStateChange = async (state) => {
      if (state.isActive && !isAppLoading) {
        await initMatrix.reconnect();
      }
    };

    App.addListener("appStateChange", handleAppStateChange);

    return () => {
      App.removeListener("appStateChange", handleAppStateChange);
    };
  }, []);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(window.navigator.userAgent);
  };

  const connectedContent = () => (
    <ConnectedContent isAppConnected={isAppConnected} mx={mx} />
  );

  const disconnectedContentWeb = () =>
    isMobileDevice() ? <MobileLanding /> : <Landing />;

  const disconnectedContentMobile = () => <MobileLanding />;

  const renderContent = () => {
    if (isAppConnected) {
      return connectedContent();
    } else {
      return isWeb ? disconnectedContentWeb() : disconnectedContentMobile();
    }
  };

  return (
    <Router>
      <div className="appbody-container-wrapper">
        <Routes>
          <Route
            path="/"
            element={
              <AppBodyDisplay
                isAppLoading={isAppLoading}
                renderContent={renderContent}
              />
            }
          />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/account-deactivation"
            element={<AccountDeletionUnconnected />}
          />
          <Route path="/legal/terms-of-service" element={<TermsOfUse />} />
        </Routes>
      </div>
    </Router>
  );
};

export default AppBody;
