import React, { useState, useRef, useEffect } from "react";
import "./CreatePost.scss";
import useKeyboard from "../../hooks/useKeyboard";
import UserAvatar from "../../atoms/avatar/UserAvatar";
import { uploadMedia, getMediaInfo, pickMedia } from "../../../util/MediaUtils";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import Loader from "../../atoms/loader/Loader";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";
import { Capacitor } from "@capacitor/core";

const CreatePost = ({ user, avatarUrl, onPost, onCancel, onClose, mx }) => {
  const [postContent, setPostContent] = useState("");
  const [postLoading, setPostLoading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const textareaRef = useRef(null);
  const { keyboardHeight } = useKeyboard();
  const MAX_CHAR_LIMIT = 220;
  const remainingChars = MAX_CHAR_LIMIT - postContent.length;
  const getViewportHeight = () => window.innerHeight;
  const platform = Capacitor.getPlatform();
  console.log(platform);
  const newContainerHeight = `calc(${getViewportHeight()}px * 0.9 - ${keyboardHeight}px - env(safe-area-inset-bottom) - 41px)`;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  const handleMediaSelection = async () => {
    const file = await pickMedia();

    if (file) {
      setSelectedMedia(file);
    } else {
      console.error("No media selected or an error occurred.");
    }
  };

  const handleRemoveMedia = () => {
    setSelectedMedia(null);
  };

  const handlePost = async () => {
    setPostLoading(true);
    let mediaUrl = null;
    let mediaInfo = null;

    if (selectedMedia) {
      mediaUrl = await uploadMedia(selectedMedia);
      mediaInfo = await getMediaInfo(selectedMedia);
    }

    if (postContent.trim() || mediaUrl) {
      await onPost(
        postContent,
        mediaUrl,
        mediaInfo,
        selectedMedia ? selectedMedia.type : null,
        selectedMedia ? selectedMedia.name : null
      );
      await updateCommunityMembershipScore(mx, "post");
      setPostContent("");
      setSelectedMedia(null);
      setPostLoading(false);
      onClose();
    }
  };

  let bottomValue;

  if (keyboardHeight === 0) {
    if (platform === "ios") {
      bottomValue = "env(safe-area-inset-bottom)"; // iOS with keyboard hidden
    } else {
      bottomValue = "0px"; // Other platforms with keyboard hidden
    }
  } else {
    if (platform === "ios") {
      bottomValue = `${keyboardHeight}px`; // iOS with keyboard visible
    } else {
      bottomValue = "0px"; // Other platforms with keyboard visible
    }
  }

  // Define the mediaSelectionStyle with the conditional bottom value
  const mediaSelectionStyle = {
    position: "absolute",
    bottom: bottomValue,
    left: 0,
    right: 0,
  };

  const isActive = (postContent.trim() || selectedMedia) && remainingChars >= 0;
  const isClickable = isActive && !postLoading;
  const handleClick = () => {
    if (isClickable) {
      handlePost();
    }
  };

  return (
    <div
      className="create-post-container"
      style={{ height: newContainerHeight }}
    >
      <div className="create-post-header">
        <div className="create-post-cancel" onClick={onCancel}>
          <span className="create-post-cancel-text">Cancel</span>
        </div>
        <div
          className={`create-post-post ${isActive ? "active" : "inactive"}`}
          onClick={handleClick}
          aria-disabled={!isClickable}
          style={{ cursor: isClickable ? "pointer" : "not-allowed" }}
        >
          {postLoading ? (
            <Loader
              size="16px"
              dotSize="5px"
              color="var(--light)"
              multiplier={1.6}
            />
          ) : (
            <span
              className={`create-post-post-text ${
                isActive ? "active" : "inactive"
              }`}
            >
              Post
            </span>
          )}
        </div>
      </div>
      <div className="create-post-body">
        <div className="avatar-and-textarea">
          <UserAvatar size={32} userId={user.userId} imageSrc={avatarUrl} />
          <textarea
            ref={textareaRef}
            className="post-input"
            placeholder="Let's hear it?"
            value={postContent}
            onChange={(e) => setPostContent(e.target.value)}
          ></textarea>
        </div>
        <div className="media-preview-wrapper">
          {selectedMedia && (
            <div className="media-preview-container">
              <div className="media-preview">
                {selectedMedia.type.startsWith("image/") && (
                  <img
                    src={URL.createObjectURL(selectedMedia)}
                    alt="Selected media"
                  />
                )}
              </div>
              <div className="remove-media-button" onClick={handleRemoveMedia}>
                <Plus className="remove-media-button-icon" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="media-selection" style={mediaSelectionStyle}>
        {remainingChars < 11 && (
          <div className="remaining-characters-limit-container">
            <span
              className={`remaining-characters-limit-text ${
                remainingChars < 0 ? "negative-chars" : ""
              }`}
            >
              {remainingChars}
            </span>
          </div>
        )}
        <div
          className="editor-container-file-upload"
          onClick={handleMediaSelection}
        >
          <Plus className="editor-container-file-upload-icon" />
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
