/**
 * Format display name if it matches the pattern @XXX:homeserver or an Ethereum address.
 * If the display name starts with "Empty Room", extract and return the original name.
 * If the display name contains a Matrix user struct within parentheses, remove it.
 * @param {string} displayName - The display name to format.
 * @returns {string} - The formatted display name.
 */
export function formatDisplayName(displayName) {
  const emptyRoomPattern = /^Empty room \(was ([^\)]+)\)$/i;
  const roomPattern = /^@([^:]+):/;
  const ethAddressPattern = /^0x[a-fA-F0-9]{40}$/;
  const matrixUserPattern = /\s*\(@[^:]+:[^\)]+\)/; // Pattern to match the Matrix user struct

  // Check if the display name starts with "Empty Room (was ...)"
  let match = displayName.match(emptyRoomPattern);
  if (match) {
    return match[1];
  }

  // Check if the display name contains a Matrix user struct within parentheses
  if (matrixUserPattern.test(displayName)) {
    displayName = displayName.replace(matrixUserPattern, "").trim();
  }

  // Check if the display name matches the pattern @XXX:homeserver
  match = displayName.match(roomPattern);
  if (match) {
    const part = match[1].toUpperCase();
    if (part.length > 10) {
      return `${part.slice(0, 6)}...${part.slice(-4)}`;
    }
    return part; // If XXX part is less than or equal to 10 characters, return it as is.
  }

  // Check if the display name is an Ethereum address
  match = displayName.match(ethAddressPattern);
  if (match) {
    return `${displayName.slice(0, 6).toUpperCase()}...${displayName
      .slice(-4)
      .toUpperCase()}`;
  }

  return displayName; // Return the original display name if it doesn't match any pattern.
}
