export const validateAndExecute = async ({
  input,
  inputType, // Add this parameter to determine the type of input
  additionalInput,
  acceptTermsAndPrivacy = false,
  validateInput,
  action,
  onSuccess,
  onError,
  onValidationFailure,
  resetInput,
  validationMessageDuration = 2000,
}) => {
  if (!validateInput.isValid(input)) {
    onValidationFailure(validateInput.failureMessage);
    setTimeout(() => onValidationFailure(""), validationMessageDuration);
    return;
  }
  try {
    await action(input, additionalInput, acceptTermsAndPrivacy);
    const successMessage =
      inputType === "email" ? "Email added!" : "Address added!";
    onSuccess(successMessage);
    resetInput();
    setTimeout(() => onSuccess(""), validationMessageDuration);
  } catch (error) {
    onError(error.message);
    setTimeout(() => onError(""), validationMessageDuration);
  }
};
