import React from "react";
import "./Voters.scss";
import VoteEntry from "./VoteEntry";

const Voters = ({ governanceData, mx, tokenSymbol }) => {
  return (
    <div className="scrollable-list-container">
      {governanceData.votes.map((vote, index) => (
        <VoteEntry
          key={index}
          userId={vote.userId}
          selectedVoteOption={vote.selectedVoteOption}
          votingPower={vote.votingPower}
          timestamp={vote.timestamp}
          mx={mx}
          proposalCreator={governanceData.proposalCreator}
          selectedStrategyOption={governanceData.selectedStrategyOption}
          tokenSymbol={tokenSymbol}
        />
      ))}
    </div>
  );
};

export default Voters;
