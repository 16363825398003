import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [contextLoading, setContextLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [details, setDetails] = useState("");

  const showLoading = (msgArray, detail = "") => {
    setMessages(msgArray);
    setDetails(detail);
    setContextLoading(true);
  };

  const hideLoading = () => {
    setContextLoading(false);
    setMessages([]);
    setDetails("");
  };

  return (
    <LoadingContext.Provider
      value={{ contextLoading, messages, details, showLoading, hideLoading }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
